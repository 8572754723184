


import { Container, Row, Col, Form, Card } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import _ from 'lodash'
import axios from 'axios';
import { useForm } from "react-hook-form";


let AdminDashboard = () => {

    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;

    const history = useHistory();

    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")

        if (user_id == 'undefined' && isadmin == 'undefined') {
            history.push('/userlogin');

        }
        else if ((user_id != 'undefined' && isadmin == 'false') || isadmin != 'true') {
            history.push('/userlogin');
        }

        else {
            getPayerList();
            var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

            if (elements.length) {
                elements[0].id = 'dt_dropdown';
            }

            //   console.log(user_id,"user_id")

        }


    }, []);

    const columns = [
        {
            dataField: "remove",
            text: "Payer Name",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/ViewOrganization/${row.payer_id}`}>
                        {row.name}{() => getIdPayerList(row.payer_id)}
                    </Link>
                );
            },
        },
        //  { dataField: 'name', text: 'Payer Name' },
        { dataField: 'email', text: 'Payer Email' },
        { dataField: 'type', text: 'Payer Type' },
        // { dataField: 'is_approve', text: 'Status' },
        {
            dataField: "is_approve",
            text: "Status",
            formatter: (cellContent, row,) => {
                if (row.is_approve === 1) {
                    return (
                        <button disabled={true}
                            className="btn btn-success btn-xs" Width="95px"
                            style={{ margin: "2px" }}

                        >
                            Approved
                        </button>
                    );
                }
                if (row.is_approve === -1) {
                    return (
                        <button disabled={true}
                            className="btn btn-danger btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                        >
                            Reject
                        </button>
                    );
                }
                if (row.is_approve === 0) {
                    return (
                        <button disabled={true}
                            className="btn btn-warning btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                        >
                            Pending
                        </button>
                    );
                }

            },
        },

    ]


    function getIdPayerList(value) {
        fetch(process.env.REACT_APP_BASE_URL + `/api/organization/getOrganisationByViewId/${value}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp.data, "getOrganizationByViewId")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }

    function getPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getAllOrganisation').then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "sucess");

        });
    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    const [searchInput, setSearchInput] = useState('');
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = payerList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setPayerList(filteredData)
        }
        else {
            setPayerList(payerList)
        }
    }
    return (
        <Container fluid="md">


            <Row>

                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", align: "center",minHeight:"600px"}} >
                        <h3 className='text-center mb-3 heading'>Payer Registered with HC</h3>
                        <br></br>

                        {/* <Form.Group className="mb-3" controlId="pcform.name">

                            <Form.Label style={{ textAlign: "center" }}>Search Payer</Form.Label>

                            <input type="type" class="form-control"
                                placeholder='Search...'
                                onChange={(e) => searchItems(e.target.value)}
                            />
                        </Form.Group> */}

                        <div className="container-fluid p-3">
                            <BootstrapTable bootstrap4 keyField='id'
                                columns={columns}
                                data={payerList}
                                pagination={pagination}
                                filter={filterFactory()}
                            />
                        </div>

                    </Card >
                </Col>
            </Row>


            < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>


        </Container >
    );

}

export default AdminDashboard;