import React, { Component,useState, useEffect } from 'react';
import './css/Form.css'; //Import here your file style
import { Container, Row, Col, Form, Button, Card, ListGroup, Dropdown, Table } from 'react-bootstrap';
import axios from 'axios'
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { Date } from 'prismic-reactjs';
import { format } from 'date-fns-tz';

// Outputs as "February 17, 2017"

const btn = {
  "width": "20%",
  "textAlign": "center",
  "marginLeft": "40%",
}

const col = {
  "margin": "6px"
};

const sheader = {
  "backgroundColor": "blue",
  "color": "#ffffff"
}

const sheader_inner1 = {
  "backgroundColor": "gray",
  "color": "white"
}
const Billing = () => {
  const [userList, setUserList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");



   
    const columns = [
        { dataField: 'email', text: 'Payer Email' },
        { dataField: 'phone', text: 'Payer Phone' },
        { dataField: 'email', text: ' Payer Email' },
    
        
        { dataField: 'inserted_date', text: 'Added Date'},

  
        { dataField: 'subtotal', text: 'Subtotal' },
        {
            dataField: "remove",
            text: "Action",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/BillingDetails/${row.id}`}>
                        <button
                            className="btn btn-primary btn-xs" Width="95px"
                            style={{margin:"2px"}}
                           onClick={() => getIdBilingList(row.id)}
                        >
                          Submit
                        </button>
                      
                    </Link>
               
                );
            },
        }
    ]
   
    function getIdBilingList(id) {
        fetch(process.env.REACT_APP_BASE_URL+`/api/getBillingDetailId/${id}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {

                console.log(resp,"padma")
                console.log(resp.data.id,"padma")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }

    // function addMyList(id) {
    //     fetch(process.env.REACT_APP_BASE_URL+`/api/addMylist/${id}`, {
    //         method: 'get'

    //     }).then((result) => {
    //         result.json().then((resp) => {

    //             console.log(resp,"padma")
    //             console.log(resp.data.id,"padma")
    //         })
    //     })
    //         .catch(error => {

    //             console.log(error.data)
    //         });

    // }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    useEffect(() => {
      getbillingList()

    }, []);

    function getbillingList(){
          axios.get(process.env.REACT_APP_BASE_URL+'/api/getBillingDetailReports').then(res => {
            setUserList(res.data.data);
            console.log(res.data.data,"setUserList")
              });
          }

    return (
        <Container fluid="md">
        <Row>
          
          <Col md={12} style={{ textAlign: "left" }}>
            <Card style={{ width: "90%", align: "center"}}>
                <h3 className='text-center mb-3 heading'>Billing</h3>
                        <center><hr className='heading_line2'></hr></center>
                 <div className="container-fluid p-3">
                    <BootstrapTable bootstrap4 keyField='id'
                        columns={columns}
                        data={userList}
                        pagination={pagination}
                        filter={filterFactory()}
                    />
                </div> 

                < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

            </Card >
            </Col>
            </Row>
        </Container >
    );
}
export default Billing













// const Billing = () => {
//   const [billingList, setBillingList] = useState([]);

//   useEffect(() => {
//       getbillingList()
//       // axios.get(process.env.REACT_APP_BASE_URL+'/api/GetAllPayercontact').then(res => {
//       //     setUserList(res.data.data);
//       // });
//   }, []);


//   function getbillingList(){
//     axios.get(process.env.REACT_APP_BASE_URL+'/api/getBillingDetailReports').then(res => {
//       setBillingList(res.data.data);
//       console.log(res.data.data,"setBillingList")
//         });
//     }
  
//   return (
//     <Container fluid="md">
//       <Card border="primary" style={{ width: "80%", align: "center", marginLeft: "10%" }}>
//         <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Billing</Card.Header>
//         <Card.Body>
//           <Row>
//             <Col>
//               <Dropdown style={{ float: "right" }}>
//                 <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: "white", color: "#000000" }}>
//                   Date
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                   <Dropdown.Item as="button">January 2021</Dropdown.Item>
//                   <Dropdown.Item as="button">February 2021</Dropdown.Item>
//                   <Dropdown.Item as="button">March 2021</Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <p style={{ textAlign: "center" }}>Billing</p>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
                   
//                     <th>Payer Name</th>
//                     <th>Payer Email</th>
//                     <th>Payer Phone</th>
//                     <th>Added Date</th>
//                     <th>Subtotal</th>
//                     <th>Action</th>
                   
//                   </tr>
//                 </thead>
//                 <tbody>
//                     { billingList.map(x => <tr>
//                         <td>{x.name}</td>
//                         <td>{x.email}</td>
//                         <td>{x.phone}</td>
//                         <td>{x.inserted_date}</td>
//                         <td>{x.subtotal}</td>
//                     </tr>)}
//                     {billingList.length == 0 && <tr>
//                         <td className="text-center" colSpan="4">
//                             <b>No data found to display.</b>
//                         </td>
//                     </tr>}
//                 </tbody>
//                 {/* <tbody>
//                   <tr>
//                     <td>1</td>
//                     <td>BCBSRI</td>
//                     <td>info@bcbsri.com</td>
//                     <td>455-981-221</td>
//                     <td>20-09-2021</td>
//                     <td>$250.30</td>
//                   </tr>
//                 </tbody> */}
//               </Table>
//             </Col>
//           </Row>
//           {/* <Row>
//             <Col>
//               <p>Payment Methods:</p>
//               <div class="form-group">
//                 <div class="col-md-12">
//                   <ol class="cards">
//                     <li class="visa hand">Visa</li>
//                     <li class="mastercard hand">MasterCard</li>
//                     <li class="amex hand">Amex</li>
//                   </ol>
//                   <div class="clearfix"></div>
//                 </div>
//               </div>
//               <p>Lorem ipsum represents a long-held tradition for designers, typographers and the like.Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans.</p>
//             </Col>
//             <Col>
//               <Row>
//                 <Col>
//                   <Table striped hover>
//                     <thead>
//                       <tr>
//                         <th>Subtotal:</th>
//                         <th>$250.30</th>
//                       </tr>
//                     </thead>
//                     <thead>
//                       <tr>
//                         <th>Tax(9.3%)</th>
//                         <th>$10.34</th>
//                       </tr>
//                     </thead>
//                     <thead>
//                       <tr>
//                         <th>Total</th>
//                         <th>$260.64</th>
//                       </tr>
//                     </thead>
//                   </Table>
//                 </Col>
//               </Row>

//             </Col>
//           </Row> */}
//           <Row>
//             <Col>
//               <div >
//                 <Button onClick={() => window.print()}>PRINT</Button>
//               </div>
//             </Col>
//             <Col style ={{marginLeft : "58%"}}>
//               <div class="col-md-12 text-right">
//                 <Button variant="primary">SUBMIT PAYMENT</Button>{' '}
//               </div>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container >
//   );
// }

// export default Billing;