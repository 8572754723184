import React, { Component, useState, useEffect } from 'react';
//import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';

import { Link, useHistory, useParams } from 'react-router-dom';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let Documentation = () => {


    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const history = useHistory();
    const [resultedOrganization, setOrganization] = useState({
        payer_id: sessionStorage.getItem('payerid'),
        user_id: sessionStorage.getItem('userid')
    });
    // setOrganization(sessionStorage.getItem('payerid'))
    

    const onSubmit = (data) => {
        var payer_id = sessionStorage.getItem('payerid');


        console.log("Appended payer id", data)

        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/documentationdetails',
                data

                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {

                setSuccessMessage("Successfully created a documentationdetails!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/searchPayer');
                console.log(response.data, "documentationdetails")
            })
            .catch(error => {
                setErrorMessage("Cannot created documentationdetails")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };

    return (

        <Container fluid="md">

            <Card border="primary"
                style={{ width: "50%", align: "center", marginLeft: "25%" }}>
                <h3 className='text-center mb-3 heading'>Documentation Details</h3>
                <center><hr className='heading_line'></hr></center>
                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

                            {...register("payer_id")}
                        />
                        <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

                            {...register("user_id")}
                        />

                        <Form.Group className="mb-3" controlId="pcform.organisation_name">
                            <Form.Label>Organization Name</Form.Label>
                            <input className="form-control"

                                {...register("organisation_name", { required: true, maxLength: 20 })}
                            />
                            {errors.organisation_name && errors.organisation_name.type === "required" && <p style={{ color: "red" }}>* </p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.name">
                            <Form.Label>New App Registration API</Form.Label>
                            <input className="form-control"

                                {...register("new_app", { required: true, maxLength: 20 })}
                            />
                            {errors.new_app && errors.new_app.type === "required" && <p style={{ color: "red" }}>* </p>}

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label>Delete App API</Form.Label>
                            <input className="form-control"

                                {...register("delete_app", { required: true })}
                            />
                            {errors.delete_app && errors.delete_app.type === "required" && <p style={{ color: "red" }}>*</p>}

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>Provider Access API </Form.Label>
                            <input className="form-control"
                                {...register("provider_access_API", { required: true, maxLength: 60 })}
                            />
                            {errors.provider_access_API && errors.provider_access_API.type === "required" &&
                                <p style={{ color: "red" }}>*</p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>View Existing App API </Form.Label>
                            <input className="form-control"
                                {...register("view_existing_app", { required: true, maxLength: 60 })}
                            />
                            {errors.view_existing_app && errors.view_existing_app.type === "required" &&
                                <p style={{ color: "red" }}>*</p>}

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>Patient Access API </Form.Label>
                            <input className="form-control"
                                {...register("patient_access_API", { required: true, maxLength: 60 })}
                            />
                            {errors.patient_access_API && errors.patient_access_API.type === "required" &&
                                <p style={{ color: "red" }}>*</p>}

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>Other Details </Form.Label>
                            <textarea as="textarea" rows={3} className="form-control" rows="5"
                                {...register("other_details")}
                            />


                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                    <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                </Card.Body>

            </Card>
        </Container>
    )

}
export default Documentation;

