import './css/pages.css'; //Import here your file style
import React, { Component, useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.css";
import './css/pages.css'; //Import here your file style
import Img from './footer-logo.png'
import { Link, useHistory } from 'react-router-dom';


const hstyle = {
  "width": "100%",
  "border": '0px solid white',
  // "padding-left": "250px",
  "margin": "10px",

  "display":"flex",
  "flexContent":"spacearound"
};
const hstyle1 = {
  "width": "100%",
  "border": '0px solid white',
  "padding-left": "250px",
  "margin": "10px",

  "display":"flex",
  "flexContent":"spacearound"
};

const container = {
  "padding":"0px",
  "margin":"0px",
  // "align":"center"
}

const logoStyle = {
  "width": "150px"
}

const textStyle = {
  "textAlign":"center",
  "paddingLeft":'57px'
}

const btn1 = {
 "backgroundColor":"#fff",
  "color": "#000",
  "borderRadius": "3rem",
  "fontWeight": "600",
  "border": "none",
  "width": "100px",
  "height": "36px"
}
const btn2 = {
  "marginLeft":"10px",
  "backgroundColor":"#e36821",
  "borderRadius": "3rem",
  "fontWeight": "600",
  "color": "#ffff",
  "border": "none",
  "width": "100px",
  "height": "36px",
}

function Header()  {
    return (
      <div style={hstyle1}>
        <div style={container}>
          <img style={logoStyle} src={Img} alt="logo" />
        </div>
          <div style={hstyle}>
            {/* <h4 style={textStyle}>info@health-chain.io/ +1 (415)689-8693</h4> */}
          </div>
          <div style={hstyle}>
              <button style={btn1}><Link style={{color:"#000"}} to='/userlogin'>Login</Link></button>
              <button style={btn2} ><Link style={{color:"#ffff"}} to='/signup'>Register</Link></button>
          </div>
      </div>
    );
  }

export default Header;
