// import React, { Component, useState, useEffect } from 'react';
// import './css/Form.css'; //Import here your file style
// import { Container, Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap';
// import axios from 'axios';



// const btn = {
//   "width": "20%",
//   "textAlign": "center",
//   "marginLeft": "40%",
// }

// const col = {
//   "margin": "6px"
// };

// const sheader = {
//   "backgroundColor": "blue",
//   "color": "#ffffff"
// }

// const sheader_inner1 = {
//   "backgroundColor": "gray",
//   "color": "white"
// }

// const PayersRegistered = () => {
//   const [userList, setUserList] = useState([]);

//   useEffect(() => {
//   //  getPayerList();
//   }, []);



//   function getPayerList(id) {
//     axios.get(process.env.REACT_APP_BASE_URL+`/api/user/getIdByUser/${id}`).then(res => {
//       setUserList(res.data.data);
//       console.log("res",res.data.data)
//     });
//   }
//   getPayerList()
//   return (
//     <Container fluid="md">
//       <Card>
//         <h3 className='text-center mb-3 heading'>Payers Registered By</h3>
//                         <center><hr className='heading_line'></hr></center>
//         <Card.Body>
//        <table class="table table-sm mt-3">
//               <thead class="thead-dark">
//                   <th>Payer Name</th>
//                   <th>Payer Email</th>
//                   <th>Payer URL</th>
//                   <th>Description</th>

//               </thead>
//               <tbody>
//                   { userList.map(x => <tr>
//                       <td>{x.name}</td>
//                       <td>{x.email}</td>
//                       <td>{x.org.payer_url}</td>
//                       <td>{x.org_payer_details}</td>


//                   </tr>)}
//                   {userList.length == 0 && <tr>
//                       <td className="text-center" colSpan="4">
//                           <b>No data found to display.</b>
//                       </td>
//                   </tr>}
//               </tbody>
//           </table>
//           <Row>
//             <Col>
//               {/* {userList.map(user => {
//                 const { name, email, org_payer_url, org_payer_details } = user;
//                 return (
//                           <div >
//                              <hr />
//                            <strong> <p style={{ color: "#2E71FF" }}>{name}.</p></strong>
//                           <p><strong> Email-</strong> {email}</p>
//                         <p><strong>Payer URL- </strong>{org_payer_url} </p>

//                             <p>{org_payer_details}</p>
//                           </div>

//             )

//                       })
//                       }  */}
//             {/* <p style={{ color: "#2E71FF" }}>BCBSRI.</p>
//               <p>Email: info@bcbsri.com</p>
//               <p>Payer URL: </p>
//               <p>Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans.</p> */}
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             {/* <hr />
//               <p style={{ color: "#2E71FF" }}>Aetna.</p>
//               <p>Email: info@aetna.com</p>
//               <p>Payer URL: https://reqres.in/api/products/url </p>
//               <p>Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans.</p> */}
//           </Col>
//         </Row>
//       </Card.Body>
//     </Card>
//     </Container >
//   );
// }

// export default PayersRegistered;


// import './css/Form.css';
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';


const PayersRegistered = () => {
  const { id } = useParams();
  const [userList, setUserList] = useState([]);
  const columns = [
    { dataField: 'user_id', text: 'Id' },
    { dataField: 'username', text: 'Name' },
    { dataField: 'email', text: 'Email' },
    // { dataField: 'org_payer_url', text: 'Payer Url' },
    // { dataField: 'org_payer_details', text: 'Description' }
  ]

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page)
      console.log('sizePerPage', sizePerPage)
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page)
      console.log('sizePerPage', sizePerPage)
    }
  })



  const history = useHistory();

  useEffect(() => {
    var user_id = sessionStorage.getItem('userid');
    var isadmin = sessionStorage.getItem('isadmin');
    console.log(user_id, isadmin, "user_idisadmin")

    if (user_id == 'undefined' && isadmin == 'undefined') {
      history.push('/userlogin');

    }
    // else if (user_id!='undefined'  && isadmin =='false'){
    else if ((user_id != 'undefined' && isadmin == 'false') || isadmin != 'true') {
      history.push('/userlogin');
    }

    else {
      axios
        .get(process.env.REACT_APP_BASE_URL + `/api/user/getIdByUser/${id}`)
        .then((res) => {

          setUserList(res.data.data);
          console.log(res.data.data, "getIdByUser")
        });


      //   console.log(user_id,"user_id")

    }


  }, []);




  return (
    <Container fluid="md">
      <Card border="primary" style={{minHeight:"600px"}}>

        <h3 className='text-center mb-3 heading'>Payers Registered By</h3>
        <center><hr className='heading_line2'></hr></center>
        <br></br>
        <Card.Body>

          <Row>
            <Col>
              {userList.map(user => {
                const { username, email, phone, address1,city,zip_code } = user;
                return (
                  <div >
                    <hr />
                    <p><strong>Name:</strong> {username}</p>
                    <p><strong> Email:</strong> {email}</p>
                    <p><strong>Phone Number: </strong>{phone} </p>
                    <p><strong>Address:</strong>{address1}</p>
                    <p><strong>City:</strong>{city}</p>
                    <p><strong>Zip code:</strong> {zip_code} </p>


                  </div>

                )

              })
              }
            </Col>
          </Row>
          {/* <div className="container-fluid p-3">
    <BootstrapTable bootstrap4 keyField='id'
        columns={columns}
        data={userList}
        pagination={pagination}

    />
</div>  */}
          {/* <table class="table table-sm mt-3">
                <thead class="thead-dark">
                  <th>Name</th>
                  <th>Email</th>
                  <th>Payer Url</th>

                </thead>
                <tbody>

                  {userList.map(x => <tr>
                    <td>{x.name}</td>
                    <td>{x.email}</td>
                    <td>{x.payer_url}</td>



                  </tr>)}
                  {userList.length == 0 && <tr>
                    <td className="text-center" colSpan="4">
                      <b>No data found to display.</b>
                    </td>
                  </tr>}
                </tbody>
              </table>  */}


        </Card.Body>
      </Card>
    </Container>



  );

}

export default PayersRegistered





//
