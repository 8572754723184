import { initializeApp } from 'firebase/app';

// TODO: Replace the following with your app's Firebase project

const firebaseConfig = {   
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID ,
  // url : "http://localhost:7000/api/user/login"
};

const app = initializeApp(firebaseConfig);

export default app;