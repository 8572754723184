import React, { Component, useState, useEffect } from 'react';
import './css/Form.css'; //Import here your file style
import { Container, Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const btn = {
  "width": "20%",
  "textAlign": "center",
  "marginLeft": "40%",
}

const col = {
  "margin": "6px"
};

const sheader = {
  "backgroundColor": "blue",
  "color": "#ffffff"
}

const sheader_inner1 = {
  "backgroundColor": "gray",
  "color": "white"
}
const state = {
  curTime: new Date().toLocaleDateString(),
}




let Contract = () => {

  const [userDetails, setUserDetails] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const { payer_id } = useParams();
  const location = useLocation();

  const history = useHistory();
  useEffect(() => {

    var token =localStorage.getItem("token");
    console.log("token==== ",token)

    if(token=="")
    {
      console.log("token----------- ",token)
      setErrorMessage("Error in getting token,  Please try after some time");

      setTimeout(() => {
          setErrorMessage()
      }, 4000);
    }
    getUserDetails();
    // updatePayerSignedStatus()
  },
    []);
    // var id =sessionStorage.getItem("id");



  function getUserDetails() {
    var id = localStorage.getItem("payer_signed_status_id");
    axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getBothPayers/${id}`).then(res => {
      setUserDetails(res.data.data);

      console.log(res.data.data, "getUserDetails");
    });
  }

  function updatePayerSignedStatus() {
    // var id =sessionStorage.getItem("id");
    var id = localStorage.getItem("payer_signed_status_id");
    var status='Initiated';
    const data = {payer_status:status, id:id};
    axios
      .post(process.env.REACT_APP_BASE_URL + `/api/PayersSignedStatus/update`, data)
      .then((res) => {
        localStorage.removeItem("payer_signed_status_id")
        localStorage.removeItem("token");
       // setContractList(res.data.data);
       console.log(res.data.data[0].data_recieving_api, "updatePayerSignedStatus")
       if(res.data.data[0].data_recieving_api!=null)
       {
           console.log("Destination Url is not null");
           const payerdata = res.data.data;
           axios
             .post(res.data.data[0].data_recieving_api, payerdata)
              .then((res) => {
                 console.log("Data has been sent to destination payer");

             });
       }

          history.push('/RegisteredOrgnization?tab=third&success=successfully organization added and sent for approval');
          // history.push('/');

        // console.log(res.payer_status);
        // console.log(res.data.data, "updatePayerSignedStatus")
      });
    // history.push("/searchPayer");
  }







  return (
    <Container fluid="md">


      <Row>

        <Col md={12} style={{ textAlign: "left" }}>
          <Card style={{ width: "80%" }} >
            <Card.Body>


              <Row>
                <div class="col-md-12" style={{ textAlign: "center" }}>
                  <h3 className='mb-3 heading'>Contract

                  </h3>
                  <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </h4>

                </div>
                <Col>
                  <sup style={{ width: "80%", align: "right", marginLeft: "85%" }}>Date : {state.curTime}</sup>
                </Col>

              </Row>
              <Row>
                <Col>
                  {userDetails.map(user => {
                    const { sname, semail } = user;
                    return (
                      <div >
                        <p style={{ color: "#2E71FF" }}>Name:{sname}.</p>
                        <p>Email:{semail}</p>

                      </div>

                    )

                  })
                  }
                </Col>

                <Col style={{marginLeft:"20%"}}>
                  {userDetails.map(user => {
                    const { dname, demail } = user;
                    return (
                      <div >
                        <p style={{ color: "#2E71FF" }}>Name:{dname}.</p>
                        <p>Email:{demail}</p>
                      </div>

                    )

                  })
                  }
                </Col>
              </Row>


              <Row>
                <Col>
                  <Card border="primary" style={{ width: "100%", align: "center" }}>
                    <div class="card-header" style={{ backgroundColor: "#a9afb5", color: "#ffffff" }}>
                      Terms and Condition
                    </div>
                    {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Terms and Condition</Card.Header> */}
                    <Card.Body>
                      <Row>
                        <Col>
                          {/*  {contractList.map(user => {
//                         const { terms_and_condition } = user;
//                         return (
//                           <div >
//                            <p>{terms_and_condition}</p>

//                           </div>

//                         )

//                       })
//                       }*/}
                          <p>This Terms of Service Agreement ("Agreement") is entered into by and between HealthChain, registered address California, USA ("Company") and you, and is made effective as of the date of your use of this website http://www.test.com ("Site") or the date of electronic acceptance.</p>
                          <p>This Agreement sets forth the general terms and conditions of your use of the http://www.test.com as well as the products and/or services purchased or accessed through this Site (the "Services").Whether you are simply browsing or using this Site or purchase Services, your use of this Site and your electronic acceptance of this Agreement signifies that you have read, understand, acknowledge and agree to be bound by this Agreement our Privacy policy. The terms "we", "us" or "our" shall refer to Company. The terms "you", "your", "User" or "customer" shall refer to any individual or entity who accepts this Agreement, uses our Site, has access or uses the Services. Nothing in this Agreement shall be deemed to confer any third-party rights or benefits.</p>
                          <p>Company may, in its sole and absolute discretion, change or modify this Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or modifications shall be effective immediately upon posting to this Site. Your use of this Site or the Services after such changes or modifications have been made shall constitute your acceptance of this Agreement as last revised.</p>
                          <p>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AS LAST REVISED, DO NOT USE (OR CONTINUE TO USE) THIS SITE OR THE SERVICES.</p>

                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Row>
                    <Card border="primary" style={{ width: "47%", margin: "1.5%", align: "left" }}>
                      <div class="card-header" style={{ backgroundColor: "#a9afb5", color: "#ffffff" }}>
                        ELIGIBILITY
                      </div>
                      {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>The Agreement</Card.Header> */}
                      <Card.Body>
                        <Col>
                          {/*   {contractList.map(user => {
//                         const { agreement } = user;
//                         return (
//                           <div >
//                             <p>{agreement}</p>
//                           </div>

//                         )

//                       })
//                     }*/}
                          <p>This Site and the Services are available only to Users who can form legally binding contracts under applicable law. By using this Site or the Services, you represent and warrant that you are (i) at least eighteen (18) years of age, (ii) otherwise recognized as being able to form legally binding contracts under applicable law, and (iii) are not a person barred from purchasing or receiving the Services found under the laws of the India or other applicable jurisdiction.</p>
                          <p>If you are entering into this Agreement on behalf of a company or any corporate entity, you represent and warrant that you have the legal authority to bind such corporate entity to the terms and conditions contained in this Agreement, in which case the terms "you", "your", "User" or "customer" shall refer to such corporate entity. If, after your electronic acceptance of this Agreement, Company finds that you do not have the legal authority to bind such corporate entity, you will be personally responsible for the obligations contained in this Agreement.</p>

                        </Col>
                      </Card.Body>
                    </Card>
                    <Card border="primary" style={{ width: "47%", margin: "1.5%", align: "right" }}>
                      <div class="card-header" style={{ backgroundColor: "#a9afb5", color: "#ffffff" }}>
                        RULES OF USER CONDUCT
                      </div>
                      {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Term of Agreement</Card.Header> */}
                      <Card.Body>
                        <Col>
                          {/*  {contractList.map(user => {
//                         const { term_of_agreement } = user;
//                         return (
//                           <div >
//                             <p>{term_of_agreement}</p>
//                           </div>

//                         )

//                       })
//                       }*/}
                          <p>By using this Site You acknowledge and agree that:
                            Your use of this Site, including any content you submit, will comply with this Agreement and all applicable local, state, national and international laws, rules and regulations.</p>
                          <p>You will not use this Site in a manner that:</p>
                          Is illegal, or promotes or encourages illegal activity;
                          <p><ul><li>Promotes, encourages or engages in child pornography or the exploitation of children;</li>
                            <li>Promotes, encourages or engages in terrorism, violence against people, animals, or property;</li>
                            <li>Promotes, encourages or engages in any spam or other unsolicited bulk email, or computer or network hacking or cracking;</li>
                            <li>Infringes on the intellectual property rights of another User or any other person or entity;</li>
                            <li> Violates the privacy or publicity rights of another User or any other person or entity, or breaches any duty of confidentiality that you owe to another User or any other person or entity;</li>
                          </ul></p>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Row>
                  <Row>
                    {/*  <Card border="primary" style={{ width: "47%", margin: "1.5%", align: "left" }}>
//                 <div class="card-header" style={{ backgroundColor: "blue", color: "#ffffff" }}>
//                 Non-Compete
//                         </div>*/}
                    {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Non-Compete</Card.Header> */}
                    {/*  <Card.Body>
//                     <Col>
//                     {contractList.map(user => {
//                         const { non_compete } = user;
//                         return (
//                           <div >
//                             <p>{non_compete}</p>
//                           </div>

//                         )

//                       })
//                       }

//                     </Col>
//                   </Card.Body>
//                 </Card>
//                 <Card border="primary" style={{ width: "47%", margin: "1.5%", align: "right" }}>
//                 <div class="card-header" style={{ backgroundColor: "blue", color: "#ffffff" }}>
//                 Non-Solicitation
//                         </div>*/}
                    {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Non-Solicitation</Card.Header> */}
                    {/* <Card.Body>
//                     <Col>
//                     {contractList.map(user => {
//                         const { non_solicitation } = user;
//                         return (
//                           <div >
//                             <p>{non_solicitation}</p>
//                           </div>

//                         )

//                       })
//                       }

//                     </Col>
//                   </Card.Body>
//                 </Card>*/}
                  </Row>
                </Col>
              </Row>
              <Row>

                <Col>
                  <div class="col-md-12 text-center">



                    {/* <Button

                    onClick={updatePayerSignedStatus}   variant="primary">

                      Submit


                    </Button>{' '} */}
                    <Link to={`/RegisteredOrgnization`}>
                      <button

                        className="btn btn-primary " Width="95px"
                        style={{ margin: "2px" }}
                        onClick={() => updatePayerSignedStatus()}
                      >
                        Submit
                      </button>
                    </Link>

                    {/* <Button  variant="success">Accept</Button>{' '}
                 <Button  variant="danger">Reject</Button>{' '} */}


                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card >
        </Col>
      </Row>




    </Container >
  );

}

export default Contract;