import React from "react";
import { useHistory } from "react-router-dom";

const TodoCard = (props) => {
  const { todo } = props;
  const { id, name, email } = todo;
  let history = useHistory();

  return (
    <div
      style={{
        backgroundColor: "grey",
        margin: "10px",
        padding: "15px",
        width: "150px"
      }}
      onClick={() => history.push(`/viwePayer/${id}`)}
    >
     
      <h6> {`Name: ${name}`} </h6>
      <h6> {`Email: ${email}`} </h6>
     
    </div>
  );
};

export default TodoCard;