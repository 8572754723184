import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import public_profile from './public_profile.png'
import profile_pic from './profile_pic.png'
import plus_icon from './plus_icon.png'
import "bootstrap/dist/css/bootstrap.css";
import _ from 'lodash'
import axios from 'axios';
import TodoCard from "./TodoCard"
import { useForm } from "react-hook-form";
import validator from 'validator';



let EditMyOrganizationProfile = () => {


    // const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
        console.log(setUserList, "llllll")

    }

    const [userList, setUserList] = useState([]);
    const [usertypeList, setUserTypeList] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;
    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;
    const [resultedpayerid, setpayerid] = useState("");

    const [resultedOrganization, setOrganization] = useState({ name: "", first_name: "", last_name: "", email: "", phone: "", payer_id: "", user_id: "" });

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [label, setLabel] = useState("");
    const [country, setCountry] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [zip_code, setPostalCode] = useState('');
    const [policylink, setPolicyLink] = useState('');
    const [conditionlink, setConditionLink] = useState('');
    const [website, setWebsite] = useState('');
    const [organisation_display_name, setOrganizationDisplayName] = useState('')
    const [primary_developer_name, setPrimaryDeveloperName] = useState('')
    const [primary_developer_title, setPrimaryDeveloperTitle] = useState('')
    const [primary_developer_email, setPrimaryDeveloperEmail] = useState('')
    const [app_url_pre_production, setAppUrlPreProduction] = useState('')
    const [api_url_production, setApiUrlProduction] = useState('')
    const [return_url, setReturnAPI] = useState('')
    const [auth_type, setAuthtype] = useState('')
    const [authorize_url, setAuthorizeURL] = useState('')
    const [token_url, setTokenURL] = useState('')
    const [callback_url, setCallbackURL] = useState('')
    const [payer_environment_id, setPayerEnvironmentId] = useState("")


    const [organisation_type_id, setOrganisation_type_id] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [environmentList, setEnvironmentList] = useState([]);

    const [formError, setFormError] = useState({})
    const error = {}
    const history = useHistory();

    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'last_modified_on', text: 'Last Modified On' },


    ]

    var endpoints = [{
        name: "Patient Access API",
        description: "Endpoint to access patient details",
        last_modified_on: "December 13,2021",
    }, {
        name: "Coverage",
        description: "Endpoint to access coverage details",
        last_modified_on: "December 13,2021",
    }];

    useEffect(() => {
        getUserTypesList();
        getLocation();
        MyOrganizationProfile()
        getPayersEnvironment()

    }, []);


    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }
    function getPayersEnvironment() {
        console.log(getPayersEnvironment, "getPayersEnvironment")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getPayersEnvironment`).then(res => {
            setEnvironmentList(res.data.data);
            console.log(res.data.data, "getPayersEnvironment");
        });
    }
    function getUserTypesList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getUserOrganisationTypes').then(res => {
            setUserTypeList(res.data.data);
            console.log(res.data.data, "getUserOrganisationTypes")
        });
    }

    const runValidation = () => {
        if (name.length == 0) {
            error.name = "Organization Name is required"
        }

        if (website.length == 0) {
            error.website = "Organization Website is required"
        }else if(!validator.isURL(website)){
            error.website = 'not a valid URL'
        }
        else if(!validator.isURL(website))
            {
            error.website = 'Not a valid URL'
        }
        if (policylink.length == 0) {
            error.policylink = "Privacy Policy is required"
        }
        else if(!validator.isURL(policylink))
            {
            error.policylink = 'Not a valid URL'
        }
        if (conditionlink.length == 0) {
            error.conditionlink = "Condition Link is required"
        }
        else if(!validator.isURL(conditionlink))
            {
            error.conditionlink = 'Not a valid URL'
        }
        if (address1.length == 0) {
            error.address1 = "Address Line is required"
        }
        if (city.length == 0) {
            error.city = "City is required"
        }
        //else if(!validator.isEmail(email)) {
        //     error.email = 'invalid email format'
        // }
        if (phone.length == null) {
            error.phone = "phone numberis required"
        } else if (phone.length > 10 || phone.length < 10) {
            error.phone = "Invalid Phone Number"
        }
        // if(address1.length == 0){
        //     error.address1 = "address cannot be empty"
        // }
        // if(city.length == 0){
        //     error.city = "City cannot be empty"
        // }
        if (zip_code.length == null) {
            error.zip_code = "ZipCode is required"
        } else if (zip_code.length > 5 || zip_code.length < 5) {
            error.zip_code = "invalid zip code"
        }
    }
    function MyOrganizationProfile() {

        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "MyOrganizationProfile")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getMyOrganizationProfile/${value}`).then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "getMyOrganizationProfile");
            setName(res.data.data[0].name)
            setPhone(res.data.data[0].phone)
            setAddress1(res.data.data[0].address1)
            setAddress2(res.data.data[0].address2)
            setCity(res.data.data[0].city)
            setCountry(res.data.data[0].country)
            setPostalCode(res.data.data[0].zip_code)
            setWebsite(res.data.data[0].website)
            setPolicyLink(res.data.data[0].policylink)
            setConditionLink(res.data.data[0].conditionlink)
            setReturnAPI(res.data.data[0].data_recieving_api)
            setLabel(res.data.data[0].label)
            setOrganisation_type_id(res.data.data[0].organisation_type_id)
            setOrganizationDisplayName(res.data.data[0].organisation_display_name)
            setPrimaryDeveloperName(res.data.data[0].primary_developer_name)
            setPrimaryDeveloperTitle(res.data.data[0].primary_developer_title)
            setAppUrlPreProduction(res.data.data[0].app_url_pre_production)
            setPrimaryDeveloperEmail(res.data.data[0].primary_developer_email)
            setApiUrlProduction(res.data.data[0].api_url_production)
            setAuthorizeURL(res.data.data[0].authorize_url)
            setAuthtype(res.data.data[0].auth_type)
            setTokenURL(res.data.data[0].token_url)
            setCallbackURL(res.data.data[0].callback_url)
            setPayerEnvironmentId(res.data.data[0].payer_environment_id)

           console.log(res.data.data[0].payer_environment_id,"getMyOrganizationProfile")
           console.log(res.data.data[0].label,"label")


        });
    }
    var value = sessionStorage.getItem('payerid');

    const updateAPIData = (e) => {

        e.preventDefault()

        runValidation()
        if (Object.keys(error).length == 0) {
            setFormError({})
            axios.put(process.env.REACT_APP_BASE_URL + `/api/updateMyOrgProfile/${value}`, {
                name, phone, address1, address2, label, city, country, zip_code, website, policylink, conditionlink,
                api_url_production, primary_developer_name, organisation_display_name, organisation_type_id,
                primary_developer_title, primary_developer_email, app_url_pre_production,return_url,
                auth_type, authorize_url, token_url, callback_url,payer_environment_id

                //  description
            })
                .then(response => {
                    history.push('/MyOrganizationProfile');
                    console.log(response.data, "updateMyOrgProfile")
                })
                .catch(error => {
                    console.log(error,"error")
                });

        } else {
            setFormError(error)
        }
    }

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");

    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };
    var value = sessionStorage.getItem('payerid');
    const uploadFile = async (e) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        try {
            const res = await axios.put(
                process.env.REACT_APP_BASE_URL + `/api/updateImageProfile/${value}`, formData)
            console.log(res);
            history.push('/MyOrganizationProfile');
        } catch (ex) {
            console.log(ex);
        }
    };



    return (
        <Container fluid="md">


            <Row>


                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%" }} >

                        <Card.Body>

                            <div class="col-md-12" style={{ textAlign: "center" }}>
                                <h3 className='mb-3 heading'>My Organization Profile
                                    <div class="pull-right">
                                        <button type="button" onClick={updateAPIData} id="" class="form-control btn-save btn btn-primary">Save</button>
                                    </div>
                                    <div class="pull-left">
                                    <button
                                            className="btn btn-primary " Width="95px"
                                            style={{ marginTop: "26px", borderRadius: "20px", }} id="download_button"

                                            onClick={() => {
                                                history.push('/MyOrganizationProfile')
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </h3>

                            </div>



                            <div class="clearfix"></div>
                            <br></br>
                            <div class="pull-right">
                                <span style={{ color: "#687080", fontWeight: 600 }}> Last Edited</span> December 20, 2021
                            </div>
                            <div class="clearfix"></div>
                            <hr></hr>
                            <div style={{ display: 'block', padding: 20 }}>

                                <div class="col-md-12" style={{ textAlign: "left" }}>
                                    <div
                                        style={{ width: "100%" }}>


                                        <center><div className="box_tittle"><h4>Organization Details</h4></div></center>


                                        <Row md={12}>
                                            <Col md={6} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

                                                <Form.Group
                                                    controlId="pcform.organizationname"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Organization Name <span style={{ color: "red" }}>*</span></Form.Label>


                                                    <input class="form-control"
                                                        value={name} onChange={(e) => { setName(e.target.value) }}

                                                    />
                                                    <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

                                                        {...register("payer_id")}
                                                    />
                                                    <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

                                                        {...register("user_id")}
                                                    />

                                                    {formError.name && <span style={{ color: 'red' }}>{formError.name}</span>}<br />
                                                </Form.Group>
                                            </Col>

                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>

                                                <Form.Group className="mb-3" controlId="pcform.organisation_type_id">
                                                    <Form.Label>Organization Type <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <br />
                                                    <select className="form-control" name="payer"
                                                        //  value={payer_id}
                                                        //  onChange={PayerChange}


                                                        // {...register("organisation_type_id", { required: true, validate: (value) => value !== "Select Payer" })}
                                                        // onChange={(e) => { setOrganisation_type_id(e.target.value) }}

                                                        input class="form-control" value={organisation_type_id}
                                                        onChange={(e) => { setOrganisation_type_id(e.target.value) }}
                                                    >
                                                        {/* <option>{organisation_type_id}</option> */}
                                                        {
                                                            usertypeList.map((result) => (<option id={result.id} value={result.id}> {result.organisation_type}</option>
                                                            ))
                                                        }
                                                    </select>


                                                    {errors.organisation_type_id && <p style={{ color: "red" }}>Please select at least one option</p>}

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row md={12}>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.organizationname"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Organization Phone Number <span style={{ color: "red" }}>*</span></Form.Label>
                                                    {/* <Form.Control type="text" placeholder="" value={resultedOrganization.phone}

                                                {...register("phone", { required: true})}
                                                    /> */}
                                                    <input class="form-control"
                                                        value={phone} onChange={(e) => { setPhone(e.target.value) }} />

                                                    {formError.phone && <span style={{ color: 'red' }}>{formError.phone}</span>}<br />
                                                </Form.Group>
                                            </Col>

                                            {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.organizationdisplayname"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Organisation Display Name</Form.Label>
                                                    <input class="form-control" value={organisation_display_name}
                                                        onChange={(e) => { setOrganizationDisplayName(e.target.value) }} />
                                                </Form.Group>
                                            </Col> */}
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.type" className="mb-1">
                                                    <Form.Label>Organization Website <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <input class="form-control" type="url"




                                                        value={website} onChange={(e) => { setWebsite(e.target.value) }} />
                                                    {formError.website && <span style={{ color: 'red' }}>{formError.website}</span>}<br />

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row md={12}>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.type" className="mb-1">
                                                    <Form.Label>App has a Privacy Policy?  <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <input class="form-control"
                                                        value={policylink} onChange={(e) => { setPolicyLink(e.target.value) }} />
                                                    {formError.policylink && <span style={{ color: 'red' }}>{formError.policylink}</span>}<br />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.type" className="mb-1">
                                                    <Form.Label>Organization Terms and Conditions <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <input class="form-control"
                                                        value={conditionlink} onChange={(e) => { setConditionLink(e.target.value) }} />
                                                    {formError.conditionlink && <span style={{ color: 'red' }}>{formError.conditionlink}</span>}<br />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row md={12}>

                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.email" className="mb-1">
                                                    <Form.Label>Address Line-1 <span style={{ color: "red" }}>*</span></Form.Label>
                                                    {/* <Form.Control type="text"  value={resultedOrganization.address1} placeholder=""

                                                    {...register("address1", { required: true})}
                                                    /> */}
                                                    <input class="form-control"
                                                        value={address1} onChange={(e) => { setAddress1(e.target.value) }} />
                                                    {formError.address1 && <span style={{ color: 'red' }}>{formError.address1}</span>}<br />


                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.organizationname"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Address Line-2</Form.Label>
                                                    {/* <Form.Control type="text" value={resultedOrganization.address2} placeholder=""

                                                  {...register("address2", { required: true})}
                                                  /> */}
                                                    <input class="form-control"
                                                        value={address2} onChange={(e) => { setAddress2(e.target.value) }} />

                                                </Form.Group>
                                            </Col>
                                     
                                        </Row>
                                        <Row md={12}>
                                          
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.email" className="mb-1">
                                                    <Form.Label>City <span style={{ color: "red" }}>*</span></Form.Label>
                                                    {/* <Form.Control type="text"  value={resultedOrganization.city} placeholder=""
                                                  {...register("city", { required: true})}
                                                  /> */}
                                                    <input class="form-control"
                                                        value={city} onChange={(e) => { setCity(e.target.value) }} />

                                                    {formError.city && <span style={{ color: 'red' }}>{formError.city}</span>}<br />

                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.organizationname"
                                                    className="mb-1"
                                                >
                                                    <Form.Label>State <span style={{ color: "red" }}>*</span></Form.Label>
                                                    {/* <Form.Control type="text" placeholder=""  value={resultedOrganization.label}

                                                  {...register("state", { required: true})}
                                                  /> */}
                                                    <select className="form-control" name="payer"
                                                    

                                                        // {...register("value", {
                                                        //     required: true,
                                                        //     validate: (value) => value !== "Select State"
                                                        // })}
                                                        value={label}  onChange={(e) => { setLabel(e.target.value) }}
                                                    >
                                                        {/* <option>{value}</option> */}
                                                        {
                                                            locationList.map((result) => (<option id={result.value} value={result.value}> {result.label}</option>
                                                            ))
                                                        }
                                                    </select>

                                                    {errors.state && errors.state.type === "required" &&
                                                        <h4 style={{ color: "red" }}>*</h4>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row md={12}>
                                         

                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.type" className="mb-1">
                                                    <Form.Label>Country <span style={{ color: "red" }}>*</span></Form.Label>
                                                    {/* <Form.Control type="text" placeholder=""   value={resultedOrganization.country}
                                                        /> */}

                                                    <input class="form-control"
                                                        value={country} onChange={(e) => { setCountry(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.zip_code" className="mb-1">
                                                    <Form.Label >ZipCode <span style={{ color: "red" }}>*</span> </Form.Label>
                                                    {/* <Form.Control type="text" value={resultedOrganization.postal_code} placeholder=""
                                                {...register("postal_code", { required: true})}
                                                  /> */}
                                                    <input class="form-control"
                                                        value={zip_code} onChange={(e) => { setPostalCode(e.target.value) }} />

                                                    {formError.zip_code && <span style={{ color: 'red' }}>{formError.zip_code}</span>}<br />
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row md={12}>
                                        
                                          
                                            {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.devlopername" className="mb-1">
                                                    <Form.Label>Primary Developer Name </Form.Label>
                                                    <input class="form-control" value={primary_developer_name}
                                                        onChange={(e) => { setPrimaryDeveloperName(e.target.value) }} />
                                                </Form.Group>
                                            </Col> */}
                                        </Row>
                                        {/* <Row md={12}>

                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.devlopertitle" className="mb-1">
                                                    <Form.Label>Primary Developer Title </Form.Label>
                                                    <input class="form-control" value={primary_developer_title}
                                                        onChange={(e) => { setPrimaryDeveloperTitle(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.developeremail"
                                                    className="mb-3" >
                                                    <Form.Label>Primary Developer Email</Form.Label>
                                                    <input class="form-control" value={primary_developer_email}
                                                        onChange={(e) => { setPrimaryDeveloperEmail(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
{/* 

                                        <Row md={12}>
                                           
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group controlId="pcform.app_url_pre_production" className="mb-1">
                                                    <Form.Label>API URL (for Pre-Production App redirect) </Form.Label>
                                                    <input class="form-control" value={app_url_pre_production}
                                                        onChange={(e) => { setAppUrlPreProduction(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.api_url_production"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>API URL (for Production App redirect)</Form.Label>
                                                    <input class="form-control" value={api_url_production}
                                                        onChange={(e) => { setApiUrlProduction(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                        </Row> */}
                                        <Row md={12}>
                                          
                                            {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.api_url_production"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Authtype</Form.Label>
                                                    <input class="form-control" value={auth_type}
                                                        onChange={(e) => { setAuthtype(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.api_url_production"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Token URL</Form.Label>
                                                    <input class="form-control" value={token_url}
                                                        onChange={(e) => { setTokenURL(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.api_url_production"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Authorize URL</Form.Label>
                                                    <input class="form-control" value={authorize_url}
                                                        onChange={(e) => { setAuthorizeURL(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.api_url_production"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Callback URL</Form.Label>
                                                    <input class="form-control" value={callback_url}
                                                        onChange={(e) => { setCallbackURL(e.target.value) }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                                <Form.Group
                                                    controlId="pcform.organizationname"
                                                    className="mb-1"
                                                >
                                                    <Form.Label>Payer Environment <span style={{ color: "red" }}>*</span></Form.Label>
                                                    <select className="form-control" name="payer"

                                                        input class="form-control" value={payer_environment_id}
                                                        onChange={(e) => { setPayerEnvironmentId(e.target.value) }}
                                                    >
                                                      
                                                        {
                                                            environmentList.map((result) => (<option id={result.id} value={result.id}> {result.payer_environment}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </Form.Group>
                                            </Col> */}
                                            
                                        </Row>


                                    </div>
                                </div>
                                <div class="col-md-5" style={{ textAlign: "left" }}>

                                    <div class="clearfix"></div>

                                </div>
                                <div class="clearfix"></div>

                            </div>
                        </Card.Body>
                    </Card >
                </Col>
            </Row>


            < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>


        </Container >
    );

}

export default EditMyOrganizationProfile;