import React, { Component, useState, useMemo, useEffect } from 'react';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const SignUpsubmit = (props) => {

  const [userList, setUserList] = useState([]);
    useEffect(() => {
      document.getElementById("root").classList.add("root_bg");
      getUserList()

    }, []);


    function getUserList() {
      axios.get(process.env.REACT_APP_BASE_URL + '/api/users').then(res => {
          setUserList(res.data.data);
          console.log(res.data.data, "sucess");

      });
  }

  return <div>
          <div class="signup_header">
            <div style={{ width: '67%', marginLeft: '7%' }}>
              <Card>
                <Card.Body>
                  <Row md={12} style={{ width: "100%", }}>
                    <ul id="progressbar" class="mt-4">
                        <li class="active first_li">Organization Information</li>
                        <li class="active">Administrator Information</li>
                        <li class="active">Review and  Confirm</li>
                    </ul>

                    <Col md={12}> <h3 className='text-center mb-5 heading2'>Registration Complete</h3></Col>
                    <Col md={12}>

                        {userList.slice(0,1).map(x =>
                            <div className="w-100 text-center mt-2">
                                {/* <h3 className='mb-3 heading'>{x.email}</h3> */}
                               <center>
                               Your information has been submitted. Please wait while Health Chain verifies your access. Upon verification, an email will be sent to<h4> {x.email}</h4>


                      </center>
                            </div>
                        )}
                      <center>
                      <a href="/userlogin"><Button type='button' className='w-90 mt-4 sbt-btn'>Back to Home</Button></a> </center>

                    </Col>
                  </Row><br></br>
                </Card.Body>
              </Card>
              <br></br><br></br>
            </div>
          </div>
  </div>;
};

export default SignUpsubmit;
