import { Container, Row, Col, Form, Button, Card, ExploreButton, Alert } from "react-bootstrap";
import React, { useState, onClick, useEffect } from "react";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';




import axios from 'axios'



let TicketRequest = () => {

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const { register, handleSubmit, reset, formState: { errors } } = useForm();


  const onSubmit = data => {

    axios
      .post(
        process.env.REACT_APP_BASE_URL+'/api/requestedtickets',
        data,
        //   { headers: { 'Content-Type': 'application/json' }}
      )

      .then(response => {
        console.log(response.data, "padma")
        setSuccessMessage("Successfully created a requested tickets!")
        reset(response.data);
        setTimeout(() => {
          setSuccessMessage()
        }, 2000);

        console.log(response.data)

      })
      .catch(error => {
        setErrorMessage("Cannot created")
        reset(error.data);
        setTimeout(() => {
          setErrorMessage()
        }, 2000);
        console.log(error.data)
      });
  };
  // const TicketRequest = () => {
  //  const { register,handleSubmit, formState: { errors } } = useForm();
  //   // const onSubmit = data => console.log(data);

  //https://github.com/toofaniCoder/React-Users/tree/master/src/components

  //   const [message, setTitle] = useState("");
  //   const [payer_id, setBody] = useState( );
  //   const [severity, Body] = useState("");
  //   const onTitleChange = e => setTitle(e.target.value);
  //  const PayerChange = e => setBody(e.target.value);
  //   const SeverityChange = e => Body(e.target.value);


  //   const handleSubmitex = e => {
  //     e.preventDefault();
  //     const data = { message,payer_id, severity};
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(data)
  //     };
  //     fetch(process.env.REACT_APP_BASE_URL+"/api/requestedtickets", requestOptions)
  //       .then(response => response.json())
  //       .then(res => console.log(res));
  //   };




  return (

    <Container fluid="md">
      <Row>
              
              <Col md={12} style={{ textAlign: "left" }}>
      <Card
        
        style={{ width: "80%"}}
      >
        
        <h3 className='text-center mb-3 heading'>Request Ticket</h3>
                        <center><hr className='heading_line'></hr></center>
                        <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>
      
        <Card.Body>
          {/* onSubmit={handleSubmit} */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="pcform.payername">
                  <Form.Label>Payer Name</Form.Label>
                  <br />
                  <select className="form-control" name="payer"
                    //  value={payer_id}
                    //  onChange={PayerChange} 
                    {...register("payer_id", { required: true, validate: (value) => value !== "Select Payer" })}
                  >
                    <option>Select Payer</option>
                    <option >1</option>
                    <option >2</option>
                    <option >3</option>
                  </select>

                  {errors.payer_id && <p style={{ color: "red" }}>Please select at least one option</p>}

                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="pcform.severity">
                  <Form.Label>Severity</Form.Label><br />
                  <select className="form-control" name="severity"
                    //  value={severity}
                    //  onChange={SeverityChange} 
                    {...register("severity", { required: true, validate: (value) => value !== "Select Severity " })}
                  >
                    <option>Select Severity</option>
                    <option>High</option>
                    <option>Low</option>
                    <option>Mid</option>
                    <option>Three</option>
                  </select>
                  {errors.severity && <p style={{ color: "red" }}>Please select at least one option</p>}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="pcform.message">
                  <Form.Label>Message</Form.Label>
                  <textarea className="form-control" rows={3}
                    {...register("message", {
                      required: true,
                      pattern: /^[a-zA_Z\d ]*$/
                    })}
                  // value={message}
                  //   onChange={onTitleChange}
                  />
                  {errors.message && errors.message.type === "required" && <p style={{ color: "red" }}>This message is required</p>}
                  {errors.message && errors.message.type === "pattern" && <p style={{ color: "red" }}>Please correct the Message</p>}
                </Form.Group>
              </Col>
            </Row>
           
           <center>
              <Col md={12}>
                <Form.Group style={{ width: "20%" }} className="mb-3" controlId="pcform.submit">
                  <input type="submit"
                    className="form-control btn sbt-btn btn-primary" />
                </Form.Group>


              </Col>
              </center>
            

          </form>

          {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
          <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

        </Card.Body>

      </Card>

          </Col>
          </Row>



    </Container>
  )



  // };
  // const INITIAL = {
  //   message: "",
  //   severity: "",
  //   payer_id: 0

  // }

  // const TicketRequest = () => {


  //   const url = process.env.REACT_APP_BASE_URL+"/api/requestedtickets"
  //   const [data, setData] = useState(INITIAL);
  //   const [message, setMessage] = useState(null);

  //   function handleSubmit(e) {
  //     e.preventDefault();


  //      axios.post(url, data)
  //     .then(res => {
  //       if(res.data.code ===200){
  //         swal({
  //               title:"Successfully created a requested tickets",
  //               text:res.data.message,
  //               icon:"success",
  //               button:"Ok"
  //             })

  //           }
  //       console.log(res.data,"padma")
  //     })


  //     .catch(err => {
  //       if (err) {
  //         // swal({
  //         //   title:"error",
  //         //   text:err.data.message,
  //         //   icon:"error",
  //         //   button:"Ok"
  //         // })
  //         swal("Oh noes!", "The AJAX request failed!", "error");
  //       }

  //     //  }
  //      console.log(err.message,"padma")


  //     })


  //   }

  //   function handleChange(e) {
  //     const { id, value } = e.target; //destructuring 
  //     setData({ ...data, [id]: value })
  //   }


  //   // return (
  //   //   <div className="message-form">
  //   //     <div className="container">
  //   //       <div className="title">
  //   //         <span>Contact Now</span>
  //   //         <div className="main-title">Send us a message</div>
  //   //       </div>
  //   //       {/* form start */}
  //   //       <form className="apply" onSubmit={handleSubmit}>

  //   //         {message && <div>{message}</div>}

  //   //         <div className="row row-1">
  //   //           {/* Name here */}
  //   //           <div className="input-field name">
  //   //             <label htmlFor="Payer"></label>
  //   //             <input onChange={handleChange}
  //   //               value={data.payer_id}
  //   //               required
  //   //               type="text"
  //   //               placeholder="Your Payer"
  //   //               name="payer_id"
  //   //               id="payer_id"
  //   //             />
  //   //           </div>
  //   //           <div className="input-field name">
  //   //             <label htmlFor="Name">Severity</label>
  //   //             <input onChange={handleChange}
  //   //               value={data.severity}
  //   //               required
  //   //               type="text"
  //   //               placeholder="Your Last Name"
  //   //               name="severity"
  //   //               id="severity"
  //   //             />
  //   //           </div>
  //   //         </div>

  //   //         <div className="row row-2">
  //   //           {/* phone here */}
  //   //           <div className="input-field phone">
  //   //             <label htmlFor="Phone">Message</label>
  //   //             <input onChange={handleChange}
  //   //               value={data.message}
  //   //               required
  //   //               type="text"
  //   //               placeholder="Your Phone Here"
  //   //               name="message"
  //   //               id="message"
  //   //             />
  //   //           </div>


  //   //         </div>



  //   //         {/* submit button */}
  //   //         <button hoverText="Submit" hover="hoverTrue">
  //   //           Send Now
  //   //         </button>
  //   //       </form>
  //   //       {/* Form end */}
  //   //     </div>
  //   //   </div>
  //   // );
  //   return (
  //         <Container fluid="md">
  //           <Card
  //             border="primary"
  //             style={{ width: "50%", align: "center", marginLeft: "25%" }}
  //           >
  //             <Card.Header
  //               as="h5"
  //               style={{ backgroundColor: "blue", color: "#ffffff" }}
  //             >
  //               Request Ticket
  //             </Card.Header>
  //             <Card.Body>


  //             {/* onSubmit={handleSubmit} */}
  //                <form  onSubmit={handleSubmit} >

  //                 <Row>
  //                   <Col md={12}>
  //                     <Form.Group className="mb-3" controlId="pcform.payername">
  //                       <Form.Label>Payer Name</Form.Label>
  //                       <br />
  //                       <select className="form-control"  name="payer" id ="payer_id" 
  //                     onChange={handleChange}
  //                                    value={data.payer_id}
  //                                    required

  //                         // {...register("payer", {required:true,  validate: (value) => value !== "Select Payer"})}
  //                          >
  //                         <option>Select Payer</option>
  //                         <option >1</option>
  //                         <option >2</option>
  //                         <option >3</option>
  //                       </select>

  //     				  {/* {errors.payer && <p style={{color:"red"}}>Please select at least one option</p>}  */}

  //                     </Form.Group>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col md={12}>
  //                     <Form.Group className="mb-3" controlId="pcform.severity">
  //                       <Form.Label>Severity</Form.Label><br />
  //                       <select className="form-control" name="severity"  id="severity"   
  //                        required
  //                          onChange={handleChange}
  //                          value={data.severity}

  //                       // {...register("severity", {required:true})}
  //                       >
  //                         <option>High</option>
  //                         <option>Low</option>
  //                         <option>Mid</option>
  //                         <option>Three</option>
  //                       </select>
  //     				  {/* {errors.severity && <p style={{color:"red"}}>Please select at least one option</p>} */}
  //                     </Form.Group>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col md={12}>
  //                     <Form.Group className="mb-3" controlId="pcform.message">
  //                       <Form.Label>Message</Form.Label>
  //                       <textarea className="form-control"  name="message" id="message" rows={3}
  //                       required
  //               //          {...register("message", { required:true,
  //     					// pattern: /^[a-zA_Z\d ]*$/  } )}
  //               onChange={handleChange}
  //               value={data.message}
  //            />
  //     					{/* {errors.message && errors.message.type === "required" && <p style={{color:"red"}}>This message is required</p>}
  //     					{errors.message && errors.message.type === "pattern" && <p style={{color:"red"}}>Please correct the Message</p>} */}
  //                     </Form.Group>
  //                   </Col>
  //                 </Row>
  //                 <Row>
  //                   <Col md={4}>
  //                     <Form.Group className="mb-3" controlId="pcform.submit">
  //                        <input type="submit" hover="hoverTrue"
  //                            className="form-control btn btn-primary" 
  //                           />

  //                     </Form.Group>
  //                   </Col>
  //                 </Row>
  //               </form>
  //             </Card.Body>
  //           </Card>
  //         </Container>
  //       )

}




export default TicketRequest;





