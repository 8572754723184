// import React, { Component, useState, useEffect } from 'react';

// import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
// import { set, useForm } from "react-hook-form";
// import axios from 'axios';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
// import { propTypes } from 'react-bootstrap/esm/Image';
// import { errorPrefix } from '@firebase/util';
// import swal from 'sweetalert';
// import { Link, useHistory, useParams } from 'react-router-dom';

// import './css/Form.css';
// const btn = {
//     width: "20%",
//     textAlign: "center",
//     marginLeft: "40%",
// };

// const col = {
//     margin: "2px",
// };


// let ViewProfile = () => {


//     const { register, handleSubmit, reset, formState: { errors } } = useForm();
//     const [errorMessage, setErrorMessage] = React.useState("");
//     const [successMessage, setSuccessMessage] = React.useState("");
//     const [resultedOrganization, setOrganization] = useState({name:"",first_name:"",last_name:"",email:"",phone:"",payer_id:"",user_id:""});
//     const [resultedpayerid, setpayerid] = useState("");

//   const history = useHistory();

//     const onSubmit = data => {
//         console.log("onSubmit",data)
//         console.log("resultedpayerid ",resultedpayerid);
//         console.log("userid ",sessionStorage.getItem('userid'));

//         axios
//             .put(
//                 process.env.REACT_APP_BASE_URL+`/api/organization/${resultedpayerid}`,
//                 data,
//                 //   { headers: { 'Content-Type': 'application/json' }}
//             )
//             .then(response => {
//                 setSuccessMessage("Successfully created a organization!")
//                 reset(response.data);
//                 setTimeout(() => {
//                     setSuccessMessage()
//                 }, 2000);
//                 history.push('/Documentation');
//                 console.log(response.data, "organization")
//             })
//             .catch(error => {
//                 setErrorMessage("Cannot created organization")
//                 reset(error.data);
//                 setTimeout(() => {
//                     setErrorMessage()
//                 }, 2000);
//                 console.log(error.data)
//             });
//     };

//     useEffect(() => {
//         getOrganizationDetails();
//     }, []);

//     function getOrganizationDetails() {
//         console.log("getOrganizationDetails userid ",sessionStorage.getItem('userid'));

//         axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByUserId/${sessionStorage.getItem('userid')}`,).then(res => {
//         console.log("length",res.data.data.length)
//         if(res.data.data.length!=0)
//         {

//             // setOrganization(res.data.data);
//             setOrganization({name:res.data.data[0].name,first_name:res.data.data[0].first_name,last_name:res.data.data[0].last_name,email:res.data.data[0].email,phone:res.data.data[0].phone,payer_id:res.data.data[0].payer_id,user_id:sessionStorage.getItem('userid')})
//             setpayerid(res.data.data[0].payer_id);
//             // sessionStorage.setItem('userid', res.data.data[0].payer_id);
//             console.log(res.data.data, "getOrganizationDetails11111")
//             console.log(res.data.data[0].payer_id, "getOrganizationDetails")

//         }
//         });
//     }

//     return (
//         <Container md="fluid">
//             <Card border="primary"
//                 style={{ marginTop: "56px" }}>
//                 {/* <Card.Header
//                     as="h5"
//                     style={{ backgroundColor: "Blue", color: "#ffffff" }}
//                 >
//                     Fill The Form
//                 </Card.Header> */}

//                         <h3 className='text-center mb-3 heading'>Organization Details</h3>
//                         <center><hr className='heading_line'></hr></center>
//                         <br></br>
//                 {/* {
//                 resultedOrganization.map(result => {
//                     return( */}
//                 {/* <Card.Body> */}
//                     <form onSubmit={handleSubmit(onSubmit)} >
//                         <Row>
//                             <Col md={6} style={{ textAlign: "left" }}>

//                                 <Card border="primary"
//                                     style={{ width: "100%", marginLeft: "5%" }} >
//                                     <div md={12}
//                                         style={{ width: "100%" }}>
//                                         {/* <Card.Header
//                                             as="h5"
//                                             style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                                         >
//                                             Organization Details
//                                         </Card.Header> */}


//                                   <center><div className="box_tittle"><h4>Organization Details</h4></div></center>
//                                         <Col md={12} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

//                                             <Form.Group
//                                                 controlId="pcform.organizationname"
//                                                 className="mb-3"
//                                             >
//                                                 <Form.Label>Organization Name</Form.Label>
//                                                 <Form.Control type="text" value={resultedOrganization.name} placeholder="organization name"
//                                                     // {...register("name")}
//                                                     // name="name" required />
//                                                     //  {errors.name && errors.name.type === "required" &&
//                                                     //  <p style={{ color: "red" }}>Payer Name is required</p>}

//                                                     {...register("name")}
//                                                     />
//                                                     <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

//                                                     {...register("payer_id")}
//                                                     />
//                                                      <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

//                                                    {...register("user_id")}
//                                                    />

//                                              {errors.name && errors.name.type === "required" &&
//                                              <p style={{ color: "red" }}>Organization Name is required</p>}

//                                                 {/* <div style={{ color: "red" }} id="error_organizationname"></div> */}
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group controlId="pcform.email" className="mb-1">
//                                                 <Form.Label>Organization Email</Form.Label>
//                                                 <Form.Control type="text" placeholder="organization email"

//                                                 {...register("email", { required: true})}
//                                                     />
//                                              {errors.email && errors.email.type === "required" &&
//                                             <h4 style={{ color: "red" }}>*</h4>}
//                                             </Form.Group>
//                                         </Col>
//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group
//                                                 controlId="pcform.organizationname"
//                                                 className="mb-3"
//                                             >
//                                                 <Form.Label>Organization Phone</Form.Label>
//                                                 <Form.Control type="text" placeholder="organization phone"

//                                                 {...register("phone", { required: true})}
//                                                     />
//                                              {errors.phone && errors.phone.type === "required" &&
//                                               <h4 style={{ color: "red" }}>*</h4>}

//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group controlId="pcform.email" className="mb-1">
//                                                 <Form.Label>Organization Address Line-1</Form.Label>
//                                                 <Form.Control type="text" placeholder="organization address line-1"

//                                                     {...register("address1", { required: true})}
//                                                     />
//                                              {errors.address1 && errors.address1.type === "required" &&
//                                               <h4 style={{ color: "red" }}>*</h4>}



//                                             </Form.Group>
//                                         </Col>
//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group
//                                                 controlId="pcform.address2"
//                                                 className="mb-3"
//                                             >
//                                                 <Form.Label>Organization Address Line-2</Form.Label>
//                                                 <Form.Control type="text" placeholder="organization address line-2"

//                                                   {...register("address2", { required: true})}
//                                                   />
//                                            {/* {errors.address2 && errors.address2.type === "required"  &&
//                                             <h4 style={{ color: "red" }}>*</h4>
//                                            } */}
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group controlId="pcform.city" className="mb-1">
//                                                 <Form.Label>City</Form.Label>
//                                                 <Form.Control type="text" placeholder="city"
//                                                   {...register("city", { required: true})}
//                                                   />
//                                            {errors.city && errors.city.type === "required" &&
//                                             <h4 style={{ color: "red" }}>*</h4>}

//                                             </Form.Group>
//                                         </Col>
//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group
//                                                 controlId="pcform.state"
//                                                 className="mb-1"
//                                             >
//                                                 <Form.Label>State</Form.Label>
//                                                 <Form.Control type="text" placeholder="state"

//                                                   {...register("state", { required: true})}
//                                                   />
//                                            {errors.state && errors.state.type === "required" &&
//                                             <h4 style={{ color: "red" }}>*</h4>}
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group controlId="pcform.email" className="mb-1">
//                                                 <Form.Label >Postal Code  </Form.Label>
//                                                 <Form.Control type="text" placeholder="postal code"
//                                                 {...register("postal_code", { required: true})}
//                                                   />
//                                            {errors.postal_code && errors.postal_code.type === "required" &&
//                                             <h4 style={{ color: "red" }}>*</h4>}
//                                             </Form.Group>
//                                         </Col>
//                                         <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
//                                             <Form.Group controlId="pcform.email" className="mb-1">
//                                                 <Form.Label>Payer Details</Form.Label>
//                                                 <Form.Control as="textarea" rows={3}
//                                                 {...register("payer_details", { required: true})}
//                                                   />
//                                            {errors.payer_details && errors.payer_details.type === "required" &&
//                                            <h4 style={{ color: "red" }}>*</h4>}
//                                             </Form.Group>
//                                         </Col>

//                                     </div>
//                                 </Card>
//                             </Col>
//                             <Col md={6} style={{ textAlign: "left" }}>

//                                 <Card border="primary"
//                                     style={{ width: "80%", marginLeft: "10%" }} >
//                                     {/* <Card.Header
//                                         as="h5"
//                                         style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                                     >
//                                         Contact Person 1 Details
//                                     </Card.Header> */}

//                         <center><div className="box_tittle"><h4>Contact Person 1 Details</h4></div></center>
//                                     <Row md={12} style={{  marginLeft: "3%" }}>

//                                         <Col md={6}  style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.fname1" className="mb-3">
//                                                 <Form.Label>First Name</Form.Label>

//                                                         <Form.Control type="text" value={resultedOrganization.first_name} placeholder="first name" readOnly = {true}
//                                                         {...register("first_name")} name="first_name"
//                                                         />


//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.lname1" className="mb-3">
//                                                 <Form.Label>Last Name</Form.Label>

//                                                         <Form.Control type="text" value={resultedOrganization.last_name} placeholder="last name" readOnly = {true}
//                                                         {...register("last_name")} name="last_name"
//                                                     />

//                                             </Form.Group>
//                                         </Col>

//                                     </Row>
//                                     <Row md={12} style={{ marginLeft: "3%" }}>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.fname1" className="mb-3">
//                                                 <Form.Label>Email</Form.Label>
//                                                 <Form.Control type="text" value={resultedOrganization.email} placeholder="email" readOnly = {true}

//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.lname1" className="mb-3">
//                                                 <Form.Label>Phone</Form.Label>
//                                                 <Form.Control type="text" value={resultedOrganization.phone} placeholder="phone" readOnly = {true}

//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                     </Row>

//                                 </Card>

//                                 <br></br>
//                                 <br></br>
//                                 <Card border="primary"
//                                     style={{ width: "80%", marginLeft: "10%" }} >
//                                     {/* <Card.Header
//                                         as="h5"
//                                         style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                                     >
//                                         Contact Person 2 Details
//                                     </Card.Header> */}

//                         <center><div className="box_tittle"><h4>Contact Person 2 Details</h4></div></center>
//                                     <Row md={12} style={{ marginLeft: "3%" }}>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.fname2" className="mb-3">
//                                                 <Form.Label>First Name</Form.Label>
//                                                 <Form.Control type="text" placeholder="first name"
//                                                     {...register("first_name2")} name="first_name2"
//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.lname2" className="mb-3">
//                                                 <Form.Label>Last Name</Form.Label>
//                                                 <Form.Control type="text" placeholder="last name"
//                                                     {...register("last_name2")} name="last_name2"
//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                     </Row>
//                                     <Row md={12} style={{  marginLeft: "3%" }}>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.email2" className="mb-3">
//                                                 <Form.Label>Email</Form.Label>
//                                                 <Form.Control type="text" placeholder="email" name="email2"
//                                                     {...register("email2")}
//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                         <Col md={6} style={{ textAlign: "left" }}>
//                                             <Form.Group controlId="pcform.phone2" className="mb-3">
//                                                 <Form.Label>Phone</Form.Label>
//                                                 <Form.Control type="text" placeholder="phone" name="phone2"
//                                                 {...register("phone2")}
//                                                 />
//                                             </Form.Group>
//                                         </Col>

//                                     </Row>

//                                 </Card>
//                             </Col>


//                         </Row>
// <br></br>
//                         <Col md={12} style={{ textAlign: "center" }}>
//                             <Button   type="submit" variant="primary" >SUBMIT</Button>
//                         </Col>

//                     </form>
//                     <br></br>
//                     <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
//                     <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

//                 {/* </Card.Body> */}
//                  {/* )

//                 })
//               } */}
//             </Card>
//         </Container>



//     )
// }
// export default ViewProfile;


import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';

import './css/Form.css';


let ViewProfile = () => {


    const { register, handleSubmit, reset, trigger, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [resultedOrganization, setOrganization] = useState({ name: "", first_name: "", last_name: "", email: "", phone: "", payer_id: "", user_id: "" });
    const [setpayerid] = useState("");




    const onSubmit = data => {
        console.log("data", data)
        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/createOrganizationdetails',
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a create orgnization details!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                console.log(response.data)
            })
            .catch(error => {
                setErrorMessage("Cannot Created Orgnization Details")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };

    /*const onSubmit = data => {
        console.log("onSubmit",data)
        console.log("resultedpayerid ",resultedpayerid);
        console.log("userid ",sessionStorage.getItem('userid'));

        axios
            .put(
                process.env.REACT_APP_BASE_URL+`/api/organization/${resultedpayerid}`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a organization!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/Documentation');
                console.log(response.data, "organization")
            })
            .catch(error => {
                setErrorMessage("Cannot created organization")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };*/

    useEffect(() => {
        getOrganizationDetails();
    }, []);

    function getOrganizationDetails() {
        console.log("getOrganizationDetails userid ", sessionStorage.getItem('userid'));

        axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByUserId/${sessionStorage.getItem('userid')}`,).then(res => {
            console.log("length", res.data.data.length)
            if (res.data.data.length != 0) {

                // setOrganization(res.data.data);
                setOrganization({ name: res.data.data[0].name, first_name: res.data.data[0].first_name, last_name: res.data.data[0].last_name, email: res.data.data[0].email, phone: res.data.data[0].phone, payer_id: res.data.data[0].payer_id, user_id: sessionStorage.getItem('userid') })
                //  setpayerid(res.data.data[0].payer_id);
                // sessionStorage.setItem('userid', res.data.data[0].payer_id);
                console.log(res.data.data, "getOrganizationDetails11111")
                console.log(res.data.data[0].payer_id, "getOrganizationDetails")

            }
        });
    }

    return (
        <Container fluid="md">
        <Row>

          <Col md={12} style={{ textAlign: "left" }}>
            <Card
                style={{  }}>
                {/* <Card.Header
                    as="h5"
                    style={{ backgroundColor: "Blue", color: "#ffffff" }}
                >
                    Fill The Form
                </Card.Header> */}

                <h3 className='text-center mb-3 heading'>Organization Details</h3>
                <center><hr className='heading_line'></hr></center>
                <br></br>
                {/* {
                resultedOrganization.map(result => {
                    return( */}
                {/* <Card.Body> */}
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Row>
                        <Col md={7} style={{ textAlign: "left" }}>

                            <Card border="primary"
                                style={{ width: "100%", marginLeft: "5%" }} >
                                <div md={12}
                                    style={{ width: "100%" }}>
                                    {/* <Card.Header
                                            as="h5"
                                            style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                        >
                                            Organization Details
                                        </Card.Header> */}


                                    <center><div className="box_tittle"><h4>Organization Details</h4></div></center>
                                    <Row>
                                        <Col md={12} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

                                            <Form.Group
                                                controlId="pcform.organizationname"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Organization Name</Form.Label>
                                                <Form.Control type="text" placeholder="organization name"


                                                    {...register("name", { required: true })}
                                                />

                                                {errors.name && errors.name.type === "required"
                                                }

                                                {/* <div style={{ color: "red" }} id="error_organizationname"></div> */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.type" className="mb-1">
                                                <Form.Label><span style={{ color: "red" }}>*</span>Organization Type </Form.Label>
                                                <Form.Control type="text" placeholder="Organization type"



                                                    {...register("type", { required: true })}
                                                />
                                                {errors.type && errors.type.type === "required"}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.organizationwebsite"
                                                className="mb-3"
                                            >
                                                <Form.Label> <span style={{ color: "red" }}>*</span>Organization Website</Form.Label>
                                                <Form.Control type="text" placeholder="organization website"

                                                    {...register("website", { required: true })}
                                                />
                                                {errors.website && errors.website.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.organizationlogo"
                                                className="mb-3"
                                            >
                                                <Form.Label> <span style={{ color: "red" }}>*</span>Organization Logo</Form.Label>
                                                <Form.Control type="text" placeholder="organization logo"

                                                    {...register("logo", { required: true })}
                                                />
                                                {errors.logo && errors.logo.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.username"
                                                className="mb-3"
                                            >
                                                <Form.Label> <span style={{ color: "red" }}>*</span>Username</Form.Label>
                                                <Form.Control type="text" placeholder="Username"

                                                    {...register("username", { required: true })}
                                                />
                                                {errors.username && errors.username.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.password"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Password</Form.Label>
                                                <Form.Control type="password" placeholder="Password"

                                                    {...register("password", { required: true })}
                                                />
                                                {errors.password && errors.password.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.address1"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Address 1</Form.Label>
                                                <Form.Control type="text" placeholder="Address 1"

                                                    {...register("address1", { required: true })}
                                                />
                                                {errors.address1 && errors.address1.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.address2"
                                                className="mb-3"
                                            >
                                                <Form.Label>Address 2</Form.Label>
                                                <Form.Control type="text" placeholder="Address 2"

                                                    {...register("address2", { required: true })}
                                                />
                                                {errors.address && errors.address2.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.city"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>City</Form.Label>
                                                <Form.Control type="text" placeholder="City"

                                                    {...register("city", { required: true })}
                                                />
                                                {errors.city && errors.city.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.state"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>State</Form.Label>
                                                <Form.Control type="text" placeholder="State"

                                                    {...register("state", { required: true })}
                                                />
                                                {errors.state && errors.state.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.country"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Country</Form.Label>
                                                <Form.Control type="text" placeholder="Country"

                                                    {...register("country", { required: true })}
                                                />
                                                {errors.country && errors.country.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group controlId="pcform.zip" className="mb-1">
                                            <Form.Label ><span style={{ color: "red" }}>*</span>Zip Code </Form.Label>
                                            <Form.Control type="text" placeholder="Zip code"
                                                {...register("zip_code", { required: true })}
                                            />
                                            {errors.zip_code && errors.zip_code.type === "required"
                                            }
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        {/* <Form.Group
                                            controlId="pcform.phone"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Phone</Form.Label>
                                            <Form.Control type="text" placeholder="Phone"

                                                {...register("phone", { required: true })}
                                            />
                                            {errors.phone && errors.phone.type === "required"
                                            }

                                        </Form.Group> */}
                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                        <Form.Label><span style={{ color: "red" }}>*</span>Phone</Form.Label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.phone && "invalid"}`}
                                        {...register("phone", {
                                          //  required: "Phone is Required",
                                            pattern: {
                                                value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                message: "Invalid phone number",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("phone");
                                        }}
                                    />
                                   {errors.phone && (
                                            < p style={{ color: "red" }}>{errors.phone.message}</p>
                                        )}
                                </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.fax"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Fax</Form.Label>
                                            <Form.Control type="text" placeholder="Fax"

                                                {...register("fax", { required: true })}
                                            />
                                            {errors.fax && errors.fax.type === "required"
                                            }

                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.org_ein"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Orgnization EIN</Form.Label>
                                            <Form.Control type="text" placeholder="Orgnization EIN"

                                                {...register("ein", { required: true })}
                                            />
                                            {errors.ein && errors.ein.type === "required"
                                            }

                                        </Form.Group>
                                    </Col>


                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.policylink"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Privacy Policy Link</Form.Label>
                                            <Form.Control type="text" placeholder="Policy link"

                                                {...register("policylink", { required: true })}
                                            />
                                            {errors.policylink && errors.policylink.type === "required"
                                            }

                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.conditonlink"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Terms Conditions Link</Form.Label>
                                            <Form.Control type="text" placeholder="Terms conditions link"

                                                {...register("conditionlink", { required: true })}
                                            />
                                            {errors.conditionlink && errors.conditionlink.type === "required"
                                            }

                                        </Form.Group>
                                    </Col>

                                    {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.email" className="mb-1">
                                                <Form.Label>Payer Details</Form.Label>
                                                <Form.Control as="textarea" rows={3}
                                                {...register("payer_details")}
                                                  />

                                            </Form.Group>
                                    </Col>*/}

                                </div>
                                <br></br>
                            </Card>
                        </Col>
                        <Col md={5} style={{ textAlign: "left" }}>

                            <Card border="primary"
                                style={{ width: "80%", marginLeft: "10%" }} >
                                {/* <Card.Header
                                        as="h5"
                                        style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                    >
                                        Contact Person 1 Details
                                    </Card.Header> */}

                                <center><div className="box_tittle"><h4>Contact Person 1 Details</h4></div></center>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname1" className="mb-3">
                                            <Form.Label>First Name</Form.Label>

                                            <Form.Control type="text" value={resultedOrganization.first_name} placeholder="first name" readOnly={true}
                                                {...register("first_name")} name="first_name"
                                            />


                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname1" className="mb-3">
                                            <Form.Label>Last Name</Form.Label>

                                            <Form.Control type="text" value={resultedOrganization.last_name} placeholder="last name" readOnly={true}
                                                {...register("last_name")} name="last_name"
                                            />

                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname1" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" value={resultedOrganization.email} placeholder="email" readOnly={true}

                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname1" className="mb-3">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" value={resultedOrganization.phone} placeholder="phone" readOnly={true}

                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <br></br>
                            </Card>

                            <br></br>
                            <br></br>
                            <Card border="primary"
                                style={{ width: "80%", marginLeft: "10%" }} >
                                {/* <Card.Header
                                        as="h5"
                                        style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                    >
                                        Contact Person 2 Details
                                    </Card.Header> */}

                                <center><div className="box_tittle"><h4>Contact Person 2 Details</h4></div></center>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname2" className="mb-3">
                                            <Form.Label><span style={{ color: "red" }}>*</span>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="first name"
                                                {...register("first_name", { required: true })} name="first_name"
                                            />
                                        </Form.Group>
                                        {errors.first_name && errors.first_name.type === "required"}
                                    </Col>


                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname2" className="mb-3">
                                            <Form.Label><span style={{ color: "red" }}>*</span>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="last name"
                                                {...register("last_name", { required: true })} name="last_name"
                                            />
                                        </Form.Group>
                                        {errors.last_name && errors.last_name.type === "required"
                                        }
                                    </Col>

                                </Row>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>

                                        <Form.Group className="mb-3" controlId="pcform.email">
                                            <Form.Label><span style={{ color: "red" }}>*</span>Email</Form.Label>
                                            <input class="form-control"
                                                {...register("email", { required: true, pattern: /^\S+@\S+$/i })}

                                            />
                                            {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid  Email</p>}

                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>

                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                            <Form.Label><span style={{ color: "red" }}>*</span>Phone</Form.Label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.phone2 && "invalid"}`}
                                                {...register("phone2", {
                                                    // required: "Phone is Required",
                                                    pattern: {
                                                        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                        message: "Only 10 digits ",
                                                    },
                                                })}
                                                onKeyUp={() => {
                                                    trigger("phone2");
                                                }}
                                            />
                                            {errors.phone2 && (
                                                < p style={{ color: "red" }}>{errors.phone2.message}</p>
                                            )}
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <br></br>
                            </Card>
                        </Col>


                    </Row>
                    <br></br>
                    <Col md={12} style={{ textAlign: "center" }}>
                        <Button type="submit" variant="primary" >SUBMIT</Button>
                    </Col>

                </form>
                <br></br>
                <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                {/* </Card.Body> */}
                {/* )

                })
              } */}
            </Card>
            </Col>

            </Row>
            <br></br>
        </Container>


    )
}
export default ViewProfile;

