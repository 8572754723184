import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import "bootstrap/dist/css/bootstrap.css";

import _ from 'lodash'
import axios from 'axios';
import { useForm } from "react-hook-form";

let SearchPayer = () => {
    const [selectedValue, setSelectedValue] = useState([]);
   const handleChange = (e) => {
    console.log("handleChange",e)
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

    var payerName = 'All'
    var localtion='All'
    var organizationtype='All'
     var selectedValue = e.map(x=>x.value)
     console.log(selectedValue,"selectedValue")
    console.log(selectedValue, "payerList-selectedtypeValue")
    console.log(selectedValue.length, "payerList-selectedValue Count")


    if (selectedValue.length>0) {
        console.log(selectedValue, "selectedValue")
        localtion=selectedValue;
    }
    else
    {
        console.log(selectedValue, "ELSE selectedValue")
    }

    console.log(payerName, "=== payerName")
    console.log(localtion,organizationtype, "=== localtion")
    getPayerList(payerName, localtion,organizationtype)


}

    const handletypeChange = (e) => {
        console.log("organizationType",e)
        setSelectedtypeValue(Array.isArray(e) ? e.map(x => x.value) : []);
         console.log("selectedtypeValue",selectedtypeValue)
         var payerName = 'All'
         var localtion='All'
         var organizationtype='All'
         var selectOrganization = e.map(x=>x.value)
         console.log(selectOrganization,"selectOrganization")
         console.log(selectOrganization, "payerList-selectedtypeValue")
         console.log(selectOrganization.length, "payerList-selectedValue Count")
         if (selectOrganization.length>0) {
             console.log(selectOrganization, "selectedtypeValue")
             organizationtype=selectOrganization;

         }
         else
         {
             console.log(selectOrganization, "ELSE selectedtypeValue")
         }

         // if (data.organisation_name != '') {
         //     console.log(payerName, "organisation_name")
         //     payerName=data.organisation_name;
         // }
         // else
         // {
         //     console.log(payerName, "ELSE organisation_name")
         // }
         console.log(payerName, "=== payerName")
         console.log(localtion,organizationtype, "=== localtion")
         getPayerList(payerName, localtion,organizationtype)


    }



    const [userList, setUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [organizationTypeList, setOrganizationTypeList] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [endpointNamescount, setEndpointNamesCount] = useState([]);
    //  const [selectedValue, setSelectedValue] = useState([]);
    const [selectedtypeValue, setSelectedtypeValue] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;


    const [organisation_name, setOrganisationName] = useState("");
   const { register, handleSubmit, reset, trigger, formState } = useForm();
   const { errors } = formState;
    const history = useHistory();


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const succmessage = urlParams.get('success');
        console.log("Login useEffect", succmessage);
        setSuccessMessage(succmessage);
        setTimeout(() => {
            setSuccessMessage();
        }, 3000);


        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")

        if (user_id == 'undefined') {
            history.push('/userlogin');

        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }

        else {
            getPayers();
            getLocation();
            getOrganizationType();
            getEndpointNamesCount()

            var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

            if (elements.length) {
                elements[0].id = 'dt_dropdown';
            }


            //   console.log(user_id,"user_id")

        }


    }, []);

    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        {
            dataField: "remove",
            text: "Organization Name",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/ViewOrganization/${row.value}`}>
                        {row.name}

                    </Link>

                );
            },
        },
        // {
        //     dataField: "point_count",
        //     text: "Number of endpoints",
        //     formatter: (cellContent, row) => {
        //         return (
        //             <div>
        //              {endpointNamescount.map(x => {
        //                 return (
        //                  <div>{x.point_count}</div>
        //                 )
        //               })
        //             }
        //           </div>

        //         );
        //     },
        // },
        { dataField: 'organisation_type', text: 'Organization Type' },

        // { dataField: 'count', text: 'Number of Endpoints' },
        { dataField: 'fullname', text: 'Modified By' },
        { dataField: 'updated_date', text: 'Modified On' },


    ]


    function getPayers() {

        getPayerList('All', 'All', 'All')

    }

    const onSubmit = data => {
        var payerName = 'All'
        var localtion='All'
        var organizationtype='All'
        console.log(data.organisation_name, "payerList-data")
        console.log(selectedValue, "payerList-data-label")
        console.log(selectedtypeValue, "payerList-selectedtypeValue")
        console.log(selectedtypeValue.length, "payerList-selectedValue Count")
        if (selectedValue.length>0) {
            console.log(selectedValue, "selectedValue")
            localtion=selectedValue;
        }
        else
        {
            console.log(selectedValue, "ELSE selectedValue")
        }
        if (selectedtypeValue.length>0) {
            console.log(selectedtypeValue, "selectedtypeValue")
            organizationtype=selectedtypeValue;

        }
        else
        {
            console.log(selectedtypeValue, "ELSE selectedtypeValue")
        }

        if (data.organisation_name != '') {
            console.log(payerName, "organisation_name")
            payerName=data.organisation_name;
        }
        else
        {
            console.log(payerName, "ELSE organisation_name")
        }
        console.log(payerName, "=== payerName")
        console.log(localtion,organizationtype, "=== localtion")
        getPayerList(payerName, localtion,organizationtype)

    };
    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }
    function getOrganizationType() {
        console.log(getOrganizationType, "getOrganizationType")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getOrganisationTypes`).then(res => {
            setOrganizationTypeList(res.data.data);
            console.log(res.data.data, "setOrganizationTypeList");
        });
    }
    function getIdPayerList(value) {
        fetch(process.env.REACT_APP_BASE_URL + `/api/organization/getOrganisationByViewId/${value}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {

                console.log(resp, "getOrganisationByViewId")
                console.log(resp.data, "getOrganisationByViewId")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }



    function getEndpointNamesCount() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getEndpointCount').then(res => {
            setEndpointNamesCount(res.data.data);
            console.log(res.data.data, "endpointnames count");
        });
    }
    function getRequestePayerList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/organization').then(res => {
            setPayerList(res.data.data);
        });
    }

    function getPayerList(payername, location, organizationtype) {
        console.log("getPayerList function involked ", payername, location, organizationtype)
        var payerid = sessionStorage.getItem('payerid')

        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/get/${payername}/${location}/${payerid}/${organizationtype}`).then(res => {
            setUserList(res.data.data);
            // setUserList()
            console.log(res.data.data, "getPayerList ==== ");


        });
    }


    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })



    return (
        <Container fluid="md">

            <Row>

                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", align: "center" }} >
                        <h4 style={{ width: "100%" }}> {successMessage && <div
                            className="d-flex justify-content-center error" style={{ color: "green", marginLeft: "25%", paddingTop: "10px" }} > {successMessage}</div>}
                            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong></h4>
                        {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff", }}>Search  Payer </Card.Header>  */}
                        <h3 className='text-center mb-3 heading'>Organization Directory </h3>

                        <br></br>
                        <center>

                            <Row className="row-centered">


{/* <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red"}} > {errorMessage}</div>} </h4> */}


<div class="form-group row col-md-6 ">
                <label for="filters" class="col-form-label filter_label">Filters</label>
                <div class="col-md-5">
                    <Select
                        className="dropdown"
                        placeholder="Select Type"
                        selectedtypeValue
                        label={organizationTypeList.filter(obj => selectedtypeValue.includes(obj.label))}
                        options={organizationTypeList}
                        onChange={handletypeChange}
                        isMulti
                        isClearable

                    />



                </div>
                <div class="col-md-5">
                <Select
                        className="dropdown"
                        placeholder="Select State"
                        selectedValue
                        label={locationList.filter(obj => selectedValue.includes(obj.label))}
                        options={locationList}
                        onChange={handleChange}
                        isMulti
                        isClearable

                    />
                </div>
            </div>


        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>


<Row className="row-centered">


    <Form.Group className="form-group row col-md-9" controlId="">
        <div class="col-md-10">

        <Form.Group className="mb-3" controlId="pcform.organisation_name">

            <input className="form-control" type="text" placeholder="Search Directory"
                {...register("organisation_name")}
            />

        </Form.Group>

        </div>


        <Form.Group className="mb-4" controlId="pcform.submit">
            <input type="submit" className="search_btn btn btn-primary"/>
        </Form.Group>
    </Form.Group>


</Row>
</form>

                        </center>
                        <br></br>


                        <div className="container-fluid p-3">
                            <BootstrapTable bootstrap4 keyField='id'
                                columns={columns}
                                data={userList}
                                pagination={pagination}
                                filter={filterFactory()}
                            />
                        </div>

                    </Card >
                </Col>
            </Row>


        </Container >
    );

}

export default SearchPayer;



