


import { Container, Row, Col, Card } from "react-bootstrap";
import './css/pages.css'; //Import here your file style

import React, {  useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import _ from 'lodash'
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";

let PayerListDashboard = () => {


    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;
    const history = useHistory();

    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin =sessionStorage.getItem('isadmin');
        console.log(user_id,isadmin,"user_idisadmin")


        if(user_id=='undefined' && isadmin =='undefined'){
            history.push('/userlogin');

        }
        // else if (user_id!='undefined'  && isadmin =='false'){
        else if ((user_id!='undefined'  && isadmin =='false') || isadmin != 'true'){
            history.push('/userlogin');
        }

        else{
            getPayerList();
            var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

            if (elements.length) {
                elements[0].id = 'dt_dropdown';
            }


         //   console.log(user_id,"user_id")

        }


    }, []);


    const columns = [
        {
            dataField: "remove",
            text: "Payer Name",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/PayerViewOrganization/${row.value}`}>
                        {row.name}{() => getIdPayerList(row.value)}
                    </Link>
                );
            },
        },

        { dataField: 'type', text: 'Payer Type' },
        { dataField: 'email', text: 'Payer Email' },
       { dataField: 'address1', text: 'Address' },
      {
        dataField: "is_approve",
        text: "Status",
        formatter: (cellContent, row,) => {
            if (row.is_approve === 1) {
                return (
                    <button disabled={true}
                        className="btn btn-success btn-xs" Width="95px"
                        style={{ margin: "2px" }}

                    >
                      Mapped
                    </button>
                );
            }
            if (row.is_approve === null) {
                return (
                    <button disabled={true}
                        className="btn btn-warning btn-xs" Width="95px"
                        style={{ margin: "2px" }}
                    >
                     Pending
                    </button>
                );
            }

        },
    },

    ]


    function getIdPayerList(value) {
        fetch(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp.data, "getOrganisationByViewId")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }

    function getPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllAdminOrganisation').then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "sucess");

        });
    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    return (
        <Container fluid="md">



            <Row>

                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", align: "center" ,minHeight:"600px"}} >
                        <h3 className='text-center mb-3 heading'>HC Registered Payer </h3>
                        <br></br>
                        <div className="w-100 text-center mt-2">
                        <Link to={`/AddOrganization`}>
                            <button
                                className="btn btn-info " Width="95px"
                                style={{ marginLeft: "80%", borderRadius: "20px" }} id="download_button"

                            >
                                Add Payer
                            </button>
                            </Link>
                        </div>

                        <br></br>

                        <div className="container-fluid p-3">
                                <BootstrapTable bootstrap4 keyField='id'
                                    columns={columns}
                                    data={payerList}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                />
                            </div>


                    </Card >
                </Col>
            </Row>


            < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>


        </Container >
    );

}

export default PayerListDashboard;