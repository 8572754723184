import React, { Component, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
//import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { errorPrefix } from '@firebase/util';
import swal from 'sweetalert';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let ResetPassword = () => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    console.log(email,"email")
    const userEmail = email.slice(0, -168)
    console.log("userEmail ",userEmail)

    const history = useHistory();
    var user_id = sessionStorage.getItem('userid');
    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const onSubmit = data => {

        console.log(data,"data")
        console.log(user_id,"user_id")
        axios
            .put(
                process.env.REACT_APP_BASE_URL+`/api/user/ResetPassword`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Password changed successfully!")
                reset(response.data);
                history.push('/userlogin');
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                 console.log(response.data,"data") })
            .catch(error => {
                setErrorMessage("Email does not exits")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });

    };

    return (

        <Container fluid="md">

            <Card border="primary"
              style={{ width: "50%", align: "center", marginLeft: "15%" }}>
                <h3 className='text-center mb-3 heading'>Reset Password</h3>
                        <center><hr className='heading_line'></hr></center>
                        <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>

                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Control type="text" value={userEmail} hidden={true}
                       {...register("email")}
                              />

                        {/* <Form.Group className="mb-3" controlId="pcform.password">
                            <Form.Label>Password</Form.Label>
                            <input className="form-control" type="password"

                             {...register("password", {required: true } )}
                            />
                            {errors.password &&  <h4 style={{color:"red"}}>*</h4>}

                        </Form.Group> */}
                         <Form.Group className="mb-3" controlId="pcform.password">
                                <Form.Label>Password <span style={{ color: "red" }}>*</span></Form.Label>

                                <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("password", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.password && errors.password.type === "required" &&
                                    <p style={{ color: "red" }}>Password Required</p>
                                }
                                  {errors.password && errors.password.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.password && errors.password.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}

                            </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label> Confirm Password</Form.Label>
                            <input className="form-control"     type="password"

                             {...register("password", {required: true } )}
                            />
                            {errors.passwords &&  <h4 style={{color:"red"}}>*</h4>}

                        </Form.Group> */}
                         <Form.Group className="mb-3" controlId="pcform.passwords">
                                <Form.Label>Confirm Password <span style={{ color: "red" }}>*</span></Form.Label>

                                <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("passwords", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.passwords && errors.passwords.type === "required" &&
                                    <p style={{ color: "red" }}>Confirm Password Required</p>
                                }
                                  {errors.passwords && errors.passwords.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.passwords && errors.passwords.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}

                            </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
             <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

                </Card.Body>

            </Card>
        </Container>
    )

}
export default ResetPassword;

//

