
import { Container, Row, Col, Form, Button, Card, CloseButton } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import organization_img from './massachusetts.png'
import { CircularProgress, useScrollTrigger } from "@material-ui/core";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useForm } from "react-hook-form";
import { xorBy } from "lodash";
import public_profile from './public_profile.png'


const ViewOrganization = () => {
    const { value } = useParams();
    const [userList, setUserList] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [endpointUrls, setEndpointUrls] = useState([]);
    const history = useHistory();

    const [endpointNamelist, setEndPointListNames] = useState([]);
    const [endpointAdminNamelist, setEndPointAdminListNames] = useState([]);
    const [payerlistDashboard, setPayerListDashboard] = useState([])

    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { register, handleSubmit, reset, trigger, formState } = useForm();


    var isadmin = sessionStorage.getItem('isadmin');
    var is_approve = sessionStorage.getItem('is_approve');
    console.log(is_approve, "isapprove")

    const columns = [
        { dataField: 'payer_id', text: 'Id' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'email', text: 'Email' },
        { dataField: 'payer_url', text: 'Payer Url' },
        {
            dataField: "is_approve",
            text: "Action",
            formatter: (cellContent, row) => {

                return (

                    <div className="w-100 text-center mt-2">

                        <button

                            className="btn btn-success btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => updateApproveById(row.payer_id)}
                        >
                            Approve
                        </button>


                        {/* <Link to={`/viewpayer/${row.payer_id}`}> */}
                        <button
                            className="btn btn-danger btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => updateRejectedById(row.payer_id)}
                        >
                            Rejected
                        </button>

                        {/* </Link> */}
                    </div>


                );
            },
        },
    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })




    useEffect(() => {
        document.getElementById("container").classList.remove("conthome");
        // getMyEndPointsList()
        getMyEndPointsUrls()
        getMyEndPointsList_payer_id()
        getAdminMyEndPointsList()
        getPayerListDashboard()
        var user_id = sessionStorage.getItem('userid');
        console.log(user_id, "user_id")


        axios
            .get(process.env.REACT_APP_BASE_URL + `/api/organization/getOrganisationByViewId/${value}`)
            .then((res) => {

                setUserList(res.data.data);
                console.log(res.data.data, "getOrganisationByViewId")
                console.log("payerid ", sessionStorage.getItem('payerid'));
            });
    },
        []);

    function getPayerListDashboard() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getAllAdminOrganisation').then(res => {
            setPayerListDashboard(res.data.data);
            console.log(res.data.data, "sucess");

        });
    }

    function getMyEndPointsUrls() {
        // var valu = sessionStorage.getItem('payerid');
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsUrls/${value}}`).then(res => {
            setEndpointUrls(res.data.data);
            console.log(res.data.data, "getEndPointsUrls");

        });

    }

    var payer_id = sessionStorage.getItem('payerid');
    console.log(payer_id, "payer_id")
    // function getMyEndPointsList() {
    //     axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsAll/${user_id}`).then(res => {
    //         setEndPointListNames(res.data.data);
    //         console.log(res.data.data, "getMyEndPointsList")
    //     });


    // }
    //var value = sessionStorage.getItem('payerid');
    //console.log(value,"value")
    function getMyEndPointsList_payer_id() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getAllEndPoints/${value}`).then(res => {
            setEndPointListNames(res.data.data);
            console.log(res.data.data, "getAllEndPoints")
        });


    }
    var user_id = sessionStorage.getItem('userid');
    console.log(user_id, "user_id")
    function getAdminMyEndPointsList() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getAdminEndPointsAll/${user_id}`).then(res => {
            setEndPointAdminListNames(res.data.data);
            console.log(res.data.data, "getAdminMyEndPointsList")
        });


    }
    function updateApproveById(value) {
        console.log(value, "updateApproveById")
        axios.put(process.env.REACT_APP_BASE_URL + `/api/updateIsApprove/${value}`)
            //     setPayerList(res.data.data);
            //     console.log(res.data.data, "updateApprove");

            .then(response => {
                setSuccessMessage("Successfully Approved!")

                setTimeout(() => {
                    setSuccessMessage()
                }, 4000);
                console.log(response.data, "documentationdetails")
            })
            .catch(error => {
                setErrorMessage("Not Approved")

                setTimeout(() => {
                    setErrorMessage()
                }, 4000);
                console.log(error.data)
            });
    }
    function updateRejectedById(value) {
        console.log(value, "updateRejectedById")
        axios.put(process.env.REACT_APP_BASE_URL + `/api/updateIsRejected/${value}`).then(response => {
            setSuccessMessage("Successfully Reject!")

            setTimeout(() => {
                setSuccessMessage()
            }, 4000);

        })
            .catch(error => {
                setErrorMessage("Not Rejected")

                setTimeout(() => {
                    setErrorMessage()
                }, 4000);
                console.log(error.data)
            });

    }
    function getPayers() {

        getPayerList('All', 'All')
        var payerId = sessionStorage.getItem('payerid')
        getAddMyList(payerId)

    }
    function getPayerList(name, label) {
        console.log("getPayerList function involked ", name, label)
        var value = sessionStorage.getItem('payerid')

        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/get/${name}/${label}/${value}`).then(res => {
            setUserList(res.data.data);
            console.log(res.data.data, "getPayerList");


        });
    }


    function getAddMyList(value) {
        console.log(value, "getAddMyList")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getOrganisationAddMyList/${value}`).then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "AAAAA");
        });
    }
    function createContract(destinatoin_payer_id) {

        var source_payer_id = sessionStorage.getItem('payerid');
        var user_id = sessionStorage.getItem('userid');

        var addArr = {
            'destinatoin_payer_id': destinatoin_payer_id,
            //'destinatoin_payer_id': 4,
            'source_payer_id': source_payer_id, 'user_id': user_id
        };
        console.log(addArr, "addArr")
        axios.post(
            process.env.REACT_APP_BASE_URL + '/api/PayersSignedStatus/create',
            addArr,

        )
            .then(response => {
                setSuccessMessage("Successfully created a payer!")
                console.log(response.data, "sucess");
                localStorage.setItem("payer_signed_status_id", response.data.data[0].payer_signed_status_id);
                // console.log(response.body.data[0].payer_signed_status_id,"response.data.payer_signed_status_id")
                //  window.open(process.env.REACT_APP_DEVPORTAL_URL+`/login.php?id=${response.data.data[0].payer_signed_status_id}`, 'name', 'height=500,width=850')
                //var win = window.open(process.env.REACT_APP_DEVPORTAL_URL+`/loginwithHC.php?id=${response.data.data[0].payer_signed_status_id}&name=${userList[0].name}`, 'name', 'height=500,width=850')
                var win = window.open(process.env.REACT_APP_DEVPORTAL_URL + `?id=${response.data.data[0].payer_signed_status_id}&name=${userList[0].name}`, 'name', 'height=500,width=850')

                // console.log(localStorage.getItem("payer_signed_status_id"),"test22222");
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                window.location.reload();

                console.log(response.data.data[0].payer_signed_status_id, "payer_signed_status_id")



            })
            .catch(error => {
                console.log(error, "catch")
                setErrorMessage("Cannot created payer")
                // reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            })
    }

    const onSubmit = (data) => {
        console.log("onSubmit", data);

        axios
            .put(
                process.env.REACT_APP_BASE_URL + `/api/updateAdminPayerId/${value}`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully updated!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/AdminDashboard');
                console.log(response.data, "updateAdminPayerId data")
            })
            .catch(error => {
                setErrorMessage("Cannot updated")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };

    return (
        <Container fluid="md">
            <Row>
                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%" }} >
                        <Card.Body>


                            {/* <h3 className='mb-3 heading'>Blue Cross and Blue Shield of Massachusetts </h3> */}
                            <div class="pull-left">
                                {userList.map(x =>
                                    <div className="w-100 text-center mt-2">
                                        <h3 className='mb-3 heading'>{x.name}</h3>

                                    </div>
                                )}
                            </div>
                            {/* <h4> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </h4> */}
                            <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </h4>

                            <div class="pull-right">
                                {
                                    userList.map(x => {
                                        //   if( x.payer_status === 'Signed' && isadmin === 'true') {
                                        //     return   <div className="w-100 text-center mt-2">
                                        //          <button
                                        //         className="btn btn-primary " Width="95px"
                                        //         style={{ marginTop: "26px", borderRadius: "20px" }} id="download_button"
                                        //         onClick={() => {

                                        //             createContract(x.value);  }}
                                        //     >
                                        //         Add Organization
                                        //     </button>

                                        // </div>
                                        //   }
                                        if (isadmin === 'true') {
                                            return <div className="w-100 text-center mt-2">

                                            </div>
                                        }
                                        else {
                                            return <div className="w-100 text-center mt-2">
                                                <button
                                                    className="btn btn-primary " Width="95px"
                                                    style={{ marginTop: "26px", borderRadius: "20px" }} id="download_button"
                                                    onClick={() => {

                                                        createContract(x.value);
                                                    }}
                                                >
                                                    Add Organization
                                                </button>
                                            </div>

                                        }

                                    })
                                }


                            </div>


                            <div class="clearfix"></div>

                            <div style={{ display: 'block', padding: 30 }}>

                                <form onSubmit={handleSubmit(onSubmit)} >
                                    <Tabs defaultActiveKey="first">
                                        <Tab eventKey="first" title="Organization Profile">

                                            {
                                                userList.map(x => {
                                                    const { logo } = x;
                                                    if (logo != null) {
                                                        return (
                                                            <div>
                                                                <img src={process.env.REACT_APP_BASE_URL + `/api/images/${logo}`} style={{ maxWidth: '20%' }} alt="pic" /> <span class="label btn-upload label-default"></span>
                                                            </div>
                                                        )

                                                    }
                                                    else {
                                                        return (
                                                            <div>
                                                                <img src={public_profile} style={{ maxWidth: '20%' }} alt="pic" /> <span class="label btn-upload label-default"></span>
                                                            </div>
                                                        )

                                                    }


                                                })
                                            }
                                            <br></br><br></br>
                                            <Table className="view_tbl">

                                                {userList.map(user => {
                                                    const { name, organisation_type, website, policylink, conditionlink, address1, city, state, phone } = user;
                                                    return (
                                                        <div >
                                                            <tr>
                                                                <td><strong>Organization Name:</strong></td>
                                                                <td>{name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Organization Type:</strong></td>
                                                                <td>{organisation_type}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Organization website:</strong></td>
                                                                <td>{website}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Organization Privacy Policy:</strong></td>
                                                                <td>{policylink}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Organization Terms and Conditions:</strong></td>
                                                                <td>{conditionlink}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Address:</strong></td>
                                                                <td>{address1},<br></br>
                                                                    {city},<br></br>
                                                                    {state}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Phone Number:</strong></td>
                                                                <td>{phone}</td>
                                                            </tr>

                                                        </div>
                                                    )
                                                })
                                                }

                                            </Table>
                                        </Tab>

                                        <Tab eventKey="second" title="Endpoints">


                                            {/* Hii, I am 2nd tab content */}
                                            <Table className="view_tbl">
                                              
                                            {endpointUrls.map(user => {
                                    const { base_url, payer_id, auth_scope, token_url, id, auth_type, authorize_url, return_url, payer_environment_type } = user;


                                    return (

                                        <Row md={12} style={{ width: "100%", }}>
                                            <Col md={12}>
                                                <div >
                                                    <tr>
                                                        <td> <h4>{payer_environment_type}</h4></td>
                                                    </tr>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Return URL:</strong></td>
                                                        <td>{return_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Token URL:</strong></td>
                                                        <td>{token_url}</td>
                                                    </tr>

                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Authorize URL:</strong></td>
                                                        <td>{authorize_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Base URL:</strong></td>
                                                        <td>{base_url}</td>
                                                    </tr>

                                                </div>


                                            </Col>
                                            <Col md={4}>

                                                <div >
                                                    <tr>
                                                        <td><strong>Auth Type:</strong></td>
                                                        <td>{auth_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Auth Scope:</strong></td>
                                                        <td>{auth_scope}</td>
                                                    </tr>

                                                </div>

                                            </Col>

                                        </Row>

                                    )

                                })
                                }



                                            </Table>
                                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Payer Name</th>
                                                <th>Endpoint Name</th>
                                                {/* <th>Base Url</th>
                                                            <th>Auth Scope</th> */}
                                                <th>Last Modified On</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {endpointNamelist.map((x) =>
                                                <tr>
                                                    <td>{x.name}</td>
                                                    <td>{x.endpoint_name}</td>
                                                    {/* <td>{x.base_url}</td>
                                                                <td>{x.auth_scope}</td> */}
                                                    <td>{x.updated_date}</td>
                                                    {/* <td>
                                                        <button type="button"
                                                            className="btn btn-outline-danger btn-xs"
                                                            onClick={() => onDelete(x.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td> */}
                                                </tr>)}
                                            {endpointNamelist.length == 0 && <tr>
                                                <td className="text-center" colSpan="4" >
                                                    <b >No data found to display.</b>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                                        </Tab>
                                    </Tabs>

                                    {(isadmin == 'true') ? (

                                        userList.slice(0, 1).map(x => {

                                            if (x.is_approve === 0) {
                                                return <div className="w-100 text-center mt-2">
                                                    <tr >


                                                        <td><strong >Payer List:</strong></td>
                                                        <br></br>
                                                        <td>
                                                            <Form.Group style={{ marginTop: "1%" }} className="mb-3" controlId="pcform.organisation_type_id">

                                                                <select className="form-control" name="payer"
                                                                    {...register("admin_payer_id", {
                                                                        // required: true,
                                                                        validate: (value) => value !== "Select Payer"
                                                                    })}>
                                                                    <option value='null' selected  >Select  Payer</option>
                                                                    {
                                                                        payerlistDashboard.map((result) => (<option id={result.payer_id} value={result.admin_payer_id}> {result.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </Form.Group>
                                                        </td>

                                                        {/* <td>
                                                                   <Col md={12} style={{ textAlign: "center" }}>
                                                       <Button className="bsbt-btn" type="submit" variant="primary" >Approve</Button>
                                                   </Col>
                                                                   </td> */}

                                                        <div className="w-100 text-center mt-2">

                                                            <button

                                                                className="btn btn-success btn-xm" Width="95px"
                                                                style={{ margin: "2px" }}


                                                            >
                                                                Approve
                                                            </button>

                                                            <Link to={`/AdminDashboard`}>
                                                                <button
                                                                    className="btn btn-danger btn-xm" Width="95px"
                                                                    style={{ margin: "2px" }}

                                                                    onClick={() => updateRejectedById(x.value)}
                                                                >
                                                                    Reject
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </tr>
                                                </div>


                                            }


                                        })




                                    ) : (
                                        null
                                    )}

                                </form>
                            </div>


                        </Card.Body>

                    </Card >
                </Col>
            </Row>

        </Container >



    );

}

export default ViewOrganization





// const ViewPayer = () => {






//   const [viewpayerList, setViewPayerList] = useState([]);
//   const [viewpayer, setViewPayer] = useState([]);

//   useEffect(() => {
//     getIdPayerList()
//     //getPayerList()

//   }, []);



// //https://github.com/bezkoder/react-fetch-example/blob/master/src/App.js
// //https://www.codingdeft.com/posts/react-fetch-data-api/
//   function getPayerList() {
//     axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllrequestedpayers').then(res => {
//       setViewPayerList(res.data.data);
//       console.log(res.data.data, "padma")
//     });
//   }

//   const { id } = useParams();
//   function getIdPayerList  ()  {

//     fetch(process.env.REACT_APP_BASE_URL+`/api/getIdrequestedpayers/${id}`, {
//         method: 'get',

//     }).then((res) => {
//       setViewPayerList(res.data.data);
//       console.log(res.data.data, "ppppppppppppppp")
//     })
//     .catch(error => {

//         console.log(error.data) });

// }

//   return (
//     <Container fluid="md">
//       <Card border="primary">
//         <Card.Header
//           as="h5"
//           style={{ backgroundColor: "blue", color: "#ffffff" }}
//         >
//           Payer
//         </Card.Header>
//         <Card.Body>

//           <Row>
//             <Col>
//             </Col>
//           </Row>
//           <table class="table table-sm mt-3">
//             <thead class="thead-dark">
//               <th>Name</th>
//               <th>Email</th>
//               <th>Payer Url</th>


//             </thead>
//             <tbody>

//               {viewpayerList.map(x => <tr>
//                 <td>{x.name}</td>
//                 <td>{x.email}</td>
//                 <td>{x.payer_url}</td>

//               </tr>)}
//               {viewpayerList.length == 0 && <tr>
//                 <td className="text-center" colSpan="4">
//                   <b>No data found to display.</b>
//                 </td>
//               </tr>}
//             </tbody>
//           </table>

//           <Row>
//             <Col md={2} style={{ width: "50%", textAlign: "left" }}>
//               <Form.Group controlId="pcform.payercontact">
//               <Link to={`/addPayerContact`}>
//                 <Button variant="success">Payer Contact</Button>{" "}
//                 </Link>
//               </Form.Group>

//             </Col>
//             <Col md={2} style={{ width: "50%", textAlign: "right" }}>
//               <Form.Group controlId="pcform.request">
//                 <Button variant="danger">Request</Button>{" "}
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row style={{ marginTop: "5px" }}>
//             <Col md={12}>
//               <Card style={{ marginTop: "12px" }}>
//                 <Card.Header
//                   style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                 >
//                   <i class="fa fa-document"></i> Documentation
//                 </Card.Header>
//                 <Card.Body>
//                   <Row>
//                     <Col md={6}>
//                       <Card bg="primary" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to request for a new App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="secondary" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to view existing App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="warning" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to delete App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="success" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>
//                             Patient Access API End Point details
//                           </Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="light" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>
//                             Provider Access API End Point details
//                           </Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                   </Row>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ViewPayer;
