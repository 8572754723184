import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory, useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import public_profile from './public_profile.png'
import profile_pic from './profile_pic.png'
import plus_icon from './plus_icon.png'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import "bootstrap/dist/css/bootstrap.css";
import _, { result } from 'lodash'
import axios from 'axios';
import TodoCard from "./TodoCard";
import { useForm } from "react-hook-form";
import { transform } from "lodash";

import swal from 'sweetalert';

let Endpoints = () => {

    const handleChange = (e) => {
        setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
        console.log(setUserList, "llllll")

    }

    const [userList, setUserList] = useState([]);

    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;
    const { register, handleSubmit, reset, trigger, formState } = useForm();


    const { errors } = formState;

    const [APIData, setAPIData] = useState([]);
    const history = useHistory();
    //  const [resultedOrganization, setOrganization] = useState({ name: "", first_name: "", last_name: "", email: "", phone: "", payer_id: "", user_id: "" });
    var isadmin = sessionStorage.getItem('isadmin');
    const [allpayerList, setAllPayerList] = useState([]);
    const [endpointUrls, setEndpointUrls] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [id, setID] = useState(null);
    const [auth_scope, setAuthScope] = useState('');
    const [base_url, setBaseUrl] = useState('');
    const [other_endpoint_name, setOtherEndpointName] = useState('');
    const [payer_id, setPayerId] = useState(null);

    const [editShow, setEditShow] = useState(false);

    const handleEditClose = () => setEditShow(false)
    const handleEditShow = () => setEditShow(true)



    const [resultedOrganization, setOrganization] = useState({
        payer_id: sessionStorage.getItem('payerid'),
        user_id: sessionStorage.getItem('userid'),
        name: sessionStorage.getItem('name')

    });

    // if(search.length > 0 ){
    //     APIData = APIData.filter((data) => {
    //      return    data.endpoint_name.toLowerCase().indexOf(search.toLowerCase())
    //                                          })
    //        }

    const [searchInput, setSearchInput] = useState('');
    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = APIData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setAPIData(filteredData)
        }
        else {
            setAPIData(APIData)
        }
    }


    const onSubmit = data => {
        console.log("name", sessionStorage.getItem('name'))
        console.log("new data ", data)
        var payer_id = sessionStorage.getItem('payerid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log("Appended payer id", payer_id)
        axios
            .post(
                process.env.REACT_APP_BASE_URL + '/api/EndPoints',
                data,
            )
            .then(response => {
                setSuccessMessage("Successfully created a endpoints!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 20000);
                if (isadmin === 'true') {
                    history.push('/PayerListDashboard');

                }
                else {
                    window.location.reload();
                    //  history.push('/Endpoints');

                }
                console.log(response.data)
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    var message = error.response.data.message
                    console.log(error.response.data.message, "error message")
                    setErrorMessage(message)
                    setTimeout(() => {
                        setErrorMessage()
                    }, 2000);
                }
            });
    };
    const runValidations = () => {
        if (auth_scope.length === 0) {
            error.auth_scope = 'Authscope is required'
        }
        if (base_url.length == 0) {
            error.base_url = "Base url is required"
        }

    }




    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        var end_point_master_id = sessionStorage.getItem('end_point_master_id');
        console.log(end_point_master_id, "end_point_master_id")
        console.log(user_id, isadmin, "user_idisadmin")

        if (user_id == 'undefined') {
            history.push('/userlogin');

        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }

        else {
            getMyEndPointsUrls()
            getMyEndPointsUserList()
            getAllPayerList()
            getMyEndPointsList()
           


        }


    }, []);
    function getAllPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getAllOrganisation').then(res => {
            setAllPayerList(res.data.data);
            console.log(res.data.data, "sucess");

        });
    }
    var user_id = sessionStorage.getItem('userid');
    // /getEndPointsUrls/:payer_id

    function getMyEndPointsUrls() {
        var payer_id = sessionStorage.getItem('payerid');
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsUrls/${payer_id}}`).then(res => {
            setEndpointUrls(res.data.data);
            console.log(res.data.data, "getEndPointsUrls");

        });

    }
    function getMyEndPointsUserList() {
        // payer_environment_type
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsAll/${user_id}`).then(res => {
            setUserList(res.data.data, "getMyEndPointsList");
            var getMyEndPointsUserList = res.data.data
            console.log(getMyEndPointsUserList, "getMyEndPointsUserList")
            var finalCount = res.data.data.length

            sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
            console.log(finalCount, "finalCount");

            var result = getMyEndPointsUserList.map(x => x.end_point_master_id);
            console.log(result, "result")


            sessionStorage.setItem('Result', JSON.stringify(result));
            const resultData = JSON.parse(sessionStorage.getItem('Result'));
            console.log(resultData, "resultData");





            if (finalCount > 0) {
                setAuthScope(res.data.data[0].auth_scope)
                setBaseUrl(res.data.data[0].base_url)


            }

        });
    }

    function getMyEndPointsList() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsList`).then(res => {
            setAPIData(res.data.data);
            console.log(res.data.data, "getEndPointsList");

            //     var listData = res.data.data
            //     var finalDataIds = listData.map(x => x.id);
            //     console.log(finalDataIds, "finalDataIds")

            //     sessionStorage.setItem('id',finalDataIds)
            // sessionStorage.getItem('id')

        


        });
    }


    const columns = [

        // { dataField: 'name', text: 'Payer Name' },
        { dataField: 'endpoint_name', text: ' Endpoint Name' },
        { dataField: 'base_url', text: 'Base Url' },
        { dataField: 'updated_date', text: 'Last Modified On' },
        {
            dataField: "is_approve",
            text: "Action",
            formatter: (cellContent, row) => {
                return (
                    <div className="w-100 text-center mt-2">

                        {/* <button

                            className="btn btn-sm btn-primarybg btn-primary" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={handleShow}
                        >
                            Edit
                        </button> */}


                        {/* <Link to={`/viewpayer/${row.payer_id}`}> */}
                        <button
                            className="btn btn-sm btn-primarybg btn-primary" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => onDelete(row.id)}
                        >
                            Delete
                        </button>

                        {/* </Link> */}
                    </div>


                );
            },
        },


    ]


    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the data!",
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
            .then((willDelete) => {
                if (willDelete) {

                    swal("Your data has been deleted!", {
                        icon: "success",
                    });
                    axios.delete(process.env.REACT_APP_BASE_URL + `/api/delete/EndPoints/${id}`)
                    window.location.reload();
                    getMyEndPointsList();

                }
            });
    }

    const [data, setUpdate] = useState({
        endpoint_name: "",
        //   endpoint_names:"",
        payer_id: "",
        other_endpoint_name: "",
        user_id: ""

    })

    function handle(e) {
        const newData = { ...data }
        newData[e.target.id] = e.target.value
        setUpdate(newData)
        console.log(newData)

    }

    const updateAPIData = (e) => {

        e.preventDefault();
        var payer_id = sessionStorage.getItem('payerid');
        axios.post(process.env.REACT_APP_BASE_URL + `/api/update/EndPoints`, {
            auth_scope,
            base_url,
            //   endpoint_names:data.endpoint_names,
            endpoint_name: data.endpoint_name,

            payer_id: payer_id,
            user_id: user_id,
            //other_endpoint_name
            other_endpoint_name: data.other_endpoint_name,


        })
            .then(response => {
                history.push('/Endpoints');
                window.location.reload();
                console.log(response.data, "udpateEndpoint")
            })
            .catch(error => {
                console.log(error.data)
            });

    }
    const [formError, setFormError] = useState({})
    const error = {}

    const runValidation = () => {
        if (base_url.length == 0) {
            error.base_url = "Base URL is required"
        }
        if (auth_scope.length == 0) {
            error.auth_scope = "Auth Scope is required"
        }
    }

    const update = () => {
        // let  navigate = useNavigate();

        // navigate({
        //     pathname: '/UpdateEndpoints',
        //     search: '?sort=date&order=newest',
        //   });
        // window.location.reload()
        // window.location.reload();
        history.push('/UpdateEndpoints');



    }

    // const updateAPIData = (e) => {
    //     var payer_id = sessionStorage.getItem('payerid');
    //     console.log(payer_id,payer_id)
    //     e.preventDefault()

    //     runValidation()
    //     if (Object.keys(error).length == 0) {
    //         setFormError({})
    //         axios.put(process.env.REACT_APP_BASE_URL + `/api/update/EndPoints`, {
    //             auth_scope,
    //             base_url,
    //             endpoint_name
    //             //  description
    //         })
    //             .then(response => {
    //                 history.push('/Endpoints');
    //                 window.location.reload();
    //                 console.log(response.data.data, "updateEndpoint")
    //             })
    //             .catch(error => {
    //                 console.log(error.data)
    //             });


    //     } else {
    //         setFormError(error)
    //     }

    // }

    return (
        <Container fluid="md">
            <Row>
                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", minHeight: "630px" }} >
                        <Card.Body>
                            <div class="col-md-12" style={{ textAlign: "center" }}>
                                <h3 className='mb-3 heading'>My Endpoints
                                    <div class="pull-right">
                                        <button type="button" id="" class="form-control btn-edit btn btn-primary" style={{ marginLeft: "5%" }} onClick={handleShow} > Add Endpoints</button>
                                    </div>

                                </h3>
                            </div>
                            <div class="clearfix"></div>
                            <br></br>
                            <div class="pull-right">
                                {userList.slice(0, 1).map(x => {
                                    return (
                                        <div>
                                            <tr>
                                                <td><strong style={{ color: "#687080", fontWeight: 600 }}>Last Edited:</strong></td>
                                                <td> {x.updated_date}</td>
                                            </tr>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                            <div class="clearfix"></div>
                            <hr></hr>
                            {/* <h6 className='mb-3 heading'>Production </h6>
                            <div class="clearfix"></div>
                            <hr></hr> */}

                            <Table className="view_tbl">

                                {endpointUrls.map(user => {
                                    const { base_url, payer_id, auth_scope, token_url, id, auth_type, authorize_url, return_url, payer_environment_type } = user;


                                    return (

                                        <Row md={12} style={{ width: "100%", }}>
                                            <Col md={12}>
                                                <div >
                                                    <tr>
                                                        {/* <td><strong>Payer Environment:</strong></td> */}
                                                        <td> <h3>{payer_environment_type}</h3></td>

                                                        {/* {userList.map(x => {
                                        return ( */}
                                                        <div >
                                                            {/* <Link to='register' state={{name:'zayne'}}></Link> */}
                                                            {/* <Link to={`/UpdateEndpoints/${x.payer_id }`} state={{name:'zayne'}}>
                                                    <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={update} >Edit</button>
                                                </Link> */}
                                                            <Link to={{
                                                                pathname: `/UpdateEndpoints/${payer_id}`, state: { type: payer_environment_type, payer_endpoint_id: id }
                                                            }}>
                                                                <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={update} >Edit</button>
                                                            </Link>
                                                            
                                                            
                                                        </div>
                                                       
                                                        {/* <button type="button"
                                                            className="btn btn-outline-danger btn-xm"
                                                            onClick={() => onDelete(id)}
                                                        >
                                                            Delete
                                                        </button> */}

                                                    </tr>
                                                    


                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Return URL:</strong></td>
                                                        <td>{return_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Token URL:</strong></td>
                                                        <td>{token_url}</td>
                                                    </tr>

                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Authorize URL:</strong></td>
                                                        <td>{authorize_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Base URL:</strong></td>
                                                        <td>{base_url}</td>
                                                    </tr>

                                                </div>


                                            </Col>
                                            <Col md={4}>

                                                <div >
                                                    <tr>
                                                        <td><strong>Auth Type:</strong></td>
                                                        <td>{auth_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Auth Scope:</strong></td>
                                                        <td>{auth_scope}</td>
                                                    </tr>

                                                </div>

                                            </Col>

                                        </Row>

                                    )

                                })
                                }

                            </Table>

                            <br></br>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Payer Name</th>
                                                <th>Endpoint Name</th>
                                                {/* <th>Base Url</th>
                                                            <th>Auth Scope</th> */}
                                                <th>Last Modified On</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList.map((x) =>
                                                <tr>
                                                    <td>{x.name}</td>
                                                    <td>{x.endpoint_name}</td>
                                                    {/* <td>{x.base_url}</td>
                                                                <td>{x.auth_scope}</td> */}
                                                    <td>{x.updated_date}</td>
                                                    <td>
                                                        <button type="button"
                                                            className="btn btn-outline-danger btn-xs"
                                                            onClick={() => onDelete(x.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td>
                                                </tr>)}
                                            {userList.length == 0 && <tr>
                                                <td className="text-center" colSpan="4" >
                                                    <b >No data found to display.</b>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                            <Modal show={show} onHide={handleClose} animation={false}>

                                <Modal.Body>
                                    <div class="col-md-12" style={{ textAlign: "center" }}>
                                        <h3 className='mb-3 heading'>Add Endpoint
                                            <div class="pull-right">

                                                <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={handleClose}>Cancel</button>

                                            </div>
                                        </h3>

                                    </div>
                                    <div class="clearfix"></div>
                                    <br></br>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

                                            {...register("payer_id")}
                                        />
                                        <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

                                            {...register("user_id")}
                                        />
                                        <Form.Control type="text" value={resultedOrganization.name} hidden={true}

                                            {...register("name")}
                                        />

                                        <Form.Group className="mb-3" controlId="pcform.username">
                                            <fieldset >

                                                <Row>
                                                    <Col md={6} style={{ textAlign: "left" }}>

                                                        {(() => {
                                                            if (isadmin == 'true') {
                                                                return (
                                                                    <div className="w-100 text-center mt-2">
                                                                        <Form.Group className="mb-3" controlId="pcform.payer_id">
                                                                            <Form.Label>Select Organization<strong style={{ color: "red" }}>*</strong></Form.Label>
                                                                            <select className="form-control" name="payer"
                                                                                {...register("payer_id", {
                                                                                    required: true,
                                                                                    validate: (value) => value !== "Select Organization"
                                                                                })}>

                                                                                {
                                                                                    allpayerList.map((result) => (<option id={result.payer_id} value={result.payer_id}> {result.name}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </Form.Group>
                                                                    </div>
                                                                )
                                                            }
                                                        })()}

                                                    </Col>
                                                </Row>
                                                <Row md={12} style={{ width: "100%", }}>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="pcform.base_url">
                                                            <Form.Label>Base URL <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <input className="form-control" type="text"
                                                                {...register("base_url", {
                                                                    required: {
                                                                        value: true,
                                                                        message: "Base url is required"
                                                                    },
                                                                    pattern: {
                                                                        // value: /^((ftp|http|https|www):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                                                        value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                                                        message: "please enter a valid url"
                                                                    },
                                                                })}
                                                            />
                                                            {errors.base_url &&
                                                                <p style={{ color: "red" }}>{errors.base_url.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" controlId="pcform.auth_scope">
                                                            <Form.Label>Auth Scope<strong style={{ color: "red" }}>*</strong></Form.Label>
                                                            <input class="form-control"
                                                                {...register("auth_scope", {
                                                                    required: true,

                                                                })}
                                                            />
                                                            {errors.auth_scope && errors.auth_scope.type === "required" && <p style={{ color: "red" }}>Auth Scope Required</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row md={12} style={{ width: "100%", }}>
                                                    <Col md={6}>

                                                        <Form.Group className="mb-3 return_url" controlId="pcform.return_url">
                                                            <Form.Label>Return URL <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <input className="form-control" type="url"
                                                                {...register("return_url", {
                                                                    required: {
                                                                        value: true,
                                                                        message: "Return url is required"
                                                                    },
                                                                    pattern: {
                                                                        // value: /^((ftp|http|https|www):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                                                        value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                                                        message: "please enter a valid url"
                                                                    },
                                                                })}
                                                            />
                                                            {errors.return_url &&
                                                                <p style={{ color: "red" }}>{errors.return_url.message}</p>}
                                                        </Form.Group>



                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3 token_url" controlId="pcform.token_url">
                                                            <Form.Label>Token URL <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <input className="form-control"
                                                                {...register("token_url", {
                                                                    required: {
                                                                        value: true,
                                                                        message: "Token url is required"
                                                                    },
                                                                    pattern: {
                                                                        value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                                                        message: "please enter a valid url"
                                                                    },
                                                                })}
                                                            />
                                                            {errors.token_url &&
                                                                <p style={{ color: "red" }}>{errors.token_url.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row md={12} style={{ width: "100%", }}>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3 authorize_url" controlId="pcform.authorize_url">
                                                            <Form.Label>Authorize URL <span style={{ color: "red" }}>*</span></Form.Label>
                                                            <input className="form-control"
                                                                {...register("authorize_url", {
                                                                    required: {
                                                                        value: true,
                                                                        message: "Authorize url is required"
                                                                    },
                                                                    pattern: {
                                                                        value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                                                        message: "please enter a valid url"
                                                                    },
                                                                })}
                                                            />
                                                            {errors.authorize_url &&
                                                                <p style={{ color: "red" }}>{errors.authorize_url.message}</p>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Form.Group className="mb-3 auth_type" controlId="pcform.auth_type">
                                                            <Form.Label>Auth Type<span style={{ color: "red" }}>*</span></Form.Label>
                                                            <input className="form-control"
                                                                {...register("auth_type", {
                                                                    required: {
                                                                        value: true,
                                                                        message: "Authtype is required"
                                                                    },

                                                                })}
                                                            />
                                                            {errors.auth_type &&
                                                                <p style={{ color: "red" }}>{errors.auth_type.message}</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Col>
                                                    <Form.Label>Payer Environment <strong style={{ color: "red" }}>*</strong></Form.Label>
                                                    <select className="form-control" name="payer"
                                                        {...register("payer_environment_type", {
                                                            required: true,
                                                            validate: (value) => value !== "Select Payer Environment"
                                                        })}>
                                                        <option>Select Payer Environment</option>
                                                        <option value="Sandbox">Sandbox</option>
                                                        <option value="Production">Production</option>
                                                        <option value="Both (Production And Sandbox)">Both</option>
                                                    </select>
                                                </Col>
                                                <br></br>
                                                <Col>

                                                    <Form.Group className="mb-3" controlId="pcform.username">

                                                        <Form.Label style={{ textAlign: "center" }}>Search Endpoints</Form.Label>

                                                        <input type="type" class="form-control"
                                                            placeholder='Search...'
                                                            onChange={(e) => searchItems(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Form.Label style={{ textAlign: "center" }}>Select Endpoints<strong style={{ color: "red" }}>*</strong></Form.Label>
                                                <Row md={12} style={{ width: "100%", }}>

                                                    <Col md={6} style={{ textAlign: "left" }}>
                                                        {
                                                            APIData
                                                                .map((x, i) => {
                                                                    if (x.endpoint_order == 1) {
                                                                        return <div className="w-100 text-center mt-2">
                                                                            <Row >
                                                                                <Col md={12} style={{ textAlign: "left" }}>
                                                                                    <label key={x}>
                                                                                        <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                                                                        <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                                                                    </label>
                                                                                </Col>


                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                })
                                                        }
                                                    </Col>
                                                    <Col md={6} style={{ textAlign: "left" }}>
                                                        {
                                                            APIData.map((x, i) => {
                                                                if (x.endpoint_order == 2) {
                                                                    return <div className="w-100 text-center mt-2">
                                                                        <Row >

                                                                            <Col md={12} style={{ textAlign: "left" }}>
                                                                                <label key={x}>

                                                                                    <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                                                                    <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                                                                </label>
                                                                            </Col>


                                                                        </Row>
                                                                    </div>
                                                                }
                                                            })
                                                        }
                                                    </Col>

                                                </Row>



                                            </fieldset>
                                        </Form.Group>

                                        <Row>
                                            <Col md={12}>

                                                <Form.Group className="mb-3" controlId="pcform.other_endpoint_name">
                                                    <Form.Label>Other endpoint name</Form.Label>
                                                    <input class="form-control"
                                                        {...register("other_endpoint_name")}

                                                    />

                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Col md={12} style={{ textAlign: "center" }}>


                                            <Button className="bsbt-btn" type="submit" variant="primary" >SUBMIT</Button>

                                        </Col>
                                        < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green", marginLeft: "20%" }} > {successMessage}</div>} </strong>
                                        <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red", marginLeft: "30%" }} > {errorMessage}</div>} </strong>



                                    </form>

                                </Modal.Body>
                            </Modal>

                            <Modal show={editShow} onHide={handleEditClose} animation={false}>

                                <Modal.Body>
                                    <div class="col-md-12" style={{ textAlign: "center" }}>
                                        <h3 className='mb-3 heading'>Edit Endpoint
                                            <div class="pull-right">

                                                <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={handleEditClose}>Cancel</button>

                                            </div>
                                        </h3>

                                    </div>
                                    <div class="clearfix"></div>
                                    <br></br>

                                </Modal.Body>

                            </Modal>
                        </Card.Body>
                    </Card >
                </Col>
            </Row>
        </Container >
    );

}

export default Endpoints;