
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState, Checkbox } from 'react';


let AddEndpoints = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [APIData, setAPIData] = useState([]);
  const [allpayerList, setAllPayerList] = useState([]);
  const history = useHistory();
  //const [resultedOrganization, setOrganization] = useState({ payer_id: "", user_id: "" });
  var isadmin = sessionStorage.getItem('isadmin');

  const [resultedOrganization, setOrganization] = useState({
    payer_id: sessionStorage.getItem('payerid'),
    user_id: sessionStorage.getItem('userid')
  });




  const onSubmit = data => {
    console.log("new data ", data)
    var payer_id = sessionStorage.getItem('payerid');
    var isadmin = sessionStorage.getItem('isadmin');
    console.log("Appended payer id", payer_id)
    axios
      .post(
        process.env.REACT_APP_BASE_URL+'/api/EndPoints',
        data,
      )
      .then(response => {
        setSuccessMessage("Successfully created a endpoints!")
        reset(response.data);
        setTimeout(() => {
          setSuccessMessage()
        }, 20000);
        if(isadmin === 'true'){
          history.push('/PayerListDashboard');

        }
        else{
          history.push('/MyEndpoints');

        }



        console.log(response.data)
      })
      .catch(error => {
        setErrorMessage("Cannot created endpoints")
        reset(error.data);
        setTimeout(() => {
          setErrorMessage()
        }, 2000);
        console.log(error.data)
      });
  };

  useEffect(() => {
    getMyEndPointsList()
    getAllPayerList()

  }, []);
  function getMyEndPointsList() {
    axios.get(process.env.REACT_APP_BASE_URL+`/api/getEndPointsList`).then(res => {
      setAPIData(res.data.data);
      console.log(res.data.data, "getMyEndPointsList");
    });
  }
  function getAllPayerList() {
    axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllAdminOrganisation').then(res => {
      setAllPayerList(res.data.data);
      console.log(res.data.data, "sucess");

    });
  }

  console.log("getusers called", isadmin);
  return (

    <div class="signup_header">
      <div style={{ width: '80%', marginLeft: '0%' }}>
        <Card>

          <Card.Body>
            <ul id="progressbar">

            </ul>
            <h3 className='text-center mb-3 heading'>Add Endpoints</h3>
            <center><hr className='heading_line'></hr></center>
            <br></br>
            <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green", paddingBottom: "5px", paddingTop: "5px", textAlign: "center" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red", paddingBottom: "5px", paddingTop: "5px", textAlign: "center" }} > {errorMessage}</div>} </strong>

            <form onSubmit={handleSubmit(onSubmit)}>

                <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

                  {...register("payer_id")}
                />
                <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

                  {...register("user_id")}
                />

                <Form.Group className="mb-3" controlId="pcform.username">
                  <fieldset >

                  <Row>
                <Col md={6} style={{ textAlign: "left" }}>

                    {(() => {
                      if (isadmin == 'true') {
                        return (
                          <div className="w-100 text-center mt-2">
                            <Form.Group className="mb-3" controlId="pcform.payer_id">
                              <Form.Label>Select Organization<strong style={{ color: "red" }}>*</strong></Form.Label>
                              <select className="form-control" name="payer"
                                {...register("payer_id", {
                                  required: true,
                                  validate: (value) => value !== "Select Organization"
                                })}>
                                {/* <option selected>Select  Payer</option> */}
                                {
                                  allpayerList.map((result) => (<option id={result.payer_id} value={result.payer_id}> {result.name}</option>
                                  ))
                                }
                              </select>
                            </Form.Group>
                          </div>
                        )
                      }
                    })()}

                </Col>
                </Row>
              <Row md={12} style={{ width: "100%", }}>
                <Col md={6}>
                  {/* <Form.Group className="mb-3" controlId="pcform.username">
                    <Form.Label>Base Url<strong style={{ color: "red" }}>*</strong></Form.Label>
                    <input class="form-control"
                      {...register("base_url", { required: true })}

                    />

                  </Form.Group> */}
                  <Form.Group className="mb-3 return_url" controlId="pcform.return_url">
                                <Form.Label>Base Url <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="url"
                                    {...register("base_url", {
                                        required: {
                                            value: true,
                                            message: "Base url is required"
                                        },
                                        pattern: {
                                           // value: /^((ftp|http|https|www):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                           value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.base_url &&
                                    <p style={{ color: "red" }}>{errors.base_url.message}</p>}
                            </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="pcform.auth_scope">
                    <Form.Label>Auth Scope<strong style={{ color: "red" }}>*</strong></Form.Label>
                    <input class="form-control"
                      {...register("auth_scope", { required: true })}

                    />
                    {errors.auth_scope && errors.auth_scope.type === "pattern" && <p style={{ color: "red" }}>Invalid  Auth Scope</p>}
                  </Form.Group>
                </Col>
              </Row>

                    <Form.Label style={{textAlign:"center"}}>Select Endpoints<strong style={{ color: "red" }}>*</strong></Form.Label>
                    {/* {
                      APIData.map(
                        (x, i) =>
                          <Row >

                            <Col md={12} style={{ textAlign: "left" }}>
                              <label key={x}>

                                <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                              </label>
                            </Col>

                          </Row>

                      )
                    } */}
                    <Row md={12} style={{ width: "100%", }}>
                    <Col md={6} style={{ textAlign: "left" }}>
                    {
                      APIData.map((x, i) => {
                        if (x.endpoint_order == 1) {
                          return <div className="w-100 text-center mt-2">
                            <Row >
                              <Col md={12} style={{ textAlign: "left" }}>
                                <label key={x}>
                                  <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                  <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                </label>
                              </Col>


                            </Row>
                          </div>
                        }
                      })
                    }
                      </Col>
                      <Col md={6} style={{ textAlign: "left" }}>
                      {
                      APIData.map((x, i) => {
                        if (x.endpoint_order == 2) {
                          return <div className="w-100 text-center mt-2">
                            <Row >

                              <Col md={12} style={{ textAlign: "left" }}>
                                <label key={x}>

                                  <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                  <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                </label>
                              </Col>


                            </Row>
                          </div>
                        }
                      })
                    }
                      </Col>

                    </Row>



                  </fieldset>
                </Form.Group>


                <Col md={12} style={{ textAlign: "center" }}>
                        <Button className="bsbt-btn" type="submit" variant="primary" >SUBMIT</Button>
                    </Col>

            </form>
            {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
              <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

          </Card.Body>


        </Card>

        {/* </Container> */}
      </div>
    </div>

  )
}
export default AddEndpoints;
