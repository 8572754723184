import React, { Component, useState, useMemo, useEffect } from 'react';
import { useForm, useFormContext } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { MDBInput } from "mdbreact";
import '../css/custom.css'; //Import here your file style


const AdministratorInformation = (props) => {
    const { formStep, register, errors, trigger, getValues } = props

    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordTracker, setPasswordTracker] = useState({});

    const calculatePasswordStrength = (password) => {
        let score = 0;
        const passwordTracker = {
            uppercase: false,
            lowercase: false,
            specialChar: false,
            number: false,
            sixCharsOrGreater: false,
        };




        if (password.match(/[A-Z]/)) {
            score += 1;
            passwordTracker.uppercase = true;
        }

        if (password.match(/[a-z]/)) {
            score += 1;
            passwordTracker.lowercase = true;
        }

        if (password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            score += 1;
            passwordTracker.specialChar = true;
        }

        if (password.match(/[0-9]/)) {
            score += 1;
            passwordTracker.number = true;
        }
        if (password.length >= 6) {
            score += 1;
            passwordTracker.sixCharsOrGreater = true;
        }
        setPasswordStrength(score);
        setPasswordTracker(passwordTracker);
    };



    const validatePassword = (password) => {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-zA-Z]).{6,}$/;

        if (!regex.test(password)) {

            return 'Password must contain at least one uppercase letter, one lowercase letter, one special character, one number, and be at least eight characters long.';
        }

        return true;
    };

    const handlePasswordChange = (e) => {
        calculatePasswordStrength(e.target.value);
    };


    const [einList, setEIN] = useState([]);
    const [emailList, setEmail] = useState([]);

    async function getOrganizationList() {
        const response = await axios.get(process.env.REACT_APP_BASE_URL+`/api/user/getEINByOrganization`)
        const data = await response.data.data
        console.log(data, "getOrganizationList")
        return data;
    }

    async function getUserList() {
        const response = await axios.get(process.env.REACT_APP_BASE_URL+`/api/users`)
        const data = await response.data.data
        console.log(data, "getUserList")
        return data;

    }
    function validateEIN(einList) {
        return (value) => {
            const isDuplicate = einList.some(ein => ein.ein === value);
            return !isDuplicate || 'EIN already exists';
        };
    }
    function validateEmail(emailList) {
        return (value) => {
            const isDuplicate = emailList.some(email => email.email === value);
            return !isDuplicate || 'Email already exists';
        };
    }


    useEffect(() => {
        getOrganizationList().then(data => setEIN(data));
        getUserList().then(data => setEmail(data));
    }, []);

    return (
        <div>
            {
                formStep == 1 && <section className={formStep === 1 ? "block" : "hidden"}>
                    {/* className={formStep === 1 ? "block" : "hidden"} */}
                    <Row md={12} style={{ width: "100%", }}>
                        <ul id="progressbar" class="mt-4">
                            <li class="active">Organization Information</li>
                            <li class="active">Administrator Information</li>
                            <li>Review and  Confirm</li>
                        </ul>

                        <Col md={12}> <h3 className='text-center mb-5 heading2'></h3></Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="pcform.administrator_name">
                                <Form.Label>Administrator Name <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="text"
                                    {...register("administrator_name", {
                                        // required: true, pattern :'/^[a-zA-Z]+$/'
                                        required: "Name is Required",
                                        pattern: {
                                            value: /^[a-zA-Z\s]*$/,
                                            message: "Only letters "
                                        },
                                    })}
                                    onKeyUp={() => {
                                        trigger("administrator_name");
                                    }}


                                />
                                {errors.administrator_name && (
                                    < p style={{ color: "red" }}>{errors.administrator_name.message}</p>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="pcform.administrator_phone">
                                <Form.Label>Administrator Phone <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="phone"
                                    {...register("administrator_phone", {
                                        required: true,
                                        pattern: {
                                            value: /(^\d{10}$)|(^\d{10}-\d{4}$)/,
                                            message: "Only 10 digits ",
                                        },
                                    })} onKeyUp={() => {
                                        trigger("administrator_phone")
                                    }}
                                />
                                {errors.administrator_phone && (
                                    < p style={{ color: "red" }}>{errors.administrator_phone.message}</p>
                                )}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="pcform.email">
                                <Form.Label>Administrator Email <span style={{ color: "red" }}>*</span></Form.Label>
                                <input type="text" className={`form-control ${errors.email && "invalid"}`}
                                    {...register("email", {
                                        required: 'email is required',
                                        validate: validateEmail(emailList),

                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: "Invalid email ",

                                        },
                                    })}
                                />
                                {errors.email && (
                                    < p style={{ color: "red" }}>{errors.email.message}</p>
                                )}
                                {/* {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid  Email</p>} */}

                            </Form.Group>
                        </Col>

                        <Col md={6}>

                            <Form.Group className="mb-3" controlId="pcform.administrator_password">
                                <Form.Label>Administrator Password <span style={{ color: "red" }}>*</span></Form.Label>

                                <input className="form-control"
                                    type="password"
                                    id="password"
                                    {...register("password", {
                                        required: "Password is required",
                                        validatePassword

                                    })}


                                    onChange={handlePasswordChange}
                                />
                                {passwordStrength > 0 && (
                                    <div>
                                        {/* <div>Password strength:</div> */}
                                        {/* <div>{passwordStrength}/5</div> */}
                                        <div>
                                            < p style={{ color: "red" }}>
                                                {passwordStrength < 5 && 'Must contain '}
                                                {!passwordTracker.uppercase && 'uppercase, '}
                                                {!passwordTracker.lowercase && 'lowercase, '}
                                                {!passwordTracker.specialChar && 'special character, '}
                                                {!passwordTracker.number && 'number, '}
                                                {!passwordTracker.sixCharsOrGreater &&
                                                    'six characters or more'}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {/* {errors.password && (
                                     < p style={{ color: "red" }}>{errors.password.message}</p>
                                )} */}




                            </Form.Group>
                        </Col>


                        <Col md={6}>
                            <Form.Group className="mb-3 organization_ein" controlId="pcform.organization_ein">
                                <Form.Label>Organization EIN <span style={{ color: "red" }}>*</span></Form.Label>

                                <input
                                    type="text"
                                    className={`form-control ${errors.ein && "invalid"}`}
                                    {...register("ein", {
                                        required: "EIN is Required",
                                        validate: validateEIN(einList),
                                        pattern: {
                                            value: /(^\d{9}$)|(^\d{9}-\d{4}$)/,
                                            message: "Only 9 digits "
                                        },
                                    })}
                                    onKeyUp={() => {
                                        trigger("ein");
                                    }}
                                />
                                {errors.ein && (
                                    < p style={{ color: "red" }}>{errors.ein.message}</p>
                                )}


                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3 organization_website" controlId="pcform.organization_website">
                                <Form.Label>Organization Website <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="url"
                                    {...register("website", {
                                        required: {
                                            value: true,
                                            message: "Website is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            // value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.website &&
                                    <p style={{ color: "red" }}>{errors.website.message}</p>}
                            </Form.Group>
                        </Col>


                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="pcform.terms_condition_link">
                                <Form.Label>Organization Terms and Conditions Link <span style={{ color: "red" }}>*</span></Form.Label>

                                <input className="form-control" type="text"
                                    {...register("conditionlink", {
                                        required: {
                                            value: true,
                                            message: "Terms & Condition is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            // value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.conditionlink &&
                                    <p style={{ color: "red" }}>{errors.conditionlink.message}</p>}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className="mb-3" controlId="pcform.privacy_policy_link">
                                <Form.Label>Organization Privacy Policy Link <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="text"
                                    {...register("policylink", {
                                        required: {
                                            value: true,
                                            message: "Privacy Policy Links is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            //  value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.policylink &&
                                    <p style={{ color: "red" }}>{errors.policylink.message}</p>}
                            </Form.Group>
                        </Col>

                    </Row>

                </section>
            }


        </div>
    )
}

export default AdministratorInformation



{/* <Col md={6}>
                        <Form.Group className="mb-3 return_url" controlId="pcform.return_url">
                                <Form.Label>Return URL <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="url"
                                    {...register("return_url", {
                                        required: {
                                            value: true,
                                            message: "Return url is required"
                                        },
                                        pattern: {
                                           value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.return_url &&
                                    <p style={{ color: "red" }}>{errors.return_url.message}</p>}
                            </Form.Group>

                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3 auth_type" controlId="pcform.auth_type">
                                <Form.Label>Auth Type<span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control"
                                    {...register("auth_type", {
                                        required: {
                                            value: true,
                                            message: "Authtype is required"
                                        },

                                    })}
                                />
                                {errors.auth_type &&
                                    <p style={{ color: "red" }}>{errors.auth_type.message}</p>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3 authorize_url" controlId="pcform.authorize_url">
                                <Form.Label>Authorize URL <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control"
                                    {...register("authorize_url", {
                                        required: {
                                            value: true,
                                            message: "Authorize url is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.authorize_url &&
                                    <p style={{ color: "red" }}>{errors.authorize_url.message}</p>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3 token_url" controlId="pcform.token_url">
                                <Form.Label>Token URL <span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control"
                                    {...register("token_url", {
                                        required: {
                                            value: true,
                                            message: "Token url is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.token_url &&
                                    <p style={{ color: "red" }}>{errors.token_url.message}</p>}
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3 callback_url" controlId="pcform.callback_url">
                                <Form.Label>Callback URL<span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="url"
                                    {...register("callback_url", {
                                        required: {
                                            value: true,
                                            message: "Callback url is required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                         //   value: /^((ftp|http|https|www):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.callback_url &&
                                    <p style={{ color: "red" }}>{errors.callback_url.message}</p>}
                            </Form.Group>
                        </Col> */}