
import React, { useState, useEffect } from 'react';
//export default class UserLogout extends Component {

let logout = () => {



  var userid = sessionStorage.getItem('userid');
  console.log(userid, "logoutuserid")

  var user_id = sessionStorage.setItem('userid', '');

  var isadmin = sessionStorage.setItem('isadmin', '')
  console.log(user_id, isadmin, "logoutuser")

  window.location.href = "/userlogin";
  return (
   <div></div>
  )



}



export default logout;