


import React, { Component, useState, useSearchParams,useEffect } from 'react';

import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { errorPrefix } from '@firebase/util';
import swal from 'sweetalert';
import { Link, useHistory,useParams } from 'react-router-dom';

import Select from 'react-select';
import './css/Form.css';

const btn = {
    width: "20%",
    textAlign: "center",
    marginLeft: "40%",
};

const col = {
    margin: "2px",
};


let NewOrganization = () => {

    const [selectedValue, setSelectedValue] = useState([]);

    // handle onChange event of the dropdown
    const handleChange = (e) => {
        console.log("handleChange", e)
        setSelectedValue(Array.isArray(e) ? e.map(x => x.label) : []);
        console.log("setSelectedValue== ", e.value)
        setLocation(e.value)
    }
    // const onTodoChange= (e) => {
    //     console.log("onTodoChange== ", e)
    //     setLocation(e)
    // }

    const { register, handleSubmit, reset, trigger, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [resultedOrganization, setOrganization] = useState({ name: "", first_name: "", last_name: "", email: "", phone: "", payer_id: "", user_id: "" });
    const [resultedpayerid, setpayerid] = useState("");
    const [locationList, setLocationList] = useState([]);
    const [resultedState, setLocation] = useState([]);


    const history = useHistory();

    // const params = new URLSearchParams(window.location.search);
    // const userId = params.get('userId');
    //   //  console.log("userId ",userId)


    //     const user_id = userId.slice(0, -55)
    //     console.log("user_id ",user_id)


    const onSubmit = (data) => {
        // data={state:4};
        console.log("onSubmit", data);
        // data={state:4};
        console.log("resultedpayerid ", resultedpayerid);
        console.log("userid ", sessionStorage.getItem('userid'));



        //  this.locationList.map(({selectedValue}) => data.push(selectedValue));

        axios
            .put(
                process.env.REACT_APP_BASE_URL+`/api/organization/${resultedpayerid}/${resultedState}`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a organization and Sent for Approval")
                reset(response.data);

                setTimeout(() => {
                    setSuccessMessage()
                    history.push('/userlogin?success=Please verify email and wait till approval from admin');
                }, 3000);
                // setTimeout(() => {

                // }, 5000);

                console.log(response.data, "pppppp")
            })
            .catch(error => {
                setErrorMessage("Cannot created organization")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };

    useEffect(() => {
        getOrganizationDetails();
        getLocation()
    }, []);
    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }

    function getOrganizationDetails() {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId');
          //  console.log("userId ",userId)


            const UserId = userId.slice(0, -55)
            console.log("UserId ",UserId)
        console.log("userId--ppp ",UserId)
        console.log("getOrganizationDetails userid ", sessionStorage.getItem('userid'));
        var User_Id="";
        if(sessionStorage.getItem('userid')!=null)
        {
            User_Id=sessionStorage.getItem('userid');
        }
        else
        {
            User_Id=UserId;
        }

        axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByUserId/${User_Id}`,).then(res => {
            console.log("length", res.data.data.length)
            if (res.data.data.length != 0) {

                // setOrganization(res.data.data);
                setOrganization({ name: res.data.data[0].name, first_name: res.data.data[0].first_name,

                    last_name: res.data.data[0].last_name, email: res.data.data[0].email, phone: res.data.data[0].phone,
                     payer_id: res.data.data[0].payer_id, user_id: sessionStorage.getItem('userid') })
                setpayerid(res.data.data[0].payer_id);
                // sessionStorage.setItem('userid', res.data.data[0].payer_id);
                console.log(res.data.data, "getOrganizationDetails11111")
                console.log(res.data.data[0].payer_id, "getOrganizationDetails")

            }
        });
    }

    return (
        <Container fluid="md">
        <Row>

          <Col md={12} style={{ textAlign: "left" }}>
            <Card
                style={{  }}>
                {/* <Card.Header
                    as="h5"
                    style={{ backgroundColor: "Blue", color: "#ffffff" }}
                >
                    Fill The Form
                </Card.Header> */}

                <h3 className='text-center mb-3 heading'>Organization Details</h3>
                <center><hr className='heading_line'></hr></center>
                <br></br>
                <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>

                {/* {
                resultedOrganization.map(result => {
                    return( */}
                {/* <Card.Body> */}
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Row>
                        <Col md={7} style={{ textAlign: "left" }}>

                            <Card border="primary"
                                style={{ width: "100%", marginLeft: "5%" }} >
                                <div md={12}
                                    style={{ width: "100%" }}>
                                    {/* <Card.Header
                                            as="h5"
                                            style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                        >
                                            Organization Details
                                        </Card.Header> */}


                                    <center><div className="box_tittle"><h4>Organization Details</h4></div></center>
                                    <Row>
                                        <Col md={12} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

                                            <Form.Group
                                                controlId="pcform.organizationname"
                                                className="mb-3"
                                            >
                                                <Form.Label>Organization Name</Form.Label>
                                                <Form.Control type="text" value={resultedOrganization.name} placeholder="organization name"
                                                    // {...register("name")}
                                                    // name="name" required />
                                                    //  {errors.name && errors.name.type === "required" &&
                                                    //  <p style={{ color: "red" }}>Payer Name is required</p>}

                                                    {...register("name")}
                                                />
                                                <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

                                                    {...register("payer_id")}
                                                />
                                                <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

                                                    {...register("user_id")}
                                                />
                                                <Form.Control type="text" value={resultedState} hidden={true}

                                                    {...register("state_id")}
                                                />

                                                {errors.name && errors.name.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization Name is required</p>}

                                                {/* <div style={{ color: "red" }} id="error_organizationname"></div> */}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>


                                            <Form.Group className="mb-3" controlId="pcform.payeremail">
                                                <Form.Label><span style={{ color: "red" }}>*</span> Organization Email</Form.Label>
                                                <input class="form-control"
                                                    {...register("email", { required: true, pattern: /^\S+@\S+$/i })}

                                                />
                                                {errors.email && errors.email.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization Email is required</p>}
                                                {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid  Email</p>}

                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>

                                               <Form.Group className="mb-3" controlId="pcform.phone">
                                               <Form.Label><span style={{ color: "red" }}>*</span> Organization Phone</Form.Label>

                                    <input
                                        type="text"
                                        className={`form-control ${errors.phone && "invalid"}`}
                                        {...register("phone", {
                                            required: true,
                                            pattern: {
                                                value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                message: "Invalid phone no",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("phone");
                                        }}
                                    />
                                       {errors.phone && errors.phone.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization Phone is required</p>}
                                   {errors.phone && (
                                            < p style={{ color: "red" }}>{errors.phone.message}</p>
                                        )}
                                </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.email" className="mb-1">
                                                <Form.Label><span style={{ color: "red" }}>*</span> Organization Address Line-1</Form.Label>
                                                <Form.Control type="text" placeholder="organization address line-1"

                                                    {...register("address1", { required: true })}
                                                />
                                                {errors.address1 && errors.address1.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization Address1 required</p>}



                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.address2"
                                                className="mb-3"
                                            >
                                                <Form.Label>Organization Address Line-2</Form.Label>
                                                <Form.Control type="text" placeholder="organization address line-2"

                                                    {...register("address2")}
                                                />

                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.city" className="mb-1">
                                                <Form.Label><span style={{ color: "red" }}>*</span> City</Form.Label>
                                                <Form.Control type="text" placeholder="city"
                                                    {...register("city", { required: true })}
                                                />
                                                {errors.city && errors.city.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization city is required</p>}

                                            </Form.Group>
                                        </Col>

                                        {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.state"
                                                className="mb-1"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span> State</Form.Label>
                                                <Form.Control type="text" placeholder="state"

                                                  {...register("state", { required: true})}
                                                  />
                                           {errors.state && errors.state.type === "required" &&
                                             <p style={{ color: "red" }}>Organization State is required</p>}
                                            </Form.Group>
                                        </Col> */}

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
										<Form.Group className="mb-3 lable" controlId="pcform.state">
										<Form.Label><span style={{ color: "red" }}>*</span> State</Form.Label>
                                            <Select
                                                className="dropdown"
                                                placeholder="Select Option"
                                                selectedValue
                                                label={locationList.filter(obj => selectedValue.includes(obj.label))}
                                                options={locationList}
                                                onChange={handleChange}


                                            />
			</Form.Group>
                                        </Col>

                                        {errors.selectedValue && errors.selectedValue.type === "required" &&
                                            <h4 style={{ color: "red", marginLeft: "30px" }}>*</h4>}


                                        {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                            {/* <div><b>Selected Value: </b> {JSON.stringify( selectedValue, null, 2)}</div>    */}

                                        </div>}

                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            {/* <Form.Group controlId="pcform.email" className="mb-1">
                                                <Form.Label ><span style={{ color: "red" }}>*</span> Zip Code  </Form.Label>
                                                <Form.Control type="text" placeholder="postal code"
                                                    {...register("postal_code", { required: true })}
                                                />
                                                {errors.postal_code && errors.postal_code.type === "required" &&
                                                    <p style={{ color: "red" }}>Organization Zip Code is required</p>}
                                            </Form.Group> */}


                                            <Form.Group className="mb-3" controlId="pcform.postal_code">
                                                <Form.Label> <strong style={{ color: "red" }}>*</strong>Zip Code </Form.Label>

                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.postal_code && "invalid"}`}
                                                    {...register("postal_code", {

                                                        pattern: {
                                                            value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                                            message: "Invalid zipcode ",
                                                        },
                                                    })}
                                                    onKeyUp={() => {
                                                        trigger("postal_code");
                                                    }}
                                                />
                                                {errors.postal_code && (
                                                    < p style={{ color: "red" }}>{errors.postal_code.message}</p>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.email" className="mb-1">
                                                <Form.Label>Payer Details</Form.Label>
                                                <Form.Control as="textarea" rows={3}
                                                    {...register("payer_details")}
                                                />

                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </div>
                                <br></br>
                            </Card>
                        </Col>
                        <Col md={5} style={{ textAlign: "left" }}>

                            <Card border="primary"
                                style={{ width: "80%", marginLeft: "10%" }} >
                                {/* <Card.Header
                                        as="h5"
                                        style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                    >
                                        Contact Person 1 Details
                                    </Card.Header> */}

                                <center><div className="box_tittle"><h4>Contact Person 1 Details</h4></div></center>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname1" className="mb-3">
                                            <Form.Label>First Name</Form.Label>

                                            <Form.Control type="text" value={resultedOrganization.first_name} placeholder="first name" readOnly={true}
                                                {...register("first_name")} name="first_name"
                                            />


                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname1" className="mb-3">
                                            <Form.Label>Last Name</Form.Label>

                                            <Form.Control type="text" value={resultedOrganization.last_name} placeholder="last name" readOnly={true}
                                                {...register("last_name")} name="last_name"
                                            />

                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname1" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" value={resultedOrganization.email} placeholder="email" readOnly={true}

                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname1" className="mb-3">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" value={resultedOrganization.phone} placeholder="phone" readOnly={true}

                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <br></br>
                            </Card>

                            <br></br>
                            <br></br>
                            <Card border="primary"
                                style={{ width: "80%", marginLeft: "10%" }} >
                                {/* <Card.Header
                                        as="h5"
                                        style={{ backgroundColor: "#000000", color: "#ffffff" }}
                                    >
                                        Contact Person 2 Details
                                    </Card.Header> */}

                                <center><div className="box_tittle"><h4>Contact Person 2 Details</h4></div></center>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.fname2" className="mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" placeholder="first name"
                                                {...register("first_name2")} name="first_name2"
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        <Form.Group controlId="pcform.lname2" className="mb-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" placeholder="last name"
                                                {...register("last_name2")} name="last_name2"
                                            />
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row md={12} style={{ marginLeft: "3%" }}>

                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {/* <Form.Group controlId="pcform.email2" className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" placeholder="email" name="email2"
                                                {...register("email2")}
                                            />
                                        </Form.Group> */}
                                          <Form.Group className="mb-3" controlId="pcform.email2">
                                                <Form.Label>  Email</Form.Label>
                                                <input class="form-control"
                                                    {...register("email2", { pattern: /^\S+@\S+$/i })}

                                                />

                                                {errors.email2 && errors.email2.type === "pattern" && <p style={{ color: "red" }}>Invalid  Email</p>}

                                            </Form.Group>
                                    </Col>

                                    <Col md={6} style={{ textAlign: "left" }}>

                                           <Form.Group className="mb-3" controlId="pcform.phone2">
                                    <Form.Label> Phone Number </Form.Label>

                                    <input
                                        type="text"
                                        className={`form-control ${errors.phone2 && "invalid"}`}
                                        {...register("phone2", {
                                          //  required: "Phone is Required",
                                            pattern: {
                                                value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                message: "Invalid phone no",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("phone2");
                                        }}
                                    />
                                   {errors.phone2 && (
                                            < p style={{ color: "red" }}>{errors.phone2.message}</p>
                                        )}
                                </Form.Group>
                                    </Col>

                                </Row>
                                <br></br>
                            </Card>
                        </Col>


                    </Row>
                    <br></br>
                    <Col md={12} style={{ textAlign: "center" }}>
                        <Button className="bsbt-btn" type="submit" variant="primary" >SUBMIT</Button>
                    </Col>

                </form>
                <br></br>
                {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

                {/* </Card.Body> */}
                {/* )

                })
              } */}
            </Card>
            </Col>
            </Row>
        </Container>


        //         <Container fluid="md">
        //         <Card border="primary">
        //           <Card.Header
        //             as="h5"
        //             style={{ backgroundColor: "#000000", color: "#ffffff" }}
        //           >
        //             Organization Details
        //           </Card.Header>
        //           <Card.Body >

        //             <Form id="pcform" >
        // 			   <Form.Control type="hidden" value="0" id="flag" name="flag" />
        //               <Row md ={6}>

        //                 <Col md={3}   className="mb-1" style={{ textAlign: "left", marginTop:"1px" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationname"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>Organization Name</Form.Label>
        //                     <Form.Control type="text" placeholder="organization name"

        // 					name="organizationname"  />
        // 					<div style={{color:"red"}} id="error_organizationname"></div>
        //                   </Form.Group>
        //                 </Col>
        //                 <Col md={3} className="mb-1" style={{ textAlign: "left" , marginTop:"1px" }}>
        //                   <Form.Group controlId="pcform.email" className="mb-1">
        //                     <Form.Label>Organization Email</Form.Label>
        //                     <Form.Control  type="text" placeholder="organization email"  name="organizationemail"

        // 					    />
        // 					<div style={{color:"red"}} id="error_organizationemail"></div>
        //                   </Form.Group>
        //                 </Col>
        //                 </Row>
        //                 <Row md ={6}>

        //                 <Col md={3}  className="mb-1" style={{ textAlign: "left",  marginTop:"1px" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationphone"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>Organization Phone</Form.Label>
        //                     <Form.Control
        //                       type="text"
        //                       placeholder="organization phone"
        //                     />
        //                   </Form.Group>
        //                 </Col>
        //                 <Col md={3} className="mb-1" style={{ textAlign: "left" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationaddr1"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>Organization Address Line-1</Form.Label>
        //                     <Form.Control
        //                       type="text"
        //                       placeholder="organization address line-1"

        //                     />
        //                   </Form.Group>
        //                 </Col>

        // </Row>
        // <Row md ={6}>

        //                 <Col md={3} className="mb-1" style={{ textAlign: "left" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationaddr2"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>Organization Address Line-2</Form.Label>
        //                     <Form.Control
        //                       type="text"
        //                       placeholder="organization address line-2"
        //                     />
        //                   </Form.Group>
        //                 </Col>

        //                 <Col md={3} className="mb-1" style={{ textAlign: "left" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationcity"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>City</Form.Label>
        //                     <Form.Control type="text" placeholder="city"   />
        //                   </Form.Group>
        //                 </Col>
        //                 </Row>
        //                 <Row md ={6}>
        //                 <Col md={3} className="mb-1" style={{ textAlign: "left" }}>
        //                   <Form.Group
        //                     controlId="pcform.organizationstate"
        //                     className="mb-1"
        //                   >
        //                     <Form.Label>State</Form.Label>
        //                     <Form.Control type="text" placeholder="state"  />
        //                   </Form.Group>
        //                 </Col>

        //                 <Col md={3} style={{ textAlign: "left" }}>
        //                   <Form.Group controlId="pcform.postalCode" className="mb-1">
        //                     <Form.Label>Postal Code</Form.Label>
        //                     <Form.Control type="text" placeholder="postalCode"  />
        //                   </Form.Group>
        //                 </Col>
        //                 </Row>
        //                 <Col md={6} style={{ textAlign: "left" }}>
        //                   <Form.Group controlId="pcform.payerdetails" className="mb-1">
        //                     <Form.Label>Payer Details</Form.Label>
        //                     <Form.Control as="textarea" rows={3}  />
        //                   </Form.Group>
        //                 </Col>

        //               {/* </Row> */}

        //             </Form>

        //           </Card.Body>
        //         </Card>
        //       </Container>
    )
}
export default NewOrganization;

