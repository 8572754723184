
import React, { Component, useState, useEffect } from 'react';


let LandingPage = (props) => {  
    let[htmlFileString, setHtmlFileString] = useState();
    async function fetchHtml() {
        setHtmlFileString(await (await fetch(`PayerHubLandingPage.htm`)).text());
      }


     


      useEffect(() => {
        fetchHtml();
      }, []);
    
      return(
        <div className="LandingPage">
          <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
        </div>
      );

}

export default LandingPage;
