// import "./css/Form.css";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import React from "react";
import Organization from "./Organization";
import ContactDetails from "./ContactDetails";
import axios from "axios";
import { useForm } from "react-hook-form";
const FormFill = () => {
  
  const handleChange = data => { console.log(data)}

  
  return (
    <Container md="fluid">
      <Card border="primary">
        <Card.Header
          as="h5"
          style={{ backgroundColor: "Blue", color: "#ffffff" }}
        >
          Fill The Form
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={6} style={{ textAlign: "left" }}>
              <Organization />
            </Col>
            <Col md={6} style={{ textAlign: "left" }}>
              <ContactDetails />
            </Col>
          </Row>
		  <Row> 
			<Col md={12} style={{ textAlign: "center" }}>
		    <Button type="submit" onClick={handleChange} variant="primary" >SUBMIT</Button>
			</Col>
		  </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default FormFill;
