import React, {  useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route,Redirect } from 'react-router-dom'
// import Login from './Login';
import Signup from './Signup';
import { Container } from 'react-bootstrap';
import FormFill from './FormFill';
import NewPayer from './NewPayer';
import TicketRequest from './TicketRequest';
import Billing from './Billing';
import Contract from './Contract';
import MyPayerList from './MyPayerList'
import PayersRegistered from './PayersRegistered';
import Reports from './Reports';
import ManagerDashboard from './ManagerDashboard';
import PayerContactList from '../components/PayerContactForm/PayerContactList';
import SearchPayer from './SearchPayer';
import AddPayerContact from '../components/PayerContactForm/AddPayerContact'
import Userlogin from './UserLogin'
import NewOrganization from './NewOrganization';
import ViewProfile from './ViewProfile';
import BillingDetails from './BilingDetails';
import Navbar from './NavBar';
import Documentation from './Documentation';
import UpdatePayerContact from './PayerContactForm/UpdatePayerContact';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import AdminUser from './AdminUser';
import OrganizationDirectory from './OrganizationDirectory'
import ViewPayer from './ViewPayer'
import AdminDashboard from './AdminDashboard'
import ViewMyPayers from './ViewMyPayers'
import ViewOrganization from './ViewOrganization';
import { CHECKBOX_STATUS_UNCHECKED } from 'react-bootstrap-table-next';
import { Link, useHistory ,ConditionalLink} from 'react-router-dom';
import MyEndpoints from './MyEndPoints';
import AddEndpoints from './AddEndpoints';
import UpdateEndpoints from './UpdateEndpoints'
import UpdateAdminUser from './UpdateAdminUser';
import PayerListDashboard from './PayerListDashboard.js';
import AddOrganization from './AddOrganization.js';
import PayerViewOrganization from './PayerViewOrganization'
import AddAdminEndpoints  from './AddAdminEndpoints'
import RegisteredOrgnization from './RegisteredOrganization';
import NewDashboard from './NewDashboard'
import Endpoints from './Endpoints'
import MyOrganizationProfile  from './MyOrganizationProfile'
import EditMyOrganizationProfile from './EditMyOrganizationProfile'
import  Logout from './Logout';
import ResetPassword from './ResetPassword';
import SignUpsubmit from './Register/SignupSubmit';
import ViewRegisteredOrg from './ViewRegisteredOrg';
import Notification from './Notification';
import ContractSubmit from './ContractSubmit';
import OrganizationDirectoryHome from './OrganizationDirectoryHome'
import ViewOrganizationHome from './ViewOrganizationHome'
import LandingPage from './LandingPage'
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUsePage from './TermsOfUsePage';
//import { useState, useEffect, useRef } from 'react';


function App() {
 // const history = useHistory();
  var isadmin =sessionStorage.getItem('isadmin')
  console.log("isadmin ", sessionStorage.getItem('isadmin'));



  return (

<div id="container" className='d-flex'>

     {/* <Container className='d-flex align-items-center justify-content-center' style={{ minHeight: '100vh' }}> */}
      <div className='w-100'>




        <Router>


          <Switch>


            <Route path='/' component={LandingPage} exact={true}/>

            <Route exact path='/OrganizationDirectoryHome' component={OrganizationDirectoryHome}/>
            <Route exact path='/PrivacyPolicy' component={PrivacyPolicy}/>
            <Route exact path ='/TermsOfUse' component={TermsOfUsePage}/>
              
              <Route path='/ViewOrganizationHome/:id' component={ViewOrganizationHome}/>
              {/* <Route path='/ViewOrganizationHome/:id' render={(props) => {
                return <ViewOrganizationHome
                {...props}
                />
              }}/>
             */}

            {/* <Route exact path='/userlogin' component={Userlogin} /> */}
            {/* <Route exact path='/' component={Userlogin} /> */}
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/userlogin' component={Userlogin} />
            <Route exact path='/ForgotPassword' component={ForgotPassword} />
            <Route exact path='/newOrganization' component={NewOrganization} />
            <Route path='/ChangePassword/:user_id' component={ChangePassword} />
            <Route path='/ResetPassword' component={ResetPassword} />
            <Route path='/logout' component={Logout} />
            <Route path ='/signupSubmit' component={SignUpsubmit} />
            <Route path ='/ContractSubmit' component={ContractSubmit} />
            <Route path='/Contract' component={Contract} />



            <div>


           <Navbar />

              {/* <Route path="/managerDashboard" component={ManagerDashboard}>
          { is_admin=true}
          </Route> */}



              <Route  path="/ticketRequest" component={TicketRequest}></Route>
              <Route  path="/AddOrganization" component={AddOrganization}/>
              <Route  path="/Notification" component={Notification}/>
              <Route  path="/MyEndpoints" component={MyEndpoints}/>
              <Route  path="/AddEndpoints" component={AddEndpoints}/>
              <Route  path="/AddAdminEndpoints" component={AddAdminEndpoints}/>
              <Route  path="/UpdateEndpoints/:id" component={UpdateEndpoints}/>
              <Route  path="/UpdateAdminUser/:user_id" component={UpdateAdminUser}/>
              <Route  path="/Billing" component={Billing}></Route>

              <Route path='/form' component={FormFill} />
              <Route path='/MyPayerList/:id' component={MyPayerList} />
             <Route path='/updatePayer/:id' component={UpdatePayerContact} />
              <Route path='/newPayer' component={NewPayer} />
              <Route path='/PayerListDashboard' component={PayerListDashboard} />
             <Route path='/viewPayer/:value' component={ViewPayer} />
              <Route path='/BillingDetails/:id' component={BillingDetails} />

              <Route path='/PayersRegistered/:id' component={PayersRegistered} />
              <Route path='/reports' component={Reports} />
            <Route path='/managerDashboard' component={ManagerDashboard} />
              <Route path='/payerContactList' component={PayerContactList} />
               <Route path='/MyOrganizationProfile' component={MyOrganizationProfile} />
              <Route path='/editPayerContact' component={UpdatePayerContact} />
              <Route path='/addPayerContact' component={AddPayerContact} />

              <Route path='/viewProfile' component={ViewProfile} />
              <Route path='/documentation' component={Documentation} />
              <Route path='/RegisteredOrgnization' component={RegisteredOrgnization}   />

              <Route path='/NewDashboard' component={NewDashboard} />
              <Route path='/Endpoints' component={Endpoints} />

              <Route path='/CreateAdminUser' component={AdminUser} />
             <Route path='/OrganizationDirectory' component={OrganizationDirectory} />



              <Route path='/ViewOrganization/:value' component={ViewOrganization} />
              <Route path='/ViewRegisteredOrg/:value' component={ViewRegisteredOrg} />
              <Route path='/PayerViewOrganization/:value' component={PayerViewOrganization} />


              <Route path='/AdminDashboard' component={AdminDashboard} />


              <Route path='/ViewMyPayers' component={ViewMyPayers} />
              <Route path ='/EditMyOrganizationProfile' component={EditMyOrganizationProfile}/>




            </div>

          </Switch>

        </Router>
        {/*
        <Router>
        <Route exact path='/userlogin' component={Userlogin} />
          <Navbar />
          <Switch>
           /* <Route path='/MyPayerList' component={MyPayerList} /> *
            <Route path='/ticketRequest' component={TicketRequest} />
            <Route path='/Billing' component={Billing} />
            <Route path='/form' component={FormFill} />
            <Route path='/MyPayerList/:id' component={MyPayerList} />
            <Route path='/MyPayerList' component={MyPayerList} />
            <Route path='/newPayer' component={NewPayer} />
            <Route path='/viewPayer/:id' component={ViewPayer} />
            <Route path='/BillingDetails/:id' component={BillingDetails} />
            <Route path='/Contract' component={Contract} />
            <Route path='/PayersRegistered' component={PayersRegistered} />
            <Route path='/reports' component={Reports} />
            <Route path='/managerDashboard' component={ManagerDashboard} />
            <Route path='/payerContactList' component={PayerContactList} />
            <Route path='/searchPayer' component={SearchPayer} />
            <Route path='/editPayerContact' component={UpdatePayerContact} />
            <Route path='/addPayerContact' component={AddPayerContact} />
            <Route path='/newOrganization' component={NewOrganization} />
            <Route path='/signup' component={Signup} />


          </Switch>

        </Router> */}

        {/* <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path='/' component={Dashboard} />
              <Route path='/signup' component={Signup} />
              <Route path='/Home' component={Navbar} />

              <Route path='/userlogin' component={Userlogin} />
              <Route path='/form' component={FormFill} />
              <Route path='/MyPayerList/:id' component={MyPayerList} />
              <Route path='/MyPayerList' component={MyPayerList} />
              <Route path='/newPayer' component={NewPayer} />
              <Route path='/viewPayer/:id' component={ViewPayer} />

              <Route path='/ticketRequest' component={TicketRequest} />
              <Route path='/Billing' component={Billing} />
              <Route path='/BillingDetails/:id' component={BillingDetails} />
              <Route path='/Contract' component={Contract} />
              <Route path='/PayersRegistered' component={PayersRegistered} />
              <Route path='/reports' component={Reports} />
              <Route path='/managerDashboard' component={ManagerDashboard} />
              <Route path='/payerContactList' component={PayerContactList} />
              <Route path='/searchPayer' component={SearchPayer} />
              <Route path='/editPayerContact' component={UpdatePayerContact} />
              <Route path='/addPayerContact' component={AddPayerContact} />
              <Route path='/newOrganization' component={NewOrganization} />

            </Switch>
          </AuthProvider>
        </Router> */}
      </div>
    {/* </Container> */}
    </div>
  );
}
{/* <Route path='/viewPayer' component={ViewPayer} /> */ }
{/* <Route path='/login' component={Login} /> */ }
export default App;