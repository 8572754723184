import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const ContactDetails = () => {
 
  return (
    <Container md="fluid">
      <Card border="primary">
        <Card.Header
          as="h5"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          Contact Person 1 Details
        </Card.Header>
        <Card.Body>
          <Form id="pcform" >
            <Row>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.fname1"className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="first name"
                   />
                </Form.Group>
              </Col>

              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.lname1" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="last name"
                  />
                </Form.Group>
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.email1" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="email"
                    />
                </Form.Group>
              </Col>

              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.phone1" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" placeholder="phone" 
                   />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card border="primary" style={{ marginTop: "56px"}}>
        <Card.Header
          as="h5"
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          Contact Person 2 Details
        </Card.Header>
        <Card.Body>
          <Form id="pcform">
            <Row>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.fname2" className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" placeholder="first name" />
                </Form.Group>
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.lname2" className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" placeholder="last name" />
                </Form.Group>
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.email2" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="email" />
                </Form.Group>
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group controlId="pcform.phone2" className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" placeholder="phone" />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ContactDetails;
