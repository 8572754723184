import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import public_profile from './public_profile.png'
import profile_pic from './profile_pic.png'
import plus_icon from './plus_icon.png'
import { PieChart } from 'react-minimal-pie-chart';
import { Donut } from 'react-dial-knob'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';





import "bootstrap/dist/css/bootstrap.css";

import _ from 'lodash'
import axios from 'axios';

import TodoCard from "./TodoCard";

import { useForm } from "react-hook-form";

let Notification = () => {



    // const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
        console.log(setUserList, "llllll")

    }

    const [userList, setUserList] = useState([]);

    const [payerList, setPayerList] = useState([]);
    const [Reqcount, setReqCount] = useState([]);
    const [allNotifactions, setAllNotifactions] = useState([]);
    const [UserByEndpointCount, setUserByEndpointCount] = useState([]);
    const [Regcount, setRegCount] = useState([]);
    const [regReqsingedStatus, setRegReqSingedStatus] = useState([]);
    const [tab, setTab] = useState('first');







    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'last_modified_on', text: 'Last Modified On' },
        { dataField: 'modify', text: 'Modify' },


    ]

    var endpoints = [{
        name: "Patient Access API",
        description: "Endpoint to access patient details",
        last_modified_on: "December 13,2021",
        modify: <div><button type="button" class="btn btn-sm btn-primarybg btn-primary">Edit</button>  <button type="button" class="btn btn-edit  btn-sm btn-primary">Delete</button></div>
    }];


    const Organization_profile = 0.90;
    const history = useHistory();

    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")

        if (user_id == 'undefined') {
            history.push('/userlogin');

        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }

        else {
            MyOrganizationProfile()
            RegReqPayersSignedStatusCount()
            RegPayersSignedStatusCount()
            getRegReqPayersSignedStatusList()
            getMyEndPointsUserList()
            getAllNotifactions()
            var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
            console.log(UserByIdEndpointCount, "UserByIdEndpointCount")
            // var RegisterPayerCount = sessionStorage.getItem('RegisterPayerCount');
            // console.log(RegisterPayerCount, "RegisterPayerCount")


        }
    }, []);




    var payer_id = sessionStorage.getItem('payerid');
    console.log(payer_id, "payer_id")

    var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
    console.log(UserByIdEndpointCount, "UserByIdEndpointCount")

    function getRegReqPayersSignedStatusList() {
        console.log(payer_id, "RegReqPayersSignedStatus")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatus/${payer_id}`).then(res => {
            setRegReqSingedStatus(res.data.data);
            console.log(res.data.data, "RegReqPayersSignedStatus")

        });
    }

    function MyOrganizationProfile() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "MyOrganizationProfile")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getMyOrganizationProfile/${value}`).then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "MyOrganizationProfile");
        });
    }
    function RegReqPayersSignedStatusCount() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "RegReqPayersSignedStatusCount")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatusCount/${value}`).then(res => {
            setReqCount(res.data.data);
            console.log(res.data.data, "RegReqPayersSignedStatusCount");
        });
    }

    function RegPayersSignedStatusCount() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "RegPayersSignedStatusCount")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegPayersSignedStatusCount/${value}`).then(res => {
            setRegCount(res.data.data);
            console.log(res.data.data, "RegPayersSignedStatusCount");
        });
    }
    var user_id = sessionStorage.getItem('userid');
    function getMyEndPointsUserList() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsAll/${user_id}`).then(res => {
            setUserByEndpointCount(res.data.data, "getMyEndPointsList");
            console.log(res.data.data, "getMyEndPointsUserList")
            var finalCount = res.data.data.length
            sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
            console.log(finalCount, "finalCount");
        });
    }
    var payer_id = sessionStorage.getItem('payerid');
    function getAllNotifactions() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getAllNotifactions/${payer_id}`).then(res => {
            setAllNotifactions(res.data.data, "getAllNotifactions");
            console.log(res.data.data, "getAllNotifactions")
            // var finalCount = res.data.data.length
            // sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
            // console.log(finalCount, "finalCount");
        });
    }


    // const notificationData = [
    //     {id:1,name:'notification1'},
    //     {id:2,name:'notification2'},
    //     {id:3,name:'notification3'},
    //     {id:4,name:'notification4'},
    //     {id:5,name:'notification5'},
    //     {id:6,name:'notification6'},
    //     {id:7,name:'notification7'},
    //     {id:8,name:'notification8'},
    //     {id:9,name:'notification9'},
    //     {id:10,name:'notification10'},
    //     {id:11,name:'notification11'},
    //     {id:12,name:'notification12'},
    //     {id:13,name:'notification13'},
    //     {id:14,name:'notification14'},
    //     {id:15,name:'notification15'},
    //     {id:16,name:'notification16'},
    // ]


    return (
        <Container fluid="md">


            <Row>

                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%" }} >

                        <Card.Body>
                            <div class="col-md-12" style={{ textAlign: "center" }}>
                                <h3 className='mb-3 heading'>Notifications

                                </h3>

                            </div>

                            <div class="clearfix"></div>
                            <br></br>

                            <Col md={12} style={{ textAlign: "left" }} className="dashboard">
                                <div>
                                <h5 style={{ color: "#1E468E" }}><strong>All Activity</strong> </h5>
                                                <hr></hr>
                                    {allNotifactions.map((ele) => {

                                        return (
                                            <div>
                                                <p>{ele.notificationtext} <strong>{ele.name}</strong>  <a href={ele.redirect_url}>{ele.button_text}</a></p>
                                            </div>
                                        )
                                    }
                                    )}


                                </div>

                            </Col>
                            <br></br>

                        </Card.Body>
                    </Card >
                </Col>
            </Row>




        </Container >
    );

}

export default Notification;