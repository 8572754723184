
import { Container, Row, Col, Form, Button, Card, CloseButton } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import organization_img from './massachusetts.png'
import { CircularProgress, useScrollTrigger } from "@material-ui/core";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useForm } from "react-hook-form";

import public_profile from './public_profile.png'


const ViewRegisteredOrg = () => {

    const { value } = useParams();
    const [userList, setUserList] = useState([]);
    const [endpointNamelist,setEndPointListNames]=useState([]);
    const [endpointUrls, setEndpointUrls] = useState([]);
    const history = useHistory();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { register, handleSubmit, reset, trigger, formState } = useForm();



    useEffect(() => {
        getMyEndPointsList_payer_id()
        getMyEndPointsUrls()
      axios
        .get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`)
        .then((res) => {

          setUserList(res.data.data);
          console.log(res.data.data,"getOrganisationByViewId")

        });
    },
    []);

    function getMyEndPointsList_payer_id() {
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getAllEndPoints/${value}`).then(res => {
            setEndPointListNames(res.data.data);
            console.log(res.data.data,"getAllEndPoints")
       });
    }
    function getMyEndPointsUrls() {
        // var valu = sessionStorage.getItem('payerid');
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsUrls/${value}}`).then(res => {
            setEndpointUrls(res.data.data);
            console.log(res.data.data, "getEndPointsUrls");
        });
    }
    return (
        <Container fluid="md">
        <Row>
            <Col md={12} style={{ textAlign: "left" }}>
                <Card style={{ width: "100%" }} >
                    <Card.Body>
                        <div class="pull-left">
                           {userList.map(x =>
                                <div className="w-100 text-center mt-2">
                                    <h3 className='mb-3 heading'>{x.name}</h3>

                                </div>
                            )}
                        </div>
                        <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red"}} > {errorMessage}</div>} </h4>

                       <div class="pull-right">

                        </div>


                        <div class="clearfix"></div>

                        <div style={{ display: 'block', padding: 30 }}>

                        <form >
                            <Tabs defaultActiveKey="first">
                                <Tab eventKey="first" title="Organization Profile">
                                {
                                   userList.map(x =>{
                                    const { logo } = x;
                                    if(logo != null){
                                        return (
                                            <div>
                                                <img src={process.env.REACT_APP_BASE_URL+`/api/images/${logo}`}  style={{ maxWidth: '20%' }}alt="pic" /> <span class="label btn-upload label-default"></span>
                                                </div>
                                        )

                                    }
                                    else{
                                        return (
                                            <div>
                                                <img src={public_profile}  style={{ maxWidth: '20%' }}alt="pic" /> <span class="label btn-upload label-default"></span>
                                                </div>
                                        )

                                    }
                                   })
                                        }
                                    <Table className="view_tbl">

                                        {userList.map(user => {
                                            const { name, organisation_type, website, policylink, conditionlink, address1, city, state, phone } = user;
                                            return (
                                                <div >
                                                    <tr>
                                                        <td><strong>Organization Name:</strong></td>
                                                        <td>{name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Type:</strong></td>
                                                        <td>{organisation_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization website:</strong></td>
                                                        <td>{website}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Privacy Policy:</strong></td>
                                                        <td>{policylink}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Terms and Conditions:</strong></td>
                                                        <td>{conditionlink}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Address:</strong></td>
                                                        <td>{address1},<br></br>
                                                            {city},<br></br>
                                                            {state}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Phone Number:</strong></td>
                                                        <td>{phone}</td>
                                                    </tr>

                                                </div>
                                            )
                                        })
                                    }

                                    </Table>
                                </Tab>

                                <Tab eventKey="second" title="Endpoints">
                                    <Table className="view_tbl">
                                      {/* <p><strong>Endpoint Name:</strong></p>
                                          {endpointNamelist.map(x => {
                                              const { endpoint_name } = x;
                                              return (
                                                  <div >
                                                          <li>{endpoint_name}</li>
                                                  </div>
                                              )
                                          })
                                          }
                                          <br></br>
                                           <p><strong>Base Url:</strong></p>

                                                {endpointNamelist.slice(0, 1).map(x => {
                                                    const { base_url } = x;
                                                    return (
                                                        <div >
                                                            <li>{base_url}</li>
                                                        </div>
                                                    )
                                                })
                                                }
                                           <br></br>
                                           <p><strong>Auth Scope:</strong></p>
                                          {endpointNamelist.slice(0, 1).map(x => {
                                              const {auth_scope } = x;
                                              return (
                                                  <div >
                                                          <li>{auth_scope}</li>
                                                  </div>
                                              )
                                          })
                                          } */}
                                            {endpointUrls.map(user => {
                                    const { base_url, payer_id, auth_scope, token_url, id, auth_type, authorize_url, return_url, payer_environment_type } = user;
                                    return (
                                        <Row md={12} style={{ width: "100%", }}>
                                            <Col md={12}>
                                                <div >
                                                    <tr>
                                                        <td> <h4>{payer_environment_type}</h4></td>
                                                    </tr>
                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Return URL:</strong></td>
                                                        <td>{return_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Token URL:</strong></td>
                                                        <td>{token_url}</td>
                                                    </tr>

                                                </div>
                                            </Col>
                                            <Col md={4}>
                                                <div >
                                                    <tr>
                                                        <td><strong>Authorize URL:</strong></td>
                                                        <td>{authorize_url}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Base URL:</strong></td>
                                                        <td>{base_url}</td>
                                                    </tr>

                                                </div>


                                            </Col>
                                            <Col md={4}>

                                                <div >
                                                    <tr>
                                                        <td><strong>Auth Type:</strong></td>
                                                        <td>{auth_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Auth Scope:</strong></td>
                                                        <td>{auth_scope}</td>
                                                    </tr>

                                                </div>

                                            </Col>

                                        </Row>

                                    )

                                })
                                }

                                    </Table>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Payer Name</th>
                                                <th>Endpoint Name</th>
                                                {/* <th>Base Url</th>
                                                            <th>Auth Scope</th> */}
                                                <th>Last Modified On</th>
                                              
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {endpointNamelist.map((x) =>
                                                <tr>
                                                    <td>{x.name}</td>
                                                    <td>{x.endpoint_name}</td>
                                                    {/* <td>{x.base_url}</td>
                                                                <td>{x.auth_scope}</td> */}
                                                    <td>{x.updated_date}</td>
                                                    {/* <td>
                                                        <button type="button"
                                                            className="btn btn-outline-danger btn-xs"
                                                            onClick={() => onDelete(x.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </td> */}
                                                </tr>)}
                                            {endpointNamelist.length == 0 && <tr>
                                                <td className="text-center" colSpan="4" >
                                                    <b >No data found to display.</b>
                                                </td>
                                            </tr>}
                                        </tbody>
                                    </Table>
                                </Tab>
                            </Tabs>



                        </form>
                        </div>


                    </Card.Body>

                </Card >
            </Col>
        </Row>

    </Container >


    );





}

export default ViewRegisteredOrg





// const ViewPayer = () => {







