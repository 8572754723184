import {Container, Row, Col,Form,Button, Card} from 'react-bootstrap';
import React, {Component, useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import overlayFactory from 'react-bootstrap-table2-overlay';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import axios from 'axios';
import Pagination from "react-js-pagination";
import  Navbar  from './NavBar';




// const products = [{"id":"1","payer_name":"BCBSRI","payer_email":"info@bcbsri.org","payer_url":"https://reqres.in/url",
// "payer_endpoints":"https://reqres.in/Endpoints","added_date":"20-09-2021",
// "description":"With supporting text below as a natural lead-in to additional Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise."},
// {"id":"2","payer_name":"BCBSMA","payer_email":"info@bcbsma.org","payer_url":"https://reqres.in/url",
// "payer_endpoints":"https://reqres.in/Endpoints","added_date":"20-09-2021",
// "description":"With supporting text below as a natural lead-in to additional Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise."},
// {"id":"3","payer_name":"BCBSTX","payer_email":"info@bcbstx.org","payer_url":"https://reqres.in/url",
// "payer_endpoints":"https://reqres.in/Endpoints","added_date":"20-09-2021",
// "description":"With supporting text below as a natural lead-in to additional Lorem ipsum represents a long-held tradition for designers, typographers and the like. Some people hate it and argue for its demise."}];
// const columns = [{
//   dataField: 'id',
//   text: 'Slno',
//   sort: true, 
//   filter: textFilter() // apply text filter
// }, {
//   dataField: 'payer_name',
//   text: 'Payer Name', 
//   sort: true, 
//   filter: textFilter() // apply text filter
// }, {
//   dataField: 'payer_email',
//   text: 'Payer Email', 
//   sort: true, 
//   filter: textFilter() // apply text filter
// },{
//   dataField: 'payer_url',
//   text: 'Payer URL', 
//   sort: true, 
//   filter: textFilter() // apply text filter
// },{
//   dataField: 'payer_endpoints',
//   text: 'Payer End Points', 
//   sort: true, 
//   filter: textFilter() // apply text filter
// }, {
//   dataField: 'added_date',
//   text: 'Added Date', 
//   sort: true, 
//   filter: textFilter() // apply text filter
// },  {
//   dataField: 'description',
//   text: 'Description', 
//   filter: textFilter() // apply text filter
// }];

const selectRow = {
  mode: 'checkbox',
  style: { background: 'gainsboro' },
  clickToSelect: true

};


let Reports = () => {


  const [userList, setUserList] = useState([]);

  useEffect(() => {
      getPayerList();
      var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

      if (elements.length) {
          elements[0].id = 'dt_dropdown';
      }

  }, []);

  const history = useHistory();


  
 
  const columns = [
  
    // { dataField: 'id', text: 'Id' },
    { dataField: 'name', text: ' Payer Name', filter: textFilter() },
    { dataField: 'email', text: ' Payer Email', filter: textFilter() },
    { dataField: 'phone', text: ' Payer Phone Number' },   
    { dataField: 'end_point', text: 'Payer End Points', },
    { dataField: 'inserted_date', text: ' Added Date' },
    { dataField: 'payer_details', text: 'Description' },                                  
]



const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    },
    onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    }
})

  function getPayerList(){
      axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllrequestedpayers').then(res => {
              setUserList(res.data.data);
          });
      }
     

  return (
    
 <div className="container-fluid ">
          <Container fluid="md"> 
          <Row>
              
              <Col md={12} style={{ textAlign: "left" }}>
 				<Card style={{width:"100%", align:"center"}}>
 				{/* <Card.Header as="h5" style={{backgroundColor:"blue", color:"#ffffff"}}>Reports</Card.Header> */}
                 
                        <h3 className='text-center mb-3 heading'>Reports </h3>
                        <center><hr className='heading_line2'></hr></center>
                        <br></br>
                 <div className="container-fluid p-3">
                    <BootstrapTable bootstrap4 keyField='id'
                        columns={columns}
                        data={userList}
                        pagination={pagination}
                        filter={filterFactory()}
                    />
                </div> 
          {/* <table class="table table-sm mt-3">
              <thead class="thead-dark">
                 <th>S.No</th>
                  <th>Payer Name</th>
                  <th>Payer Email</th>
                  <th>Payer URL</th>
                  <th>Payer End Points</th>
                  <th>Added Date</th>
                  <th>Description</th>
                
              </thead>
              <tbody>
                  {/* { userList.map(x => <tr>
                      <td>{x.name}</td>
                      <td>{x.email}</td>
                      <td>{x.payer_url}</td>
                      <td>{x.end_point}</td>
                      <td>{x.inserted_date}</td>
                      <td>{x.description}</td>
                  </tr>)} */}
{/* 
                  {userList.map((x, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>{x.name}</td>
                      <td>{x.email}</td>
                      <td>{x.payer_url}</td>
                      <td>{x.end_point}</td>
                      <td>{x.inserted_date}</td>
                      <td>{x.description}</td>
              </tr>
            ))}
                  {userList.length == 0 && <tr>
                      <td className="text-center" colSpan="4">
                          <b>No data found to display.</b>
                      </td>
                  </tr>}
              </tbody>
          </table> */}
         
				</Card>
            </Col>
            </Row>
 		   </Container>

      </div>
    
     
  );




}
// let Reports = () => {
// 		return (
// 		   <Container fluid="md"> 
// 				<Card border="primary" style={{width:"100%", align:"center"}}>
// 				<Card.Header as="h5" style={{backgroundColor:"blue", color:"#ffffff"}}>Reports</Card.Header>
// 				<Card.Body>
// 				   <BootstrapTable keyField='id' data={ products } columns={ columns } overlay={ overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' }) } pagination={ paginationFactory() } filter={ filterFactory() } selectRow={ selectRow } />
			
// 				</Card.Body>
// 				</Card>
// 		   </Container>
// 		)
// }



export default Reports;