import React, { Component, useState, useEffect } from 'react';
import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { errorPrefix } from '@firebase/util';
import swal from 'sweetalert';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let PayerContactForm = () => {


    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const onSubmit = data => {
        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/payercontact',
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                // swal({
                //     text: response.data.title,
                //     icon: "success",
                //     type: "success",
                //     title:"Successfully created a payer contact!"
                //   });
                //   reset(response.data);
               
                // })
                setSuccessMessage("Successfully created a payer contact!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                 console.log(response.data) })
            .catch(error => { 
                setErrorMessage("Cannot created payer contact")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });
                // swal({
                //     text: error.data,
                //     icon: "error",
                //     type: "error",
                //     title:"Cannot created payer contact"
                //   });
                // })
    };
 

    
 
    return (
        
        <Container fluid="md">

            <Card border="primary"
              style={{ width: "50%", align: "center", marginLeft: "25%" }}>
                <h3 className='text-center mb-3 heading'>Payer Contact</h3>
                        <center><hr className='heading_line'></hr></center>
                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="pcform.name">
                            <Form.Label>Name</Form.Label>
                            <input className="form-control"
                             
                              {...register("name", { required: true, maxLength: 20 })}
                            />
                            {errors.name && <h4 style={{color:"red"}}>*</h4>}
    				
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label>Email address</Form.Label>
                            <input className="form-control"
                               
                             {...register("email", {required: true, pattern: /^\S+@\S+$/i   } )}
                            />
                            {errors.email &&  <h4 style={{color:"red"}}>*</h4>}
    				{errors.email && errors.email.type === "pattern" && <p style={{color:"red"}}>Invalid Email</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>Subject</Form.Label>
                            <input className="form-control"
                         {...register("subject", { required: true, maxLength: 60 })}
                            />
                            {errors.subject && <h4 style={{color:"red"}}>*</h4>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.message">
                            <Form.Label>Message</Form.Label>
                            <textarea className="form-control" rows={3}
                               
                             {...register("message", { required: true,
                            	pattern: /^[a-zA_Z\d ]*$/  } )}
                                
                            />
                            {errors.message &&  <h4 style={{color:"red"}}>*</h4>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
             <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                </Card.Body>
              
            </Card>
        </Container>
    )

}
export default PayerContactForm;

// let PayerContactForm = () => {

//     // const [userList, setUserList] = useState([]);
//     const [message, setMessage] = useState(null);
//     // useEffect(() => {
//     //     getPayerList()
//     //     // axios.get(process.env.REACT_APP_BASE_URL+'/api/GetAllPayercontact').then(res => {
//     //     //     setUserList(res.data.data);
//     //     // });
//     // }, []);

//     var url = process.env.REACT_APP_BASE_URL+'/api/payercontact'
//     const [data, setData] = useState({
//         name: "",
//         email: "",
//         subject: "",
//         message: ""
//     })
//     function submit(e) {
//         e.preventDefault();
//         // axios.post(url, {
//         //     name: data.name,
//         //     email: data.email,
//         //     subject: data.subject,
//         //     message: data.message
//         // })

//         setMessage(null);

//         axios.post(url,{
//             name: data.name,
//             email: data.email,
//             subject: data.subject,
//             message: data.message
//         })
//           .then(res => {
//             setData(res.data);
//             setMessage(res.message);
//           })
//           .catch(err => {
//             setMessage(err.message);
//           })
//         // .then(res=>{
//         //     if(!res.ok){
//         //         throw Error('not addded')

//         //     }
//         //     return res.json()
//         // })
//         //     .then(res => {
//         //         console.log(res.data)
//         //     })
//         //     .catch(err=>{
//         //    // setError(err.message)
//         //     })

//     }
//     function handle(e) {
//         const newData = { ...data }
//         newData[e.target.id] = e.target.value
//         setData(newData)
//         console.log(newData)

//     }
//     const { register, handleSubmit, formState: { errors } } = useForm();
//     const onSubmit = data => console.log(data);





//       return (
//       <Container fluid="md">

//              <Card border="primary"> 
//              <Card.Header as="h5" style={{backgroundColor:"Blue", color:"#ffffff"}}>Request to Add New Payer</Card.Header>
//          <Card.Body>

//             <form onSubmit ={(e)=>submit(e)}>
//                  {message && <div>{message}</div>}
//                 <Form.Group className="mb-3" controlId="pcform.name">
//                     <Form.Label>Name</Form.Label>
//     					<input className="form-control" 
//                         onChange ={(e)=>handle(e)} id ="name" value ={data.name}
//                     //    {...register("name", { required: true, maxLength: 20 })}
//                        />
//     				 {/* {errors.name && errors.name.type === "required" && <p style={{color:"red"}}>Name is required </p>}
//     					{errors.name && errors.name.type === "maxLength" && <p style={{color:"red"}}> max length must be below 20</p>}  */}
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="pcform.email">
//                     <Form.Label>Email address</Form.Label>
//                    	<input className="form-control" 
//                        onChange ={(e)=>handle(e)} id ="email" value ={data.email}
//                     //    {...register("email", {required: true, pattern: /^\S+@\S+$/i   } )}
//                    />
//     				{/* {errors.email && errors.email.type === "required" && <p style={{color:"red"}}>Email is required</p>}
//     				{errors.email && errors.email.type === "pattern" && <p style={{color:"red"}}>Invalid Email</p>} */}
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="pcform.subject">
//                     <Form.Label>Subject</Form.Label>
//                  	<input className="form-control" 
//                      onChange ={(e)=>handle(e)} id ="subject" value ={data.subject}
//                     //  {...register("subject", { required: true, maxLength: 60 })}
//                      />
//     				{/* {errors.subject && errors.subject.type === "required" && <p style={{color:"red"}}>Subject is required</p>}
//     				{errors.subject && errors.subject.type === "maxLength" && <p style={{color:"red"}}>max length must be below 60</p>} */}
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="pcform.message">
//                     <Form.Label>Message</Form.Label>
//                     <textarea className="form-control" rows={3}
//                     onChange ={(e)=>handle(e)} id ="message" value ={data.message}
//                     //  {...register("message", {
//     				// 	pattern: /^[a-zA_Z\d ]*$/  } )}
//                          />
//     					{/* {errors.message && <p style={{color:"red"}}>Please correct the Message</p>} */}
//                 </Form.Group>
//                 <Form.Group className="mb-3" controlId="pcform.submit">
//                     <input type="submit" className="form-control btn btn-primary" style={{width:"20%", marginLeft:"40%"}} />
//                 </Form.Group>
//             </form>
//         </Card.Body>
//     </Card>
//         </Container>
//       );



// }



