import React, { Component, useState, useEffect } from 'react';
//import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';
import { Link, useHistory,useParams } from 'react-router-dom';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let ChangePassword = () => {

    var user_id = sessionStorage.getItem('userid');
    const { register, handleSubmit,reset,trigger, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const history = useHistory();

    const onSubmit = data => {

        console.log(data,"data")
        console.log(user_id,"user_id")
        axios
            .put(
                process.env.REACT_APP_BASE_URL+`/api/user/ChangePassword/${user_id}`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Password changed successfully!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                  history.push('/MyOrganizationProfile');
                 console.log(response.data,"data") })
            .catch(error => {
                setErrorMessage("Email does not exits")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });

    };

    return (

        <Container fluid="md">

            <Card border="primary"
              style={{ width: "50%", align: "center", marginLeft: "25%" }}>
                <h3 className='text-center mb-3 heading'>Reset Password</h3>
                        <center><hr className='heading_line'></hr></center>
                        <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>

                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="mb-3" controlId="pcform.password">
                            <Form.Label>Password</Form.Label>
                            <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("password", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.password && errors.password.type === "required" &&
                                    <p style={{ color: "red" }}>Password Required</p>
                                }
                                  {errors.password && errors.password.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.password && errors.password.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}


                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label> Confirm Password</Form.Label>
                            <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("password2", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.password2 && errors.password2.type === "required" &&
                                    <p style={{ color: "red" }}>Confirm Password Required</p>
                                }
                                  {errors.password2 && errors.password2.type === "minLength" &&
                                 <p style={{color:"red"}}>Confirm Password must be at least 6 characters </p>}

                                {errors.password2 && errors.password2.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}

                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
             <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

                </Card.Body>

            </Card>
        </Container>
    )

}
export default ChangePassword;

//

