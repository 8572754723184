import React, { useState, useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react'
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import axios from 'axios';
import { useHistory } from 'react-router';
import { set, useForm, Controller } from "react-hook-form";
//const { register, handleSubmit, reset,formState: { errors } } = useForm();
// import validator from 'validator'
import validator from 'validator';
import './UpdateAdminUser.css'


let UpdateAdminUser = () => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    let history = useHistory();
    const [user_id, setUserId] = useState(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    //  const [label, setState] = useState('');
    const [address1, setAddress1] = useState('');
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [zip_code, setZipcode] = useState('');

    const [formError, setFormError] = useState({})
    const error = {}


    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    // useEffect(() => {
    //     setID(localStorage.getItem('ID'))
    //     setEndpointName(localStorage.getItem('endpoint_name'));
    //     setBaseUrl(localStorage.getItem('base_url'));
    //     setAuthScope(localStorage.getItem('auth_scope'))
    // }, []);

    useEffect(() => {
        //   var user_id = sessionStorage.getItem('userid');
        //   var isadmin =sessionStorage.getItem('isadmin');
        //   console.log(user_id,isadmin,"user_idisadmin")

        //   if(user_id=='undefined'){
        //       history.push('/userlogin');

        //   }
        //   else if (user_id!='undefined'  && isadmin !='false'){
        //       history.push('/userlogin');
        //   }

        //   else{
        setUserId(localStorage.getItem('user_id'))
        //  localStorage.setItem('userid', user_id);
        setAddress1(localStorage.getItem('address1'));
        setCity(localStorage.getItem('city'));
        //  localStorage.setItem('label',label);
        setZipcode(localStorage.getItem('zip_code'));
        setPhone(localStorage.getItem('phone'));
        setEmail(localStorage.getItem('email'));
        //     setPassword( localStorage.getItem('password'));
        setUserName(localStorage.getItem('username'));
        // setBaseUrl(localStorage.getItem('base_url'));
        // setAuthScope(localStorage.getItem('auth_scope'))

        console.log(localStorage.getItem('email'), "email")

    }, []);

    const runValidation = () => {
        if(username.length == 0){
            error.username = " name cannot be empty"
        }
        if(email.length == null){
            error.email = "email cannot be empty"
        }else if(!validator.isEmail(email)) {
            error.email = 'invalid email format'
        }
        if(phone.length == null){
            error.phone = "phone number cannot be empty"
        }else if(phone.length  > 10 || phone.length < 10 ){
            error.phone = "Invalid Phone Number"
        }
        if(address1.length == 0){
            error.address1 = "address cannot be empty"
        }
        if(city.length == 0){
            error.city = "City cannot be empty"
        }
        if(zip_code.length == null){
            error.zip_code = "zipCode cannot be empty"
        } else if(zip_code.length  > 5 || zip_code.length < 5 ){
        error.zip_code = "invalid zip code"
        }
    }


    // var user_id = sessionStorage.getItem('userid');
    const updateAPIData = (e) => {
        e.preventDefault()

        runValidation()
        if(Object.keys(error).length == 0){
            setFormError({})
            axios.put(process.env.REACT_APP_BASE_URL + `/api/UpdateAdminUser/${user_id}`, {
                email, phone, username, password, city, address1, zip_code

                //  description
            })
                .then(response => {
                    history.push('/managerDashboard');
                    window.location.reload();
                    console.log(response.data, "UpdateAdminUser")
                })
                .catch(error => {
                    console.log(error.data)
                });

        } else {
            setFormError(error)
            }
        }

    //     axios.put(process.env.REACT_APP_BASE_URL + `/api/UpdateAdminUser/${user_id}`, {
    //         email, phone, username, password, city, address1, zip_code

    //         //  description
    //     })
    //         .then(response => {
    //             history.push('/managerDashboard');
    //             window.location.reload();
    //             console.log(response.data, "UpdateAdminUser")
    //         })
    //         .catch(error => {
    //             console.log(error.data)
    //         });

    // }

    return (
        <Container fluid="md">
            <Card border="primary" style={{ width: "50%", marginLeft: "25%" }}>
                <h3 className='text-center mb-3 heading'> Update HC User</h3>
                <center><hr className='heading_line'></hr></center>
                <Card.Body>
                    <form>
                        <Row>
                            <Form.Group className="mb-3" controlId="pcform.username">
                                <fieldset >
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.username">
                                            <Form.Label>Username<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input class="form-control"
                                                value={username} onChange={(e) => { setUserName(e.target.value) }} />
                                                {formError.username &&<span style={{color:'red'}}>{formError.username}</span>}<br/>
                                        </Form.Group>

                                    </Col>


                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.email">
                                            <Form.Label>Email<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input class="form-control"
                                                value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        {formError.email &&<span style={{color:'red'}}>{formError.email}</span>}<br/>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                            <Form.Group className="mb-3" controlId="pcform.password">
                                                <Form.Label>Password</Form.Label>
                                                <input class="form-control"
                                                    value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                            </Form.Group>
                                            </Col> */}
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                            <Form.Label>Phone Number<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input type="number" class="form-control"
                                                value={phone}

                                                onChange={(e) => {
                                                    setPhone(e.target.value)

                                                }}

                                            />
                                            {formError.phone &&<span style={{color:'red'}}>{formError.phone}</span>}<br/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                            <Form.Label>Address<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input class="form-control"
                                                value={address1} onChange={(e) => { setAddress1(e.target.value) }} />
                                                {formError.address1 &&<span style={{color:'red'}}>{formError.address1}</span>}<br/>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.city">
                                            <Form.Label>City<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input class="form-control"
                                                value={city} onChange={(e) => { setCity(e.target.value) }} />
                                                {formError.city &&<span style={{color:'red'}}>{formError.city}</span>}<br/>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                            <Form.Group className="mb-3" controlId="pcform.phone">
                                                <Form.Label>State<strong style={{ color: "red" }}>*</strong></Form.Label>
                                                <input  class="form-control"
                                                    value={label} onChange={(e) => { setState(e.target.value) }}/>
                                            </Form.Group>
                                     </Col> */}
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                            <Form.Label>Zipcode<strong style={{ color: "red" }}>*</strong></Form.Label>
                                            <input class="form-control" type="number"
                                                value={zip_code} onChange={(e) => { setZipcode(e.target.value) }} />
                                                {formError.zip_code &&<span style={{color:'red'}}>{formError.zip_code}</span>}<br/>
                                        </Form.Group>
                                    </Col>
                                </fieldset>
                            </Form.Group>


                        </Row>


                        <Row>
                            <Col>
                                <br></br>
                                <center><Button type='submit' onClick={updateAPIData}>Update</Button></center>
                            </Col>
                        </Row>
                    </form>
                </Card.Body>
            </Card>
        </Container>

    )
}
export default UpdateAdminUser