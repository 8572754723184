import React, { Component, useState, useEffect } from 'react';
import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { errorPrefix } from '@firebase/util';
import swal from 'sweetalert';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let UpdatePayer = () => {
    // const [subject, setSubject] = useState([])
    // const [name, setName] = useState("");
    // const [email, setEmail] = useState("");
    // const [message, setMessage] = useState("");
    // const [id,setId]=useState(null)

    const [userList, setUserList] = useState([]);
    useEffect(() => {
        getPayerList()
    
    }, []);

    function getPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL+'/api/GetAllPayercontact').then(res => {
            setUserList(res.data.data);
            console.log(res.data.data, "pppppppppp");

        });
    }

    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const onSubmit = data => {
        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/payercontact',
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a payer contact!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                 console.log(response.data) })
            .catch(error => { 
                setErrorMessage("Cannot created payer contact")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });
    };
   // function updatePayer(id) {
     //   console.log(id,"id")
        
        //   let item={subject,message,email,name}
        //   console.warn("item",item)
        //   fetch(process.env.REACT_APP_BASE_URL+`/api/GetAllPayercontact/${id}`, {
        //     method: 'PUT',
        //     headers:{
        //       'Accept':'application/json',
        //       'Content-Type':'application/json'
        //     },
        //     body:JSON.stringify(item)
        //   }).then((result) => {
        //     result.json().then((resp) => {
        //       console.warn(resp)
        //      // getPayerList()
        //     })
        //   })
        
   // }
 
   // updatePayer()
    return (
        
        <Container fluid="md">

            <Card border="primary"
              style={{ width: "50%", align: "center", marginLeft: "25%" }}>
                <Card.Header as="h5" style={{ backgroundColor: "Blue", color: "#ffffff" }}> Payer Contact</Card.Header>
                <Card.Body>
                {/* <div> */}
      {/* <input type="text" value={subject} onChange={(e)=>{setSubject(e.target.value)}} /> <br /><br />
        <input type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} /> <br /><br />
        <input type="text" value={message}  onChange={(e)=>{setMessage(e.target.value)}} /> <br /><br />

        <button onClick={updatePayer} >Update User</button>  
      </div> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="mb-3" controlId="pcform.name">
                            <Form.Label>Name</Form.Label>
                            <input className="form-control"
                             
                              {...register("name", { required: true, maxLength: 20 })}
                            />
                            {errors.name && errors.name.type === "required" && <p style={{color:"red"}}>Name is required </p>}
    					{errors.name && errors.name.type === "maxLength" && <p style={{color:"red"}}> max length must be below 20</p>} 
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label>Email address</Form.Label>
                            <input className="form-control"
                               
                             {...register("email", {required: true, pattern: /^\S+@\S+$/i   } )}
                            />
                            {errors.email && errors.email.type === "required" && <p style={{color:"red"}}>Email is required</p>}
    				{errors.email && errors.email.type === "pattern" && <p style={{color:"red"}}>Invalid Email</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.subject">
                            <Form.Label>Subject</Form.Label>
                            <input className="form-control"
                         {...register("subject", { required: true, maxLength: 60 })}
                            />
                            {errors.subject && errors.subject.type === "required" && <p style={{color:"red"}}>Subject is required</p>}
    				{errors.subject && errors.subject.type === "maxLength" && <p style={{color:"red"}}>max length must be below 60</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.message">
                            <Form.Label>Message</Form.Label>
                            <textarea className="form-control" rows={3}
                               
                             {...register("message", {
                            	pattern: /^[a-zA_Z\d ]*$/  } )}
                            />
                            {errors.message && <p style={{color:"red"}}>Please correct the Message</p>}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
             <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                </Card.Body>
              
            </Card>
        </Container>
    )

}
export default UpdatePayer;





