import React, { Component, useState, useMemo, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { MDBInput } from "mdbreact";
import './css/custom.css';
import Select from 'react-select';//Import here your file style
import OrganizationInformation from './Register/OrganizationInformation';
import AdministratorInformation from './Register/AdministratorInformation'
import ReviewConfirm from './Register/ReviewConfirm'

const Signup = () => {

    const [formStep, setFormStep] = useState(0)


    // get functions to build form with useForm() hook
    const { register, handleSubmit, getValues, reset, trigger, formState } = useForm({
        mode: 'onTouched',
        defaultValues: {
            // country: 'USA',
            // website:'https://',
            // conditionlink: 'https://'

        }
    });
    const { errors, isValid, isValidating } = formState;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [usertypeList, setUserTypeList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [payerEnvironmentList, setPayerEnvironmentList] = useState([]);


    const [terms, setTerms] = useState(false)

    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }
    const history = useHistory();
    useEffect(() => {
        document.getElementById("root").classList.add("root_bg");
        document.getElementById("container").classList.remove("conthome");
        getUserTypesList();
        getLocation();
        getPayerEnvironmentList();
    }, []);

    function getUserTypesList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getUserOrganisationTypes').then(res => {
            setUserTypeList(res.data.data);
            console.log(res.data.data, "getUserOrganisationTypes")
        });
    }
    function getPayerEnvironmentList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getPayersEnvironment').then(res => {
            setPayerEnvironmentList(res.data.data);
            console.log(res.data.data, "getPayersEnvironment")
        });
    }

    const onSubmit = data => {
        console.log('data1', data)
        axios.post(
            process.env.REACT_APP_BASE_URL + '/api/user/create',
            data,
        )
            .then(response => {
                setSuccessMessage("Successfully created a user!")
                reset(response.data);
                sessionStorage.setItem('userid', response.data.data[0].user_id);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/signupSubmit');
                console.log(response.data, " USer Signup ", response.data.data[0].user_id)

            })
            .catch(error => {

                if (error.response && error.response.data) {
                    var message = error.response.data.message
                    console.log(error.response.data.message, "error message")
                    setErrorMessage(message)
                    setTimeout(() => {
                        setErrorMessage()
                    }, 3000);
                }

                console.log(error.response, "data")
            });
    };


    const pageDisplay = () => {
        if (formStep === 0) {
            return <OrganizationInformation formStep={formStep} register={register} errors={errors} usertypeList={usertypeList}
                locationList={locationList} payerEnvironmentList={payerEnvironmentList} trigger={trigger} />
        } else if (formStep === 1) {
            return <AdministratorInformation formStep={formStep} register={register} errors={errors} trigger={trigger} />
        } else if (formStep === 2) {
            return <ReviewConfirm formStep={formStep} getValues={getValues} terms={terms} setTerms={setTerms} />
        }
    }


    return (
        <div class="signup_header">
            <div style={{ width: '70%', marginLeft: '8%' }}>

                <Card>
                    <Col md={12}> <h3 className='text-center mb-5 heading2'>Register</h3></Col>

                    <Card.Body>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            {pageDisplay()}
                            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red", paddingBottom: "5px", paddingTop: "5px", textAlign: "center" }} > {errorMessage}</div>} </strong>

                            <button
                                style={{ width: "15%" }}
                                type="button"
                                // disabled={(!isValid && formStep == 1) &&(formStep == 2 && !isValid)}
                                className="form-control btn btn-primary prev_btn"
                                hidden={formStep === 0}
                                onClick={() => {
                                    setFormStep((currPage) => currPage - 1)
                                }}> <i class="fas fa-arrow-left"></i>&nbsp;&nbsp; Previous</button>


                            <button
                                //  type='submit'
                                style={{ width: "15%" }}
                                className="form-control btn btn-primary next_btn"
                                disabled={formStep == 2 && !terms || !isValid}
                                onClick={() => {
                                    if (formStep === 2) {
                                        onSubmit()
                                    } else {
                                        setFormStep((currPage) => currPage + 1)
                                    }
                                }
                                }
                            >
                                {formStep === 2 ? "Submit" : "Next"} &nbsp;<i class='fas fa-arrow-right'></i>
                            </button>


                        </form>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-3">
                    {/* “Already Registered? Please Login” */}
                    <strong> Already Registered? <Link to='/userlogin'>Please Login</Link></strong>
                </div>

         
     <hr class="hr_border"  ></hr>
      <div class="l_col-md-12 text-center text-md-center mb-3 mb-md-0 copyright_content" style={{ width: "90%", align: "center", minHeight: "600px" }}>

      <p> &copy; Copyright 2022 |  <a href="https://health-chain.io" target="_blank">health-chain.io</a> | All Rights Reserved | <a href="/PrivacyPolicy" 
      target="_blank">Privacy Policy</a> | <a href="/TermsOfUse" target="_blank">Terms of Use </a>
       | <a href="https://health-chain.io/contact-us/" target="_blank">Get Help ?</a></p>
      </div>
            </div>
        </div>

    )
}

export default Signup


