
import { Container, Row, Col, Form, Button, Card, CloseButton } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import organization_img from './massachusetts.png'
import { CircularProgress, useScrollTrigger } from "@material-ui/core";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useForm } from "react-hook-form";
import { values } from "lodash";



const ViewOrganization =() =>{
  const { value } = useParams();
  const [userList, setUserList] = useState([]);
  const [payerList, setPayerList] = useState([]);
  const [allpayerList, setAllPayerList] = useState([]);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const { register, handleSubmit, reset, trigger, formState } = useForm();
  const history = useHistory();
  const [endpointNamelist,setEndPointListNames]=useState([]);


  var isadmin = sessionStorage.getItem('isadmin');
  var is_approve = sessionStorage.getItem('is_approve');
   console.log(is_approve,"isapprove")

  const columns = [
    { dataField: 'payer_id', text: 'Id' },
   { dataField: 'name', text: 'Name' },
   { dataField: 'email', text: 'Email' },
   { dataField: 'payer_url', text: 'Payer Url' },
   {
    dataField: "is_approve",
    text: "Action",
    formatter: (cellContent, row) => {

        return (

          <div className="w-100 text-center mt-2">

          <button

              className="btn btn-success btn-xs" Width="95px"
              style={{ margin: "2px" }}
              onClick={() => updateApproveById(row.payer_id)}
          >
              Approve
          </button>


          {/* <Link to={`/viewpayer/${row.payer_id}`}> */}
                  <button
                      className="btn btn-danger btn-xs" Width="95px"
                      style={{ margin: "2px" }}
                     onClick={() => updateRejectedById(row.payer_id)}
                  >
                     Rejected
                  </button>

              {/* </Link> */}
              </div>


        );
    },
},
]

function getMyEndPointsList() {
    console.log("getMyEndPointsList",value)
            axios.get(process.env.REACT_APP_BASE_URL+`/api/getEndPoints/${value}`).then(res => {
                setEndPointListNames(res.data.data);
                console.log(res.data.data,"getMyEndPointsList")
           });


        }
// const onSubmit = (data) => {
//     console.log("onSubmit", data);

//     axios
//         .put(
//             process.env.REACT_APP_BASE_URL+`/api/updateAdminPayerId/${value}`,
//             data,
//             //   { headers: { 'Content-Type': 'application/json' }}
//         )
//         .then(response => {
//             setSuccessMessage("Successfully updated!")
//             reset(response.data);
//             setTimeout(() => {
//                 setSuccessMessage()
//             }, 2000);
//            history.push('/PayerListDashboard');
//             console.log(response.data, "updateAdminPayerId")
//         })
//         .catch(error => {
//             setErrorMessage("Cannot updated")
//             reset(error.data);
//             setTimeout(() => {
//                 setErrorMessage()
//             }, 2000);
//             console.log(error.data)
//         });
// };
function getAllPayerList() {
    axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllOrganisation').then(res => {
        setAllPayerList(res.data.data);
        console.log(res.data.data, "sucess");

    });
}

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    },
    onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    }
})


  console.log("getusers called");
    var email = sessionStorage.getItem('email');
    //  var name = sessionStorage.getItem('name');
      var username = sessionStorage.getItem('username');

      console.log("getusers called",email,username,isadmin);




//   useEffect(() => {
//     getAllPayerList()
//    getMyEndPointsList()
//     axios
//       .get(process.env.REACT_APP_BASE_URL+`/api/organization/getAdminOrganisationByViewId/${value}`)
//       .then((res) => {

//         setUserList(res.data.data);
//         console.log(res.data.data,"getAdminOrganisationByViewId")
//         console.log("payerid ", sessionStorage.getItem('payerid'));
//       });
//   },



//   []);



  useEffect(() => {
      var user_id = sessionStorage.getItem('userid');
      var isadmin =sessionStorage.getItem('isadmin');
      console.log(user_id,isadmin,"user_idisadmin")

      if(user_id=='undefined' && isadmin =='undefined'){
          history.push('/userlogin');

      }
    //   else if (user_id!='undefined'  && isadmin =='false'){
        else if ((user_id!='undefined'  && isadmin =='false') || isadmin != 'true'){
          history.push('/userlogin');
      }

      else{
        getAllPayerList()
        getMyEndPointsList()
         axios
           .get(process.env.REACT_APP_BASE_URL+`/api/organization/getAdminOrganisationByViewId/${value}`)
           .then((res) => {

             setUserList(res.data.data);
             console.log(res.data.data,"getAdminOrganisationByViewId")
             console.log("payerid ", sessionStorage.getItem('payerid'));
           });

       //   console.log(user_id,"user_id")

      }


  }, []);

  function updateApproveById(value) {
    console.log(value, "updateApproveById")
     axios.put(process.env.REACT_APP_BASE_URL+`/api/updateIsApprove/${value}`)
    //     setPayerList(res.data.data);
    //     console.log(res.data.data, "updateApprove");

        .then(response => {
          setSuccessMessage("Successfully Approved!")

          setTimeout(() => {
              setSuccessMessage()
          }, 4000);
          console.log(response.data, "documentationdetails")
      })
      .catch(error => {
          setErrorMessage("Not Approved")

          setTimeout(() => {
              setErrorMessage()
          }, 4000);
          console.log(error.data)
      });
 }



function updateRejectedById(value) {
  console.log(value, "updateRejectedById")
  axios.put(process.env.REACT_APP_BASE_URL+`/api/updateIsRejected/${value}`)  .then(response => {
    setSuccessMessage("Successfully Reject!")

    setTimeout(() => {
        setSuccessMessage()
    }, 4000);

})
.catch(error => {
    setErrorMessage("Not Rejected")

    setTimeout(() => {
        setErrorMessage()
    }, 4000);
    console.log(error.data)
});

}

function getPayers() {

  getPayerList('All', 'All')
  var payerId = sessionStorage.getItem('payerid')
  getAddMyList(payerId)

}
function getPayerList(name, label) {
  console.log("getPayerList function involked ", name, label)
  var value = sessionStorage.getItem('payerid')

  axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/get/${name}/${label}/${value}`).then(res => {
      setUserList(res.data.data);
      console.log(res.data.data, "getPayerList");


  });
}
function getAddMyList(value) {
  console.log(value, "getAddMyList")
  axios.get(process.env.REACT_APP_BASE_URL+`/api/getOrganisationAddMyList/${value}`).then(res => {
      setPayerList(res.data.data);
      console.log(res.data.data, "AAAAA");
  });
}
function createContract(destinatoin_payer_id) {

  var source_payer_id = sessionStorage.getItem('payerid');
  var user_id = sessionStorage.getItem('userid');

  var addArr = {
      'destinatoin_payer_id': destinatoin_payer_id,
      //'destinatoin_payer_id': 4,
      'source_payer_id': source_payer_id, 'user_id': user_id
  };
  console.log(addArr, "addArr")
  axios.post(
      process.env.REACT_APP_BASE_URL+'/api/PayersSignedStatus/create',
      addArr,

  )
      .then(response => {
          setSuccessMessage("Successfully created a payer contact!")
       //   reset(response.data);
          getPayers();
          setTimeout(() => {
              setSuccessMessage()
          }, 2000);
          console.log(response.data)
      })
      .catch(error => {
          setErrorMessage("Cannot created payer contact")
         // reset(error.data);
          setTimeout(() => {
              setErrorMessage()
          }, 2000);
          console.log(error.data)
      })
}
  return (

    <Container fluid="md">
    <Row>
        <Col md={12} style={{ textAlign: "left" }}>
            <Card style={{ width: "100%",minHeight:"600px" }} >
                <Card.Body>


                    {/* <h3 className='mb-3 heading'>Blue Cross and Blue Shield of Massachusetts </h3> */}
                    <div class="pull-left">
                       {userList.map(x =>
                            <div className="w-100 text-center mt-2">
                                <h3 className='mb-3 heading'>{x.name}</h3>

                            </div>
                        )}
                    </div>
                    <div className="w-100 text-center mt-2">
                        <Link to={`/AddAdminEndpoints`}>
                            <button
                                className="btn btn-info " Width="95px"
                                style={{ marginLeft: "80%", borderRadius: "20px" }} id="download_button"

                            >
                                Add Endpoints
                            </button>
                            </Link>
                        </div>
                    <h4> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </h4>
                    <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red"}} > {errorMessage}</div>} </h4>

                   <div class="pull-right">




                                 {
            userList.map( x => {
              if( x.payer_status === 'Signed') {
                return   <div className="w-100 text-center mt-2">

            </div>
              }
              if(isadmin === 'true') {
                return   <div className="w-100 text-center mt-2">

            </div>
              }
             else {
                return <div className="w-100 text-center mt-2">
                <button
                    className="btn btn-primary " Width="95px"
                    style={{ marginTop: "26px", borderRadius: "20px" }} id="download_button"
                    onClick={() => {

                        createContract(x.value); window.open(`http://localhost/devportal/login.php?id=1`, 'name', 'height=500,width=850') }}
                >
                    Add Organization
                </button>
            </div>

             }

            })
          }


                    </div>


                    <div class="clearfix"></div>

                    <div style={{ display: 'block', padding: 30 }}>

                    <form >
                        <Tabs defaultActiveKey="first">
                            <Tab eventKey="first" title="Organization Profile">
                                <img src={organization_img} alt="pic" /><br></br>
                                <Table className="view_tbl">


                                    {userList.map(user => {
                                        const { name, type, website, policylink, conditionlink, address1, city, state, phone } = user;
                                        return (
                                            <div >
                                                <tr>
                                                    <td><strong>Organization Name:</strong></td>
                                                    <td>{name}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Organization Type:</strong></td>
                                                    <td>{type}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Organization website:</strong></td>
                                                    <td>{website}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Organization Privacy Policy:</strong></td>
                                                    <td>{policylink}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Organization Terms and Conditions:</strong></td>
                                                    <td>{conditionlink}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Address:</strong></td>
                                                    <td>{address1},<br></br>
                                                        {city},<br></br>
                                                        {state}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Phone Number:</strong></td>
                                                    <td>{phone}</td>
                                                </tr>
                                                {/* {
            userList.map( x => {
              if( x.org_payer_id === null) {
                return   <div className="w-100 text-center mt-2">
                     <tr >


                                                     <td><strong >Payer List:</strong></td>
                                                     <br></br>
                                                    <td>
                                                    <Form.Group style ={{marginTop: "1%"}}  className="mb-3" controlId="pcform.organisation_type_id">

                              <select className="form-control" name="payer"
                                {...register("org_payer_id", {
                                  required: true,
                                  validate: (value) => value !== "Select Payer"
                                })}>
                                <option selected  >Select  Payer</option>
                                {
                                  allpayerList.map((result) => (<option id={result.admin_payer_id} value={result.payer_id}> {result.name}</option>
                                  ))
                                }
                              </select>
                            </Form.Group>
                                    </td>

                                    <td>
                                    <Col md={12} style={{ textAlign: "center" }}>
                        <Button className="bsbt-btn" type="submit" variant="primary" >Submit</Button>
                    </Col>
                                    </td>
                                                </tr>
            </div>
              }

            })
          }   */}

                                            </div>
                                        )
                                    })
                                    }


                                </Table>
                            </Tab>

                            <Tab eventKey="second" title="Endpoints">


                                {/* Hii, I am 2nd tab content */}
                                <Table className="view_tbl">


                           <p><strong>Endpoint Name:</strong></p>
                                    {endpointNamelist.map(x => {
                                        const { endpoint_name } = x;
                                        if(x.endpoint_name !=''){
                                            return (
                                                <div >
                                                        <li>{endpoint_name}</li>

                                                </div>
                                            )
                                        }

                                    })
                                    }
                                    <br></br>
                                     <p><strong>Base Url:</strong></p>
                                          {endpointNamelist.slice(0, 1).map(x => {
                                              const { base_url } = x;
                                              return (
                                                  <div >
                                                      <li>{base_url}</li>
                                                  </div>
                                              )
                                          })
                                          }
                                     <br></br>
                                     <p><strong>Auth Scope:</strong></p>
                                    {endpointNamelist.slice(0, 1).map(x => {
                                        const {auth_scope } = x;
                                        return (
                                            <div >
                                                    <li>{auth_scope}</li>
                                            </div>
                                        )
                                    })
                                    }


                                </Table>
                            </Tab>
                        </Tabs>
                        </form>


                    </div>


                </Card.Body>

            </Card >
        </Col>
    </Row>

</Container >
//     <Container fluid="md">
//           <Card border="primary">
//             {/* <Card.Header
//               as="h5"
//               style={{ backgroundColor: "blue", color: "#ffffff" }}
//             >
//               Payer
//             </Card.Header> */}
//               <h3 className='text-center mb-3 heading'>Payer  Details</h3>
//                         <center><hr className='heading_line2'></hr></center>
//                         <br></br>
//             <Card.Body>

//               <Row>
//                 <Col>
//                 </Col>
//               </Row>
//              <div className="container-fluid p-3">
//     <BootstrapTable bootstrap4 keyField='id'
//         columns={columns}
//         data={userList}
//         pagination={pagination}

//     />
// </div>
// <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
// <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>
//                {/* <table class="table table-sm mt-3">
//                 <thead class="thead-dark">
//                   <th>Name</th>
//                   <th>Email</th>
//                   <th>Payer Url</th>

//                 </thead>
//                 <tbody>

//                   {userList.map(x => <tr>
//                     <td>{x.name}</td>
//                     <td>{x.email}</td>
//                     <td>{x.payer_url}</td>



//                   </tr>)}
//                   {userList.length == 0 && <tr>
//                     <td className="text-center" colSpan="4">
//                       <b>No data found to display.</b>
//                     </td>
//                   </tr>}
//                 </tbody>
//               </table>  */}

//              <Row>
//                 <Col md={2} style={{ width: "50%", textAlign: "left" }}>
//                   <Form.Group controlId="pcform.payercontact">
//                   <Link to={`/addPayerContact`}>
//                     <Button variant="success">Payer Contact</Button>{" "}
//                     </Link>
//                   </Form.Group>

//                 </Col>
//                 <Col md={2} style={{ width: "50%", textAlign: "right" }}>
//                   <Form.Group controlId="pcform.request">
//                   <Link to={`/ticketRequest`}>
//                     <Button variant="danger" style={{ marginLeft:"500%" }}>Request</Button>{" "}
//                     </Link>
//                   </Form.Group>
//                 </Col>
//               </Row>
//               {/* <Row style={{ marginTop: "5px" }}>
//                 <Col md={12}>
//                   <Card style={{ marginTop: "12px" }}>
//                     <Card.Header
//                       style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                     >
//                       <i class="fa fa-document"></i> Documentation
//                     </Card.Header>
//                     <Card.Body>
//                       <Row>
//                         <Col md={6}>
//                           <Card bg="primary" style={{ margin: "6px", textAlign: "left" }}>
//                              {userList.map(user => {
//                         const { new_app } = user;
//                         return (
//                           <div >
//                              <Card.Body>
//                              <Card.Title>How to request for a new App</Card.Title>
//                              <Card.Text>{new_app}</Card.Text>
//                              </Card.Body>
//                           </div>

//                         )

//                       })
//                       }
//                           </Card>
//                         </Col>
//                         <Col md={6}>
//                           <Card bg="secondary" style={{ margin: "6px", textAlign: "left" }}>
//                              {userList.map(user => {
//                         const { view_existing_app } = user;
//                         return (
//                           <div >
//                              <Card.Body>
//                              <Card.Title>How to view existing App</Card.Title>
//                              <Card.Text>{view_existing_app}</Card.Text>
//                              </Card.Body>
//                           </div>

//                         )

//                       })
//                       }
//                           </Card>
//                         </Col>
//                         <Col md={6}>
//                           <Card bg="warning" style={{ margin: "6px", textAlign: "left" }}>
//                                        {userList.map(user => {
//                         const { delete_app } = user;
//                         return (
//                           <div >
//                              <Card.Body>
//                              <Card.Title>How to delete App</Card.Title>
//                              <Card.Text>{delete_app}</Card.Text>
//                              </Card.Body>
//                           </div>

//                         )

//                       })
//                       }
//                           </Card>
//                         </Col>
//                         <Col md={6}>
//                           <Card bg="success" style={{ margin: "6px", textAlign: "left" }}>


//                               {userList.map(user => {
//                         const { patient_access_api } = user;
//                         return (
//                           <div >
//                              <Card.Body>
//                              <Card.Title>Patient Access API End Point details</Card.Title>
//                              <Card.Text>{patient_access_api}</Card.Text>
//                              </Card.Body>
//                           </div>

//                         )

//                       })
//                       }
//                           </Card>
//                         </Col>
//                         <Col md={6}>
//                           <Card bg="light" style={{ margin: "6px", textAlign: "left" }}>

//                             {userList.map(user => {
//                         const { provider_access_api } = user;
//                         return (
//                           <div >
//                              <Card.Body>
//                              <Card.Title>Provider Access API End Point details</Card.Title>
//                              <Card.Text>{provider_access_api}</Card.Text>
//                              </Card.Body>
//                           </div>

//                         )

//                       })
//                       }
//                           </Card>
//                         </Col>
//                       </Row>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               </Row> */}
//             </Card.Body>
//           </Card>
//         </Container>



  );

}

export default ViewOrganization





// const ViewPayer = () => {






//   const [viewpayerList, setViewPayerList] = useState([]);
//   const [viewpayer, setViewPayer] = useState([]);

//   useEffect(() => {
//     getIdPayerList()
//     //getPayerList()

//   }, []);



// //https://github.com/bezkoder/react-fetch-example/blob/master/src/App.js
// //https://www.codingdeft.com/posts/react-fetch-data-api/
//   function getPayerList() {
//     axios.get(process.env.REACT_APP_BASE_URL+'/api/getAllrequestedpayers').then(res => {
//       setViewPayerList(res.data.data);
//       console.log(res.data.data, "padma")
//     });
//   }

//   const { id } = useParams();
//   function getIdPayerList  ()  {

//     fetch(process.env.REACT_APP_BASE_URL+`/api/getIdrequestedpayers/${id}`, {
//         method: 'get',

//     }).then((res) => {
//       setViewPayerList(res.data.data);
//       console.log(res.data.data, "getIdrequestedpayers")
//     })
//     .catch(error => {

//         console.log(error.data) });

// }

//   return (
//     <Container fluid="md">
//       <Card border="primary">
//         <Card.Header
//           as="h5"
//           style={{ backgroundColor: "blue", color: "#ffffff" }}
//         >
//           Payer
//         </Card.Header>
//         <Card.Body>

//           <Row>
//             <Col>
//             </Col>
//           </Row>
//           <table class="table table-sm mt-3">
//             <thead class="thead-dark">
//               <th>Name</th>
//               <th>Email</th>
//               <th>Payer Url</th>


//             </thead>
//             <tbody>

//               {viewpayerList.map(x => <tr>
//                 <td>{x.name}</td>
//                 <td>{x.email}</td>
//                 <td>{x.payer_url}</td>

//               </tr>)}
//               {viewpayerList.length == 0 && <tr>
//                 <td className="text-center" colSpan="4">
//                   <b>No data found to display.</b>
//                 </td>
//               </tr>}
//             </tbody>
//           </table>

//           <Row>
//             <Col md={2} style={{ width: "50%", textAlign: "left" }}>
//               <Form.Group controlId="pcform.payercontact">
//               <Link to={`/addPayerContact`}>
//                 <Button variant="success">Payer Contact</Button>{" "}
//                 </Link>
//               </Form.Group>

//             </Col>
//             <Col md={2} style={{ width: "50%", textAlign: "right" }}>
//               <Form.Group controlId="pcform.request">
//                 <Button variant="danger">Request</Button>{" "}
//               </Form.Group>
//             </Col>
//           </Row>
//           <Row style={{ marginTop: "5px" }}>
//             <Col md={12}>
//               <Card style={{ marginTop: "12px" }}>
//                 <Card.Header
//                   style={{ backgroundColor: "#000000", color: "#ffffff" }}
//                 >
//                   <i class="fa fa-document"></i> Documentation
//                 </Card.Header>
//                 <Card.Body>
//                   <Row>
//                     <Col md={6}>
//                       <Card bg="primary" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to request for a new App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="secondary" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to view existing App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="warning" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>How to delete App</Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="success" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>
//                             Patient Access API End Point details
//                           </Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                     <Col md={6}>
//                       <Card bg="light" style={{ margin: "6px", textAlign: "left" }}>
//                         <Card.Body>
//                           <Card.Title>
//                             Provider Access API End Point details
//                           </Card.Title>
//                           <Card.Text>
//                             With supporting text below as a natural lead-in to
//                             additional Lorem ipsum represents a long-held
//                             tradition for designers, typographers and the like.
//                             Some people hate it and argue for its demise.
//                           </Card.Text>
//                         </Card.Body>
//                       </Card>
//                     </Col>
//                   </Row>
//                 </Card.Body>
//               </Card>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default ViewPayer;
