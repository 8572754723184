


import React, { Component, useState, useMemo, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { MDBInput } from "mdbreact";
import '../css/custom.css';
//Import here your file style



const ReviewConfirm = (props) => {
  const { formStep, getValues, terms, setTerms } = props
  let resvalue =''
  let state_name =''
  let payer_environment_name=''

  // console.log(getValues("organisation_type_id"),'getvalues')
  let org_type = getValues("organisation_type_id")
  console.log(org_type,'org_type')
  if(org_type != null && org_type != 'undefined' && org_type != ''){
    resvalue = org_type.split("-")
    console.log(resvalue,'resvalue')

  }
  let state_id = getValues("value")
  if(org_type != null && org_type != 'undefined' && org_type != ''){

   state_name = state_id.split("-")
   console.log(state_name,'state Name')
  }

  let payer_environment_id = getValues("payer_environment")
  if(payer_environment_id != null && payer_environment_id != 'undefined' && payer_environment_id != ''){

    payer_environment_name = payer_environment_id.split("-")
   console.log(payer_environment_name,'payer_environment_name')
  }
  return (
    <div>
      {formStep == 2 &&
        <section className={formStep === 2 ? "block" : "hidden"}>
          {/* <ReviewConfirm terms={terms} setTerms={setTerms}/> */}
          {/* {/* className={formStep === 2 ? "block" : "hidden"} */}
          <Row md={12} style={{ width: "100%", }}>
            <ul id="progressbar" class="mt-4">
              <li class="active first_li">Organization Information</li>
              <li class="active">Administrator Information</li>
              <li class="active">Review and  Confirm</li>
            </ul>

            <Col md={12}> <h3 className='text-left mb-5 heading2'></h3></Col>
            <Col md={6}  className='text-left mb-2' >
              <Form.Group className="mb-3">
                <Form.Label>Organization Name</Form.Label>
                <p>{getValues("organisation_name")}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Organization Type</Form.Label>
                <p>{resvalue[1]}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <p>{getValues("username")}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <p>{getValues("address1")}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <p>{getValues("city")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <p>{state_name[1]}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <p>{getValues("country")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <p>{getValues("phone")}</p>
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>Authtype</Form.Label>
                <p>{getValues("auth_type")}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Authorize URL</Form.Label>
                <p>{getValues("authorize_url")}</p>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Token URL</Form.Label>
                <p>{getValues("token_url")}</p>
              </Form.Group> */}


            </Col>

            <Col md={6} className='text-left mb-2' >
              <Form.Group className="mb-3">
                <Form.Label>Administrator Name</Form.Label>
                <p>{getValues("administrator_name")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Administrator Email</Form.Label>
                <p>{getValues("email")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Administrator Password</Form.Label>
                <p>****************</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Administrator Phone Number</Form.Label>
                <p>{getValues("administrator_phone")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Organization EIN</Form.Label>
                <p>{getValues("ein")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Organization Website</Form.Label>
                <p>{getValues("website")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Organization Privacy Policy Link</Form.Label>
                <p>{getValues("policylink")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Organization Terms and Conditions Link</Form.Label>
                <p>{getValues("conditionlink")}</p>
              </Form.Group>
              {/* <Form.Group className="mb-3">
                <Form.Label>Return URL</Form.Label>
                <p>{getValues("return_url")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Callback URL</Form.Label>
                <p>{getValues("callback_url")}</p>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Payer Environment</Form.Label>
                <p>{payer_environment_name[1]}</p>
              </Form.Group> */}
            </Col>
            <Col className='md-12'>
              <p>&nbsp;</p>

              <input type="checkbox" checked={terms} onChange={(e) => { setTerms(e.target.checked) }}></input>   I agree to the Health Chain Organizations Hub Terms and Conditions and Privacy Policy.
              <p>&nbsp;</p>
            </Col>





          </Row>
        </section>
      }

    </div>
  )
}

export default ReviewConfirm