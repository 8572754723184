
import React, { Component, useState, useEffect } from 'react';


let TermsOfUsePage = (props) => {  
    let[htmlFileString, setHtmlFileString] = useState();
    async function fetchHtml() {
        setHtmlFileString(await (await fetch(`terms_of_use.html`)).text());
      }
      useEffect(() => {
        fetchHtml();
      }, []);
    
      return(
        <div className="TermsOfUsePage">
          <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
        </div>
      );

}

export default TermsOfUsePage;
