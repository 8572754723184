import React, { Component, useState ,useEffect} from "react";
import { Link, useHistory } from 'react-router-dom';
/*import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardHeader,MDBCardBody,MDBBtn,MDBInput } from 'mdb-react-ui-kit';*/
// import "./css/Form.css"; //Import here your file style
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from 'axios';






let NewPayer = () => {
  const { register, handleSubmit, reset,trigger,formState: { errors } } = useForm();
  
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const history = useHistory();

 
  useEffect(() => {
      var user_id = sessionStorage.getItem('userid');
      var isadmin =sessionStorage.getItem('isadmin');
      console.log(user_id,isadmin,"user_idisadmin")
      
      if(user_id=='undefined'){
          history.push('/userlogin');

      }
      else if (user_id!='undefined'  && isadmin !='false'){
          history.push('/userlogin'); 
      }
     
      else{
       
     
       //   console.log(user_id,"user_id")

      }
   

  }, []);

  const onSubmit = data => {
    console.log("data",data)
      axios
          .post(
              process.env.REACT_APP_BASE_URL+'/api/requestedpayers',
              data,
              //   { headers: { 'Content-Type': 'application/json' }}
          )
          .then(response => {  
             setSuccessMessage("Successfully created a payer!")
             reset(response.data);
             setTimeout(() => {
              setSuccessMessage()
            }, 2000);
           console.log(response.data) })
          .catch(error => { 
            setErrorMessage("Cannot created payer")
            reset(error.data);
            setTimeout(() => {
              setErrorMessage()
            }, 2000);
            console.log(error.data) });
  };
  return (
    <Container fluid="md">
        <Row>
        <Col md={12} style={{ textAlign: "left" }}>
        <Card>
           {/* <Card.Header
              as="h5"
              style={{ backgroundColor: "Blue", color: "#ffffff" }}
            >
              Request to Add New Payer
            </Card.Header> */}
            {/*<div class="card-header" style={{ backgroundColor: "blue", color: "#ffffff" }}>
              Request to Add New Payer
          </div>*/}
                        <h3 className='text-center mb-3 heading'>Request to Add New Payer</h3>
                        <center><hr className='heading_line'></hr></center>
                        <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>
      
            <Card.Body>
              {/* //onSubmit ={(e)=>submit(e)} */}
              <form onSubmit={handleSubmit(onSubmit)}  >
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.payername">
                      <Form.Label>Payer Name<strong style={{color:"red"}}>*</strong></Form.Label>
                      <input class="form-control"
                         {...register("name", { required: true, maxLength: 20 })}
                         />
               
                   
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.payeremail">
                      <Form.Label>Payer Email<strong style={{color:"red"}}>*</strong></Form.Label>
                      <input class="form-control" 
                      {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                        
                         />
                      {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid Payer Email</p>}
                    
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.payerurl">
                      <Form.Label>Payer URL<strong style={{color:"red"}}>*</strong></Form.Label>
                      <input class="form-control" type="url"  placeholder="https://exapmle.com"
                       {...register("payer_url", {
                        required: true,
                        pattern: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
                      })}
                      
                        />
                      {errors.payer_url && errors.payer_url.type === "pattern" && <p style={{ color: "red" }}>Invalid payer URL</p>}
                    
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                  <Form.Group className="mb-3" controlId="pcform.phone">
                                    <Form.Label> Phone Number <strong style={{color:"red"}}>*</strong></Form.Label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.phone && "invalid"}`}
                                        {...register("phone", {required: true,
                                       
                                            pattern: {
                                                value: /(^\d{10}$)|(^\d{10}-\d{4}$)/,
                                                message: "Only 10 digits ",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("phone");
                                        }}
                                    />
                                   {errors.phone && (
                                            < p style={{ color: "red" }}>{errors.phone.message}</p>
                                        )}
                                </Form.Group>
                  </Col>
                  {/* <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.payerendpoints">
                      <Form.Label>Payer End Points</Form.Label>
                      <input class="form-control" 
                     {...register("end_point", {required: true})}
                     
                   
                         />
                      {errors.end_point && errors.end_point.type === "required" &&  <h4 style={{ color: "red" }}>*</h4>}
                    </Form.Group>
                  </Col> */}
                </Row>
               
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="pcform.message">
                      <Form.Label>Other Description <strong style={{color:"red"}}>*</strong></Form.Label>
                      <textarea class="form-control"
                      //  rows={7} 
                      {...register("description", {
                        required: true,
                        pattern: /^[a-zA_Z\d ]*$/
                      })}
                        
                         />
                         {/* {errors.description && errors.description.type === "required" && <p style={{color:"red"}}>This message is required</p>} */}
                   

                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <br></br>
                  <center> <Form.Group className="mb-1" controlId="pcform.submit" style={{ textAlign: "right", width: "20%"}}>
                      <input type="submit" class="form-control sbt-btn btn btn-primary" />
                    </Form.Group></center> 
                  </Col>
                </Row>
              </form>
              {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
          <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>
       */}
            </Card.Body>
          </Card>
          </Col>
          </Row>
        </Container>
   
  )
}
export default NewPayer;

// const NewPayer = () => {
//   var url = process.env.REACT_APP_BASE_URL+'/api/requestedpayers'
//   const [data, setData] = useState({
//     name: "",
//     payer_URL: "",
//     payerendpointurl: "",
//     payeremail: "",
//     payerphone: "",
//     message: ""
//   })
//   function submit(e) {
//     e.preventDefault();
//     axios.post(url, {
//       name: data.name,
//       payer_URL: data.payer_url,
//       end_point: data.payerendpointurl,
//      email: data.payeremail,
//      phone: data.payerphone,
//      description: data.message
//     })
//       .then(res => {
//         console.log(res.data)
//       })


//   }
//   function handle(e) {
//     const newData = { ...data }
//     newData[e.target.id] = e.target.value
//     setData(newData)
//     console.log(newData)

//   }


//   const { register, handleSubmit, formState: { errors } } = useForm();
//   const onSubmit = data => console.log(data);

//   return (
//     <Container fluid="md">
//       <Card border="primary" style={{ width: "50%", marginLeft: "25%" }}>
//         <Card.Header
//           as="h5"
//           style={{ backgroundColor: "Blue", color: "#ffffff" }}
//         >
//           Request to Add New Payer
//         </Card.Header>
//         <Card.Body>
//           {/* //onSubmit ={(e)=>submit(e)} */}
//           <form onSubmit ={(e)=>submit(e)}  >
//             <Row>
//               <Col md={12}>
//                 <Form.Group className="mb-3" controlId="pcform.payername">
//                   <Form.Label>Payer Name</Form.Label>
//                   <input class="form-control"
//                     //  {...register("payername", { required: true, maxLength: 20 })}
//                      onChange={(e) => handle(e)} id="name" value={data.name}
//                      />
//               {/* {errors.payername && errors.payername.type === "required" && <p style={{ color: "red" }}>Payer Name is required</p>}
//                   {errors.payername && errors.payername.type === "maxLength" && <p style={{ color: "red" }}>Payer Name maxLength must be less than or = 20</p>}  */}
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col md={6} style={{ textAlign: "left" }}>
//                 <Form.Group className="mb-3" controlId="pcform.payerurl">
//                   <Form.Label>Payer URL</Form.Label>
//                   <input class="form-control"
//                   //  {...register("payerurl", {
//                   //   required: true,
//                   //   pattern: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
//                   // })}
//                      onChange={(e) => handle(e)} id="payer_url" value={data.payer_url} 
//                     />
//                   {/* {errors.payerurl && errors.payerurl.type === "pattern" && <p style={{ color: "red" }}>Invalid payer URL</p>}
//                   {errors.payerurl && errors.payerurl.type === "required" && <p style={{ color: "red" }}>Payer URL is required</p>} */}
//                 </Form.Group>
//               </Col>
//               <Col md={6} style={{ textAlign: "left" }}>
//                 <Form.Group className="mb-3" controlId="pcform.payerendpoints">
//                   <Form.Label>Payer End Points</Form.Label>
//                   <input class="form-control" 
//                   // {...register("payerendpointurl")}
//                   onChange={(e) => handle(e)} id="payerendpointurl" value={data.payerendpointurl}
//                      />
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col md={6} style={{ textAlign: "left" }}>
//                 <Form.Group className="mb-3" controlId="pcform.payeremail">
//                   <Form.Label>Payer Email</Form.Label>
//                   <input class="form-control" 
//                   // {...register("payeremail", { required: true, pattern: /^\S+@\S+$/i })}
//                      onChange={(e) => handle(e)} id="payeremail" value={data.payeremail}
//                      />
//                   {/* {errors.payeremail && errors.payeremail.type === "pattern" && <p style={{ color: "red" }}>Invalid payer Email</p>}
//                   {errors.payeremail && errors.payeremail.type === "required" && <p style={{ color: "red" }}>Email is required</p>} */}
//                 </Form.Group>
//               </Col>
//               <Col md={6} style={{ textAlign: "left" }}>
//                 <Form.Group className="mb-3" controlId="pcform.payerphone">
//                   <Form.Label>Payer End Phone</Form.Label>
//                   <input class="form-control" 
//                   // {...register("payerphone", {
//                   //   required: true,
//                   //   pattern: /^[\d ]*$/
//                   // })}
//                    onChange={(e) => handle(e)} id="payerphone" value={data.payerphone}
//                      />
//                   {errors.payerphone && <p style={{ color: "red" }}>Invalid Payer Phone</p>}
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col md={12}>
//                 <Form.Group className="mb-3" controlId="pcform.message">
//                   <Form.Label>Message</Form.Label>
//                   <textarea class="form-control"
//                   //  rows={3} {...register("message", {
//                   //   pattern: /^[a-zA_Z\d ]*$/
//                   // })}
//                      onChange={(e) => handle(e)} id="message" value={data.message}
//                      />
//                   {/* {errors.message && <p style={{ color: "red" }}>Please correct the Message</p>} */}
//                 </Form.Group>
//               </Col>
//             </Row>
//             <Row>
//               <Col>
//                 <Form.Group className="mb-1" controlId="pcform.submit" style={{ textAlign: "right" }}>
//                   <input type="submit" class="form-control btn btn-primary" />
//                 </Form.Group>
//               </Col>
//             </Row>
//           </form>
//         </Card.Body>
//       </Card>
//     </Container>
//   )
// };

// export default NewPayer;
