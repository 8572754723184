import React, {useContext, useState, useEffect} from 'react';
import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth';
import fbApp from '../firebase';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
   // const url = process.env.REACT_APP_BASE_URL+"/api/user/login"
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signup(email, password) {
        const auth = getAuth(fbApp);
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function login(email, password) {
        const auth = getAuth(fbApp);
        return signInWithEmailAndPassword(auth, email, password);
    }

    function logout() {
        const auth = getAuth(fbApp);
        return signOut(auth);
    }

    useEffect(() => {
        const auth = getAuth(fbApp);
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
            setLoading(false);
        })

        return unsubscribe;
    }, [])

    const value = {
        currentUser,
        signup,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
