import React, { Component, useState, useMemo, useEffect } from 'react';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Img from './healthchain-logo.png'

const ContractSubmit = () => {


  useEffect(() => {
   let urlString = window.location.href;
   
    // console.log(payer_signed_status_id,"payer_signed_status_id");
    // var url = new URL(urlString);
    // var token = url.searchParams.get("id_token");

    //  var urlString = "www.site.com/index.php#error=hello";
     var token = urlString.substring(urlString.indexOf('#')+10);
    var finderror = urlString.substring(urlString.indexOf('#')+1);
    var error = finderror.substring(0,5);
    console.log(error,"error========");
    if(error=='error')
    {
      localStorage.setItem("token", "");
    }
    else
    {
      localStorage.setItem("token", token);
    }
    console.log(localStorage.getItem("token"),"Final Token========");

  }, []);
//  function closewindow() {
//   //  alert(2);
//    window.open = 'http://localhost:3000/OrganizationDirectory?success=successfully organization added and sent for approval';
//    // href = "http://localhost:3000/RegisteredOrgnization";

//    window.location.reload();
//   }
  function closewindow(){
    
         window.open(process.env.REACT_APP_FRONTEND_BASE_URL + '/Contract?success=successfully organization added and sent for approval');
         window.close();
}



  return <div>
    <div class="signup_header">
      <div style={{ width: '60%', marginLeft: '0%' ,marginTop:"10%"}}>
        <Card style={{minHeight: 300}}>
          <Card.Body>
            <div class="content authorized">

              <div class="top">

                <center>
                <img src={Img} alt="pic" width={200} height={100}/></center>
                <h3  style={{ textAlign :"center" }}><span class="party-popper"></span><br /> You are successfully authorized. </h3>
                <br></br><br></br>
                <div> <button  style={{ marginLeft: '35%' }} type="button" onClick={closewindow} class="btn btn-danger">Close Window</button></div>
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* <br></br><br></br> */}
      </div>
    </div>
  </div>;


};

export default ContractSubmit;
