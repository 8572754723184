import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { async } from '@firebase/util';
import { Redirect } from "react-router-dom";
import Img from './healthchain-logo.png'
import { set, useForm } from "react-hook-form";
import axios from 'axios';


export const BASE_URL = process.env.REACT_APP_BASE_URL;


//Import here your file style

const UserLogin = () => {
  const INITIAL = {
    email: "",
    password: ""

  }
  useEffect(() => {
    document.getElementById("root").classList.add("root_bg");

    document.getElementById("container").classList.remove("conthome");

    const urlParams = new URLSearchParams(window.location.search);
    const succmessage = urlParams.get('success');
    console.log("Login useEffect", succmessage);

    console.log("Login BASE_URL === ", BASE_URL, process.env.REACT_APP_BASE_URL);
    setTimeout(() => {
      setSuccessMessage()
    }, 3000);
    setSuccessMessage(succmessage);
  }, []);

  var url = process.env.REACT_APP_BASE_URL + "/api/user/login";
  console.log(url, "url=====");
  const [data, setData] = useState(INITIAL);
  const [message, setMessage] = useState(null);

  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = React.useState("");

  const { register, reset, formState: { errors } } = useForm();


  function handleChange(e) {
    const { id, value } = e.target; //destructuring
    setData({ ...data, [id]: value })
  }

  //   const location = useLocation();
  // var pathname = '/OrganizationDirectory'

  //   const reload = () => {
  //     history.push(location,'/OrganizationDirectory');
  //   };

  // const reload = () => {
  //   history.push('/OrganizationDirectory');
  // };

  function handleSubmit(e) {
    e.preventDefault();

    axios.post(url, data)
      .then(res => {
        if (res.data.code === 200) {
          sessionStorage.setItem('userid', res.data.results[0].user_id);
          sessionStorage.setItem('payerid', res.data.results[0].payer_id)
          sessionStorage.setItem('email', res.data.results[0].email)
          sessionStorage.setItem('isadmin', res.data.results[0].is_admin)
          sessionStorage.setItem('ishcuser', res.data.results[0].is_hc_user)
          sessionStorage.setItem('username', res.data.results[0].username)
          sessionStorage.setItem('organisationname', res.data.results[0].organisation_name)
          sessionStorage.setItem('name', res.data.results[0].first_name)
          // sessionStorage.setItem('isapprove', res.data.results[0].)
          //  sessionStorage.setItem('name', res.data.results[0].first_name + ' ' + res.data.results[0].last_name)
          console.log(res.data.results[0].first_name, "name")
          console.log(res.data.results[0], "isadmin")
          console.log(res.data.results[0].is_hc_user, "ishcuser")

          if (res.data.results[0].organisation_name == null) {
            window.location.reload(history.push('/AdminDashboard'));
            // history.push('/AdminDashboard');

          }
          else if (res.data.results[0].orgemail != null) {
            // reload()
            window.location.reload(history.push('/NewDashboard'));
            //  history.push('/NewDashboard');
          }

          // else {
          //   history.push('/newOrganization');
          // }

        }

        console.log(res.data, "handleSubmit On Login")
        console.log(sessionStorage.setItem('username'), "username")
        console.log(res.data.results[0].user_id, " --- result")
      })
      .catch(error => {
        var is_admin = ''
        if (error.response && error.response.data) {
          var message = error.response.data.message
          console.log(error.response.data.message, "error message")
          setErrorMessage(message)
          setTimeout(() => {
            setErrorMessage()
          }, 2000);
        }
        else if (is_admin != true) {
          setErrorMessage("Your oganization is not approved yet")
          setTimeout(() => {
            setErrorMessage()
          }, 2000);

        }



        console.log(error.data, "Error");
      })


  }

  return (

    <div style={{ width: '40%', marginLeft: '22%' }}>
      <Card>
        <Card.Body>
          <center>
            <img src={Img} alt="pic" width={150} /></center>
          <hr></hr>
          {/* <div><h4 className='text-center mb-4 '><strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong></h4></div> */}
          <h2 className='text-center mb-4 '>Log In</h2>
          <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green", paddingBottom: "5px", paddingTop: "5px", textAlign: "center" }} > {successMessage}</div>} </strong>
          <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red", paddingBottom: "5px", paddingTop: "5px", textAlign: "center" }} > {errorMessage}</div>} </strong>

          <form onSubmit={handleSubmit} >

            <Form.Group className="mb-3" controlId="pcform.email">
              <Form.Label>Email</Form.Label>
              <input type="text" name="email" id="email"
                onChange={handleChange}
                value={data.email} className="form-control"

              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="pcform.password">
              <Form.Label>Password</Form.Label>
              <input className="form-control"
                name="password" id="password" type='password'
                onChange={handleChange}
                value={data.password}
              />
            </Form.Group>
            <div className="w-100 text-center mt-3">
              <strong style={{ marginLeft: "75%" }}> <Link to='/ForgotPassword'>Forgot Password</Link></strong>
            </div>
            <center><Button type='submit' disabled={!data.email || !data.password}
              className='w-90 mt-4'>Log In</Button></center>


          </form>
          {/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
          <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}

        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-3">
        <strong>Want to register with us? <Link to='/signup'>Sign Up</Link></strong>
      </div>
     
      <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
     <hr class="hr_border"  ></hr>
      <div class="l_col-md-12 text-center text-md-center mb-3 mb-md-0 copyright_content" style={{ width: "90%", align: "center", minHeight: "600px" }}>

      <p> &copy; Copyright 2022 |  <a href="https://health-chain.io" target="_blank">health-chain.io</a> | All Rights Reserved | <a href="/PrivacyPolicy" 
      target="_blank">Privacy Policy</a> | <a href="/TermsOfUse" target="_blank">Terms of Use </a>
       | <a href="https://health-chain.io/contact-us/" target="_blank">Get Help ?</a></p>
      </div>

     </div>
      

   


  )



}

export default UserLogin;








