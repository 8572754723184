import React, { useState, useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react'
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import { set, useForm, Controller } from "react-hook-form";
import { subYears } from 'date-fns';
//const { register, handleSubmit, reset,formState: { errors } } = useForm();

let UpdateEndpoints = (props) => {
  console.log('someData', props.location.state)
  console.log('payer_endpoint_id', props.location.state.payer_endpoint_id)

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  let history = useHistory();
  const [id, setID] = useState(null);
  const [auth_scope, setAuthScope] = useState('');
  const [other_endpoint_name, setOtherEndpointName] = useState('');
  const [endpoint_name, setEndpointName] = useState('');
  // token_url,auth_type,authorize_url,return_url
  const [base_url, setBaseUrl] = useState('');
  const [token_url, setTokenURL] = useState('');
  const [auth_type, setAuthType] = useState('');
  const [authorize_url, setAuthorizeURL] = useState('');
  const [return_url, setReturnURL] = useState('');
  const [payer_environment_type, SetPayerEnvironmentType] = useState('');
  const [APIData, setAPIData] = useState([]);
  const [formError, setFormError] = useState({})
  const error = {}

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const location = useLocation();


  useEffect(() => {
   
 

   
    
    getMyEndPointsList()

    var user_id = sessionStorage.getItem('userid');
    var isadmin = sessionStorage.getItem('isadmin');
    var payer_id = sessionStorage.getItem('payerid');


    console.log(user_id, isadmin, payer_id, "user_idisadminpayer_id")


    if (user_id == 'undefined') {
      history.push('/userlogin');

    }
    else if (user_id != 'undefined' && isadmin != 'false') {
      history.push('/userlogin');
    }

    else {
      getMyEndPointsUserList()
      setID(localStorage.getItem('ID'))
      setEndpointName(localStorage.getItem('endpoint_name'));
      setBaseUrl(localStorage.getItem('base_url'));
      setAuthScope(localStorage.getItem('auth_scope'));
      //   setEndpointMasterId(localStorage.getItem('end_point_master_id'));
      console.log(localStorage.getItem('end_point_master_id'), "end_point_master_id")
      console.log(localStorage.getItem('endpoint_name'), "endpoint_name")

      //  var endpointId = sessionStorage.getItem('end_point_master_id')
      //  console.log(endpointId,"endpointId")
    }
  }, []);
  const [resultedOrganization, setOrganization] = useState({
    payer_id: sessionStorage.getItem('payerid'),
    user_id: sessionStorage.getItem('userid'),
    name: sessionStorage.getItem('name')

  });
  const [userList, setUserList] = useState([]);
  var isadmin = sessionStorage.getItem('isadmin');
  const [allpayerList, setAllPayerList] = useState([]);

  const formData = {
    auth_scope: auth_scope,
    base_url: base_url,
    token_url: token_url,
    auth_type: auth_type, authorize_url: authorize_url, return_url: return_url,
    other_endpoint_name: other_endpoint_name
  }

  const onSubmit = (data) => {
    // console.log("name", sessionStorage.getItem('name'))
    // console.log("new data ", data)
    const newFormData = Object.assign(data, formData)
    console.log(newFormData, 'data')
    var payer_id = sessionStorage.getItem('payerid');
    var isadmin = sessionStorage.getItem('isadmin');
    console.log("Appended payer id", payer_id)
    runValidations()
    if (Object.keys(error).length === 0) {
      setFormError({})
      axios
        .post(
          process.env.REACT_APP_BASE_URL + `/api/update/EndPoints/${props.location.state.payer_endpoint_id}/${props.location.state.type}`,
          newFormData
        )
        .then(response => {
          setSuccessMessage("Successfully created a endpoints!")
          reset(response.data);
          setTimeout(() => {
            setSuccessMessage()
          }, 20000);
          if (isadmin === 'true') {
            history.push('/PayerListDashboard');

          }
          else {
            window.location.reload(history.push('/Endpoints'));

          }
          console.log(response.data)
        })
        .catch(error => {
          setErrorMessage("Cannot created endpoints")
          reset(error.data);
          setTimeout(() => {
            setErrorMessage()
          }, 2000);
          console.log(error.data)
        });
    }

    else {
      setFormError(error)
    }
  };
  function getMyEndPointsUserList() {
    var user_id = sessionStorage.getItem('userid');
    console.log(props.location.state.type,"type")
   
    axios.get(process.env.REACT_APP_BASE_URL + `/api/getTypeEndPoints/${user_id}/${props.location.state.type}`).then(res => {
      setUserList(res.data.data, "getMyEndPointsList");
      var getMyEndPointsUserList = res.data.data
      console.log(getMyEndPointsUserList, "getMyEndPointsUserList")
      var finalCount = res.data.data.length
      sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
      console.log(finalCount, "finalCount");
      var otherEndpoints = getMyEndPointsUserList.map(x => x.endpoint_name)
      console.log(otherEndpoints, "otherEndpoints")

      let getAllendpointsNames = ['Condition', 'Coverage', 'Claims', 'Device', 'DiagnosticReport', 'DocumentReferences', 'Encounter',
        'ExplanationOfBenefit', 'Immunization', 'Location', 'Practitioner', 'PractitionerRole', 'Procedure', 'Provenance',
        'MedicationKnowledge', 'MedicationDispense', 'Medication', 'Observation', 'Organization', 'Patient',
      ]

      otherEndpoints = otherEndpoints.filter(x => !getAllendpointsNames.includes(x))
      console.log(otherEndpoints, "otherEndpoints")

      if (finalCount > 0) {
        setAuthScope(res.data.data[0].auth_scope)
        setBaseUrl(res.data.data[0].base_url)
        setTokenURL(res.data.data[0].token_url)
        setAuthType(res.data.data[0].auth_type)
        setAuthorizeURL(res.data.data[0].authorize_url)
        setReturnURL(res.data.data[0].return_url)
        SetPayerEnvironmentType(res.data.data[0].payer_environment_type)
        setOtherEndpointName(otherEndpoints)
      }

    });
  }
  function getMyEndPointsList() {
    axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsList`).then(res => {
      setAPIData(res.data.data);
      console.log(res.data.data, "getEndPointsList");

    });
  }
  const Cancel = () => {

    history.push('/Endpoints');
  }


  const runValidations = () => {
    if (auth_scope.length === 0) {
      error.auth_scope = 'Authscope is required'
    }
    if (base_url.length === 0) {
      error.base_url = "Base url is required"
    }


  }

  // const updateAPIData = (e) => {
  //   runValidations()
  //   e.preventDefault()
  //   console.log(Object.keys(error).length, "Edit")

  //   if (Object.keys(error).length === 0) {
  //     setFormError({})
  //     axios.put(process.env.REACT_APP_BASE_URL + `/api/update/EndPoints/${id}`, {
  //       auth_scope, endpoint_name,
  //       base_url, end_point_master_id
  //     })
  //       .then(response => {
  //         console.log("end_point_master_id ", end_point_master_id)
  //         console.log("base_url ", base_url)
  //         history.push('/Endpoints');
  //         console.log(response.data, "updateEndpoints")
  //       })
  //       .catch(error => {
  //         console.log(error.data)
  //       });
  //   }

  //   else {
  //     setFormError(error)
  //   }

  // }


  return (
    <Container fluid="md">
      <Card border="primary" style={{ width: "60%", marginLeft: "20%" }}>
        <h3 className='text-center mb-3 heading'> Update Endpoints</h3>
        <center><hr className='heading_line'></hr></center>

        <div class="pull-right">

          <button type="button" id="" style={{ marginLeft: "80%" }}
            class="btn-edit btn btn-primary" onClick={Cancel}>Cancel</button>

        </div>
        <Card.Body>
          <form onSubmit={handleSubmit(onSubmit)}>

            <Form.Control type="text" value={resultedOrganization.payer_id} hidden={true}

              {...register("payer_id")}
            />
            <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

              {...register("user_id")}
            />
            <Form.Control type="text" value={resultedOrganization.name} hidden={true}

              {...register("name")}
            />

            <Form.Group className="mb-3" controlId="pcform.username">
              <fieldset >

                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>

                    {/* {(() => {
                      if (isadmin == 'true') {
                        return (
                          <div className="w-100 text-center mt-2">
                            <Form.Group className="mb-3" controlId="pcform.payer_id">
                              <Form.Label> Select Payer<strong style={{ color: "red" }}>*</strong></Form.Label>
                              <select className="form-control" name="payer"
                                {...register("payer_id", {
                                  required: true,
                                  validate: (value) => value !== "Select Payer"
                                })}>

                                {
                                  allpayerList.map((result) => (<option id={result.payer_id} value={result.payer_id}> {result.name}</option>
                                  ))
                                }
                              </select>
                            </Form.Group>
                          </div>
                        )
                      }
                    })()} */}

                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.base_url">
                      <Form.Label>Base URL</Form.Label>
                      <input class="form-control"
                        value={base_url} onChange={(e) => { setBaseUrl(e.target.value) }}
                      />
                      {formError.base_url && <span style={{ color: 'red' }}>{formError.base_url}</span>}<br />
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.endpoint_name">
                      <Form.Label>Auth Scope</Form.Label>
                      <input class="form-control"
                        value={auth_scope} onChange={(e) => { setAuthScope(e.target.value) }} />
                      {formError.auth_scope && <span style={{ color: 'red' }}>{formError.auth_scope}</span>}<br />
                    </Form.Group>
                  </Col>

                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.return_url">
                      <Form.Label>Return URL</Form.Label>
                      <input class="form-control"
                        value={return_url} onChange={(e) => { setReturnURL(e.target.value) }}
                      />
                      {formError.return_url && <span style={{ color: 'red' }}>{formError.return_url}</span>}<br />
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.token_url">
                      <Form.Label>Token URL</Form.Label>
                      <input class="form-control"
                        value={token_url} onChange={(e) => { setTokenURL(e.target.value) }} />
                      {formError.token_url && <span style={{ color: 'red' }}>{formError.token_url}</span>}<br />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.authorize_url">
                      <Form.Label>Authorize URL</Form.Label>
                      <input class="form-control"
                        value={authorize_url} onChange={(e) => { setAuthorizeURL(e.target.value) }}
                      />
                      {formError.authorize_url && <span style={{ color: 'red' }}>{formError.authorize_url}</span>}<br />
                    </Form.Group>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.endpoint_name">
                      <Form.Label>Auth Type</Form.Label>
                      <input class="form-control"
                        value={auth_type} onChange={(e) => { setAuthType(e.target.value) }} />
                      {formError.auth_type && <span style={{ color: 'red' }}>{formError.auth_type}</span>}<br />
                    </Form.Group>
                  </Col>

                </Row>
                <Col md={12} style={{ textAlign: "left" }}>
                    <Form.Group className="mb-3" controlId="pcform.payer_environment_type">
                      <Form.Label>Payer Environment</Form.Label>
                      <input class="form-control"
                        value={payer_environment_type} onChange={(e) => { SetPayerEnvironmentType(e.target.value) }}  readOnly={true} />
                      {formError.payer_environment_type && <span style={{ color: 'red' }}>{formError.payer_environment_type}</span>}<br />
                    </Form.Group>
                  </Col>
                {/* <Col md={12} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>

                  <Form.Group className="mb-3" controlId="pcform.payer_environment_type">
                    <Form.Label>Payer Environment <span style={{ color: "red" }}>*</span></Form.Label>
                    <br />
                    <select className="form-control" name="payer"

                      input class="form-control" value={payer_environment_type}
                      onChange={(e) => { SetPayerEnvironmentType(e.target.value) }}
                    >
                    
                      <option value="Sandbox">Sandbox</option>
                      <option value="Producation">Production</option>
                      <option value="Both">Both</option>

                    </select>

                    {errors.payer_environment_type && <p style={{ color: "red" }}>Please select at least one option</p>}

                  </Form.Group>
                </Col> */}
                <Row md={12} style={{ width: "100%", }}>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {
                      APIData
                        .map((x, i) => {

                          if (x.endpoint_order == 1) {
                            if (userList.some(y => y.end_point_master_id === x.id)) {
                              return <div className="w-100 text-center mt-2">
                                <Row >
                                  <Col md={12} style={{ textAlign: "left" }}>
                                    <label key={x}>
                                      <input type="checkbox" name="endpoint_name" value={x.id}   {...register("endpoint_name")}
                                        checked='true' disabled='true' />
                                      <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            }
                            else {
                              return <div className="w-100 text-center mt-2">
                                <Row >
                                  <Col md={12} style={{ textAlign: "left" }}>
                                    <label key={x}>
                                      <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />
                                      <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                    </label>
                                  </Col>
                                </Row>
                              </div>
                            }
                          }

                        })
                    }
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {
                      APIData.map((x, i) => {
                        //   var ex = sessionStorage.getItem('result');
                        if (x.endpoint_order == 2) {
                          if (userList.some(y => y.end_point_master_id === x.id)) {
                            //    if (ex.indexOf(x.id) > -1) {
                            return <div className="w-100 text-center mt-2">
                              <Row >
                                <Col md={12} style={{ textAlign: "left" }}>
                                  <label key={x}>
                                    <input type="checkbox" name="endpoint_name" value={x.id}   {...register("endpoint_name")}
                                      checked='true' disabled='true' />
                                    <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          }
                          else {
                            return <div className="w-100 text-center mt-2">
                              <Row >
                                <Col md={12} style={{ textAlign: "left" }}>
                                  <label key={x}>
                                    <input type="checkbox" value={x.id} name="endpoint_name"   {...register("endpoint_name")} />

                                    <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          }
                        }
                      })
                    }
                  </Col>

                </Row>
              </fieldset>
            </Form.Group>

            <Row>
              <Col md={12} style={{ textAlign: "left" }}>
                <Form.Group className="mb-3" controlId="pcform.endpoint_name">
                  <Form.Label>Other endpoint name</Form.Label>
                  <input class="form-control"
                    value={other_endpoint_name} onChange={(e) => { setOtherEndpointName(e.target.value) }} />

                </Form.Group>
              </Col>
            </Row>
            {/* <Form.Group className="mb-3" controlId="pcform.other_endpoint_name">
              <Form.Label>Other endpoint name</Form.Label>
              <input class="form-control"
                {...register("other_endpoint_name")}

              />

            </Form.Group> */}
            <Col md={12} style={{ textAlign: "center" }}>
              <Button className="bsbt-btn" type="submit" variant="primary" >Update</Button>
            </Col>

          </form>
        </Card.Body>
      </Card>
    </Container>
  )
}
export default UpdateEndpoints












{/* <form>
            <Row>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group className="mb-3" controlId="pcform.endpoint_name">
                  <Form.Label>AuthScope</Form.Label>
                  <input class="form-control"
                    value={auth_scope} onChange={(e) => { setAuthScope(e.target.value) }} />
                  {formError.auth_scope && <span style={{ color: 'red' }}>{formError.auth_scope}</span>}<br />
                </Form.Group>
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                <Form.Group className="mb-3" controlId="pcform.base_url">
                  <Form.Label>Base Url</Form.Label>
                  <input class="form-control"
                    value={base_url} onChange={(e) => { setBaseUrl(e.target.value) }}
                  />
                  {formError.base_url && <span style={{ color: 'red' }}>{formError.base_url}</span>}<br />
                </Form.Group>
              </Col>
            </Row>
            <Form.Label style={{ textAlign: "center" }}>Select Endpoints<strong style={{ color: "red" }}>*</strong></Form.Label>
            <Row md={12} style={{ width: "100%", }}>

              <Col md={6} style={{ textAlign: "left" }}>
                {
                  APIData
                    .map((x, i) => {
                      if (x.endpoint_order == 1) {
                        return <div className="w-100 text-center mt-2">
                          <Row >
                            <Col md={12} style={{ textAlign: "left" }}>
                              <label key={x}>


               {(() => {
              if(x.end_point_master_id == end_point_master_id){
                <input type="checkbox" value={x.end_point_master_id} name="end_point_master_id"
                onChange={(e) => { setEndpointMasterId(e.target.value) }}
                checked="true"/>

              }
              else{
                <input type="checkbox" value={x.end_point_master_id} name="end_point_master_id"
                onChange={(e) => { setEndpointMasterId(e.target.value) }} />


              }



            })()}
              <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>



                              </label>
                            </Col>
                          </Row>
                        </div>
                      }
                    })
                }
              </Col>
              <Col md={6} style={{ textAlign: "left" }}>
                {
                  APIData.map((x, i) => {
                    if (x.endpoint_order == 2) {
                      return <div className="w-100 text-center mt-2">
                        <Row >
                          <Col md={12} style={{ textAlign: "left" }}>
                            <label key={x}>
                              <input type="checkbox" value={x.end_point_master_id} name="end_point_master_id"
                                onChange={(e) => { setEndpointMasterId(e.target.value) }} />
                              <span style={{ textAlign: "left" }}>  {x.endpoint_name}</span>
                            </label>
                          </Col>
                        </Row>
                      </div>
                    }
                  })
                }
              </Col>

            </Row>
            <Form.Group className="mb-3" controlId="pcform.base_url">
              <Form.Label>Other endpoint name</Form.Label>
              <input class="form-control"
                value={endpoint_name} onChange={(e) => { setEndpointName(e.target.value) }}
              />
            </Form.Group>
            <Row>
              <Col>
                <br></br>
                <center><Button type='submit' onClick={updateAPIData}>Update</Button></center>
              </Col>
            </Row>
          </form> */}
{/* <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
          <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>
       */}
