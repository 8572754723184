import React, { Component, useState, useEffect } from 'react';
//import '../css/Form.css';
import { Container, Row, Col, Form, Button, ExploreButton, Card } from 'react-bootstrap';
import { set, useForm } from "react-hook-form";
import axios from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { propTypes } from 'react-bootstrap/esm/Image';
import { errorPrefix } from '@firebase/util';
import swal from 'sweetalert';
import { Link, useHistory, useLocation } from 'react-router-dom';


const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}



let ForgotPassword = () => {

    useEffect(() => {
        document.getElementById("root").classList.add("root_bg");

      }, []);

    const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const onSubmit = data => {
        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/user/forgotPassword',
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("The reset password link has been sent to your email address!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                 console.log(response.data) })
            .catch(error => {
                setErrorMessage("Email does not exits")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });

    };

    return (

        <Container fluid="md">

            <Card border="primary "
              style={{ width: "50%", align: "center", marginLeft: "16%",minHeight:"200px",marginTop:"5%"}}>
                <h3 className='text-center mb-3 heading'>Forgot Password</h3>
                        <center><hr className='heading_line'></hr></center>
                <Card.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Form.Group className="mb-3" controlId="pcform.email">
                            <Form.Label>Email Address</Form.Label>
                            <input className="form-control"

                             {...register("email", {required: true, pattern: /^\S+@\S+$/i   } )}
                            />
                            {errors.email &&  <h4 style={{color:"red"}}>*</h4>}
    			     	{errors.email && errors.email.type === "pattern" && <p style={{color:"red"}}>Invalid Email</p>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="pcform.submit">
                            <input type="submit" className="form-control sbt-btn  btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                        </Form.Group>
                    </form>
                    <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
             <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                </Card.Body>


            </Card>
            <div className="text-center mt-3"  style={{marginRight:"20%"}}>
        <strong>Back to Login <Link to='/userLogin'>Login</Link></strong>
      </div>
        </Container>
    )

}
export default ForgotPassword;

//

