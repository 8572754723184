import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle, faAlignCenter } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import public_profile from './public_profile.png'
import profile_pic from './profile_pic.png'
import plus_icon from './plus_icon.png'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Modal from 'react-bootstrap/Modal'
import validator from 'validator';

import "bootstrap/dist/css/bootstrap.css";
import {

    withForm,
    validators,
    withFormButton,
    FormErrors
} from "react-form-validation-context";

import _ from 'lodash'
import axios from 'axios';

import TodoCard from "./TodoCard";

import { useForm } from "react-hook-form";
import { setPersistence } from "firebase/auth";
let MyOrganizationProfile = () => {
    //  const params = new URLSearchParams(window.location.search);
    // const email = params.get('email');
    // console.log(email,"email")
    // const userEmail = email.slice(0, -168)
    // console.log("userEmail ",userEmail)


    // const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
        console.log(setUserList, "llllll")

    }


    const [userList, setUserList] = useState([]);

    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;
    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const handleClosePassword = () => setShowPassword(false);
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(true);

    const [fullname, setFullName] = useState('');
    const [useremail, setUserEmail] = useState('');
    const [userphone, setUserPhone] = useState('');
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');

    const [formError, setFormError] = useState({})
    const error = {}
    //  const {errors} = this.state;

    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'last_modified_on', text: 'Last Modified On' },


    ]

    // useEffect(() => {
    //     MyOrganizationProfile()

    // }, []);
    const history = useHistory();


    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")
        if (user_id == 'undefined') {
            history.push('/userlogin');
        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }
        else {
            MyOrganizationProfile()
        }
    }, []);


    var endpoints = [{
        name: "Patient Access API",
        description: "Endpoint to access patient details",
        last_modified_on: "December 13,2021",
    }, {
        name: "Coverage",
        description: "Endpoint to access coverage details",
        last_modified_on: "December 13,2021",
    }];

    function MyOrganizationProfile() {
        var value = sessionStorage.getItem('payerid');
        //   var isadmin = sessionStorage.getItem('isadmin');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "MyOrganizationProfile")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getMyOrganizationProfile/${value}`).then(res => {
            setPayerList(res.data.data);
            setUserEmail(res.data.data[0].useremail)
            setUserPhone(res.data.data[0].userphone)
            setFirstName(res.data.data[0].first_name)
            // setAddress2(res.data.data[0].address2)
            console.log(res.data.data, "MyOrganizationProfile");

        });
    }
    const runValidations = () => {
        if (useremail.length === 0) {
            error.useremail = 'email cannot be blank'
        } else if (!validator.isEmail(useremail)) {
            error.useremail = 'invalid email format'
        }
        if (userphone.length == 0) {
            error.userphone = "userphone number cannot be empty"
        } else if (userphone.length > 10 || userphone.length < 10) {
            // console.log(userphone.length,"userphone length")
            error.userphone = "Invalid Phone Number"
        }
        if (firstname.length == 0) {
            console.log(firstname.length, "firstname length")
             error.firstname = 'name cannot be blank'
        }
    }
    const saveFile = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };
    var value = sessionStorage.getItem('payerid');
    const uploadFile = async (e) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", fileName);
        try {
            const res = await axios.put(
                process.env.REACT_APP_BASE_URL + `/api/updateImageProfile/${value}`, formData)
            console.log(res);
            window.location.reload();
        } catch (ex) {
            console.log(ex);
        }
    };



    var user_id = sessionStorage.getItem('userid');
    const updateAPIData = (e) => {
        console.log("updateAPIData", e)
        runValidations()
        e.preventDefault()
        console.log(Object.keys(error).length, "Edit")

        if (Object.keys(error).length === 0) {
            setFormError({})
            axios.put(process.env.REACT_APP_BASE_URL + `/api/users/${user_id}`, {
                useremail, userphone, firstname, lastname

            })
                .then(response => {
                    history.push('/MyOrganizationProfile');
                    window.location.reload();
                    setSuccessMessage("Updated successfully!")
                    reset(response.data);
                    setTimeout(() => {
                        setSuccessMessage()
                    }, 2000);
                    console.log(response.data)
                })
                .catch(error => {
                    console.log(error.response.data.message, " error message")
                    if (error.response && error.response.data) {
                        var message = error.response.data.message
                        console.log(error.response.data.message, "error message")
                        setErrorMessage(message)
                        setTimeout(() => {
                            setErrorMessage()
                        }, 3000);
                    }

                    console.log(error.response, "data")
                });


        }
        else {
            setFormError(error)
        }
    }
    const onSubmit = data => {
        console.log(data, "data")
        console.log(user_id, "user_id")
        console.log(useremail, "email")
        axios
            .put(
                process.env.REACT_APP_BASE_URL + `/api/user/ResetPassword`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                history.push('/MyOrganizationProfile');
                window.location.reload();
                setSuccessMessage("Password changed successfully!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                console.log(response.data, "data")
            })
            .catch(error => {
                setErrorMessage("Email does not exits")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });

    };
    var validateNumber = evt => {
        var theEvent = evt || window.event;

        // Handle paste
        if (theEvent.type === "paste") {
            key = theEvent.clipboardData.getData("text/plain");
        } else {
            // Handle key press
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }
        var regex = /[0-9]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    };

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");


    return (

<div>
         {/* <Container fluid="md"> */}
            <Row>


                <Modal show={show} onHide={handleClose} animation={false}>
                    <Modal.Body>
                        <div class="col-md-12" style={{ textAlign: "center" }}>

                            <h3 className='mb-3 heading'>Update
                                <div class="pull-right">
                                    <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={handleClose}>Cancel</button>
                                </div>
                            </h3>
                        </div>
                        <div class="clearfix"></div>
                        <br></br>
                        <form>

                            {/* <Form.Group className="mb-3" controlId="pcform.username"> */}
                            <fieldset >

                                <Form.Group className="mb-3" controlId="pcform.fullname">
                                    <Form.Label>Name<strong style={{ color: "red" }}>*</strong></Form.Label>
                                    <input class="form-control"
                                        value={firstname} onChange={(e) => { setFirstName(e.target.value) }} />
                                    {formError.firstname && <span style={{ color: 'red' }}>{formError.firstname}</span>}<br />

                                </Form.Group>


                                <Form.Group className="mb-3" controlId="pcform.useremail">
                                    <Form.Label>Email</Form.Label>
                                    <input class="form-control"
                                        value={useremail} onChange={(e) => { setUserEmail(e.target.value) }}  readOnly={true}/>
                                    {formError.useremail && <span style={{ color: 'red' }}>{formError.useremail}</span>}<br />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="pcform.userphone">
                                    <Form.Label>Phone Number<strong style={{ color: "red" }}>*</strong></Form.Label>
                                    <input type="tel" class="form-control"
                                        value={userphone} onKeyPress={validateNumber}

                                        onChange={(e) => {
                                            setUserPhone(e.target.value)

                                        }}

                                    />
                                    {formError.userphone && <span style={{ color: 'red' }}>{formError.userphone}</span>}<br />
                                </Form.Group>

                            </fieldset>
                            {/* </Form.Group> */}


                            <Col md={12} style={{ textAlign: "center" }}>
                                <Button className="bsbt-btn" onClick={updateAPIData} type="submit" variant="primary" >Update</Button>
                            </Col>
                            <strong> {successMessage && <div className="d-flex justify-content-center success" style={{ color: "green",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {successMessage}</div>} </strong>
                       <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red",paddingBottom:"5px",paddingTop:"5px",textAlign:"center" }} > {errorMessage}</div>} </strong>

                        </form>

                    </Modal.Body>

                </Modal>

                <Modal show={showPassword} onHide={handleClosePassword} animation={false}>
                    <Modal.Body>
                        < strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                        <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>


                        <div class="col-md-12" style={{ textAlign: "center" }}>

                            <h3 className='mb-3 heading'>Reset Password
                                <div class="pull-right">
                                    <button type="button" id="" class="form-control btn-edit btn btn-primary" onClick={handleClosePassword}>Cancel</button>
                                </div>
                            </h3>
                        </div>
                        <div class="clearfix"></div>
                        <br></br>
                        <form onSubmit={handleSubmit(onSubmit)}>



                                <Form.Control type="text" value={useremail} hidden={true}
                                    {...register("email")}
                                />

                                   <Form.Group className="mb-3" controlId="pcform.password">
                                    <Form.Label>Password  <strong style={{color:"red"}}>*</strong></Form.Label>
                                    <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("password", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.password && errors.password.type === "required" &&
                                    <p style={{ color: "red" }}>Password Required</p>
                                }
                                  {errors.password && errors.password.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.password && errors.password.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}

                                    </Form.Group>

                                <Form.Group className="mb-3" controlId="pcform.passwords">
                                    <Form.Label> Confirm Password</Form.Label>
                                    <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("passwords", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.passwords && errors.passwords.type === "required" &&
                                    <p style={{ color: "red" }}>Confirm Password Required</p>
                                }
                                  {errors.passwords && errors.passwords.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.passwords && errors.passwords.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}


                                </Form.Group>






                            <Col md={12} style={{ textAlign: "center" }}>
                                <Button className="bsbt-btn" type="submit" variant="primary" >SUBMIT</Button>
                            </Col>

                        </form>

                    </Modal.Body>

                </Modal>

                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%"}} >

                        <Card.Body>

                            <div class="col-md-12" style={{ textAlign: "center"}}>
                                <h3 className='mb-3 heading'>My Organization Profile
                                    <div class="pull-right">

                                        <a href="/EditMyOrganizationProfile"><button type="button" id="download_button"
                                            class="form-control btn-edit btn btn-primary">Edit</button></a>

                                    </div>
                                </h3>

                            </div>



                            <div class="clearfix"></div>
                            <br></br>
                            {/* <div class="pull-right">
                                <span style={{ color: "#687080", fontWeight: 600 }}> Last Edited</span> December 20, 2021
                            </div> */}
                            <div class="clearfix"></div>
                            <hr></hr>
                            <div style={{ display: 'block', padding: 20 }}>

                                <div class="col-md-7" style={{ textAlign: "left" }}>
                                    <h4 className='' style={{ fontWeight: "bold", color: "#1e468e", marginBottom: 0 }}>Public Profile </h4><small>Visible to everyone</small>
                                    <div class="clearfix">
                                        {/* <div class="upload-btn-wrapper">
                                            <button class="file_uploadbtn">Upload Or Change Logo</button>
                                            <input type="file" name="myfile" />
                                        </div> */}

                                        {
                                            payerList.map(user => {
                                                const { logo } = user;
                                                if (logo != null) {
                                                    return (
                                                        <div class="pull-center">
                                                            <from uploadFile={uploadFile(uploadFile)}>
                                                                <img src={process.env.REACT_APP_BASE_URL + `/api/images/${logo}`} style={{ maxWidth: '30%' }} alt="pic" />
                                                                <div class="upload-btn-wrapper">
                                                                    <input type="file" name="myfile" onChange={saveFile} />
                                                                    <button class="file_uploadbtn" style={{ marginLeft: "40%" }}>Upload Or Change Logo</button>
                                                                </div>
                                                            </from>
                                                        </div>
                                                    )

                                                }
                                                else {
                                                    return (
                                                        <div>
                                                            <img src={public_profile} style={{ maxWidth: '30%' }} alt="pic" />

                                                            <from uploadFile={uploadFile(uploadFile)}>

                                                                {/* <Col md={6} className="mb-3" style={{ marginTop: "15px", marginRight: "70%" }}>
                                                                    <Form.Group
                                                                        controlId="pcform.organizationlogo"
                                                                        className="mb-3">
                                                                        <Form.Control type="file" name="myfile" class="custom-file-input" placeholder="organization logo"

                                                                            onChange={saveFile}
                                                                        />


                                                                    </Form.Group>
                                                                </Col> */}
                                                                <div class="upload-btn-wrapper">
                                                                    <input type="file" name="myfile" onChange={saveFile} />

                                                                    <button class="file_uploadbtn">Upload Or Change Logo</button>

                                                                </div>

                                                            </from>

                                                        </div>
                                                    )

                                                }


                                            })
                                        }
                                    </div>
                                    <br></br>
                                    <br></br>
                                    {/* <br></br>



                                            <img src={process.env.REACT_APP_BASE_URL+`/api/images/file-1644485597365.png`}  style={{ maxWidth: '20%' }}alt="pic" /> <span class="label btn-upload label-default">Upload Or Change Logo</span>


                                    <br></br> */}
                                    <Table className="view_tbl" id="view_tbl">
                                        <tbody>
                                            {payerList.map(user => {
                                                const { name, organisation_type, website, logo, policylink, conditionlink, address1, city, state_name, phone } = user;
                                                return (
                                                    <div >
                                                        <tr>
                                                            <td><strong>Organization Name:</strong></td>
                                                            <td>{name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Organization Type:</strong></td>
                                                            <td>{organisation_type}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Organization website:</strong></td>
                                                            <td>{website}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Organization Privacy Policy:</strong></td>
                                                            <td>{policylink}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Organization Terms and Conditions:</strong></td>
                                                            <td>{conditionlink}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Address:</strong></td>
                                                            <td>{address1},<br></br>
                                                                {city},<br></br>
                                                                {state_name}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>Phone Number:</strong></td>
                                                            <td>{phone}</td>
                                                        </tr>

                                                    </div>
                                                )
                                            })
                                            }

                                        </tbody>

                                    </Table>
                                </div>
                                <div class="col-md-5" style={{ textAlign: "left" }}>

                                    <h4 className='' style={{ fontWeight: "bold", color: "#1e468e", marginBottom: 0 }}>Organization Credentials </h4><small>Visible only to admins of your organization</small>
                                    <div class="clearfix"></div>
                                    <br></br>
                                    <Table className="view_tbl" id="view_tbl">
                                        {payerList.map(user => {
                                            const { username, ein } = user;
                                            return (
                                                <div>
                                                    <tr>
                                                        <td><strong>Username:</strong></td>
                                                        <td>{username}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Password:</strong></td>
                                                        <td>********
                                                            {/* <Link to={`/ChangePassword/${user_id}`}>
                                                                <div class="pull-right">
                                                                    <button type="button" id="download_button"
                                                                        class="form-control btn-edit btn btn-primary">Change Password</button>

                                                                </div>

                                                            </Link> */}

                                                            <button type="button" onClick={handleShowPassword} id="download_button"
                                                                class="form-control btn-edit btn btn-primary">Change Password</button>


                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td><strong>Organization EIN:</strong></td>
                                                        <td>{ein}</td>
                                                    </tr>

                                                </div>

                                            )
                                        })
                                        }


                                    </Table>
                                    <div class="clearfix"></div>
                                    <div class="col-md-10">
                                        <div class="card"  style={{ minHeight:"35%"}}>
                                            <div class="card-body  mb-4" >
                                                <div class="post">

                                                    {payerList.map(user => {
                                                        const { fullname, useremail, userphone } = user;
                                                        return (
                                                            <div class="user-block">
                                                                <img class="img-circle img-bordered-sm" src={profile_pic} alt="user image" width={'60px'} />
                                                                <strong class="username"><a href="#"> {fullname} </a></strong>
                                                                <br></br>  <br></br>
                                                                <tr>
                                                                    <p><strong className="ct_title">Name: </strong>{fullname}</p>
                                                                    <p><strong className="ct_title">Email: </strong>{useremail}</p>
                                                                    <p> <strong  className="ct_title">Phone Number: </strong>{userphone}</p>

                                                                </tr>

                                                                <div class="pull-right">
                                                                    <button type="button" onClick={handleShow} id="download_button"
                                                                        class="form-control btn-edit btn btn-primary">Edit</button>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                    }


                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="clearfix"></div>

                            </div>
                        </Card.Body>
                    </Card >
                </Col>
            </Row>

         {/* </Container > */}
        </div>

    );

}

export default MyOrganizationProfile;