import { Container, Row, Col, Form, Button, Card, CloseButton } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import organization_img from './massachusetts.png'
import { CircularProgress, useScrollTrigger } from "@material-ui/core";
import paginationFactory from 'react-bootstrap-table2-paginator';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useForm } from "react-hook-form";
import { xorBy } from "lodash";
import public_profile from './public_profile.png'
// import { LinkContainer } from 'react-router-bootstrap'
import { White } from "react-dial-knob";
import { Nav } from 'react-bootstrap';
import UserLogin from "./UserLogin";
import ReviewConfirm from "./Register/ReviewConfirm";
import "bootstrap/dist/css/bootstrap.css";
import './css/pages.css'; //Import here your file style
import LoginPopup from "./LoginPopup";
import Header from './Header'
import Footer from './Footer'
import './css/org.css';


const ViewOrganization = (props) => {

    console.log('someData', props.location.state)
    const ids = props.match.params.id
    const payerData = props.location.state
    console.log(payerData)
    console.log('payerData', typeof payerData)
    console.log(Array.isArray(payerData))
    console.log(typeof ids)

    const history = useHistory();

    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { register, handleSubmit, reset, trigger, formState } = useForm();


    useEffect(() => {
        document.getElementById("root").classList.add("root_bg");
        document.getElementById("container").classList.add("conthome");

    },
        []);





    return (
        <Container fluid="md" >
            <Header />
            <Row className="controw">
                <Col md={10} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", minHeight: "600px" }} >
                        <Card.Body>
                            <div class="pull-left">
                                {/* {userList.map(x => */}
                                <div className="w-100 text-center mt-2">
                                    <h3 className='mb-3 heading' >{payerData.name}</h3>
                                </div>
                                {/* )} */}
                            </div>
                            {/* <h4> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </h4> */}
                            <h4> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </h4>

                            <div class="pull-right">
                                <button
                                    className="btn btn-primary " Width="95px"
                                    style={{ marginTop: "26px", borderRadius: "20px", }} id="download_button"
                                    onClick={() => {
                                        let confirmLogin = window.confirm('You have to Login/Register to see the content')
                                        if (confirmLogin) {
                                            history.push('/userlogin')
                                        } else {
                                            history.push('/')
                                        }
                                    }}
                                >
                                    Add Organization
                                </button>
                            </div>
                            <div class="clearfix"></div>
                            <div style={{ display: 'block', padding: 30 }}>

                                <form >
                                    <Tabs defaultActiveKey="first">
                                        <Tab eventKey="first" title="Organization Profile">
                                            <Table className="view_tbl">
                                                {/* {userList.map(user => {
                                                    const { name, organisation_type, website, policylink, conditionlink, address1, city, state, phone } = user;
                                                    return ( */}
                                                <div >
                                                    <tr>
                                                        <td><strong>Organization Name:</strong></td>
                                                        <td>{payerData.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Type:</strong></td>
                                                        <td>{payerData.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization website:</strong></td>
                                                        <td>{payerData.website}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Privacy Policy:</strong></td>
                                                        <td>{payerData.policylink}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Organization Terms and Conditions:</strong></td>
                                                        <td>{payerData.conditionlink}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Address:</strong></td>
                                                        <td>{payerData.address1},<br></br>
                                                            {payerData.city},<br></br>
                                                            {payerData.state_name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Phone Number:</strong></td>
                                                        <td>{payerData.phone}</td>
                                                    </tr>
                                                </div>
                                            </Table>
                                        </Tab>
                                        <Tab eventKey="second" title="Endpoints" >
                                            {/* Hii, I am 2nd tab content */}
                                            <LoginPopup />
                                        </Tab>

                                    </Tabs>
                                    <div class="pull-right">
                                        <button
                                            className="btn btn-primary " Width="95px"
                                            style={{ marginTop: "26px", borderRadius: "20px", }} id="download_button"

                                            onClick={() => {
                                                history.push('/OrganizationDirectoryHome')
                                            }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Card.Body>
                    </Card >
                </Col>
            </Row>
            <Footer />
        </Container >
    );
}

export default ViewOrganization




