import React, { Component, useState, useEffect } from 'react';
import './css/Form.css'; //Import here your file style
import { Container, Row, Col, Form, Button, Card, ListGroup, Dropdown, Table } from 'react-bootstrap';
import axios from 'axios'
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}
// const BillingDetails = () => {
//     const { id } = useParams();
//     const [userList, setUserList] = useState([]);
//     const [errorMessage, setErrorMessage] = React.useState("");
//     const [successMessage, setSuccessMessage] = React.useState("");


//     const columns = [
//         { dataField: 'email', text: 'Payer Email' },
//         { dataField: 'phone', text: 'Payer Phone' },
//         { dataField: 'email', text: ' Payer Email' },
//         { dataField: 'inserted_date', text: 'Added Date' },
//         { dataField: 'subtotal', text: 'Subtotal' }
//     ]
//     const pagination = paginationFactory({
//         page: 1,
//         sizePerPage: 5,
//         lastPageText: '>>',
//         firstPageText: '<<',
//         nextPageText: '>',
//         prePageText: '<',
//         showTotal: true,
//         alwaysShowAllBtns: true,
//         onPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         },
//         onSizePerPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         }
//     })

//     useEffect(() => {
//         axios
//             .get(process.env.REACT_APP_BASE_URL+`/api/getBillingDetailId/${id}`)
//             .then((res) => {

//                 setUserList(res.data.data);
//                 console.log(res.data, "getBillingDetailId")
//             });
//     },
//         []);
//     return (
//         <Container fluid="md">
//             <Card border="primary" style={{ width: "90%", align: "center", marginLeft: "%" }}>
//                 <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Billing</Card.Header>

//                 <div className="container-fluid p-3">
//                     <BootstrapTable bootstrap4 keyField='id'
//                         columns={columns}
//                         data={userList}
//                         pagination={pagination}
//                         filter={filterFactory()}
//                     />
//                 </div>

//                 < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
//                 <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

//             </Card >
//         </Container >
//     );
// }
// export default BillingDetails













const BillingDetails = () => {
    const [billingList, setBillingList] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_BASE_URL+`/api/getBillingDetailId/${id}`)
            .then((res) => {

                setBillingList(res.data.data);
                console.log(res.data, "getBillingDetailId")
            });
    },
        []);

    return (
        <Container fluid="md">
            <Card border="primary" style={{ width: "80%", align: "center", marginLeft: "10%" }}>
                <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff" }}>Billing Details</Card.Header>
                <Card.Body>
                    {/* <Row>
                        <Col>
                            <Dropdown style={{ float: "right" }}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: "white", color: "#000000" }}>
                                    Date
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as="button">January 2021</Dropdown.Item>
                                    <Dropdown.Item as="button">February 2021</Dropdown.Item>
                                    <Dropdown.Item as="button">March 2021</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col>
                            <p style={{ textAlign: "center" }}>Billing Details</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Payer Name</th>
                                        <th>Payer Email</th>
                                        <th>Payer Phone</th>
                                        <th>Added Date</th>
                                        <th>Subtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {billingList.map(x => <tr>
                                        <td>{x.name}</td>
                                        <td>{x.email}</td>
                                        <td>{x.phone}</td>
                                        <td>{x.inserted_date}</td>
                                        <td>{x.subtotal}</td>
                                    </tr>)}
                                    {billingList.length == 0 && <tr>
                                        <td className="text-center" colSpan="4">
                                            <b>No data found to display.</b>
                                        </td>
                                    </tr>}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Payment Methods:</p>
                            <div class="form-group">
                                <div class="col-md-12">
                                    <ol class="cards">
                                        <li class="visa hand">Visa</li>
                                        <li class="mastercard hand">MasterCard</li>
                                        <li class="amex hand">Amex</li>
                                    </ol>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <p>Lorem ipsum represents a long-held tradition for designers, typographers and the like.Some people hate it and argue for its demise, but others ignore the hate as they create awesome tools to help create filler text for everyone from bacon lovers to Charlie Sheen fans.</p>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Table striped hover>
                                        <thead>
                                            {billingList.map(x =>
                                                <tr>
                                                    <th>Subtotal:</th>
                                                    <th>{x.subtotal}</th>
                                                </tr>
                                            )}
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th>Tax(9.3%)</th>
                                                <th>10.34</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                        {billingList.map(x =>
                                            <tr>
                                                <th>Total</th>
                                                <th>{x.total}</th>
                                            </tr>
                                        )}
                                        </thead>
                                    </Table>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div >
                                <Button onClick={() => window.print()}>PRINT</Button>
                            </div>
                        </Col>
                        <Col style={{ marginLeft: "58%" }}>
                            <div class="col-md-12 text-right">
                                <Button variant="primary">SUBMIT PAYMENT</Button>{' '}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container >
    );
}

export default BillingDetails;