
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import React, { Component, useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import organization_img from './massachusetts.png'
import { CircularProgress, useScrollTrigger } from "@material-ui/core";
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from "react-bootstrap-table-next";


const ViewPayer =() =>{

  const { value } = useParams();
  const [userList, setUserList] = useState([]);
  const [payerList, setPayerList] = useState([]);

  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");

  const columns = [
    { dataField: 'payer_id', text: 'Id' },
   { dataField: 'name', text: 'Name' },
   { dataField: 'email', text: 'Email' },
   { dataField: 'payer_url', text: 'Payer Url' },
   {
    dataField: "is_approve",
    text: "Action",
    formatter: (cellContent, row) => {
        return (
          <div className="w-100 text-center mt-2">

          <button

              className="btn btn-success btn-xs" Width="95px"
              style={{ margin: "2px" }}
              onClick={() => updateApproveById(row.payer_id)}
          >
              Approve
          </button>


          {/* <Link to={`/viewpayer/${row.payer_id}`}> */}
                  <button
                      className="btn btn-danger btn-xs" Width="95px"
                      style={{ margin: "2px" }}
                     onClick={() => updateRejectedById(row.payer_id)}
                  >
                     Rejected
                  </button>

              {/* </Link> */}
              </div>


        );
    },
},
]

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    },
    onSizePerPageChange: function (page, sizePerPage) {
        console.log('page', page)
        console.log('sizePerPage', sizePerPage)
    }
})

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`)
      .then((res) => {

        setUserList(res.data.data);
        console.log(res.data.data,"getOrganisationByViewId")
      });
  },

  []);

  function updateApproveById(value) {
    console.log(value, "updateApproveById")
     axios.put(process.env.REACT_APP_BASE_URL+`/api/updateIsApprove/${value}`)
    //     setPayerList(res.data.data);
    //     console.log(res.data.data, "updateApprove");

        .then(response => {
          setSuccessMessage("Successfully Approve!")

          setTimeout(() => {
              setSuccessMessage()
          }, 4000);
          console.log(response.data, "documentationdetails")
      })
      .catch(error => {
          setErrorMessage("Not Approved")

          setTimeout(() => {
              setErrorMessage()
          }, 4000);
          console.log(error.data)
      });
    }


function updateRejectedById(value) {
  console.log(value, "updateRejectedById")
  axios.put(process.env.REACT_APP_BASE_URL+`/api/updateIsRejected/${value}`)  .then(response => {
    setSuccessMessage("Successfully Rejected!")

    setTimeout(() => {
        setSuccessMessage()
    }, 4000);

})
.catch(error => {
    setErrorMessage("Not Rejected")

    setTimeout(() => {
        setErrorMessage()
    }, 4000);
    console.log(error.data)
});

}


  return (

    <Container fluid="md">
          <Card border="primary">
            {/* <Card.Header
              as="h5"
              style={{ backgroundColor: "blue", color: "#ffffff" }}
            >
              Payer
            </Card.Header> */}
              <h3 className='text-center mb-3 heading'>Payer  Details</h3>
                        <center><hr className='heading_line2'></hr></center>
                        <br></br>
            <Card.Body>

              <Row>
                <Col>
                </Col>
              </Row>
             <div className="container-fluid p-3">
    <BootstrapTable bootstrap4 keyField='id'
        columns={columns}
        data={userList}
        pagination={pagination}

    />
</div>
<strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
<strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>
               {/* <table class="table table-sm mt-3">
                <thead class="thead-dark">
                  <th>Name</th>
                  <th>Email</th>
                  <th>Payer Url</th>

                </thead>
                <tbody>

                  {userList.map(x => <tr>
                    <td>{x.name}</td>
                    <td>{x.email}</td>
                    <td>{x.payer_url}</td>



                  </tr>)}
                  {userList.length == 0 && <tr>
                    <td className="text-center" colSpan="4">
                      <b>No data found to display.</b>
                    </td>
                  </tr>}
                </tbody>
              </table>  */}

             <Row>
                <Col md={2} style={{ width: "50%", textAlign: "left" }}>
                  <Form.Group controlId="pcform.payercontact">
                  <Link to={`/addPayerContact`}>
                    <Button variant="success">Payer Contact</Button>{" "}
                    </Link>
                  </Form.Group>

                </Col>
                <Col md={2} style={{ width: "50%", textAlign: "right" }}>
                  <Form.Group controlId="pcform.request">
                  <Link to={`/ticketRequest`}>
                    <Button variant="danger" style={{ marginLeft:"500%" }}>Request</Button>{" "}
                    </Link>
                  </Form.Group>
                </Col>
              </Row>
            <Row style={{ marginTop: "5px" }}>
                <Col md={12}>
                  <Card style={{ marginTop: "12px" }}>
                    <Card.Header
                      style={{ backgroundColor: "#000000", color: "#ffffff" }}
                    >
                      <i class="fa fa-document"></i> Documentation
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md={6}>
                          <Card bg="primary" style={{ margin: "6px", textAlign: "left" }}>
                             {userList.map(user => {
                        const { new_app } = user;
                        return (
                          <div >
                             <Card.Body>
                             <Card.Title>How to request for a new App</Card.Title>
                             <Card.Text>{new_app}</Card.Text>
                             </Card.Body>
                          </div>

                        )

                      })
                      }
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card bg="secondary" style={{ margin: "6px", textAlign: "left" }}>
                             {userList.map(user => {
                        const { view_existing_app } = user;
                        return (
                          <div >
                             <Card.Body>
                             <Card.Title>How to view existing App</Card.Title>
                             <Card.Text>{view_existing_app}</Card.Text>
                             </Card.Body>
                          </div>

                        )

                      })
                      }
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card bg="warning" style={{ margin: "6px", textAlign: "left" }}>
                                       {userList.map(user => {
                        const { delete_app } = user;
                        return (
                          <div >
                             <Card.Body>
                             <Card.Title>How to delete App</Card.Title>
                             <Card.Text>{delete_app}</Card.Text>
                             </Card.Body>
                          </div>

                        )

                      })
                      }
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card bg="success" style={{ margin: "6px", textAlign: "left" }}>


                              {userList.map(user => {
                        const { patient_access_api } = user;
                        return (
                          <div >
                             <Card.Body>
                             <Card.Title>Patient Access API End Point details</Card.Title>
                             <Card.Text>{patient_access_api}</Card.Text>
                             </Card.Body>
                          </div>

                        )

                      })
                      }
                          </Card>
                        </Col>
                        <Col md={6}>
                          <Card bg="light" style={{ margin: "6px", textAlign: "left" }}>

                            {userList.map(user => {
                        const { provider_access_api } = user;
                        return (
                          <div >
                             <Card.Body>
                             <Card.Title>Provider Access API End Point details</Card.Title>
                             <Card.Text>{provider_access_api}</Card.Text>
                             </Card.Body>
                          </div>

                        )

                      })
                      }
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>



  );

}

export default ViewPayer





//
