import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import { Link, useHistory, useParams } from 'react-router-dom';
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter'
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.css";
import _ from 'lodash'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import swal from 'sweetalert';
import axios from 'axios';

let ManagerDashboard = () => {

    const history = useHistory();
    const [userList, setUserList] = useState([]);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);



    useEffect(() => {


        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")

        // if (user_id == 'undefined' && isadmin == 'undefined') {
        //     history.push('/userlogin');

        // }
        // // else if (user_id!='undefined'  && isadmin =='false'){
        // else if ((user_id != 'undefined' && isadmin == 'false') || isadmin != 'true') {
        //     history.push('/userlogin');
        // }


        getPayerList();
        var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

        if (elements.length) {
            elements[0].id = 'dt_dropdown';
        }


        //   console.log(user_id,"user_id")

        // }


    }, []);


    function getPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/users/get/AdminUsers').then(res => {
            setUserList(res.data.data);


            console.log(res.data, "getPayerList");
        });
    }

    const columns = [
        //  { dataField: 'user_id', text: 'Id' },

        { dataField: 'username', text: 'Name' },
        { dataField: 'email', text: 'Email' },
        { dataField: 'phone', text: 'Phone' },
        {
            dataField: "remove",
            text: "View",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/PayersRegistered/${row.user_id}`}>
                        <button
                            className="btn btn-success btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => getIdPayerList(row.user_id)}
                        >
                            View
                        </button>

                    </Link>

                );
            },
        },
        {
            dataField: "remove",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (

                    <button
                        className="btn btn-danger btn-xs" Width="95px"
                        style={{ margin: "2px" }}
                        onClick={() => DeleteAdminUser(row.user_id)}
                    >
                        Delete
                    </button>



                );
            },
        },

        {
            dataField: "remove",
            text: "Edit",
            formatter: (cellContent, row) => {
                return (

                    <button
                        className="btn btn-primary btn-xs" Width="95px"
                        style={{ margin: "2px" }}
                        onClick={handleShow}
                    >
                        edit
                    </button>



                );
            },
        },

    ]
    // userList.forEach((user, index) => {
    //     user.serial = index + 1;
    //  })
    function getIdPayerList(id) {
        fetch(process.env.REACT_APP_BASE_URL + `/api/user/getIdByUser/${id}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp.data, "getIdPayerList")
            })
        })
            .catch(error => {
                console.log(error.data)
            });

    }
    function DeleteAdminUser(user_id) {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the data!",
            icon: "warning",
            buttons: true,
            dangerMode: false,
        })
            .then((willDelete) => {
                if (willDelete) {

                    swal("Your data has been deleted!", {
                        icon: "success",
                    });
                    axios.delete(process.env.REACT_APP_BASE_URL + `/api/DeleteAdminUser/${user_id}`)
                    window.location.reload();
                    getPayerList();


                }
            });
    }

    const setData = (data) => {
        let { user_id, email, phone, username, password, label, city, address1, zip_code } = data;
        localStorage.setItem('user_id', user_id);
        localStorage.setItem('address1', address1);
        localStorage.setItem('city', city);
        //  localStorage.setItem('label',label);
        localStorage.setItem('zip_code', zip_code);
        localStorage.setItem('phone', phone);
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('username', username);

    }

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        //   hideSizePerPage :true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    return (
        <Container fluid="md">
            <Row>
                <Col>
                    <Card style={{ width: "100%", align: "center",minHeight:"600px" }}>
                        <h3 className='text-center mb-3 heading'>Power Users</h3>
                        <center><hr className='heading_line'></hr></center>

                        <div className="w-100 text-center mt-2">
                            <Link to={`/CreateAdminUser`}>
                                <button
                                    className="btn btn-info " Width="95px"
                                    style={{ marginLeft: "80%", borderRadius: "20px" }} id="download_button"

                                >
                                    Create Power User
                                </button>
                            </Link>
                        </div>
                        <br></br>

                        <Card.Body>
                            <Row>
                                <Col>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userList.map((x) =>
                                                <tr>
                                                    <td>{x.username}</td>
                                                    <td>{x.email}</td>
                                                    <td>{x.phone}</td>
                                                    <td>

                                                        <Link to={`/UpdateAdminUser/${x.user_id}`}>

                                                            <button
                                                                className="btn btn-primary btn-xs" Width="95px"
                                                                style={{ marginRight: "6%", borderRadius: "20px" }} id="download_button"
                                                                onClick={() => setData(x)}

                                                            >
                                                                Update
                                                            </button>
                                                        </Link>


                                                        <Link to={`/PayersRegistered/${x.user_id}`}>
                                                            <button
                                                                className="btn btn-success btn-xs" Width="95px"
                                                                style={{ marginRight: "6%", borderRadius: "20px" }} id="download_button"
                                                                onClick={() => getIdPayerList(x.user_id)}

                                                            >
                                                                View
                                                            </button>
                                                        </Link>


                                                        <button
                                                            className="btn btn-danger btn-xs" Width="95px"
                                                            style={{ marginRight: "6%", borderRadius: "20px" }} id="download_button"
                                                            onClick={() => DeleteAdminUser(x.user_id)}

                                                        >
                                                            Delete
                                                        </button>
                                                    </td>

                                                </tr>)}


                                            {userList.length == 0 && <tr>
                                                <td className="text-center" colSpan="4">
                                                    <b>No data found to display.</b>
                                                </td>
                                            </tr>}

                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                            {/* <div className="container-fluid p-3">
                                <BootstrapTable bootstrap4 keyField='id'
                                    columns={columns}
                                    data={userList}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                />
                            </div> */}
                        </Card.Body>
                    </Card>

                </Col>

            </Row>

            {/* < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}


        </Container >
    );

}

export default ManagerDashboard;



//