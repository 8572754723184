// import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
// import './css/pages.css'; //Import here your file style
// import { Link, useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
// import React, { Component, useState, useEffect } from 'react';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
// import { CircularProgress } from "@material-ui/core";
// import BootstrapTable from "react-bootstrap-table-next";
// import Select from 'react-select';
// import Table from 'react-bootstrap/Table'
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
// import public_profile from './public_profile.png'
// import profile_pic from './profile_pic.png'
// import plus_icon from './plus_icon.png'
// import { PieChart } from 'react-minimal-pie-chart';
// import { Donut } from 'react-dial-knob'
// import { CircularProgressbar } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
// import "bootstrap/dist/css/bootstrap.css";

// import _ from 'lodash'
// import axios from 'axios';

// import TodoCard from "./TodoCard";

// import { useForm } from "react-hook-form";

// let Dashboard = () => {



//     // const [selectedValue, setSelectedValue] = useState([]);
//     const handleChange = (e) => {
//         setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
//         console.log(setUserList, "llllll")

//     }

//     const [userList, setUserList] = useState([]);

//     const [payerList, setPayerList] = useState([]);
//     const [Reqcount, setReqCount] = useState([]);
//     const [UserByEndpointCount, setUserByEndpointCount] = useState([]);
//     const [Regcount, setRegCount] = useState([]);
//     const [regReqsingedStatus, setRegReqSingedStatus] = useState([]);
//     const [tab, setTab] = useState('first');



//     const columns = [
//         // { dataField: 'name', text: 'Organization Name' },
//         { dataField: 'name', text: 'Name' },
//         { dataField: 'description', text: 'Description' },
//         { dataField: 'last_modified_on', text: 'Last Modified On' },
//         { dataField: 'modify', text: 'Modify' },


//     ]

//     var endpoints = [{
//         name: "Patient Access API",
//         description: "Endpoint to access patient details",
//         last_modified_on: "December 13,2021",
//         modify: <div><button type="button" class="btn btn-sm btn-primarybg btn-primary">Edit</button>  <button type="button" class="btn btn-edit  btn-sm btn-primary">Delete</button></div>
//     }];


//     const Organization_profile = 0.90;
//     const history = useHistory();

//     useEffect(() => {
//         var user_id = sessionStorage.getItem('userid');
//         var isadmin = sessionStorage.getItem('isadmin');
//         console.log(user_id, isadmin, "user_idisadmin")

//         if (user_id == 'undefined') {
//             history.push('/userlogin');

//         }
//         else if (user_id != 'undefined' && isadmin != 'false') {
//             history.push('/userlogin');
//         }

//         else {
//             MyOrganizationProfile()
//             RegReqPayersSignedStatusCount()
//             RegPayersSignedStatusCount()
//             getRegReqPayersSignedStatusList()
//             getMyEndPointsUserList()
//             var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
//             console.log(UserByIdEndpointCount, "UserByIdEndpointCount")
//             // var RegisterPayerCount = sessionStorage.getItem('RegisterPayerCount');
//             // console.log(RegisterPayerCount, "RegisterPayerCount")


//         }
//     }, []);




//     var payer_id = sessionStorage.getItem('payerid');
//     console.log(payer_id, "payer_id")

//     var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
//     console.log(UserByIdEndpointCount, "UserByIdEndpointCount")

//     function getRegReqPayersSignedStatusList() {
//         console.log(payer_id, "RegReqPayersSignedStatus")
//         axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatus/${payer_id}`).then(res => {
//             setRegReqSingedStatus(res.data.data);
//             console.log(res.data.data, "RegReqPayersSignedStatus")

//         });
//     }

//     function MyOrganizationProfile() {
//         var value = sessionStorage.getItem('payerid');
//         console.log("Appended payer id", value)
//         console.log(MyOrganizationProfile, "MyOrganizationProfile")
//         axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getMyOrganizationProfileDashboard/${value}`).then(res => {
//             setPayerList(res.data.data);
//             console.log(res.data.data, "MyOrganizationProfile");
//         });
//     }
//     function RegReqPayersSignedStatusCount() {
//         var value = sessionStorage.getItem('payerid');
//         console.log("Appended payer id", value)
//         console.log(MyOrganizationProfile, "RegReqPayersSignedStatusCount")
//         axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatusCount/${value}`).then(res => {
//             setReqCount(res.data.data);
//             console.log(res.data.data, "RegReqPayersSignedStatusCount");
//         });
//     }

//     function RegPayersSignedStatusCount() {
//         var value = sessionStorage.getItem('payerid');
//         console.log("Appended payer id", value)
//         console.log(MyOrganizationProfile, "RegPayersSignedStatusCount")
//         axios.get(process.env.REACT_APP_BASE_URL + `/api/RegPayersSignedStatusCount/${value}`).then(res => {
//             setRegCount(res.data.data);
//             console.log(res.data.data, "RegPayersSignedStatusCount");
//         });
//     }
//     var user_id = sessionStorage.getItem('userid');
//     function getMyEndPointsUserList() {
//         axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsAll/${user_id}`).then(res => {
//             setUserByEndpointCount(res.data.data, "getMyEndPointsList");
//             console.log(res.data.data, "getMyEndPointsUserList")
//             var finalCount = res.data.data.length
//             sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
//             console.log(finalCount, "finalCount");
//         });
//     }

//     const percentage = '70%'
//     const percentage2 = '90%'
//     const percentage3 = '100%'
//     const percentage4 = '80%'
//     return (
//         <Container fluid="md">


//             <Row>

//                 <Col md={12} style={{ textAlign: "left" }}>
//                     <Card style={{ width: "100%" }} >

//                         <Card.Body>
//                             <div class="col-md-12" style={{ textAlign: "center" }}>
//                                 <h3 className='mb-3 heading'>Dashboard

//                                 </h3>

//                             </div>

//                             <div class="clearfix"></div>
//                             <br></br>

//                             <Col md={12} style={{ textAlign: "left" }} className="dashboard">
//                                 <div>
//                                     {payerList.slice(0, 1).map(x => {
//                                         return (
//                                             <div >

//                                                 <h5 style={{ color: "#1E468E" }}><strong>Recent Activity</strong> </h5>
//                                                 <hr></hr>
//                                                 {/* <p><strong>{x.name}</strong>Has Registered with HC {x.updated_date} <a href={'/MyOrganizationProfile'}>See Organization Profile</a></p> */}
//                                                 <p>Organization profile was modified by administrator <strong>{x.fullname}</strong> on {x.updated_date} <a href={'/MyOrganizationProfile'}>See Organization Profile</a></p>
//                                                 <p>Patient Access API Endpoints are added by administrator <strong>{x.fullname}</strong> on {x.updated_date} <a href={'/Endpoints'}>See Endpoint</a></p>


//                                             </div>
//                                         )
//                                     }
//                                     )}
//                                     {regReqsingedStatus.slice(0, 1).map(x => {
//                                         return (
//                                             <div>
//                                                 <strong>{x.name}</strong>  sent you a registration request on {x.updated_date}.
//                                                 <Link
//                                                     to={'/RegisteredOrgnization?tab=second'}>See Request</Link>
//                                             </div>
//                                         )
//                                     }
//                                     )}

//                                 </div>

//                             </Col>
//                             <br></br>
//                             <Col md={12} style={{ textAlign: "left" }}>
//                                 <br></br>
//                                 <h5 style={{ color: "#1E468E" }}><strong>Your Organization</strong> </h5>
//                                 <hr></hr>
//                                 <Row>
//                                     <Col md={3}>


//                                         {/*
//  <center><h5 style={{ color: "#1E468E" }}><strong>Organization Profile</strong></h5>
//                   <CircularProgressbarWithChildren value={70}>
//                       <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
//                           <strong>{percentage}</strong> <br></br><span class="sub_text">complete</span>
//                       </div>
//                   </CircularProgressbarWithChildren>
//               </center> */}

//                                         {payerList.slice(0, 1).map(x => {



//                                             if (x.endpoint_name != null && x.logo != null ) {
//                                                 return (
//                                                     <div >
//                                                         <center><h5 style={{ color: "#1E468E" }}><strong>Organization Profile</strong></h5>

//                                                             <CircularProgressbarWithChildren value={100}>
//                                                                 <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
//                                                                     <strong>
//                                                                     <a href={'/MyOrganizationProfile'}>
//                                                                         {percentage3}
//                                                                         </a></strong> <br></br><span class="sub_text">complete</span>
//                                                                 </div>
//                                                             </CircularProgressbarWithChildren>

//                                                         </center>

//                                                     </div>
//                                                 )
//                                             }
//                                             else if (x.logo != null  || x.endpoint_name != null) {
//                                                 return (
//                                                     <div >
//                                                         <center><h5 style={{ color: "#1E468E" }}><strong>Organization Profile</strong></h5>
//                                                             <CircularProgressbarWithChildren value={90}>
//                                                                 <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
//                                                                     <strong>
//                                                                     <a href={'/MyOrganizationProfile'}>
//                                                                         {percentage2}
//                                                                         </a>
//                                                                         </strong> <br></br><span class="sub_text">complete</span>
//                                                                 </div>
//                                                             </CircularProgressbarWithChildren>

//                                                         </center>

//                                                     </div>
//                                                 )

//                                             }
//                                             else if (x.endpoint_name === null) {
//                                                 return (
//                                                     <div >
//                                                         <center><h5 style={{ color: "#1E468E" }}><strong>Organization Profile</strong></h5>
//                                                             <CircularProgressbarWithChildren value={70}>
//                                                                 <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
//                                                                     <strong>
//                                                                     <a href={'/MyOrganizationProfile'}>
//                                                                         {percentage}
//                                                                         </a>
//                                                                         </strong> <br></br><span class="sub_text">complete</span>
//                                                                 </div>
//                                                             </CircularProgressbarWithChildren>

//                                                         </center>

//                                                     </div>
//                                                 )

//                                             }




//                                         }
//                                         )}

//                                     </Col>

//                                     <Col md={3}>
//                                     <div>
//                                             {Regcount.map(x => {
//                                                 return (
//                                                     <div >
//                                                         <center><h5 style={{ color: "#1E468E" }}><strong>Registered Organizations  </strong></h5>
//                                                             <div class="circle">
//                                                                 <div class="circle__inner">
//                                                                     <div class="circle__wrapper">

//                                                                         <div class="circle__content">
//                                                                              <a href={'/RegisteredOrgnization?tab=first'}> {x.count_name}</a><br></br><span class="sub_text">Registered</span></div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>

//                                                         </center>
//                                                     </div>
//                                                 )
//                                             }
//                                             )}

//                                         </div>



//                                     </Col>
//                                     <Col md={3}>
//                                         <div>
//                                             {Reqcount.map(x => {
//                                                 return (
//                                                     <div >
//                                                         <center><h5 style={{ color: "#1E468E" }}><strong>Registration Requests </strong></h5>
//                                                             <div class="circle">
//                                                                 <div class="circle__inner">
//                                                                     <div class="circle__wrapper">

//                                                                         <div class="circle__content"> <a href={'/RegisteredOrgnization?tab=second'}> {x.count_name}</a><br></br><span class="sub_text">Requests</span></div>
//                                                                     </div>
//                                                                 </div>
//                                                             </div>

//                                                         </center>
//                                                     </div>
//                                                 )
//                                             }
//                                             )}

//                                         </div>

//                                     </Col>
//                                     <Col md={3}>

//                                         <div>
//                                             <center><h5 style={{ color: "#1E468E" }}><strong>Endpoints</strong></h5>
//                                                 <div class="circle">
//                                                     <div class="circle__inner">
//                                                         <div class="circle__wrapper">

//                                                             <div class="circle__content"> <a href={'/Endpoints'}> {UserByIdEndpointCount}</a> <br></br><span class="sub_text"> Endpoints </span></div>
//                                                         </div>
//                                                     </div>
//                                                 </div>

//                                             </center>
//                                         </div>

//                                     </Col>
//                                 </Row>
//                             </Col>

//                         </Card.Body>
//                     </Card >
//                 </Col>
//             </Row>




//         </Container >
//     );

// }

// export default Dashboard;


import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import public_profile from './public_profile.png'
import profile_pic from './profile_pic.png'
import plus_icon from './plus_icon.png'
import { PieChart } from 'react-minimal-pie-chart';
import { Donut } from 'react-dial-knob'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';





import "bootstrap/dist/css/bootstrap.css";

import _ from 'lodash'
import axios from 'axios';

import TodoCard from "./TodoCard";

import { useForm } from "react-hook-form";

import Notification from './Notification'

let Dashboard = () => {



    // const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        setUserList(Array.isArray(e) ? e.map(x => x.state) : []);
        console.log(setUserList, "llllll")

    }

    const [userList, setUserList] = useState([]);
    const [allNotifactions, setAllNotifactions] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [Reqcount, setReqCount] = useState([]);
    const [UserByEndpointCount, setUserByEndpointCount] = useState([]);
    const [Regcount, setRegCount] = useState([]);
    const [regReqsingedStatus, setRegReqSingedStatus] = useState([]);
    const [tab, setTab] = useState('first');
    const [profilepercent, setProfilepercent] = useState(70);



    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'description', text: 'Description' },
        { dataField: 'last_modified_on', text: 'Last Modified On' },
        { dataField: 'modify', text: 'Modify' },


    ]

    var endpoints = [{
        name: "Patient Access API",
        description: "Endpoint to access patient details",
        last_modified_on: "December 13,2021",
        modify: <div><button type="button" class="btn btn-sm btn-primarybg btn-primary">Edit</button>  <button type="button" class="btn btn-edit  btn-sm btn-primary">Delete</button></div>
    }];


    const Organization_profile = 0.90;
    const history = useHistory();

    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")

        if (user_id == 'undefined') {
            history.push('/userlogin');

        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }

        else {
            MyOrganizationProfile()
            RegReqPayersSignedStatusCount()
            RegPayersSignedStatusCount()
            getRegReqPayersSignedStatusList()
            getMyEndPointsUserList()
            getAllNotifactions()
            var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
            console.log(UserByIdEndpointCount, "UserByIdEndpointCount")
            // var RegisterPayerCount = sessionStorage.getItem('RegisterPayerCount');
            // console.log(RegisterPayerCount, "RegisterPayerCount")


        }
    }, []);




    var payer_id = sessionStorage.getItem('payerid');
    console.log(payer_id, "payer_id")

    var UserByIdEndpointCount = sessionStorage.getItem('UserByIdEndpointCount');
    console.log(UserByIdEndpointCount, "UserByIdEndpointCount")

    function getRegReqPayersSignedStatusList() {
        console.log(payer_id, "RegReqPayersSignedStatus")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatus/${payer_id}`).then(res => {
            setRegReqSingedStatus(res.data.data);
            console.log(res.data.data, "RegReqPayersSignedStatus")

        });
    }

    function MyOrganizationProfile() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "MyOrganizationProfile")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/getMyOrganizationProfileDashboard/${value}`).then(res => {
            // setPayerList(res.data.data);
            var totalperc = profilepercent;
            if (UserByIdEndpointCount != 0) {
                totalperc = totalperc + 18
                setProfilepercent(totalperc);
                console.log("endpoint_name  === ", res.data.data[0].endpoint_name);
            }
            if (res.data.data[0].logo) {
                totalperc = totalperc + 12
                setProfilepercent(totalperc);
                console.log("logo === ", res.data.data[0].logo);
            }
            console.log(totalperc,"totalperc")

            // if (res.data.data[0].organisation_display_name) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("organisation_display_name === ", res.data.data[0].organisation_display_name);
            // }
            // if (res.data.data[0].api_url_production) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("api_url_production === ", res.data.data[0].api_url_production);
            // }
            // if (res.data.data[0].app_url_pre_production) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("app_url_pre_production === ", res.data.data[0].app_url_pre_production);
            // }
            // if (res.data.data[0].primary_developer_email) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("primary_developer_email === ", res.data.data[0].primary_developer_email);
            // }
            // if (res.data.data[0].primary_developer_name) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("primary_developer_name === ", res.data.data[0].primary_developer_name);
            // }
            // if (res.data.data[0].primary_developer_title) {
            //     totalperc = totalperc + 2
            //     setProfilepercent(totalperc);
            //     console.log("primary_developer_title === ", res.data.data[0].primary_developer_title);
            // }

            console.log(res.data.data, "MyOrganizationProfile");
        });
    }
    function RegReqPayersSignedStatusCount() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "RegReqPayersSignedStatusCount")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatusCount/${value}`).then(res => {
            setReqCount(res.data.data);
            console.log(res.data.data, "RegReqPayersSignedStatusCount");
        });
    }

    function RegPayersSignedStatusCount() {
        var value = sessionStorage.getItem('payerid');
        console.log("Appended payer id", value)
        console.log(MyOrganizationProfile, "RegPayersSignedStatusCount")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegPayersSignedStatusCount/${value}`).then(res => {
            setRegCount(res.data.data);
            console.log(res.data.data, "RegPayersSignedStatusCount");
        });
    }
    var user_id = sessionStorage.getItem('userid');
    function getMyEndPointsUserList() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getEndPointsAll/${user_id}`).then(res => {
            setUserByEndpointCount(res.data.data, "getMyEndPointsList");
            console.log(res.data.data, "getMyEndPointsUserList")
            var finalCount = res.data.data.length
            sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
            console.log(finalCount, "finalCount");
        });
    }
    var payer_id = sessionStorage.getItem('payerid');
    function getAllNotifactions() {
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getAllNotifactions/${payer_id}`).then(res => {
            setAllNotifactions(res.data.data, "getAllNotifactions");
            console.log(res.data.data, "getAllNotifactions")
            // var finalCount = res.data.data.length
            // sessionStorage.setItem('UserByIdEndpointCount', res.data.data.length)
            // console.log(finalCount, "finalCount");
        });
    }

    const percentage = '70%'
    const percentage2 = '90%'
    const percentage3 = '100%'
    const percentage4 = '80%'

    // const notificationData = [
    //     { id: 1, name: 'notification1' },
    //     { id: 2, name: 'notification2' },
    //     { id: 3, name: 'notification3' },
    //     { id: 4, name: 'notification4' },
    //     { id: 5, name: 'notification5' },
    //     { id: 6, name: 'notification6' },
    //     { id: 7, name: 'notification7' },
    //     { id: 8, name: 'notification8' },
    //     { id: 9, name: 'notification9' },
    //     { id: 10, name: 'notification10' },
    //     { id: 11, name: 'notification11' },
    //     { id: 12, name: 'notification12' },
    //     { id: 13, name: 'notification13' },
    //     { id: 14, name: 'notification14' },
    //     { id: 15, name: 'notification15' },
    //     { id: 16, name: 'notification16' },
    // ]


    return (
        <Container fluid="md">



            <Col md={12} style={{ textAlign: "left" }}>
                <Card style={{ width: "100%", align: "center",minHeight:"18%" }} >
                    {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff", }}>Search  Payer </Card.Header>  */}
                    <h3 className='text-center mb-3 heading'>Dashboard</h3>


                    <div class="clearfix"></div>
                    <br></br>

                    <Col md={12} style={{ textAlign: "left" }} className="dashboard">
                        <div>
                            <h5 style={{ color: "#1E468E" }}><strong>Recent Activity</strong> </h5>
                            <hr></hr>


                            {allNotifactions.slice(0, 5).map((ele) => {
                                if (ele.button_text == 'See Request') {
                                    return (
                                        <div>
                                            <p> <strong>{ele.name}</strong> {ele.notificationtext} {ele.inserted_date} <a href={ele.redirect_url}>{ele.button_text}</a></p>
                                        </div>
                                    )

                                }
                                else {
                                    return (
                                        <div>
                                            <p> {ele.notificationtext} <strong>{ele.name}</strong> on {ele.inserted_date} <a href={ele.redirect_url}>{ele.button_text}</a></p>
                                        </div>
                                    )

                                }




                            }
                            )}
                            {regReqsingedStatus.map(x => {
                                if (x.payer_status == "Initiated") {
                                    return (
                                        <div>
                                            <strong>{x.name}</strong>  sent you a registration request on {x.updated_date}.
                                            <Link
                                                to={'/RegisteredOrgnization?tab=second'}>See Request</Link>
                                        </div>
                                    )
                                }

                                // if (x.payer_status== "Signed" ) {
                                //     return (
                                //         <div>
                                //             <strong>{x.name}</strong>  has been accepted your registration request on {x.updated_date}.
                                //             <Link
                                //                 to={'/RegisteredOrgnization?tab=first'}>See Request</Link>
                                //         </div>
                                //     )
                                // }
                            }
                            )}

                            {/* <button class="btn-edit btn-primary" ><Link style={{ textDecoration: 'none' }} to={'/Notification'}>View All</Link></button> */}
                            {allNotifactions.length > 5 ? <button class="btn-edit btn-primary"
                                style={{ float: 'left', marginRight: '200px' }}><Link to={'/Notification'}>View All</Link></button> : ''}


                        </div>

                    </Col>
                    <br></br>
                    <Col md={12} style={{ textAlign: "left" }}>
                        <br></br>
                        <h5 style={{ color: "#1E468E" }}><strong>Your Organization</strong> </h5>
                        <hr></hr>
                        <Row>
                            <Col md={3}>
                                <div >
                                    <center><h5 style={{ color: "#1E468E" }}><strong>Registered Organizations  </strong></h5>
                                        <div class="circle" style={{ width: "80%" }}>
                                            <div class="circle__inner">
                                                <div class="circle__wrapper">

                                                <CircularProgressbarWithChildren value={profilepercent}>
                                                    <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
                                                        <strong>
                                                            <a href={'/MyOrganizationProfile'}>
                                                                {profilepercent}%
                                                            </a></strong> <br></br><span class="sub_text" >complete</span>
                                                    </div>
                                                </CircularProgressbarWithChildren>

                                                </div>
                                            </div>
                                        </div>

                                    </center>
                                </div>


                                {/* <div >
                                            <center><h5 style={{ color: "#1E468E" }}><strong>Organization Profile</strong></h5>


                                                <CircularProgressbarWithChildren value={profilepercent}>
                                                    <div style={{ fontSize: 42, lineHeight: "28px", color: "#1E468E" }}>
                                                        <strong>
                                                            <a href={'/MyOrganizationProfile'}>
                                                                {profilepercent}%
                                                            </a></strong> <br></br><span class="sub_text" >complete</span>
                                                    </div>
                                                </CircularProgressbarWithChildren>


                                            </center>

                                        </div> */}


                            </Col>

                            <Col md={3}>
                                <div>
                                    {Regcount.map(x => {
                                        return (
                                            <div >
                                                <center><h5 style={{ color: "#1E468E" }}><strong>Registered Organizations  </strong></h5>
                                                    <div class="circle" style={{ width: "80%" }}>
                                                        <div class="circle__inner">
                                                            <div class="circle__wrapper">

                                                                <div class="circle__content">
                                                                    <a href={'/RegisteredOrgnization?tab=first'}> {x.count_name}</a><br></br><span class="sub_text">Registered</span></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </center>
                                            </div>
                                        )
                                    }
                                    )}

                                </div>



                            </Col>
                            <Col md={3}>
                                <div>
                                    {Reqcount.map(x => {
                                        return (
                                            <div >
                                                <center><h5 style={{ color: "#1E468E" }}><strong>Registration Requests </strong></h5>
                                                    <div class="circle" style={{ width: "80%" }}>
                                                        <div class="circle__inner">
                                                            <div class="circle__wrapper">

                                                                <div class="circle__content"> <a href={'/RegisteredOrgnization?tab=second'}> {x.count_name}</a><br></br><span class="sub_text">Requests</span></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </center>
                                            </div>
                                        )
                                    }
                                    )}

                                </div>

                            </Col>
                            <Col md={3}>

                                <div>
                                    <center><h5 style={{ color: "#1E468E" }}><strong>Endpoints</strong></h5>
                                        <div class="circle" style={{ width: "80%" }}>
                                            <div class="circle__inner">
                                                <div class="circle__wrapper">

                                                    <div class="circle__content"> <a href={'/Endpoints'}> {UserByIdEndpointCount}</a> <br></br><span class="sub_text"> Endpoints </span></div>
                                                </div>
                                            </div>
                                        </div>

                                    </center>
                                </div>

                            </Col>
                            </Row>
                            </Col>

                </Card >
            </Col>






    </Container >

    );

}

export default Dashboard;