
import React, { Component, useState, useEffect } from 'react';


let PrivacyPolicy = (props) => {  
    let[htmlFileString, setHtmlFileString] = useState();
    async function fetchHtml() {
        setHtmlFileString(await (await fetch(`privacy_policy.html`)).text());
      }
      useEffect(() => {
        fetchHtml();
      }, []);
    
      return(
        <div className="PrivacyPolicy">
          <div dangerouslySetInnerHTML={{ __html: htmlFileString }}></div>
        </div>
      );

}

export default PrivacyPolicy;
