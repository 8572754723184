import React, { Component,useEffect,useState } from 'react';
import './css/Form.css'; //Import here your file style
import { Container, Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap';
import axios from 'axios';

import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next';
import { set, useForm } from "react-hook-form";
const btn = {
  "width": "20%",
  "textAlign": "center",
  "marginLeft": "40%",
}

const col = {
  "margin": "6px"
};

const sheader = {
  "backgroundColor": "blue",
  "color": "#ffffff"
}

const sheader_inner1 = {
  "backgroundColor": "gray",
  "color": "white"
}

const MyPayerList = () => {

  const { register, handleSubmit,reset, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const onSubmit = (data,id) => {
        axios
            .post(
                process.env.REACT_APP_BASE_URL+`/api/PayersSignedStatus/create/${id}`,
                data,
    
            )
            .then(response => {
                setSuccessMessage("Successfully created a payer contact!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                  }, 2000);
                 console.log(response.data) })
            .catch(error => { 
                setErrorMessage("Cannot created payer contact")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                  }, 2000);
                console.log(error.data) });
              
    };
    return (
        
      <Container fluid="md">

          <Card border="primary"
            style={{ width: "50%", align: "center", marginLeft: "25%" }}>
              <Card.Header as="h5" style={{ backgroundColor: "Blue", color: "#ffffff" }}> Payer Contact</Card.Header>
              <Card.Body>
                  <form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="mb-3" controlId="pcform.name">
                          <Form.Label>Contract File Path</Form.Label>
                          <input className="form-control"
                           
                            {...register("contract_file_path", { required: true, maxLength: 20 })}
                          />
                          {errors.contract_file_path && errors.contract_file_path.type === "required" &&
                           <p style={{color:"red"}}>Contract File Path is required </p>}
           
                      </Form.Group>
                  
                   <Form.Group className="mb-3" controlId="pcform.subject">
                          <Form.Label>Payer Status</Form.Label>
                          <input className="form-control"
                       {...register("payer_status", { required: true, maxLength: 60 })}
                          />
                          {errors.payer_status && errors.payer_status.type === "required" && <p style={{color:"red"}}>Payer Status is required</p>}
          
                      </Form.Group> 
                 
                      <Form.Group className="mb-3" controlId="pcform.submit">
                          <input type="submit" className="form-control btn btn-primary" style={{ width: "20%", marginLeft: "40%" }} />
                      </Form.Group>
                  </form>
                  <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
           <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

              </Card.Body>
            
          </Card>
      </Container>
  )

  
}









export default MyPayerList;
