import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import organization_img from './massachusetts.png'
import "bootstrap/dist/css/bootstrap.css";
import _ from 'lodash'
import axios from 'axios';
import TodoCard from "./TodoCard";
import { useForm } from "react-hook-form";

let RegisteredOrgnization = () => {


    // const [selectedValue, setSelectedValue] = useState([]);
    const handleChange = (e) => {
        console.log("handleChange", e)
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }
    const handletypeChange = (e) => {
        console.log("handletypeChange", e)
        setSelectedtypeValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const [userList, setUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [organizationTypeList, setOrganizationTypeList] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [endpointNamescount, setEndpointNamesCount] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedtypeValue, setSelectedtypeValue] = useState([]);
    const [regReqsingedStatus, setRegReqSingedStatus] = useState([]);
    const [regReqsingedRejectStatus, setRegReqSingedRejectStatus] = useState([]);
    const [regReqsingedPendingStatus, setRegReqSingedPendingStatus] = useState([]);

    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;
    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;
    const [tab, setTab] = useState('first');
    const history = useHistory();

    const [mypayerList, setMyPayerList] = useState([]);
    const [key, setKey] = useState('first');



    useEffect(() => {
        var payerId = sessionStorage.getItem('payerid')
        var user_id = sessionStorage.getItem('userid');
        var isadmin = sessionStorage.getItem('isadmin');
        console.log(user_id, isadmin, "user_idisadmin")
        const queryParams = new URLSearchParams(window.location.search);
        const restab = queryParams.get('tab');
        const succmessage = queryParams.get('success');
        console.log("Reg ORG useEffect",succmessage);
        setSuccessMessage(succmessage);
        setTimeout(() => {
            setSuccessMessage();
        }, 3000);
        // setTab(restab);
        if(restab==null)
        {
            setTab("first");
        }
        else
        {
            setTab(restab);
        }
        if (user_id == 'undefined') {
            history.push('/userlogin');

        }
        else if (user_id != 'undefined' && isadmin != 'false') {
            history.push('/userlogin');
        }

        else {
            getPayers();
            getLocation();
            getOrganizationType();
            getEndpointNamesCount();
            getRegReqPayersSignedStatusList();
            RegReqPayersSignedStatusRejectList();
            RegReqPayersSignedStatusPendingList();
            getAddMyList(payerId)

            var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

            if (elements.length) {
                elements[0].id = 'dt_dropdown'
            }




            //   console.log(user_id,"user_id")

        }


    }, []);
    const columns = [
        // { dataField: 'name', text: 'Organization Name' },
        {
            dataField: "remove",
            text: "Organization Name",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/ViewOrganization/${row.value}`}>
                        {row.name}

                    </Link>

                );
            },
        },
        { dataField: 'organisation_type', text: 'Organization Type' },
        //  { dataField: 'count', text: 'Number of Endpoints' },
        { dataField: 'fullname', text: 'Modified By' },
        { dataField: 'updated_date', text: 'Modified On' },


    ]
    const registration_request_columns = [
        { dataField: 'name', text: 'Organization Name' },
        { dataField: 'type', text: 'Organization Type' },
        { dataField: 'email', text: 'Organization Email' },
        { dataField: 'fullname', text: 'Modified By' },
        { dataField: 'updated_date', text: 'Modified On' },
        //  { dataField: 'payer_status', text: 'Request' },
        {
            dataField: "payer_status",
            text: "Action",
            formatter: (cellContent, row, payerList) => {
                if (row.payer_status === 'Initiated') {
                    return (

                        <div className="w-100 text-center mt-2">
                            <button
                                className="btn btn-success btn-xs" Width="95px"
                                style={{ margin: "2px" }}
                                onClick={() => updatePayerSingedStatusById(row.payer_signed_status_id)}
                            >
                                Accept
                            </button>

                            <button
                                className="btn btn-danger btn-xs" Width="95px"
                                style={{ margin: "2px" }}
                                onClick={() => updatePayerSingedStatusRejectById(row.payer_signed_status_id)}
                            >
                                Reject
                            </button>
                        </div>



                    );

                }
            }
        }]


    const registration_request__reject_columns = [
        { dataField: 'name', text: 'Organization Name' },
        { dataField: 'type', text: 'Organization Type' },
        { dataField: 'email', text: 'Organization Email' },
        { dataField: 'fullname', text: 'Modified By' },
        { dataField: 'updated_date', text: 'Modified On' },
        { dataField: 'payer_status', text: 'Status' },

    ]
    const registration_request__pending_columns = [
        { dataField: 'name', text: 'Organization Name' },
        { dataField: 'type', text: 'Organization Type' },
        { dataField: 'email', text: 'Organization Email' },
        { dataField: 'fullname', text: 'Modified By' },
        { dataField: 'updated_date', text: 'Modified On' },
        { dataField: 'payer_status', text: 'Status' },

    ]

    function updatePayerSingedStatusById(payer_signed_status_id) {
        console.log(payer_signed_status_id, "updateApproveById")
        axios.put(process.env.REACT_APP_BASE_URL + `/api/updatePayersSignedStatusAccept/${payer_signed_status_id}`,{
        })
            //     setPayerList(res.data.data);


            .then(res => {
                setSuccessMessage("Successfully Approved!")

                setTimeout(() => {
                    setSuccessMessage()
                    console.log(res.data.data, "updateApprove");
                    window.location.reload();
                    //   history.push('/ViewMyPayers');
                }, 500);

                console.log(res.data.data, "updatePayerSingedStatusById")

            })
            .catch(error => {
                setErrorMessage("Not Approved")

                setTimeout(() => {
                    setErrorMessage()
                }, 3000);
                console.log(error.data)
            });
    }

    function updatePayerSingedStatusRejectById(payer_signed_status_id) {
        console.log(payer_signed_status_id, "updateRejectById")
        axios.put(process.env.REACT_APP_BASE_URL + `/api/updatePayersSignedStatusReject/${payer_signed_status_id}`)
            //     setPayerList(res.data.data);
            //     console.log(res.data.data, "updateApprove");

            .then(response => {
                setSuccessMessage("Successfully Reject!")

                setTimeout(() => {
                    setSuccessMessage()
                    window.location.reload();
                }, 500);
                console.log(response.data, "updatePayerSingedStatusRejectById")
            })
            .catch(error => {
                setErrorMessage("Not Reject")

                setTimeout(() => {
                    setErrorMessage()
                }, 4000);
                console.log(error.data)
            });
    }

    function getPayers() {

        getPayerList('All', 'All', 'All')

    }
    const onSubmit = data => {
        var payerName = 'All'
        var localtion = 'All'
        var organizationtype = 'All'
        console.log(data.organisation_name, "payerList-data")
        console.log(selectedValue, "payerList-data-label")
        console.log(selectedtypeValue, "payerList-selectedtypeValue")
        console.log(selectedtypeValue.length, "payerList-selectedValue Count")
        if (selectedValue.length > 0) {
            console.log(selectedValue, "selectedValue")
            localtion = selectedValue;
        }
        else {
            console.log(selectedValue, "ELSE selectedValue")
        }
        if (selectedtypeValue.length > 0) {
            console.log(selectedtypeValue, "selectedtypeValue")
            organizationtype = selectedtypeValue;
        }
        else {
            console.log(selectedtypeValue, "ELSE selectedtypeValue")
        }

        if (data.organisation_name != '') {
            console.log(payerName, "organisation_name")
            payerName = data.organisation_name;
        }
        else {
            console.log(payerName, "ELSE organisation_name")
        }
        console.log(payerName, "=== payerName")
        console.log(localtion, organizationtype, "=== localtion")
        getPayerList(payerName, localtion, organizationtype)

    };
    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }
    function getOrganizationType() {
        console.log(getOrganizationType, "getOrganizationType")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getOrganisationTypes`).then(res => {
            setOrganizationTypeList(res.data.data);
            console.log(res.data.data, "setOrganizationTypeList");
        });
    }
    function getIdPayerList(value) {
        fetch(process.env.REACT_APP_BASE_URL + `/api/organization/getOrganisationByViewId/${value}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {
                console.log(resp.data, "getOrganisationByViewId")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }

    var registration_request = [{
        name: "Oscar",
        type: "Payer",
        email: 4,
        modified_by: "Aidan Chopra",
        modified_on: "December 17,2021",
        modified_on: "December 17,2021",
        request: <div><button type="button" class="btn btn-sm btn-accept btn-primary">Accept</button>  <button type="button" class="btn btn-reject  btn-sm btn-primary">Reject</button></div>
    }];

    function getEndpointNamesCount() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/getEndpointCount').then(res => {
            setEndpointNamesCount(res.data.data);
            console.log(res.data.data, "endpointnames count");
        });
    }
    function getRequestePayerList() {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/organization').then(res => {
            setPayerList(res.data.data);
        });
    }

    function getPayerList(payername, location, organizationtype) {
        console.log("getPayerList function involked ", payername, location, organizationtype)
        var payerid = sessionStorage.getItem('payerid')

        axios.get(process.env.REACT_APP_BASE_URL + `/api/organization/get/${payername}/${location}/${payerid}/${organizationtype}`).then(res => {
            setUserList(res.data.data);
            // setUserList()
            console.log(res.data.data, "getPayerList ==== ");


        });
    }

    var payer_id = sessionStorage.getItem('payerid');
    console.log(payer_id, "payer_id")
    function getRegReqPayersSignedStatusList() {
        console.log(payer_id, "RegReqPayersSignedStatus")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersSignedStatus/${payer_id}`).then(res => {
            setRegReqSingedStatus(res.data.data);
            console.log(res.data.data, "RegReqPayersSignedStatus")
            console.log(res.data.payer_signed_status_id, "payer_signed_status_id")
        });
    }

    function RegReqPayersSignedStatusRejectList() {
        console.log(payer_id, "RegReqPayersRejectStatus")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersRejectStatus/${payer_id}`).then(res => {
            setRegReqSingedRejectStatus(res.data.data);
            console.log(res.data.data, "RegReqPayersRejectStatus")
            console.log(res.data.payer_signed_status_id, "payer_signed_status_id")
        });
    }
    function RegReqPayersSignedStatusPendingList() {
        console.log(payer_id, "RegReqPayersPendingStatus")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/RegReqPayersPendingStatus/${payer_id}`).then(res => {
            setRegReqSingedPendingStatus(res.data.data);
            console.log(res.data.data, "RegReqPayersPendingStatus")
            console.log(res.data.payer_signed_status_id, "payer_signed_status_id")
        });
    }
    function getAddMyList(value) {
        console.log(value, "getAddMyList")
        axios.get(process.env.REACT_APP_BASE_URL + `/api/getOrganisationAddMyList/${value}`).then(res => {
            setMyPayerList(res.data.data);
            console.log(res.data.data, "AAAAA");
            sessionStorage.setItem('RegisterPayerCount', res.data.data.length)
        });
    }

    const columnsList = [
        { dataField: 'name', text: ' Organization Name' },
        { dataField: 'email', text: ' Organization Email' },
        { dataField: 'organisation_type', text: 'Organization Type' },
        {
            dataField: "payer_status",
            text: "Action",
            formatter: (cellContent, row, payerList) => {
                if (row.payer_status === 'Signed') {
                    return (
                        <div className="w-100 text-center mt-2">
                            <Link to={`/ViewRegisteredOrg/${row.payer_id}`}>
                                <button
                                    className="btn btn-success btn-xs" Width="95px"
                                    style={{ margin: "2px", marginRight: "110px" }}
                                    onClick={() => getIdPayerList(row.payer_id)}
                                >
                                    View
                                </button>

                            </Link>
                            {/* <Link to='/newPayer'>
                        <button className="btn btn-warning btn-sm" Width="70px"
                            style={{ marginRight: "2px"}}><i class="fa fa-plus-circle"></i> App Details</button>
                        </Link> */}

                        </div>


                    );

                }

            },
        },
        {
            dataField: "remove",
            text: "Status",
            formatter: (cellContent, row) => {
                return (
                    <div>
                        {row.payer_status}
                    </div>


                );
            },
        },


    ]

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    return (

<Container fluid="md">
            <Row>
                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", align: "center",minHeight: "630px" }} >
                        {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff", }}>Search  Payer </Card.Header>  */}
                        <h3 className='text-center mb-3 heading'>Registered Organizations </h3>
                        <h4 style={{ width: "100%"}}> {successMessage && <div
                     className="d-flex justify-content-center error" style={{minHeight:"400px", color: "green" ,marginLeft:"25%",paddingTop:"10px"}} > {successMessage}</div>}
                     <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong></h4>
                        <div style={{ display: 'block', padding: 20 }}>


                            <Tabs activeKey={tab} onSelect={k => setTab(k)} >
                                <Tab eventKey="first" title="Registered Organizations">
                                    <div className="container-fluid p-3">
                                        <BootstrapTable bootstrap4 keyField='id'
                                            columns={columnsList}
                                            data={mypayerList}
                                           pagination={pagination}
                                            filter={filterFactory()}
                                        />
                                    </div>

                                </Tab>
                                <Tab eventKey="second" title="Registration Requests">


                                      <BootstrapTable bootstrap4 keyField='id'
                                        columns={registration_request_columns}
                                        data={regReqsingedStatus}

                                          pagination={pagination}
                                            filter={filterFactory()}
                                    />


                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <div class="form-group row col-md-12 " style={{ paddingLeft: 0, paddingRight: 0 }}>

                                            </div>
                                        </Row>


                                    </form>



                                </Tab>
                                <Tab eventKey="third" title="Pending Requests">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <div class="form-group row col-md-12 " style={{ paddingLeft: 0, paddingRight: 0 }}>

                                            </div>
                                        </Row>


                                    </form>

                                    <BootstrapTable bootstrap4 keyField='id'

                                        columns={registration_request__pending_columns}
                                        data={regReqsingedPendingStatus}
                                        pagination={pagination}
                                        filter={filterFactory()}
                                    >

                                    </BootstrapTable>
                                </Tab>
                                <Tab eventKey="fourth" title="Rejected Requests">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <div class="form-group row col-md-12 " style={{ paddingLeft: 0, paddingRight: 0 }}>

                                            </div>
                                        </Row>


                                    </form>

                                    <BootstrapTable bootstrap4 keyField='id'

                                        columns={registration_request__reject_columns}
                                        data={regReqsingedRejectStatus}
                                         pagination={pagination}
                                        filter={filterFactory()}
                                    >

                                    </BootstrapTable>
                                </Tab>
                            </Tabs>

                        </div>


                    </Card >
                </Col>
            </Row>


            {/* < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong> */}


        </Container >



        // <Col md={12} style={{ textAlign: "left" }}>
        //     <Card style={{ width: "100%", align: "center",minHeight: "600px"}} >

        //         <h3 className='text-center mb-3 heading'>Dashboard</h3>
        //         </Card>
        //         </Col>

    );

}

export default RegisteredOrgnization;














