import React, { Component, useState, useMemo, useEffect, Field, MenuItem } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';

import { MDBInput } from "mdbreact";
import '../css/custom.css'; //Import here your file style

const OrganizationInformation = (props) => {

    const { formStep, register, errors, usertypeList, setError, locationList, trigger, payerEnvironmentList } = props


    async function getOrganizationName() {
        const response = await axios.get(process.env.REACT_APP_BASE_URL+`/api/user/getEINByOrganization`)
        const data = await response.data.data
        console.log(data, "getEINByOrganization")
        return data;
    }

    function validateOrganizationName(organizationName) {
        return (value) => {
            const isDuplicate = organizationName.some(organizationName => organizationName.organisation_name === value);
            return !isDuplicate || 'Organization name already exists';
        };
    }
    const [organizationName, setOrganizationName] = useState([]);

    useEffect(() => {
        document.getElementById("container").classList.remove("conthome");
        getOrganizationName().then(data => setOrganizationName(data));
    }, []);


    // sessionStorage.setItem('organisation_type_id',usertypeList.organisation_type);
    // console.log('sessionStorage',sessionStorage);
    return (
        <div>
            {formStep == 0 && <section className={formStep === 0 ? "block" : "hidden"}>

                <Row md={12} style={{ width: "100%", }}>
                    <ul id="progressbar" class="mt-4">
                        <li class="active">Organization Information</li>
                        <li>Administrator Information</li>
                        <li>Review and  Confirm</li>
                    </ul>

                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.organisation_name">
                            <Form.Label>Organization Name  <strong style={{ color: "red" }}>*</strong></Form.Label>

                            <input className="form-control" type="text"
                                {...register("organisation_name", {
                                    required: "organization name is required",
                                    validate: validateOrganizationName(organizationName),
                                    pattern: {
                                        // value :/^[0-9a-zA-Z].*$/,
                                        value: /^[a-zA-Z\s].*$/,
                                        value1: /(^\d{10}$)|(^\d{10}-\d{4}$)/,
                                        message: "Starting letter to be alphabet"
                                    },
                                })}
                            />
                            {errors.organisation_name && (
                                < p style={{ color: "red" }}>{errors.organisation_name.message}</p>
                            )}
                        </Form.Group>
                    </Col>
                    <Col md={6} >

                        <Form.Group className="mb-3 lable" controlId="pcform.organisation_type_id">
                            <Form.Label>Organization Type  <strong style={{ color: "red" }}>*</strong></Form.Label>
                            <br />
                            <select className="form-control" name="payer"
                                {...register("organisation_type_id", { required: true, validate: (value) => value !== "Select Organization" })}
                            >
                                <option>Select Organization</option>
                                {
                                    usertypeList.map((result) => (<option key={result.id} value={`${result.id}-${result.organisation_type}`}> {result.organisation_type}</option>
                                    ))
                                }
                            </select>
                            {errors.organisation_type_id && <p style={{ color: "red" }}>Please select at least one option</p>}
                        </Form.Group>
                    </Col>
                </Row>

                <Row md={12} style={{ width: "100%", }}>
                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.username">

                            <Form.Label>Username<strong style={{ color: "red" }}>*</strong></Form.Label>
                            <input class="form-control"
                                {...register("username", { required: true, minLength: 6 })} />
                            {errors.username && errors.username.type === "required" &&
                                <p style={{ color: "red" }}>username is required</p>}
                            {errors.username && errors.username.type === "minLength" &&
                                <p style={{ color: "red" }}> username must be at least 6 characters long</p>}
                        </Form.Group>

                    </Col>
                    <Col md={6} >
                        <Form.Group className="mb-3 lable" controlId="pcform.phone">
                            <Form.Label>Organization Phone Number <strong style={{ color: "red" }}>*</strong></Form.Label>

                            <input
                                type="text"
                                className={`form-control ${errors.phone && "invalid"}`}
                                {...register("phone", {
                                    required: "Phone is Required",
                                    pattern: {
                                        value: /(^\d{10}$)|(^\d{10}-\d{4}$)/,
                                        message: "Only 10 digits "
                                    },
                                })}
                                onKeyUp={() => {
                                    trigger("phone");
                                }}
                            />
                            {errors.phone && (
                                < p style={{ color: "red" }}>{errors.phone.message}</p>
                            )}
                        </Form.Group>

                    </Col>


                </Row>
                <Row md={12} style={{ width: "100%", }}>
                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.address1">
                            <Form.Label>Address Line 1  <strong style={{ color: "red" }}>*</strong></Form.Label>
                            <input className="form-control" type="text"
                                {...register("address1", { required: true })}
                            />
                            {errors.address1 && errors.address1.type === "required" &&
                                <p style={{ color: "red" }}>Address1 is required</p>}
                        </Form.Group>

                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.address2">
                            <Form.Label>Address Line 2</Form.Label>
                            <input className="form-control" type="text"

                                {...register("address2")}
                            />
                            {/* {errors.address2 && errors.address2.type === "required" &&
                                            <p style={{ color: "red" }}>Address2 is required</p>} */}
                        </Form.Group>

                    </Col>
                </Row>
                <Row md={12} style={{ width: "100%", }}>

                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.city">
                            <Form.Label>City  <strong style={{ color: "red" }}>*</strong></Form.Label>
                            <input className="form-control" type="text"
                                {...register("city", {
                                    // required: true, pattern :'/^[a-zA-Z]+$/'
                                    required: "City is Required",
                                    pattern: {
                                        value: /^[a-zA-Z\s]*$/,
                                        message: "Only letters "
                                    },
                                })}
                                onKeyUp={() => {
                                    trigger("city");
                                }}


                            />
                            {errors.city && (
                                < p style={{ color: "red" }}>{errors.city.message}</p>
                            )}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.organisation_type_id">
                            <Form.Label>State <strong style={{ color: "red" }}>*</strong></Form.Label>
                            <br />
                            <select className="form-control" name="payer"

                                {...register("value", {
                                    required: true,
                                    validate: (value) => value !== "Select State"
                                })}
                            >
                                <option>Select  State</option>
                                {
                                    locationList.map((result) => (<option key={result.value} value={`${result.value}-${result.label}`}> {result.label}</option>
                                    ))
                                }
                            </select>
                            {errors.value && <p style={{ color: "red" }}>Please select at least one option</p>}

                        </Form.Group>

                    </Col>
                </Row>
                <Row md={12} style={{ width: "100%", }}>

                    <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.country">
                            <Form.Label>Country  <strong style={{ color: "red" }}>*</strong></Form.Label>
                            <input className="form-control" type="text" value="USA" readOnly
                                {...register("country", { required: true, maxLength: 60 })}
                            />
                            {errors.country && errors.country.type === "required" &&
                                <p style={{ color: "red" }}>Country is required</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6} >
                        <Form.Group className="mb-3 lable" controlId="pcform.zip_code">
                            <Form.Label> Zip Code <strong style={{ color: "red" }}>*</strong></Form.Label>

                            <input
                                // type="text"
                                // className={`form-control ${errors.zip_code && "invalid"}`}
                                className="form-control" type="text"
                                {...register("zip_code", {
                                    required: true,

                                    pattern: {
                                        value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                        message: "Invalid zipcode ",
                                    },
                                })}
                                onKeyUp={() => {
                                    trigger("zip_code");
                                }}
                            />
                            {errors.zip_code && errors.zip_code.type === "required" &&
                                <p style={{ color: "red" }}>Zipcode is required</p>}

                            {errors.zip_code && (
                                < p style={{ color: "red" }}>{errors.zip_code.message}</p>
                            )}
                        </Form.Group>

                    </Col>

                </Row>
                {/* <Row md={12} style={{ width: "100%", }}>
                
                </Row> */}

            </section>}


        </div>


    )
}
export default OrganizationInformation


{/* <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.payer_environment">
                            <Form.Label>Payer Environment <strong style={{ color: "red" }}>*</strong></Form.Label> */}

<br />
{/* <select className="form-control" name="payer"
                                {...register("payer_environment", { required: true, validate: (payer_environment) => payer_environment !== "Select Payer Environment " })}
                            >
                                <option>Select Payer Environment</option> */}
{/* {
                                    payerEnvironmentList.map((result) => (<option key={result.id} value={`${result.id}-${result.payer_environment }`}> {result.payer_environment}</option>
                                    ))
                                } */}
{/*
                            </select>
                            {errors.payer_environment && <p style={{ color: "red" }}>Please select at least one option</p>} */}

{/* <select className="form-control" name="payer"

                                {...register("payer_environment", {
                                    required: true,
                                    validate: (payer_environment) => payer_environment !== "Select Payer Environment"
                                })}
                            >
                                <option>Select Payer Environment</option>
                                {
                                    payerEnvironmentList.map((result) => (<option key={result.id} value={`${result.id}-${result.payer_environment}`}> {result.payer_environment}</option>
                                    ))
                                }
                            </select>
                            {errors.payer_environment && <p style={{ color: "red" }}>Please select at least one option</p>}

                        </Form.Group>

                    </Col> */}