// import React, { Component,useEffect,useState } from 'react';
// import './css/Form.css'; //Import here your file style
// import { Container, Row, Col, Form, Button, Card, ListGroup } from 'react-bootstrap';
// import axios from 'axios';
// import { Link, useHistory, useParams } from 'react-router-dom';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
// import BootstrapTable from 'react-bootstrap-table-next';
// import { set, useForm } from "react-hook-form";
// const btn = {
//   "width": "20%",
//   "textAlign": "center",
//   "marginLeft": "40%",
// }

// const col = {
//   "margin": "6px"
// };

// const sheader = {
//   "backgroundColor": "blue",
//   "color": "#ffffff"
// }

// const sheader_inner1 = {
//   "backgroundColor": "gray",
//   "color": "white"
// }

// const MyEndPoints = () => {



//   const [endpointList, setEndPointList] = useState([]);

//     const [errorMessage, setErrorMessage] = React.useState("");
//     const [successMessage, setSuccessMessage] = React.useState("");

//   const [id, setID] = useState(null);
//   const [endpoint_name, setEndpointName] = useState('');
//   const [endpoint_link, setEndpointLink] = useState('');
//   const [description, setDescription] = useState('');


//     useEffect(() => {

//         getMyEndPointsList()

//     }, []);


//     // const updateUser = (id) => {
//     //     axios.put(process.env.REACT_APP_BASE_URL+`/update/EndPoints/${id}`, {
//     //       endpoint_name, endpoint_link, description
//     //     })
//     //       .then(response => {

//     //         console.log(response.data, "updateEndpoint")
//     //         getMyEndPointsList()
//     //       })
//     //       .catch(error => {

//     //         console.log(error.data, "ll");

//     //       })

//     //   }


//     function getMyEndPointsList() {
//         axios.get(process.env.REACT_APP_BASE_URL+`/api/getEndPoints`).then(res => {
//           setEndPointList(res.data.data);
//        //  sessionStorage.setItem('id', res.data.data[0].id);

//         //  setID(localStorage.setItem('id',res.data.data[0].id));
//         //  setEndpointName(localStorage.setItem('endpoint_name',res.data.data[0].endpoint_name));
//         //  setEndpointLink(localStorage.setItem('endpoint_link',res.data.data[0].endpoint_link));
//         //  setDescription(localStorage.setItem('description',res.data.data[0].description));

//             console.log(res.data.data, "AAAAA");
//         });

//     }

//     function deleteEndpoints(id) {
//       fetch(process.env.REACT_APP_BASE_URL+`/api/delete/EndPoints/${id}`, {
//           method: 'delete'

//       }).then((result) => {
//           result.json().then((resp) => {
//               setSuccessMessage("Successfully deleted a endpoint!")
//               setTimeout(() => {
//                   setSuccessMessage()
//               }, 2000);
//               console.log(resp)
//               getMyEndPointsList()

//           })
//       })
//           .catch(error => {
//               setErrorMessage("Cannot deleted endpoint")
//               setTimeout(() => {
//                   setErrorMessage()
//               }, 2000);
//               console.log(error.data)
//           });

//   }
//     //add myendnpoints table
//     const columnsList = [
//         { dataField: 'endpoint_name', text: 'Name' },
//         { dataField: 'description', text: 'Description'},
//         { dataField: 'updated_date', text: 'Last Modified On' },
//         {
//           dataField: "remove",
//           text: "Modify",
//           formatter: (cellContent, row) => {
//               return (
//                 <div className="w-100 text-center mt-2">
//                   <button  type="button"
//                       className="btn btn-outline-danger btn-xs"
//                       onClick={() => deleteEndpoints(row.id, row.name)}
//                   >
//                       Delete

//                   </button>
//                     {/* <Link to={`/UpdateEndpoints/${row.id}`}>
//                    <button  type="button"
//                    className="btn btn-outline-primary btn-xs"

//                 // onClick={() => deleteEndpoints(row.id, row.name)}
//                >
//                    Edit

//                </button>
//                </Link> */}
//                </div>

//                   // <IconButton
//                   //     aria-label="delete"
//                   //     onClick={() => deletePayer(row.id, row.name)}
//                   // >
//                   //     <DeleteIcon />
//                   // </IconButton>




//               );
//           },

//       }


//     ]




//     const pagination = paginationFactory({
//         page: 1,
//         sizePerPage: 5,
//         lastPageText: '>>',
//         firstPageText: '<<',
//         nextPageText: '>',
//         prePageText: '<',
//         showTotal: true,
//         alwaysShowAllBtns: true,
//         onPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         },
//         onSizePerPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         }
//     })

//     return (
//         <Container fluid="md">
//             <Row>
//                 <Col md={12} style={{ textAlign: "left" }}>
//                 <Card style={{ width: "100%", align: "center" }}>
//                         <h3 className='text-center mb-3 heading'>My Endpoints </h3>
//                         <center><hr className='heading_line'></hr></center>
//                         <div class="pull-right">

//                             <div className="w-100 text-center mt-2">
//                           <Link to={`/AddEndpoints`}>
//                                 <button
//                                     className="btn btn-primary " Width="95px"
//                                     style={{ marginTop: "26px", borderRadius: "20px",marginLeft:"60%" }} id="download_button"

//                                    // onClick={() => { createContract(x.value); window.open(`http://localhost/devportal/login.php?id=1`, 'name', 'height=500,width=850') }}
//                                 >
//                                     Add Endpoints
//                                 </button>
//                              </Link>
//                             </div>
//                     </div>
//                         <Card.Body>
//                             <br></br>
//                             <div className="container-fluid p-3">
//                                 <BootstrapTable bootstrap4 keyField='id'
//                                     columns={columnsList}
//                                     data={endpointList}
//                                     pagination={pagination}
//                                     filter={filterFactory()}
//                                 />
//                             </div>

//                     < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
//                     <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//         </Container >
//     );

// }
//export default MyEndPoints;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable, { ROW_SELECT_DISABLED } from 'react-bootstrap-table-next';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';



let MyEndPoints = () => {

    const [APIData, setAPIData] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    useEffect(() => {
        getMyEndPointsList()

    }, []);
    var user_id = sessionStorage.getItem('userid');
    function getMyEndPointsList() {
                axios.get(process.env.REACT_APP_BASE_URL+`/api/getEndPointsAll/${user_id}`).then(res => {
                    setAPIData(res.data.data);
                    console.log( getMyEndPointsList,"getMyEndPointsList")
               });


            }

    const setData = (data) => {
        let { id, endpoint_name, endpoint_link, description } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('endpoint_name', endpoint_name);
        // localStorage.setItem('endpoint_link', endpoint_link);
        // localStorage.setItem('description', description);

    }
    // const columnsList = [
    //             { dataField: 'endpoint_name', text: 'Name' },
    //             { dataField: 'description', text: 'Description'},
    //             { dataField: 'updated_date', text: 'Last Modified On' },
    //             {
    //               dataField: "remove",
    //               text: "Modify",
    //               formatter: (cellContent, row) => {
    //                   return (
    //                     <div className="w-100 text-center mt-2">
    //                       <button  type="button"
    //                           className="btn btn-outline-danger btn-xs"
    //                           onClick={() => onDelete(row.id, row.name)}
    //                       >
    //                           Delete

    //                       </button>
    //                        <Link to={`/UpdateEndpoints/${row.id}`}>
    //                        <button  type="button"
    //                        className="btn btn-outline-primary btn-xs"

    //                      onClick={() => setData(APIData)}
    //                    >
    //                        Edit

    //                    </button>
    //                    </Link>
    //                    </div>

    //                       // <IconButton
    //                       //     aria-label="delete"
    //                       //     onClick={() => deletePayer(row.id, row.name)}
    //                       // >
    //                       //     <DeleteIcon />
    //                       // </IconButton>




    //                   );
    //               },

    //           }


    //         ]


    // const pagination = paginationFactory({
    //     page: 1,
    //     sizePerPage: 5,
    //     lastPageText: '>>',
    //     firstPageText: '<<',
    //     nextPageText: '>',
    //     prePageText: '<',
    //     showTotal: true,
    //     alwaysShowAllBtns: true,
    //     onPageChange: function (page, sizePerPage) {
    //         console.log('page', page)
    //         console.log('sizePerPage', sizePerPage)
    //     },
    //     onSizePerPageChange: function (page, sizePerPage) {
    //         console.log('page', page)
    //         console.log('sizePerPage', sizePerPage)
    //     }
    // })

    // const getData = () => {
    //     axios.get(process.env.REACT_APP_BASE_URL+`/api/getEndPoints`)
    //         .then((res) => {
    //             setAPIData(res.data.data);
    //            // getData();
    //         })
    // }
    // getData()

    const onDelete = (id) => {
        axios.delete(process.env.REACT_APP_BASE_URL+`/api/delete/EndPoints/${id}`)
            .then(() => {
                getMyEndPointsList();
            })
    }

    return (
        <Container fluid="md">
            <Row>
                <Col md={12} style={{ textAlign: "left" }}>
                    <Card style={{ width: "100%", align: "center" }}>
                        <h3 className='text-center mb-3 heading'>My Endpoints </h3>
                        <center><hr className='heading_line'></hr></center>
                        <div class="pull-right">
                            <div className="w-100 text-center mt-2">
                                <Link to={`/AddEndpoints`}>
                                    <button
                                        className="btn btn-primary " Width="95px"
                                        style={{ marginTop: "26px", borderRadius: "20px", marginLeft: "60%" }} id="download_button"

                                    // onClick={() => { createContract(x.value); window.open(`http://localhost/devportal/login.php?id=1`, 'name', 'height=500,width=850') }}
                                    >
                                        Add Endpoints
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <Card.Body>

                            <br></br>
                            {/* <div className="container-fluid p-3">
                                        <BootstrapTable bootstrap4 keyField='id'
                                            columns={columnsList}
                                            data={APIData}
                                            pagination={pagination}
                                            filter={filterFactory()}
                                        />
                                    </div> */}
                            <div>
                                <Table singleLine>
                                    <Table.Header>
                                        <Table.Row>
                                             <Table.HeaderCell>Payer Name</Table.HeaderCell>
                                            <Table.HeaderCell>Endpoint Name</Table.HeaderCell>
                                            <Table.HeaderCell>Base Url</Table.HeaderCell>
                                            <Table.HeaderCell>Auth Scope</Table.HeaderCell>
                                            <Table.HeaderCell>Action</Table.HeaderCell>
                                            <Table.HeaderCell></Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {APIData.map((data) => {
                                            return (
                                                <Table.Row>
                                                     <Table.Cell>{data.name}</Table.Cell>
                                                    <Table.Cell>{data.endpoint_name}</Table.Cell>
                                                    <Table.Cell>{data.base_url}</Table.Cell>
                                                    <Table.Cell>{data.auth_scope}</Table.Cell>

                                                        {/* <Table.Cell>
                                                        <Link to={`/UpdateEndpoints/${data.id}`}>
                                                            <button type="button"
                                                                className="btn btn-outline-primary btn-xs"
                                                                onClick={() => setData(data)}
                                                            >
                                                                Update

                                                            </button>
                                                            </Link>
                                                        </Table.Cell> */}

                                                    <Table.Cell>

                                                        <button type="button"
                                                            className="btn btn-outline-danger btn-xs"
                                                            onClick={() => onDelete(data.id)}
                                                        >
                                                            Delete

                                                        </button>

                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>

                            < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </Container >

    )
}
export default MyEndPoints
