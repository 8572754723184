
import React, { Component, useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from 'react-router-dom'
import { TweenLite, Power1, TimelineLite } from "gsap";
import ManagerDashboard from "./ManagerDashboard";
// styles
import "./react-sidenav.css";
import profile_pic from './profile_pic.png'
import { size } from 'lodash';



var isadmin = sessionStorage.getItem('isadmin')
var menuItems
class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false
    };

    this.drawer = null;
    this.menuBtn = null;
    this.contentVeil = null;

    this.drawerTween = new TimelineLite({
      paused: true
    });

    this.toggleContentVeil = this.toggleContentVeil.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.veilClickHandler = this.veilClickHandler.bind(this);

    var isadmin = sessionStorage.getItem('isadmin')
    //console.clear()
    console.log("navbar isadmin ", sessionStorage.getItem('isadmin'));
    var email = sessionStorage.getItem('email');
    var organisationname = sessionStorage.getItem('organisationname');
    var username = sessionStorage.getItem('username');
    var user_id = sessionStorage.getItem('userid');
    var ishcuser= sessionStorage.getItem('ishcuser');
    console.log("getusers called", organisationname, email, username, user_id,ishcuser);
    console.log("ishcuser",ishcuser);


    if (isadmin == 'true' && ishcuser =='true') {

      menuItems = [
        { type: "link", url: "/AdminDashboard", title: "Payer Registered with HC", icon: "fa-list" },
        { type: "link", url: "/PayerListDashboard", title: "HC Registered Payers", icon: "fa-search" },
        { type: "link", url: "/logout", title: "Logout", icon: "fa-sign-out-alt" },
      ];
    }


    else if (isadmin == 'true') {

      menuItems = [
        { type: "link", url: "/AdminDashboard", title: "Payer Registered with HC", icon: "fa-list" },
        { type: "link", url: "/PayerListDashboard", title: "HC Registered Payers", icon: "fa-search" },
        // { type: "link", url: "/AddAdminEndpoints", title: "Add Endpoints", icon: "fa-user" },
        { type: "link", url: "/managerDashboard", title: "Power Users", icon: "fa-th-large" },
        // { type: "link", url: "/CreateAdminUser", title: "Create HC User", icon: "fa-user" },
        { type: "link", url: "/logout", title: "Logout", icon: "fa-sign-out-alt" },
      ];
    }


    else {
      menuItems = [
        { type: "link", url: "/NewDashboard", title: "Dashboard", icon: "fa-user" },
        { type: "link", url: "/OrganizationDirectory", title: "Organization Directory", icon: "fa-search" },
        { type: "link", url: '/RegisteredOrgnization', title: "Registered Organizations", icon: "fa-list" },
        { type: "link", url: "/MyOrganizationProfile", title: "My Organization Profile", icon: "fa-user" },
        { type: "link", url: "/Endpoints", title: "My Endpoints", icon: "fa-list" },
        { type: "link", url: "/logout", title: "Logout", icon: "fa-sign-out-alt" }

        // { type: "link", url: "/NewDashboard", title: "Dashboard", icon: "fa-user" },
        // { type: "link", url: "/OrganizationDirectory", title: "Organization Directory", icon: "fa-search" },
        // { type: "link", url: '/RegisteredOrgnization', title: "Registered Organizations", icon: "fa-list" },
        // { type: "link", url: "/MyOrganizationProfile", title: "My Organization Profile", icon: "fa-user" },
        // { type: "link", url: "/Endpoints", title: "My Endpoints", icon: "fa-list" },
        // { type: "link", url: "/logout", title: "Logout", icon: "fa-sign-out-alt" },

      ];
    }
  }


  toggleContentVeil() {
    TweenLite.set(this.contentVeil, {
      autoAlpha: this.drawerTween.reversed() ? 0 : 0.25
    });
  }

  toggleDrawer() {
    this.drawerTween.reversed(!this.drawerTween.reversed());
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  veilClickHandler(e) {
    e.stopPropagation();
    this.toggleDrawer();
  }

  componentDidMount() {
    this.drawerTween
      .call(this.toggleContentVeil)
      .to(this.drawer, 0.25, {
        x: 0, ease: Power1.easeOut
      })
      .to(this.menuBtn, 0.25, {
        x: 170, ease: Power1.easeOut
      }, 0)
      .reverse();
  }
  getusers() {
    console.log("getusers called");

  }



  render() {






    return <nav className="drawer-wrap">
      {/* <div className="container-fluid menu-header">

          <div className="row">
            <div className="col-12">
              <div className="h2 text-center ">SideBar Slide Menu</div>
            </div>
          </div>
      </div>   */}


      <div className="drawer" id="navmenu"
        ref={e => this.drawer = e}
      >
        <div className="drawer-header">
          <center>
            <div class="image">
              <img src={profile_pic} class="img-circle elevation-2" style={{ width: "54px" }} alt="User Image" />
            </div>

          </center>


          {/* {(sessionStorage.getItem('name')) ? (
        <h4 className="pl-3 profile_heading"> { sessionStorage.getItem('username')}</h4>
      ) : (
        <h4 className="pl-3 profile_heading"> 2{sessionStorage.getItem('name')}</h4>
      )}  */}


          {/* {sessionStorage.getItem('username') ? (
       <h4 className="pl-3 profile_heading"> { sessionStorage.getItem('username')}</h4>
      ) : (
        <h4 className="pl-3 profile_heading">  { sessionStorage.getItem('name')}</h4>
      )}  */}


          <h4 className="pl-3 profile_heading"><br></br>
            {/* <h6 style={{fontSize:'90%'}} className="pl-3 profile_heading"> {sessionStorage.getItem('username')}</h6>
           {(() => {
             if (isadmin == 'false'){
               <div>
 <h4 style={{fontSize:'9px'}}>{sessionStorage.getItem('organisationname')}</h4>
            <h6 style={{fontSize:'90%'}} className="pl-3 profile_heading"> {sessionStorage.getItem('username')}</h6>
               </div>

             }

          })()}   */}
            {(() => {
              if (sessionStorage.getItem('isadmin') == 'true' && sessionStorage.getItem('ishcuser') == 'false'){
                  return (
                     <div>
                      {/* <h6 style={{fontSize:'90%'}}>{sessionStorage.getItem('username')}</h6></div> */}
                      <h6 style={{fontSize:'90%'}}>Super User</h6></div>
                  )
              }
              if (sessionStorage.getItem('isadmin') == 'true' && sessionStorage.getItem('ishcuser') == 'true'){
                return (
                   <div>
                    {/* <h6 style={{fontSize:'90%'}}>{sessionStorage.getItem('username')}</h6></div> */}
                    <h6 style={{fontSize:'90%'}}>Power User</h6></div>
                )
            }
              if (sessionStorage.getItem('isadmin') == 'false'  && sessionStorage.getItem('ishcuser') == 'false'){
                return (
                   <div>
                    <h6 style={{fontSize:'90%'}}>{sessionStorage.getItem('username')}</h6>
                    {/* <h6 style={{fontSize:'90%'}}>Payer Admin</h6> */}
                    <h4 style={{fontSize:'9px'}}>{sessionStorage.getItem('organisationname')}</h4></div>
                )
            }


            })()}


            <div class="info">
              {/* <h4> <a  href="#" class="d-block">{ sessionStorage.getItem('name')}</a>  </h4> */}
            </div></h4>
        </div>


        <div className="menu-container py-3">
          {
            menuItems.map(e => {
              if (e.type == "divider") {
                return <div className="divider" key={e.title}></div>;
              } else {
                return <NavLink key={e.title}
                  exact
                  to={e.url}
                  className="menu-item"
                  onClick={this.toggleDrawer}
                >
                  <i className={`fas ${e.icon} mr-3`}></i>{e.title}
                </NavLink>;
              }
            })
          }
        </div>

      </div>

      {/* <React.Fragment>
       <div>
         <div class="nav_dashboard">
          <NavLink to="/NewDashboard" className="menu-item">
           <span class="icon"></span> Dashboard
          </NavLink>
        </div>
        <div class="nav_orgdir">
         <NavLink to="/OrganizationDirectory" className="menu-item">
         <span class="icon"></span> Organization Directory
         </NavLink>
        </div>
       <div class="nav_regorg">
         <NavLink to="/RegisteredOrganization" className="menu-item">
         <span class="icon"></span> Registered Organizations
         </NavLink>
        </div>

        <div class="nav_orgprof">
         <NavLink to="/MyOrganizationProfile" className="menu-item ">
         <span class="icon"></span> My Organization Profile
         </NavLink>
        </div>

        <div class="nav_myendpoints">
         <NavLink to="/MyEndPoints" className="menu-item ">
         <span class="icon"></span> My Endpoints
         </NavLink>
        </div>
         <div class="nav_orgprof">
         <NavLink to="/UserLogin" className="menu-item ">
         <span class="icon"></span> Logout
         </NavLink>
        </div>

      </div>
   </React.Fragment>  */}

      <div className="content-veil"
        /* ref={ e => this.contentVeil = e }*/
        onClick={this.veilClickHandler}
      ></div>

    </nav>;
  }

}

export default Home;
