import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";

import { Link, useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';


import "bootstrap/dist/css/bootstrap.css";

import _ from 'lodash'
import axios from 'axios';

import TodoCard from "./TodoCard";

import { useForm } from "react-hook-form";
let SearchPayer = () => {

    let { name, label } = useParams();
    const [selectedValue, setSelectedValue] = useState([]);
    const history = useHistory();
    // handle onChange event of the dropdown
    const handleChange = (e) => {
        console.log("handleChange",e)
        setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const onSubmit = data => {
        var payerName = 'All'
        var localtion='All'
        console.log(data.organisation_name, "payerList-data")
        console.log(selectedValue, "payerList-data-label")
        console.log(selectedValue.length, "payerList-selectedValue Count")
        if (selectedValue.length>0) {
            console.log(selectedValue, "selectedValue")
            localtion=selectedValue;
        }
        else 
        {
            console.log(selectedValue, "ELSE selectedValue")
        }

        if (data.organisation_name != '') {
            console.log(payerName, "organisation_name")
            payerName=data.organisation_name;
        }
        else 
        {
            console.log(payerName, "ELSE organisation_name")
        }
        console.log(payerName, "=== payerName")
        console.log(localtion, "=== localtion")
        getPayerList(payerName, localtion)

    };

    const [userList, setUserList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [payerList, setPayerList] = useState([]);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const { SearchBar, ClearSearchButton } = Search;
    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;

    useEffect(() => {
        getPayers();
       getLocation();
        //  console.log(payerId,"payerid")
        //  getRequestePayerList()

    }, []);


    function createContract(destinatoin_payer_id) {

        var source_payer_id = sessionStorage.getItem('payerid');
        var user_id = sessionStorage.getItem('userid');

        var addArr = {
            'destinatoin_payer_id': destinatoin_payer_id,
            'source_payer_id': source_payer_id, 'user_id': user_id
        };
        console.log(addArr, "addArr")
        axios.post(
            process.env.REACT_APP_BASE_URL+'/api/PayersSignedStatus/create',
            addArr,
            //   { headers: { 'Content-Type': 'application/json' }}
        )
            .then(response => {
                setSuccessMessage("Successfully created a payer contact!")
                reset(response.data);
                getPayers();
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                console.log(response.data)
            })
            .catch(error => {
                setErrorMessage("Cannot created payer contact")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            })




    }
    function getPayers() {
        
        getPayerList('All','All')
        var payerId = sessionStorage.getItem('payerid')
        getAddMyList(payerId)

    }

    function getIdPayerList(value) {
        fetch(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`, {
            method: 'get'

        }).then((result) => {
            result.json().then((resp) => {

                console.log(resp, "padma")
                console.log(resp.data, "padma")
            })
        })
            .catch(error => {

                console.log(error.data)
            });

    }
    function getAddMyList(value) {
        console.log(value, "getAddMyList")
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getOrganisationAddMyList/${value}`).then(res => {
            setPayerList(res.data.data);
            console.log(res.data.data, "AAAAA");
        });
    }
    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }
    function contract(payer_id, payer_signed_status_id) {
        console.log("payerid", payer_id)
        console.log("payer_signed_status_id", payer_signed_status_id)

        console.log("session-payerid", sessionStorage.getItem('payerid'))
        if (payer_id == sessionStorage.getItem('payerid')) {
            // history.push(`/Contract/${payer_id}`);
            //  var data ='payerid'
            history.push({
                pathname: '/Contract',
                state: { payer_signed_status_id: payer_signed_status_id } // your data array of objects
            })



        }



        // fetch(process.env.REACT_APP_BASE_URL+`/api/getIdContractContentsdDetails/${payer_id}`, {
        //     method: 'get'

        // }).then((result) => {
        //     result.json().then((resp) => {

        //         console.log(resp, "padma")
        //         console.log(resp.id, "padma")
        //     })
        // })
        //     .catch(error => {

        //         console.log(error.data)
        //     });

    }
    function getPayerList(name, label) {
        console.log("getPayerList function involked ",name,label)
        var value = sessionStorage.getItem('payerid')
       
        axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/get/${name}/${label}/${value}`).then(res => {
            setUserList(res.data.data);
            console.log(res.data.data, "pppppppppp");


        });
    }
    //add mylist table
    const columnsList = [
        { dataField: 'name', text: 'Name' },
        { dataField: 'email', text: ' Email' },
        { dataField: 'payer_url', text: 'Payer Url' },
        {
            dataField: "payer_status",
            text: "Action",
            formatter: (cellContent, row, payerList) => {
                if (row.payer_status === 'Unsigned') {
                    return (
                        //  <Link to={`/Contract/${row.id}`}>
                        <button
                            className="btn btn-danger btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => contract(row.destinatoin_payer_id, row.payer_signed_status_id)}
                        >
                            Unsigned
                        </button>

                        //  </Link>

                    );
                }
                else if (row.payer_status === 'Signed') {
                    return (
                        // <button
                        //     className="btn btn-success btn-xs" Width="95px"
                        //     style={{ margin: "2px" }}
                        // >
                        //     Signed
                        // </button>

                        <div className="w-100 text-center mt-2">
                            
                            
                            {/* return ( */}
                            <Link to={`/ViewOrganization/${row.payer_id}`}>
                                <button
                                    className="btn btn-success btn-xs" Width="95px"
                                    style={{ margin: "2px" }}
                                    onClick={() => getIdPayerList(row.payer_id)}
                                >
                                    View
                                </button>

                            </Link>

                {/* ); */}
                            <Link to='/newPayer'>
                            {/* <button
                                className="btn btn-warning btn-sm" Width="95px" 
                                style={{ margin: "2px"}}
                              
                            >
                                Add App Details
                            </button>  */}

                            <button className="btn btn-warning btn-sm" Width="70px" 
                                style={{ marginRight: "2px"}}><i class="fa fa-plus-circle"></i> App</button>
                            {/* <Button variant="warning" style={{ minwidth: "5px"  }}> <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon> Add Payer Details</Button>  */}
                            </Link>
                           
                        </div>


                    );

                }
                else if (row.payer_status === 'Rejected') {
                    return (
                        <button
                            className="btn btn-danger btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                        >
                            Rejected
                        </button>


                    );

                }
                else if (row.payer_status === 'Signup') {
                    return (
                    <div className="w-100 text-center mt-2">
                       
                        <button
                            onClick={() => window.open(`http://localhost/devportal/login.php?id=${row.payer_signed_status_id}`, 'name', 'height=500,width=850')}
                            className="btn btn-primary btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                        >
                            Signup
                        </button>
                       

                        <Link to={`/ViewOrganization/${row.payer_id}`}>
                                <button
                                    className="btn btn-success btn-xs" Width="95px"
                                    style={{ margin: "2px" }}
                                    onClick={() => getIdPayerList(row.payer_id)}
                                >
                                    View
                                </button>

                            </Link>
                            </div>
                    );
                    
                }
            },
        },
        {
            dataField: "remove",
            text: "View",
            formatter: (cellContent, row) => {
                return (
                   <div>
                        {row.payer_status}
                   </div>
                            
                       
                );
            },
        },


    ]
    //serach table view
    const columns = [
        { dataField: 'value', text: 'Id' },
        { dataField: 'name', text: 'Name' },
        { dataField: 'email', text: 'Email' },
        { dataField: 'payer_url', text: 'Payer Url' },
        {
            dataField: "remove",
            text: "Add to My List",
            formatter: (cellContent, row) => {
                return (
                    // <Link to={`/create/${row.value}`}>
                    <button
                        className="btn btn-primary btn-xs" Width="95px"
                        style={{ margin: "2px" }}
                        onClick={() => createContract(row.value)}
                    >
                        Add MyList
                    </button>

                    // </Link>

                );
            },
        },
        {
            dataField: "remove",
            text: "Status",
            formatter: (cellContent, row) => {
                return (
                    <Link to={`/ViewOrganization/${row.value}`}>
                        <button
                            className="btn btn-success btn-xs" Width="95px"
                            style={{ margin: "2px" }}
                            onClick={() => getIdPayerList(row.value)}
                        >
                            View
                        </button>

                    </Link>

                );
            },
        },
    ]
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })




    const getColor = (status) => {
        if (status === 'Unsigned') return 'red';
        if (status === 'Signed') return 'green';
        return '';
    };
    return (
        <Container fluid="md">


            <Row>

                <Col md={6} style={{ textAlign: "left" }}>

                    <Card style={{ width: "100%", align: "center" }}>
                        <h3 className='text-center mb-3 heading'>My Payer List </h3>
                        <center><hr className='heading_line'></hr></center>

                        {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff"}}>My Payer List</Card.Header> */}
                        <Card.Body>
                            {/* <div className="w-100 text-center mt-2">
                                <Link to='/newPayer'><Button variant="warning" style={{ marginLeft: "70%" }}>
                                    <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon>  Add App  Details</Button> </Link>
                            </div> */}
                            <br></br>
                            <div className="container-fluid p-3">
                                <BootstrapTable bootstrap4 keyField='id'
                                    columns={columnsList}
                                    data={payerList}
                                    pagination={pagination}
                                    filter={filterFactory()}
                                />
                            </div>
                        </Card.Body>
                    </Card>

                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                    <Card style={{ width: "120%", align: "center" }} >
                        {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff", }}>Search  Payer </Card.Header>  */}
                        <h3 className='text-center mb-3 heading'>Search  Payer </h3>
                        <center><hr className='heading_line'></hr></center>
                        <br></br>

                        <div className="w-100 text-center mt-2">
                            <Link to='/newPayer'><Button variant="warning" style={{ marginLeft: "70%" }}> <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon> Add Payer</Button> </Link>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row md={12} style={{ width: "100%", }}>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="pcform.organisation_name">
                                        <Form.Label>Payer Name</Form.Label>
                                        <input className="form-control" type="text"
                                            {...register("organisation_name")}
                                        />
                                        {/* {errors.organisation_name && errors.organisation_name.type === "required" &&
                                        <p style={{ color: "red" }}>Organisation Name is required</p>} */}

                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3" controlId="pcform.organisation_type_id">
                                        <Form.Label>Location</Form.Label>
                                        <br />

                                        <Select
                                            className="dropdown"
                                            placeholder="Select Option"
                                            selectedValue
                                            label={locationList.filter(obj => selectedValue.includes(obj.label))}
                                            options={locationList}
                                            onChange={handleChange}
                                            isMulti
                                            isClearable

                                        />

                                        {errors.selectedValue && errors.selectedValue.type === "required" &&
                                            <h4 style={{ color: "red", marginLeft: "30px" }}>*</h4>}


                                        {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                            {/* <div><b>Selected Value: </b> {JSON.stringify( selectedValue, null, 2)}</div>   */}

                                        </div>}







                                    </Form.Group>
                                </Col>
                                <Col md={2}>

                                    <Form.Group className="mb-4" style={{ marginTop: '25px' }} controlId="pcform.submit">
                                        <input type="submit" className="form-control btn btn-primary"
                                            style={{ width: "100%" }} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </form>
                        <div className="container-fluid p-3">
                            <BootstrapTable hover keyField='id'
                                columns={columns}
                                data={userList}
                                pagination={pagination}
                                filter={filterFactory()}
                            />


                        </div> 

                    </Card >
                </Col>
            </Row>

            < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
            <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>


        </Container >
    );

}

export default SearchPayer;



// 