

import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Link, useHistory,useLocation  } from 'react-router-dom';

import './css/Form.css';


let AddOrganization = () => {


    const { register, handleSubmit, reset, trigger, formState: { errors } } = useForm();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    const [setpayerid] = useState("");
    const [usertypeList, setUserTypeList] = useState([]);
    const history = useHistory();
    const [locationList, setLocationList] = useState([]);
    const [resultedOrganization, setOrganization] = useState({
        payer_id: sessionStorage.getItem('payerid'),
        user_id: sessionStorage.getItem('userid')
      });

    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }

    const onSubmit = data => {
        console.log("data", data)
        axios
            .post(
                process.env.REACT_APP_BASE_URL+'/api/create/admin/Organizationdetails',
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a  orgnization details!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/PayerListDashboard') ;
                console.log(response.data)
            })
            .catch(error => {
                setErrorMessage("Cannot Created Orgnization Details")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };

    /*const onSubmit = data => {
        console.log("onSubmit",data)
        console.log("resultedpayerid ",resultedpayerid);
        console.log("userid ",sessionStorage.getItem('userid'));

        axios
            .put(
                process.env.REACT_APP_BASE_URL+`/api/organization/${resultedpayerid}`,
                data,
                //   { headers: { 'Content-Type': 'application/json' }}
            )
            .then(response => {
                setSuccessMessage("Successfully created a organization!")
                reset(response.data);
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                history.push('/Documentation');
                console.log(response.data, "data")
            })
            .catch(error => {
                setErrorMessage("Cannot created organization")
                reset(error.data);
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });
    };*/

    useEffect(() => {
        getUserTypesList();
        getLocation();
        getOrganizationDetails();
    }, []);

    function getOrganizationDetails() {
        console.log("getOrganizationDetails userid ", sessionStorage.getItem('userid'));

        axios.get(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByUserId/${sessionStorage.getItem('userid')}`,).then(res => {
            console.log("length", res.data.data.length)
            if (res.data.data.length != 0) {

                // setOrganization(res.data.data);
                setOrganization({ name: res.data.data[0].name, first_name: res.data.data[0].first_name, last_name: res.data.data[0].last_name, email: res.data.data[0].email, phone: res.data.data[0].phone, payer_id: res.data.data[0].payer_id, user_id: sessionStorage.getItem('userid') })
                //  setpayerid(res.data.data[0].payer_id);
                // sessionStorage.setItem('userid', res.data.data[0].payer_id);
                console.log(res.data.data, "getOrganizationDetails")
                console.log(res.data.data[0].payer_id, "getOrganizationDetails with payer_id")

            }
        });
    }

    function getUserTypesList() {
        axios.get(process.env.REACT_APP_BASE_URL+'/api/getUserOrganisationTypes').then(res => {
            setUserTypeList(res.data.data);
            console.log(res.data.data, "getUserOrganizationTypes")
        });
    }

    return (
        <Container fluid="md">
            <Row>

              <Col md={12} style={{ textAlign: "left" }}>
            <Card>
                {/* <Card.Header
                    as="h5"
                    style={{ backgroundColor: "Blue", color: "#ffffff" }}
                >
                    Fill The Form
                </Card.Header> */}

                <h3 className='text-center mb-3 heading'>Organization Details</h3>
                <center><hr className='heading_line'></hr></center>
                <br></br>
                {/* {
                resultedOrganization.map(result => {
                    return( */}
                {/* <Card.Body> */}
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Row>
                        <Col md={12} style={{ textAlign: "left"}}>

                                           <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                                    <Row>
                                    <Form.Control type="text" value={resultedOrganization.user_id} hidden={true}

{...register("user_id")}
/>
                                        <Col md={6} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

                                            <Form.Group
                                                controlId="pcform.organizationname"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Organization Name</Form.Label>
                                                <Form.Control type="text" placeholder="organization name"


                                                    {...register("name", { required: true })}
                                                />

                                                {errors.name && errors.name.type === "required"
                                                }

                                                {/* <div style={{ color: "red" }} id="error_organizationname"></div> */}
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="mb-1" style={{ textAlign: "left", marginTop: "1px" }}>

                                        <Form.Group className="mb-3" controlId="pcform.email">
                      <Form.Label>Email</Form.Label>
                      <input class="form-control"
                      {...register("email", {  pattern: /^\S+@\S+$/i })}

                         />
                      {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid payer Email</p>}

                    </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                             <Form.Group className="mb-3" controlId="pcform.type">
                                                <Form.Label>Organization Type  <strong style={{color:"red"}}>*</strong></Form.Label>
                                                <br />
                                                {/* <select className="form-control" name="payer"

                                                    {...register("type", {
                                                        required: true,
                                                        validate: (value) => value !== "Select Organisation Type Id"
                                                    })}
                                                >
                                                    <option>Select  Organisation Type</option>
                                                    {
                                                        usertypeList.map((result) => (<option id={result.id} value={result.id}> {result.organisation_type}</option>
                                                        ))
                                                    }
                                                </select> */}
                                                   <select className="form-control" name="payer"
                    //  value={payer_id}
                    //  onChange={PayerChange}
                    {...register("type", { required: true, validate: (value) => value !== "Select Organization" })}
                  >
                    <option>Select Organization</option>
                    {
                                                usertypeList.map((result) => (<option id={result.id} value={result.id}> {result.organisation_type}</option>
                                                ))
                                            }
                  </select>
                  {errors.type && <p style={{ color: "red" }}>Please select at least one option</p>}


                                            </Form.Group>

                                        </Col>
                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
{/*
                                            <Form.Group
                                                controlId="pcform.organizationwebsite"
                                                className="mb-3"
                                            >
                                                <Form.Label> <span style={{ color: "red" }}>*</span>Organization Website</Form.Label>
                                                <Form.Control type="url" placeholder="https://exapmle.com"

                                                   // {...register("website",  { required: true })}

                                                    {...register("website", {

                                                        required: true,
                                                        pattern: {
                                                            value: "https://.*",
                                                            message: "Invalid Url ",
                                                        },
                                                    })}
                                                />
                                                {errors.website && errors.website.type === "required" &&
                                                 <p style={{ color: "red" }}>Organization Website Required</p>
                                                }


                                            </Form.Group> */}
                                               <Form.Group className="mb-3" controlId="pcform.website">
                                <Form.Label>Organization Website<span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="text"
                                    {...register("website", {
                                        required: {
                                            value: true,
                                            message: "Organization Website Required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                          //  value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.website &&
                                    <p style={{ color: "red" }}>{errors.website.message}</p>}
                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.organizationlogo"
                                                className="mb-3"
                                            >
                                                <Form.Label>Organization Logo</Form.Label>
                                                <Form.Control type="text" placeholder="organization logo"

                                                    {...register("logo")}
                                                />
                                                {errors.logo && errors.logo.type === "required"
                                                }

                                            </Form.Group>
                                        </Col> */}



                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.address1"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Address 1</Form.Label>
                                                <Form.Control type="text" placeholder="Address 1"

                                                    {...register("address1", { required: true })}
                                                />
                                                {errors.address1 && errors.address1.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.address2"
                                                className="mb-3"
                                            >
                                                <Form.Label>Address 2</Form.Label>
                                                <Form.Control type="text" placeholder="Address 2"

                                                    {...register("address2")}
                                                />
                                                {errors.address && errors.address2.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.city"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>City</Form.Label>
                                                <Form.Control type="text" placeholder="City"

                                                    {...register("city", { required: true })}
                                                />
                                                {errors.city && errors.city.type === "required"
                                                }

                                            </Form.Group>
                                        </Col>

                                        <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group className="mb-3" controlId="pcform.organization_type_id">
                                                <Form.Label>State <strong style={{color:"red"}}>*</strong></Form.Label>
                                                <br />
                                                <select className="form-control" name="payer"

                                                    {...register("state", {
                                                        required: true,
                                                        validate: (value) => value !== "Select State"
                                                    })}
                                                >
                                                    <option>Select  State</option>
                                                    {
                                                        locationList.map((result) => (<option id={result.value} value={result.value}> {result.label}</option>
                                                        ))
                                                    }
                                                </select>

                                                {errors.state && <p style={{ color: "red" }}>Please select at least one option</p>}

                                            </Form.Group>
                                            {/* <Form.Group
                                                controlId="pcform.state"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>State</Form.Label>
                                                <Form.Control type="text" placeholder="State"

                                                    {...register("state", { required: true })}
                                                />
                                                {errors.state && errors.state.type === "required"
                                                }

                                            </Form.Group> */}
                                        </Col>

                                        {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group
                                                controlId="pcform.country"
                                                className="mb-3"
                                            >
                                                <Form.Label><span style={{ color: "red" }}>*</span>Country</Form.Label>
                                                <Form.Control type="text" placeholder="Country"

                                                    {...register("country", { required: true })}
                                                />
                                                {errors.country && errors.country.type === "required"
                                                }

                                            </Form.Group>
                                        </Col> */}
                                    </Row>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>


                                    <Form.Group className="mb-3" controlId="pcform.zip_code">
                                    <Form.Label ><span style={{ color: "red" }}>*</span>Zip Code </Form.Label>

                                    <input
                                        type="text"
                                        className={`form-control ${errors.zip_code && "invalid"}`}
                                        {...register("zip_code", { required: true ,

                                            pattern: {
                                                value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                                message: "Invalid zipcode ",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("zip_code");
                                        }}
                                    />
                                   {errors.zip_code && (
                                            < p style={{ color: "red" }}>{errors.zip_code.message}</p>
                                        )}
                                </Form.Group>

                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>

                                        <Form.Group className="mb-3" controlId="pcform.phone">
                                            <Form.Label>Phone</Form.Label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.phone && "invalid"}`}
                                                {...register("phone", {
                                                    //  required: "Phone is Required",
                                                    pattern: {
                                                        value: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                                        message: "Invalid phone number",
                                                    },
                                                })}
                                                onKeyUp={() => {
                                                    trigger("phone");
                                                }}
                                            />
                                            {errors.phone && (
                                                < p style={{ color: "red" }}>{errors.phone.message}</p>
                                            )}
                                        </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.fax"
                                            className="mb-3"
                                        >
                                            <Form.Label>Fax</Form.Label>
                                            <Form.Control type="text" placeholder="Fax"

                                                {...register("fax")}
                                            />


                                        </Form.Group>
                                    </Col>

                                    {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                        <Form.Group
                                            controlId="pcform.org_ein"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Orgnization EIN</Form.Label>
                                            <Form.Control type="text" placeholder="Orgnization EIN"

                                                {...register("ein", { required: true })}
                                            />
                                            {errors.ein && errors.ein.type === "required"
                                            }

                                        </Form.Group>
                                    </Col> */}


                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                    {/* <Form.Group
                                            controlId="pcform.policylink"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Privacy Policy Link</Form.Label>
                                            <Form.Control type="url"  placeholder="https://exapmle.com"

                                                {...register("policylink", { required: true })}
                                            />
                                            {errors.policylink && errors.policylink.type === "required" &&
                                            <p style={{ color: "red" }}> Privacy Policy Link Required</p>
                                        }
                                        </Form.Group> */}
                                          <Form.Group className="mb-3" controlId="pcform.policylink">
                                <Form.Label>Privacy Policy Link<span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="text"
                                    {...register("policylink", {
                                        required: {
                                            value: true,
                                            message: "Privacy Policy Link Required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                          //  value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.policylink &&
                                    <p style={{ color: "red" }}>{errors.policylink.message}</p>}
                            </Form.Group>
                                    </Col>

                                    <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                    {/* <Form.Group
                                            controlId="pcform.conditonlink"
                                            className="mb-3"
                                        >
                                            <Form.Label><span style={{ color: "red" }}>*</span>Terms Conditions Link</Form.Label>
                                            <Form.Control type="url"  placeholder="https://exapmle.com"

                                                {...register("conditionlink", { required: true })}
                                            />
                                            {errors.conditionlink && errors.conditionlink.type === "required" &&
                                            <p style={{ color: "red" }}>Terms Conditions Link Required</p>
                                            }

                                        </Form.Group> */}
                                        <Form.Group className="mb-3" controlId="pcform.conditionlink">
                                <Form.Label>Terms Conditions Link<span style={{ color: "red" }}>*</span></Form.Label>
                                <input className="form-control" type="text"
                                    {...register("conditionlink", {
                                        required: {
                                            value: true,
                                            message: "Terms Conditions Link Required"
                                        },
                                        pattern: {
                                            value: /^((ftp|http|https|www):\/\/)|(ftp|http|https|www)\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                          //  value: /^((ftp|http|https|www):\/\/)?www\.([A-Za-z0-9_@./#&+-]+)\.([ A-Za-z0-9_@./#&+-]{2,})/,
                                            message: "please enter a valid url"
                                        },
                                    })}
                                />
                                {errors.conditionlink &&
                                    <p style={{ color: "red" }}>{errors.conditionlink.message}</p>}
                            </Form.Group>
                                    </Col>

                                    {/* <Col md={6} className="mb-3" style={{ textAlign: "left", marginTop: "1px" }}>
                                            <Form.Group controlId="pcform.email" className="mb-1">
                                                <Form.Label>Payer Details</Form.Label>
                                                <Form.Control as="textarea" rows={3}
                                                {...register("payer_details")}
                                                  />

                                            </Form.Group>
                                    </Col>*/}



                        </Col>



                    </Row>
                    <br></br>
                    <Col md={12} style={{ textAlign: "center" }}>
                        <Button className="bsbt-btn" type="submit" variant="primary" >SUBMIT</Button>
                    </Col>

                </form>
                <br></br>

                {/* </Card.Body> */}
                {/* )

                })
              } */}
            </Card>
            </Col>
            </Row>
        </Container>


    )
}
export default AddOrganization;

