

import React, { Component, useState, useMemo, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { MDBInput } from "mdbreact";
import './css/custom.css'; //Import here your file style


const AdminUser = () => {

    //form validation rules
    const validationSchema = Yup.object().shape({

        password: Yup.string()
            .required('Password is required'),
        confirm_password: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match')

    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, reset, trigger, formState } = useForm();
    const { errors } = formState;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [usertypeList, setUserTypeList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const history = useHistory();

    function getLocation() {
        console.log(getLocation, "getLocation")
        axios.get(process.env.REACT_APP_BASE_URL+`/api/getStates`).then(res => {
            setLocationList(res.data.data);
            console.log(res.data.data, "getLocation");
        });
    }



    useEffect(() => {
        var user_id = sessionStorage.getItem('userid');
        var isadmin =sessionStorage.getItem('isadmin');
        console.log(user_id,isadmin,"user_idisadmin")

        if(user_id=='undefined' && isadmin =='undefined'){
            history.push('/userlogin');

        }
       // else if (user_id!='undefined'  && isadmin =='false'){
        else if ((user_id!='undefined'  && isadmin =='false') || isadmin != 'true'){
            history.push('/userlogin');
        }

        else{
            getLocation()

         //   console.log(user_id,"user_id")

        }


    }, []);
    const onSubmit = data => {
     //   console.log(data, "data")
        axios

            .post(
                process.env.REACT_APP_BASE_URL+'/api/user/create/AdminUser',
                data,
            )
            .then(response => {
                setSuccessMessage("Successfully created a  admin user!")
                reset(response.data);
                history.push('/managerDashboard');
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                console.log(response.data, "response")
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    var message = error.response.data.message
                    console.log(error.response.data.message,"error message")
                    setErrorMessage(message)
                    setTimeout(() => {
                              setErrorMessage()
                            }, 2000);
                  }
                console.log(error.data, "error")
            });
    };


    return (
        <Container fluid="md">
            <Row>

              <Col md={12} style={{ textAlign: "left" }}>
        <div class="signup_header">
            <div style={{ width: '80%',marginLeft:"9%" }}>
                <Card>
                    <Card.Body>
                    <h3 className='text-center mb-3 heading'>Register HC User</h3>
                        <center><hr className='heading_line'></hr></center>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <Row md={12} style={{ width: "100%", }}>
                                <Col md={6}>
                        <Form.Group className="mb-3 lable" controlId="pcform.password">
                                        <Form.Label>Username <strong style={{color:"red"}}>*</strong></Form.Label>
                                        <input className="form-control" type="text"
                                            {...register("username", { required: true})}
                                        />
                                        {/* {errors.password && errors.password.type === "required" && <p style={{ color: "red" }}>Password is required</p>} */}
                                    </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 lable" controlId="pcform.email">
                                        <Form.Label>Email  <strong style={{color:"red"}}>*</strong></Form.Label>
                                        <input className="form-control" type="text"
                                            {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })}
                                        />
                                     {/* {errors.email && errors.email.type === "required" &&
                                             <h4 style={{color:"red",marginRight:"90%"}}>*</h4>} */}
                                        {errors.email && errors.email.type === "pattern" && <p style={{ color: "red" }}>Invalid Email</p>}
                                    </Form.Group>
                                    </Col>
                                    </Row>

                                    <Row md={12} style={{ width: "100%", }}>
                                <Col md={6}>
                                    <Form.Group className="mb-3 lable" controlId="pcform.password">
                                    <Form.Label>Password  <strong style={{color:"red"}}>*</strong></Form.Label>
                                    <input className="form-control" type="password" placeholder="Abc@1234"
                                    {...register("password", {
                                        required: true,minLength:6,

                                        pattern: {
                                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
                                        }
                                    })}
                                />
                                {errors.password && errors.password.type === "required" &&
                                    <p style={{ color: "red" }}>Password Required</p>
                                }
                                  {errors.password && errors.password.type === "minLength" &&
                                 <p style={{color:"red"}}>Password must be at least 6 characters </p>}

                                {errors.password && errors.password.type === "pattern" && <p style={{ color: "red" }}>Invalid Password</p>}

                                    {/* <input className="form-control" type="password" placeholder="Abc@1234"
                                            {...register("password", { required: true,

                                                pattern:{
                                                    value: /(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!*^?](?=.*[\+\-\_\@\#\$\%\&])/,
                                                    message: "Invalid Password ",
                                                },

                                             })}
                                             onKeyUp={() => {
                                                trigger("password");
                                            }}
                                        />
                                         {errors.password && (
                                       < p style={{ color: "red" }}>{errors.password.message}</p>
                                   )} */}
                                    </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                <Form.Group className="mb-3 lable" controlId="pcform.phone">
                                    <Form.Label> Phone  <strong style={{color:"red"}}>*</strong></Form.Label>
                                                       <input
                                        type="text"
                                        className={`form-control ${errors.phone && "invalid"}`}
                                        {...register("phone", {required: true,

                                            pattern: {
                                                value: /(^\d{10}$)|(^\d{10}-\d{4}$)/,
                                                message: "Only 10 digits ",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("phone");
                                        }}
                                    />
                                   {errors.phone && (
                                            < p style={{ color: "red" }}>{errors.phone.message}</p>
                                        )}
                                </Form.Group>
                                </Col>
                                </Row>
                                <Row md={12} style={{ width: "100%", }}>
                                <Col md={6}>
                                <Form.Group className="mb-3 lable" controlId="pcform.address1">
                                        <Form.Label>Address  <strong style={{color:"red"}}>*</strong></Form.Label>
                                        <input className="form-control" type="text"
                                            {...register("address1", { required: true})}
                                        />
                                        {/* {errors.password && errors.password.type === "required" && <p style={{ color: "red" }}>Password is required</p>} */}
                                    </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                    <Form.Group className="mb-3 lable" controlId="pcform.city">
                                        <Form.Label>City  <strong style={{color:"red"}}>*</strong></Form.Label>
                                        <input className="form-control" type="text"
                                            {...register("city", { required: true })}
                                        />
                                        {/* {errors.password && errors.password.type === "required" && <p style={{ color: "red" }}>Password is required</p>} */}
                                    </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row md={12} style={{ width: "100%", }}>
                                <Col md={6}>
                                <Form.Group className="mb-3 lable" controlId="pcform.organization_type_id">
                                        <Form.Label>State <strong style={{color:"red"}}>*</strong></Form.Label>
                                        <br />
                                        <select className="form-control" name="payer"

                                            {...register("value", {
                                                required: true,
                                                validate: (value) => value !== "Select State"
                                            })}
                                        >
                                            <option>Select  State</option>
                                            {
                                                locationList.map((result) => (<option id={result.value} value={result.value}> {result.label}</option>
                                                ))
                                            }
                                        </select>
                                        {/* {errors.organisation_type_id && <p style={{ color: "red" }}>Please select at least one option</p>} */}



                                    </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                    <Form.Group className="mb-3 lable" controlId="pcform.postal_code">
                                                <Form.Label> <strong style={{ color: "red" }}>*</strong>Zip Code </Form.Label>

                                                <input
                                        type="text"
                                        className={`form-control ${errors.zip_code && "invalid"}`}
                                        {...register("zip_code", {

                                            pattern: {
                                                value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                                                message: "Invalid zipcode ",
                                            },
                                        })}
                                        onKeyUp={() => {
                                            trigger("zip_code");
                                        }}
                                    />
                                   {errors.zip_code && (
                                            < p style={{ color: "red" }}>{errors.zip_code.message}</p>
                                        )}
                                            </Form.Group>
                                    </Col>
                                    </Row>



                            <Form.Group className="mb-3 " controlId="pcform.submit">
                                <input type="submit" className="form-control btn btn-primary"
                                    style={{ width: "20%", float: "right", marginTop: "2%",
                                    backgroundColor: "#687080", borderBlockColor: "#687080",
                                    fontWeight: "bold", borderRadius: "20px", marginRight: "5%" }} />
                            </Form.Group>
                        </form>
                        <strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                        <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                    </Card.Body>


                </Card>
                {/* <div className="w-100 text-center mt-3">
                    <strong>  Already have an account? <Link to='/userlogin'>Log In</Link></strong>
                </div> */}
                {/* </Container> */}
            </div>
        </div>
        </Col>
                                                    </Row>
                                                    </Container>
    )
}

export default AdminUser




// import React, { useState, useEffect } from "react";
// import axios from "axios";
//  import { useForm } from "react-hook-form";
//  import { Form, Button, Container, custom, Card, Row, Col, Alert } from 'react-bootstrap';

// let  AdminUser =()=> {
//   const [selectedFile, setSelectedFile] = useState();
//   const [loadimage, setLoadImage] = useState([]);
//   const [name, setName] = useState("");
//   const [desc, setDesc] = useState("");
//   const { register, handleSubmit, reset, trigger, formState } = useForm();


//  // const handleSubmission = async (e) => {
//     const onSubmit =   (data) => {
//           console.log(data, "data")
//      data = new FormData();
//     data.append("file", selectedFile);
//   data.append("name", name);
//      fetch(process.env.REACT_APP_BASE_URL+"/api/createOrgnizationdetails", {
//       method: "POST",
//       body: data,
//     })
//     .then((response)=>{
//         console.log(response.data, "createOrgnizationdetails")
//     })
//     .catch(()=>{
//       alert('Error in the Code');
//     });
//   };


//   return (
//     <div className="container">
//       <h4 class="text-center text-success  ml-4 mb-4 mt-4">Image Gallary in ReactJS</h4>
//       <div className="row">

//         <div className="col-sm-3 p-2 bg-gray">
//         <form onSubmit={handleSubmit(onSubmit)}>

//          <div className="box mr-4" style={{border:"1px solid #b7b7b7",backgroundColor:"#rgb(253 253 253);"}}>
//          <h5 class="text-center  ml-4 mb-3 mt-3">Add Image</h5>

//           {/* <table className="">
//            <tr>
//             <td> */}
//             <Form.Group className="mb-3" controlId="pcform.password">
//                                          <Form.Label>Username <strong style={{color:"red"}}>*</strong></Form.Label>
//                                          <input className="form-control" type="text" name ="name"
//                                             // {...register("name", { required: true})}
//                                            onChange={(e) => setName(e.target.value)}
//                                         />

//                                     </Form.Group>
//               {/* <div class="form-group ml-3">
//                 <input type="text" name="name" className="mb-4"
//               //  onChange={(e) => setName(e.target.value)}
//                 {...register("name", { required: true })}
//                  placeholder="Country Name"/>
//               </div> */}
//             {/* </td>
//            </tr>

//            <tr>
//             <td> */}
//              {/* <div class="form-group">
//               <textarea type="text" name="desc" className="mb-4"  rows="3" cols="23" onChange={(e) => setDesc(e.target.value)} placeholder="Write Description" />
//              </div> */}
//             {/* </td>
//            </tr>

//            <tr>
//             <td> */}
//              <div class="form-group">
//               <input type="file" name="file" className="mb-4"
//             //    {...register("files", { required: true, maxLength: 60 })}/>
//            onChange={(e) => setSelectedFile(e.target.files[0])} />
//              </div>
//             {/* </td>
//            </tr>

//            <tr>
//             <td> */}
//              <div class="form-group">
//                <button type="submit" class="btn btn-success mb-3" name="submit">
//                  Add Gallary
//               </button>
//              </div>
//             {/* </td>
//            </tr>
//           </table> */}

//           </div>
//           </form>
//         </div>


//       </div>
//     </div>
//   );
// }
// export default AdminUser;



