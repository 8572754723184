import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { async } from '@firebase/util';
import { Redirect } from "react-router-dom";
import Img from './healthchain-logo.png'
import { set, useForm } from "react-hook-form";
import axios from 'axios';
export const BASE_URL = process.env.REACT_APP_BASE_URL;


const UserLogin = () => {

  return (

    <div style={{ width: '40%', marginLeft: '22%' }}>
      <Card>
        <Card.Body>
          <center>
            <img src={Img} alt="pic" width={150} /></center>
          <hr></hr>
          {/* <div><h4 className='text-center mb-4 '><strong> {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong></h4></div> */}
          <br/><br/><br/><br/>
          <Link to={'/userlogin'}>
          <p className='text-center mb-4 '><strong>Please login/register to the application</strong></p>
          {/* <h2 className='text-center mb-4 '>Log In</h2> */}
          </Link>
        </Card.Body>
      </Card>
      {/* <div className="w-100 text-center mt-3">
        <strong>Want to register with us? <Link to='/signup'>Sign Up</Link></strong>
      </div> */}
    </div>

  )



}

export default UserLogin;








