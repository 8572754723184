import React, { Component, useState, useEffect } from "react";
// import './css/Form.css'; //Import here your file style
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";

const btn = {
  width: "20%",
  textAlign: "center",
  marginLeft: "40%",
};

const col = {
  margin: "2px",
};

const Organization = () => {
  
  const [OrgState, setOrgState] = useState({
	  'organizationname': null,
	  'error_organizationname':null, 
      'organizationemail':null, 
	  'error_organizationemail':null	  
	  });

	  
	const UpdateOrgValues = (event) => {
			event.preventDefault();
			const { name, value } = event.target;
		    /*setOrgState({
				'organizationname': 'ppp',
			});*/  

            console.log('name===',name)
			switch (name) {
			case 'organizationname': 
			  var err_organizationname = 
				(value.length < 3 || value.length > 15)
				  ? 'Full Name must be 5 characters long!'
				  : '';
			   	setOrgState({
				'error_organizationname': err_organizationname,
				}); 
			  break;
			case 'organizationemail': 
			  const emailRegex = /\S+@\S+\.\S+/;
			  var err_organizationemail = 
				(emailRegex.test(value))
				  ? ''
				  : 'Email is still not valid.';
			   	setOrgState({
				'error_organizationemail': err_organizationemail,
				}); 
			  break;  
			/*setOrgState({myerrors, [name]: value}, ()=> {
				//	console.log(errors)
			})*/
		}
	
    }
    // var url = process.env.REACT_APP_BASE_URL+'/api/organization'
    // const [data, setData] = useState({
    //   name:"", email:"", phone:"", address1:"", address2:"",
    //    city:"", state:"", zip_code:"", payer_details:"" 
      
    // })
    // function submit(e) {
    //     e.preventDefault();
    //     axios.post(url, {
    //       name:data.name, email:data.email, phone:data.phone, address1:data.address1, address2:data.address2,
    //       city:data.city, state:data.state, zip_code:data.zip_code, payer_details:data.payer_details
        
          
    //     })
    //         .then(res => {
    //             console.log(res.data)
    //         })

    // }
    // function handle(e) {
    //     const newData = { ...data }
    //     newData[e.target.id] = e.target.value
    //     setData(newData)
    //     console.log(newData)

    // }

    return (
      
      <Container fluid="md">
        <Card border="primary">
          <Card.Header
            as="h5"
            style={{ backgroundColor: "#000000", color: "#ffffff" }}
          >
            Organization Details
          </Card.Header>
          <Card.Body>
        
            <Form id="pcform" >
			   <Form.Control type="hidden" value="0" id="flag" name="flag" />
              <Row>
                <Col md={12}   className="mb-1" style={{ textAlign: "left", marginTop:"1px" }}>
                  <Form.Group
                    controlId="pcform.organizationname"
                    className="mb-1"
                  >
                    <Form.Label>Organization Name</Form.Label>
                    <Form.Control type="text" placeholder="organization name"  
                  
					name="organizationname" value={OrgState.organizationname} onChange={UpdateOrgValues} />
					<div style={{color:"red"}} id="error_organizationname">{OrgState.error_organizationname}</div>
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-1" style={{ textAlign: "left" , marginTop:"1px" }}>
                  <Form.Group controlId="pcform.email" className="mb-1">
                    <Form.Label>Organization Email</Form.Label>
                    <Form.Control  type="text" placeholder="organization email"  name="organizationemail" 
                   
					value={OrgState.organizationemail}  onChange={UpdateOrgValues}    />
					<div style={{color:"red"}} id="error_organizationemail">{OrgState.error_organizationemail}</div>
                  </Form.Group>
                </Col>
                <Col md={6}  className="mb-1" style={{ textAlign: "left",  marginTop:"1px" }}>
                  <Form.Group
                    controlId="pcform.organizationphone"
                    className="mb-1"
                  >
                    <Form.Label>Organization Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="organization phone"   
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-1" style={{ textAlign: "left" }}>
                  <Form.Group
                    controlId="pcform.organizationaddr1"
                    className="mb-1"
                  >
                    <Form.Label>Organization Address Line-1</Form.Label>
                    <Form.Control
                      type="text" 
                      placeholder="organization address line-1"
                    
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-1" style={{ textAlign: "left" }}>
                  <Form.Group
                    controlId="pcform.organizationaddr2"
                    className="mb-1"
                  >
                    <Form.Label>Organization Address Line-2</Form.Label>
                    <Form.Control
                      type="text" 
                      placeholder="organization address line-2"
                    />
                  </Form.Group>
                </Col>

                <Col md={6} className="mb-1" style={{ textAlign: "left" }}>
                  <Form.Group
                    controlId="pcform.organizationcity"
                    className="mb-1"
                  >
                    <Form.Label>City</Form.Label>
                    <Form.Control type="text" placeholder="city"   />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-1" style={{ textAlign: "left" }}>
                  <Form.Group
                    controlId="pcform.organizationstate"
                    className="mb-1"
                  >
                    <Form.Label>State</Form.Label>
                    <Form.Control type="text" placeholder="state"  />
                  </Form.Group>
                </Col>

                <Col md={6} style={{ textAlign: "left" }}>
                  <Form.Group controlId="pcform.postalCode" className="mb-1">
                    <Form.Label>Postal Code</Form.Label>
                    <Form.Control type="text" placeholder="postalCode"  />
                  </Form.Group>
                </Col>
                <Col md={6} style={{ textAlign: "left" }}>
                  <Form.Group controlId="pcform.payerdetails" className="mb-1">
                    <Form.Label>Payer Details</Form.Label>
                    <Form.Control as="textarea" rows={3}  />
                  </Form.Group>
                </Col>
				<Col md={12}>
					{OrgState.errors}
				</Col>
              </Row>
            
            </Form>
          
          </Card.Body>
        </Card>
      </Container>
    );
  }
  
  
export default Organization;












