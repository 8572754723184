


// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { Table, Button } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import ReactPaginate from 'react-paginate';
// import './App.css'
// import { Container, Row, Col, Form, Card, InputGroup } from "react-bootstrap";
// import { faCoffee, faSearch, faPlus, faEye, faPlusCircle, faInfo } from '@fortawesome/free-solid-svg-icons'

// export default function PayerContactForm() {
//     const [APIData, setAPIData] = useState([]);
//     const [postsPerPage] = useState(5);
//     const [offset, setOffset] = useState(1);
//     const [posts, setAllPosts] = useState([]);
//     const [pageCount, setPageCount] = useState(0)

//     // useEffect(() => {

//     //     getData();
//     // }, []);

//     const [filteredResults, setFilteredResults] = useState([]);
//     const [search, setSearch] = useState("");
//     const setData = (data) => {
//         let { id,name,email,subject,message } = data;
//         localStorage.setItem('ID', id);
//         localStorage.setItem('Name', name);
//         localStorage.setItem('Email', email);
//         localStorage.setItem('Subject', subject);
//         localStorage.setItem('Message', message)
//     }

//     const getAllPosts = async () => {
//         const res = await axios.get(process.env.REACT_APP_BASE_URL+`/api/GetAllPayercontact`)
//         const data = res.data.data;
//         const slice = data.slice(offset - 1 , offset - 1 + postsPerPage)

//         // For displaying Data
//         const postData = setAPIData(slice)

//         // Using Hooks to set value
//         setAllPosts(postData)
//         setPageCount(Math.ceil(data.length / postsPerPage))
//       }

//       const handlePageClick = (event) => {
//         const selectedPage = event.selected;
//         setOffset(selectedPage + 1)
//       };

//       useEffect(() => {
//         getAllPosts()
//       }, [offset])
//     // const getData = () => {
//     //     axios.get(process.env.REACT_APP_BASE_URL+`/api/GetAllPayercontact`)
//     //         .then((getData) => {
//     //             setAPIData(getData.data.data,"padma");
//     //         })
//     // }

//     const onDelete = (id) => {
//         axios.delete(process.env.REACT_APP_BASE_URL+`/api/payercontact/${id}`)
//         .then(() => {
//            // getData();
//            getAllPosts()
//         })
//     }



//     return (
//         <div>
//              <h4 style={{ textAlign: "center" }}>Search Payer </h4>
//              {/* <Form.Group className="mb-3">
//                <Form.Label>Name Filter By Name</Form.Label>
//                   <input class="form-control"
//                      placeholder="Enter Name"
//                     onChange={(e) => {
//                       setSearch(e.target.value);
//                     }}


//                   />
//                 </Form.Group> */}

//             <Table >
//                 <Table.Header>
//                     <Table.Row>
//                     <Table.HeaderCell>Id</Table.HeaderCell>
//                         <Table.HeaderCell>Name</Table.HeaderCell>
//                         <Table.HeaderCell>Email</Table.HeaderCell>
//                         <Table.HeaderCell>Subject</Table.HeaderCell>
//                         <Table.HeaderCell>Message</Table.HeaderCell>
//                         <Table.HeaderCell>Action</Table.HeaderCell>
//                         <Table.HeaderCell>Action</Table.HeaderCell>

//                     </Table.Row>
//                 </Table.Header>

//                 <Table.Body>
//                {APIData 
//             //    .filter((val) => {
//             //                     if (search === "") {
//             //                       return val
//             //                     }
//             //                     else if (
//             //                     //   val.last_name&&val.first_name.toLowerCase().includes(search.toLocaleLowerCase()) ||
//             //                     //   val.last_name.toLowerCase().includes(search.toLocaleLowerCase()) ||
//             //                       val.name.toLowerCase().includes(search.toLocaleLowerCase()) 
//             //                      // val.phone.toLowerCase().includes(search.toLocaleLowerCase())
//             //                     ) {
//             //                       return val

//             //                     }

//             //                   })
//                     .map((data) => {

//                         return (
//                             <Table.Row>
//                                  <Table.Cell>{data.id}</Table.Cell>
//                                 <Table.Cell>{data.name}</Table.Cell>
//                                 <Table.Cell>{data.email}</Table.Cell>
//                                 <Table.Cell>{data.subject}</Table.Cell>
//                                 <Table.Cell>{data.message}</Table.Cell>
//                                 <Table.Cell> 
//                                 <Link to={`/updatePayer/${data.id}`}>

//                                         <Button   className="btn btn-primary btn-xs"  size="sm" onClick={() => setData(data)}>Update</Button>

//                                 </Link>
//                                 </Table.Cell>
//                                 <Table.Cell>
//                                     <Button   className="btn btn-danger btn-xs" size="sm" onClick={() => onDelete(data.id)}>Delete</Button>
//                                 </Table.Cell>
//                             </Table.Row>

//                         )

//                     })
//                    }
//                 </Table.Body>

//             </Table>


//                                                 <ReactPaginate

//               previousLabel={"previous"}
//               nextLabel={"next"}
//               breakLabel={"..."}
//               breakClassName={"break-me"}
//               pageCount={pageCount}
//               onPageChange={handlePageClick}
//               containerClassName={"pagination"}
//               subContainerClassName={"pages pagination"}
//               activeClassName={"active"} 

//            />




//         </div>
//     )
// }



import React, { Component, useState, useMemo, useEffect } from 'react';

import axios from 'axios';
import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";

import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle, faInfo } from '@fortawesome/free-solid-svg-icons'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import MaterialTable from "material-table";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ReactPaginate from 'react-paginate';
import './App.css'
import Pagination from "react-js-pagination";

const btn = {
    "width": "20%",
    "textAlign": "center",
    "marginLeft": "40%",
}

const col = {
    "margin": "6px"
};

const sheader = {
    "backgroundColor": "blue",
    "color": "#ffffff"
}

const sheader_inner1 = {
    "backgroundColor": "gray",
    "color": "white"
}

const PayerContactForm = () => {

    const history = useHistory();
    const [userList, setUserList] = useState([]);

    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");
    const [APIData, setAPIData] = useState([]);
    const [total,setTotal] = useState("");
    const [page,setPage] = useState(1);
    const [postsPerPage, setPostPerPage] = useState("5");
    const indexOfLastPage = page + postsPerPage;
    const indexOfFirstPage = indexOfLastPage-postsPerPage;
    const currentPosts = userList.slice(indexOfFirstPage,indexOfLastPage)

 


    const { SearchBar, ClearSearchButton } = Search;
    useEffect(() => {
        getPayerList();
    }, []);

 

    const setData = (data) => {
        let { id, name, email, subject, message } = data;
        localStorage.setItem('ID', id);
        localStorage.setItem('Name', name);
        localStorage.setItem('Email', email);
        localStorage.setItem('Subject', subject);
        localStorage.setItem('Message', message)
    }

    function getPayerList() {
        axios.get(process.env.REACT_APP_BASE_URL+'/api/GetAllPayercontact').then(res => {
            setUserList(res.data.data);
            setTotal(res.data.data.length)
            console.log(res.data.data.length,"psdrftghjd")
        });
    }
    const [subject, setSubject] = useState([])
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [id,setId]=useState(null)
  

    const columns = [
        { dataField: 'name', text: 'Name' },

        { dataField: 'email', text: 'Email' },
        { dataField: 'subject', text: 'Subject' },
        { dataField: 'message', text: 'Message' },
        {
            dataField: "remove",
            text: "Delete",
            formatter: (cellContent, row) => {
                return (
                    // <button
                    //     className="btn btn-danger btn-xs"
                    //     onClick={() => deletePayer(row.id, row.name)}
                    //     icon="fa-fa-user"
                    // >
                    //     Delete

                    // </button>
                    <IconButton
                        aria-label="delete"
                        onClick={() => deletePayer(row.id, row.name)}
                    >
                        <DeleteIcon />
                    </IconButton>




                );
            },

        }
        // {
        //     dataField: "edit",
        //     text: "Edit",
        //     formatter: (cellContent,user, row) => {
        //         return (
        //             // <Link to={`/updatePayer/${row.id}`}>
        //         //     <IconButton
        //         //     aria-label="edit"
        //         //     onClick={() => updatePayer(row.id)}

        //         //   >
        //         //     <EditIcon />
        //         //   </IconButton>
        //         //   </Link>




        //         );
        //     },

        // },


    ]
    function deletePayer(id) {
        fetch(process.env.REACT_APP_BASE_URL+`/api/payercontact/${id}`, {
            method: 'delete'

        }).then((result) => {
            result.json().then((resp) => {
                setSuccessMessage("Successfully deleted a payer contact!")
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000);
                console.log(resp)
                getPayerList()

            })
        })
            .catch(error => {
                setErrorMessage("Cannot deleted payer contact")
                setTimeout(() => {
                    setErrorMessage()
                }, 2000);
                console.log(error.data)
            });

    }
 
 
    // function updatePayer(id) {
    //     console.log(id,"padma")
    //    history.push(`/updatePayer/${id}`)

    //   let item={name,subject,message,email}
    //   console.log("item",item)
    //   fetch(process.env.REACT_APP_BASE_URL+`/api/GetAllPayercontact/${id}`, {
    //     method: 'PUT',
    //     // headers:{
    //     //   'Accept':'application/json',
    //     //   'Content-Type':'application/json'
    //     // },
    //     body:JSON.stringify(item)
    //   }).then((result) => {
    //     result.json().then((resp) => {
    //       console.log(resp)
    //      // getPayerList()
    //     })
    //   })
     
//   }
//     updatePayer()
    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true,
        onPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        },
        onSizePerPageChange: function (page, sizePerPage) {
            console.log('page', page)
            console.log('sizePerPage', sizePerPage)
        }
    })

    return (

        <Container fluid="sm">
            <a></a><br /><br />


            <Row  >

                {/* <Col md={6} style={{ textAlign: "left" }}>
                    <Card.Header as="h5" style={{ backgroundColor: "black", textAlign: "center", color: "#ffffff", "margin-right": "70px" }}>Payer List</Card.Header>
                    {userList.map(user => {
                        const { name, email, subject, message } = user;
                        return (
                            <div >
                                <hr style={{ "margin-right": "70px" }} />
                                <p style={{ color: "#2E71FF" }}>{name}.</p>
                                <p>Email: {email}</p>
                                <p>Subject:{subject} </p>
                                <p>Message:{message}</p>
                            </div>

                        )

                    })
                 

                    }
                
                </Col> */}
                

                <Col md={12} >
                    <Card >

                        <Card.Body>

                            <div className="container-fluid p-3">

                             
                                <h3 className='text-center mb-3 heading' >Search Payer</h3>
                        <center><hr className='heading_line2'></hr></center>
                                <ToolkitProvider
                                    bootstrap4
                                    keyField='id'
                                    data={userList}
                                    columns={columns}
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <SearchBar {...props.searchProps} style={{ "marginLeft": "280px" }} />
                                                <a></a><br /><br />
                                                <BootstrapTable
                                                    pagination={pagination}
                                                    {...props.baseProps}
                                                />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>


                            </div>

                        </Card.Body>



                    </Card>

                    < strong > {successMessage && <div className="d-flex justify-content-center error" style={{ color: "green" }} > {successMessage}</div>} </strong>
                    <strong> {errorMessage && <div className="d-flex justify-content-center error" style={{ color: "red" }} > {errorMessage}</div>} </strong>

                </Col>
            </Row>
        </Container >

    );
}

export default PayerContactForm;

//

