import { Container, Row, Col, Form, Button, Card, InputGroup } from "react-bootstrap";
import './css/pages.css'; //Import here your file style
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faSearch, faPlus, faEye, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { Component, useState, useEffect } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CircularProgress } from "@material-ui/core";
import BootstrapTable from "react-bootstrap-table-next";
import Select from 'react-select';
import Table from 'react-bootstrap/Table'
import "bootstrap/dist/css/bootstrap.css";

import _ from 'lodash'
import axios from 'axios';
import { useForm } from "react-hook-form";

let SearchPayer = () => {


    // const [selectedValue, setSelectedValue] = useState([]);  
    const handleChange = (e) => {
     console.log("handleChange",e)
     setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
 }
 const handletypeChange = (e) => {
     console.log("handletypeChange",e)
     setSelectedtypeValue(Array.isArray(e) ? e.map(x => x.value) : []);
 }
 
  
   const [payerList, setPayerList] = useState([]);
   const [selectedValue, setSelectedValue] = useState([]);
   const [selectedtypeValue, setSelectedtypeValue] = useState([]);
     const [errorMessage, setErrorMessage] = React.useState("");
     const [successMessage, setSuccessMessage] = React.useState("");
     const { SearchBar, ClearSearchButton } = Search;
     const { register, handleSubmit, reset, trigger, formState } = useForm();
     const { errors } = formState;
 
    //  useEffect(() => {
    //      var payerId = sessionStorage.getItem('payerid')
     //  getAddMyList(payerId)
      
    //  }, []);

     const history = useHistory();

 
     useEffect(() => {
        var payerId = sessionStorage.getItem('payerid')
         var user_id = sessionStorage.getItem('userid');
         var isadmin =sessionStorage.getItem('isadmin');
         console.log(user_id,isadmin,"user_idisadmin")
         
         if(user_id=='undefined'){
             history.push('/userlogin');
 
         }
         else if (user_id!='undefined'  && isadmin !='false'){
             history.push('/userlogin'); 
         }
        
         else{
            getAddMyList(payerId)
            var elements = document.querySelectorAll('.react-bs-table-sizePerPage-dropdown');

            if (elements.length) {
                elements[0].id = 'dt_dropdown';
            }

        
          //   console.log(user_id,"user_id")
 
         }
      
 
     }, []);
 
     function getAddMyList(value) {
         console.log(value, "getAddMyList")
         axios.get(process.env.REACT_APP_BASE_URL+`/api/getOrganisationAddMyList/${value}`).then(res => {
             setPayerList(res.data.data);
             console.log(res.data.data, "AAAAA");
         });
     }
     //add mylist table
     const columnsList = [
         { dataField: 'name', text: 'Name' },
         { dataField: 'email', text: ' Email' },
         { dataField: 'organisation_type', text: 'Organization Type' },
         {
             dataField: "payer_status",
             text: "Action",
             formatter: (cellContent, row, payerList) => {
                 if (row.payer_status === 'Signed') {
                     return (
                         <div className="w-100 text-center mt-2">
                             <Link to={`/ViewOrganization/${row.payer_id}`}>
                                 <button
                                     className="btn btn-success btn-xs" Width="95px"
                                     style={{ margin: "2px",marginRight:"110px" }}
                                     onClick={() => getIdPayerList(row.payer_id)}
                                 >
                                     View
                                 </button>
 
                             </Link>
                             {/* <Link to='/newPayer'>
                             <button className="btn btn-warning btn-sm" Width="70px" 
                                 style={{ marginRight: "2px"}}><i class="fa fa-plus-circle"></i> App Details</button>
                             </Link> */}
                            
                         </div>
 
 
                     );
 
                 }
                 // else if (row.payer_status === 'Rejected') {
                 //     return (
                 //         <button
                 //             className="btn btn-danger btn-xs" Width="95px"
                 //             style={{ margin: "2px" }}
                 //         >
                 //             Rejected
                 //         </button>
 
 
                 //     );
 
                 // }
                 // else if (row.payer_status === 'Signup') {
                 //     return (
                 //     <div className="w-100 text-center mt-2">
                        
                 //         <button
                 //             onClick={() => window.open(`http://localhost/devportal/login.php?id=${row.payer_signed_status_id}`, 'name', 'height=500,width=850')}
                 //             className="btn btn-primary btn-xs" Width="95px"
                 //             style={{ margin: "2px" }}
                 //         >
                 //             Signup
                 //         </button>
                        
 
                 //         <Link to={`/ViewOrganization/${row.payer_id}`}>
                 //                 <button
                 //                     className="btn btn-success btn-xs" Width="95px"
                 //                     style={{ margin: "2px" }}
                 //                     onClick={() => getIdPayerList(row.payer_id)}
                 //                 >
                 //                     View
                 //                 </button>
 
                 //             </Link>
                 //             </div>
                 //     );
                     
                 // }
             },
         },
         {
             dataField: "remove",
             text: "Status",
             formatter: (cellContent, row) => {
                 return (
                    <div>
                         {row.payer_status}
                    </div>
                             
                        
                 );
             },
         },
 
 
     ]
     
     
     function getIdPayerList(value) {
         fetch(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`, {
             method: 'get'
 
         }).then((result) => {
             result.json().then((resp) => {
 
                 console.log(resp, "padma")
                 console.log(resp.data, "padma")
             })
         })
             .catch(error => {
 
                 console.log(error.data)
             });
 
     }
    
     
     const pagination = paginationFactory({
         page: 1,
         sizePerPage: 5,
         lastPageText: '>>',
         firstPageText: '<<',
         nextPageText: '>',
         prePageText: '<',
         showTotal: true,
         alwaysShowAllBtns: true,
         onPageChange: function (page, sizePerPage) {
             console.log('page', page)
             console.log('sizePerPage', sizePerPage)
         },
         onSizePerPageChange: function (page, sizePerPage) {
             console.log('page', page)
             console.log('sizePerPage', sizePerPage)
         }
     })
 
     return (
         <Container fluid="md">
 
 
             <Row>
               
                 <Col md={12} style={{ textAlign: "left" }}>
                 <Card style={{ width: "100%", align: "center" }}>
                         <h3 className='text-center mb-3 heading'>My Payer List </h3>
                         <center><hr className='heading_line'></hr></center>
 
                         {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff"}}>My Payer List</Card.Header> */}
                         <Card.Body>
                             {/* <div className="w-100 text-center mt-2">
                                 <Link to='/newPayer'><Button variant="warning" style={{ marginLeft: "70%" }}>
                                     <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon>  Add App  Details</Button> </Link>
                             </div> */}
                             <br></br>
                             <div className="container-fluid p-3">
                                 <BootstrapTable bootstrap4 keyField='id'
                                     columns={columnsList}
                                     data={payerList}
                                     pagination={pagination}
                                     filter={filterFactory()}
                                 />
                             </div>
                         </Card.Body>
                     </Card>
                 </Col>
             </Row>
 
         </Container >
     );
 
 }
 
 export default SearchPayer;

// let SearchPayer = () => {


//    // const [selectedValue, setSelectedValue] = useState([]);  
//    const handleChange = (e) => {
//     console.log("handleChange",e)
//     setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
// }
// const handletypeChange = (e) => {
//     console.log("handletypeChange",e)
//     setSelectedtypeValue(Array.isArray(e) ? e.map(x => x.value) : []);
// }

 
//   const [payerList, setPayerList] = useState([]);
//   const [selectedValue, setSelectedValue] = useState([]);
//   const [selectedtypeValue, setSelectedtypeValue] = useState([]);
//     const [errorMessage, setErrorMessage] = React.useState("");
//     const [successMessage, setSuccessMessage] = React.useState("");
//     const { SearchBar, ClearSearchButton } = Search;
//     const { register, handleSubmit, reset, trigger, formState } = useForm();
//     const { errors } = formState;

//     useEffect(() => {
//         var payerId = sessionStorage.getItem('payerid')
//         getAddMyList(payerId)
     
//     }, []);

//     function getAddMyList(value) {
//         console.log(value, "getAddMyList")
//         axios.get(process.env.REACT_APP_BASE_URL+`/api/getOrganisationAddMyList/${value}`).then(res => {
//             setPayerList(res.data.data);
//             console.log(res.data.data, "AAAAA");
//         });
//     }
//     //add mylist table
//     const columnsList = [
//         { dataField: 'name', text: 'Name' },
//         { dataField: 'email', text: ' Email' },
//         { dataField: 'payer_url', text: 'Payer Url' },
//         {
//             dataField: "payer_status",
//             text: "Action",
//             formatter: (cellContent, row, payerList) => {
//                 if (row.payer_status === 'Signed') {
//                     return (
//                         // <button
//                         //     className="btn btn-success btn-xs" Width="95px"
//                         //     style={{ margin: "2px" }}
//                         // >
//                         //     Signed
//                         // </button>

//                         <div className="w-100 text-center mt-2">
                            
                            
//                             {/* return ( */}
//                             <Link to={`/ViewOrganization/${row.payer_id}`}>
//                                 <button
//                                     className="btn btn-success btn-xs" Width="95px"
//                                     style={{ margin: "2px" }}
//                                     onClick={() => getIdPayerList(row.payer_id)}
//                                 >
//                                     View
//                                 </button>

//                             </Link>

//                 {/* ); */}
//                             <Link to='/newPayer'>
//                             {/* <button
//                                 className="btn btn-warning btn-sm" Width="95px" 
//                                 style={{ margin: "2px"}}
                              
//                             >
//                                 Add App Details
//                             </button>  */}

//                             <button className="btn btn-warning btn-sm" Width="70px" 
//                                 style={{ marginRight: "2px"}}><i class="fa fa-plus-circle"></i> App Details</button>
//                             {/* <Button variant="warning" style={{ minwidth: "5px"  }}> <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon> Add Payer Details</Button>  */}
//                             </Link>
                           
//                         </div>


//                     );

//                 }
//                 else if (row.payer_status === 'Rejected') {
//                     return (
//                         <button
//                             className="btn btn-danger btn-xs" Width="95px"
//                             style={{ margin: "2px" }}
//                         >
//                             Rejected
//                         </button>


//                     );

//                 }
//                 else if (row.payer_status === 'Signup') {
//                     return (
//                     <div className="w-100 text-center mt-2">
                       
//                         <button
//                             onClick={() => window.open(`http://localhost/devportal/login.php?id=${row.payer_signed_status_id}`, 'name', 'height=500,width=850')}
//                             className="btn btn-primary btn-xs" Width="95px"
//                             style={{ margin: "2px" }}
//                         >
//                             Signup
//                         </button>
                       

//                         <Link to={`/ViewOrganization/${row.payer_id}`}>
//                                 <button
//                                     className="btn btn-success btn-xs" Width="95px"
//                                     style={{ margin: "2px" }}
//                                     onClick={() => getIdPayerList(row.payer_id)}
//                                 >
//                                     View
//                                 </button>

//                             </Link>
//                             </div>
//                     );
                    
//                 }
//             },
//         },
//         {
//             dataField: "remove",
//             text: "Status",
//             formatter: (cellContent, row) => {
//                 return (
//                    <div>
//                         {row.payer_status}
//                    </div>
                            
                       
//                 );
//             },
//         },


//     ]
    
    
//     function getIdPayerList(value) {
//         fetch(process.env.REACT_APP_BASE_URL+`/api/organization/getOrganisationByViewId/${value}`, {
//             method: 'get'

//         }).then((result) => {
//             result.json().then((resp) => {

//                 console.log(resp, "padma")
//                 console.log(resp.data, "padma")
//             })
//         })
//             .catch(error => {

//                 console.log(error.data)
//             });

//     }
   
    
//     const pagination = paginationFactory({
//         page: 1,
//         sizePerPage: 5,
//         lastPageText: '>>',
//         firstPageText: '<<',
//         nextPageText: '>',
//         prePageText: '<',
//         showTotal: true,
//         alwaysShowAllBtns: true,
//         onPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         },
//         onSizePerPageChange: function (page, sizePerPage) {
//             console.log('page', page)
//             console.log('sizePerPage', sizePerPage)
//         }
//     })

//     return (
//         <Container fluid="md">


//             <Row>
              
//                 <Col md={12} style={{ textAlign: "left" }}>
//                 <Card style={{ width: "100%", align: "center" }}>
//                         <h3 className='text-center mb-3 heading'>My Payer List </h3>
//                         <center><hr className='heading_line'></hr></center>

//                         {/* <Card.Header as="h5" style={{ backgroundColor: "blue", color: "#ffffff"}}>My Payer List</Card.Header> */}
//                         <Card.Body>
//                             {/* <div className="w-100 text-center mt-2">
//                                 <Link to='/newPayer'><Button variant="warning" style={{ marginLeft: "70%" }}>
//                                     <FontAwesomeIcon icon={faPlusCircle}  > </FontAwesomeIcon>  Add App  Details</Button> </Link>
//                             </div> */}
//                             <br></br>
//                             <div className="container-fluid p-3">
//                                 <BootstrapTable bootstrap4 keyField='id'
//                                     columns={columnsList}
//                                     data={payerList}
//                                     pagination={pagination}
//                                     filter={filterFactory()}
//                                 />
//                             </div>
//                         </Card.Body>
//                     </Card>
//                 </Col>
//             </Row>

//         </Container >
//     );

// }

// export default SearchPayer;



