import React from 'react'


const hstyle1 = {
  "border": '-100px solid white',
  "padding": "10px",
  "margin": "6px",
  "marginTop":"20px"
};

const textStyle = {
  "color": "#ffff"
}

const btn3 = {
  "backgroundColor": "#e36821", 
  "text": "#ffff",
  "padding": "10px 24px;",
  "borderRadius": "6px",
  "border":"none",
  "height":"30px"
  // "float":"left"
}
const btn_a = {
	"text": "#000",
  "color":"white"
}
const explore_style = {
  "backgroundColor": "#1e468e", 
  "height": "250px",
  "width": "100%",
  "display": "inline-table",
}
const explore_container_style = {
  "paddingLeft": "250px", 
  "paddingRight": "0px",
  "paddingTop": "60px",
  "paddingbottom": "20px",
  "display": "inline-table",
}

const footer_style = {
  "paddingLeft": "250px", 
  "paddingRight": "0px",
  "paddingTop": "20px",
}

function Footer() {
  return (
    <div>
		<div style={explore_style}>
			<div style={explore_container_style}>
				<div style={hstyle1}>
					<div style={textStyle}>
					  <h1>Explore How P2P Hub Can Help You!</h1>
					  <h4>Our Customer delight teams will be happy to help you get started with Payer to Payer Hub.</h4>
					  <h4>Drop us note in the form to schedule demo</h4>
					</div>
					<div>
					  <button style={btn3}><a style={btn_a} href="https://health-chain.io/contact-us/" target="_blank">Send us a Message</a></button>
					</div>
				</div>
			</div>
		</div>
		<div style={footer_style}>
			<p> &copy; Copyright 2022 |  <a href="https://health-chain.io" target="_blank">health-chain.io</a> | All Rights Reserved | <a href="/PrivacyPolicy" 
      target="_blank">Privacy Policy</a> | <a href="/TermsOfUse" target="_blank">Terms of Use </a>
       | <a href="https://health-chain.io/contact-us/" target="_blank">Get Help ?</a></p>
		</div>
    </div>
    )
  }
export default Footer;


